import { useParams } from '@solidjs/router';
import { createMemo, createSignal, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import { PhotosEditor } from '~/components/photos';
import { toast } from '~/components/ui';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { getFileAllUrl } from '~/utils/file';
import type { Component } from 'solid-js';
import type { Photo } from '~/components/photos';

export const UnitListingPhotosTab: Component = () => {
  const { t } = useLocalization();
  const params = useParams<{ listingId: `${number}` }>();
  const { unitListingDetail, addUnitListingImage, updateUnitLising } = useUnitListings();

  const [orderImages, setOrderImages] = createSignal<Photo[]>();
  const [hasOrderChanged, setHasOrderChanged] = createSignal<boolean>(false);

  const images = createMemo(() => {
    return unitListingDetail()?.images?.map((i) => ({ id: i.fileId || '', url: getFileAllUrl(i.signedUrl as string) })) || [];
  });

  const handleUpload = async (files: File[]) => {
    if (params.listingId == null) return;
    const arrFiles = [...files];
    const res = await addUnitListingImage(params.listingId, arrFiles);

    const addFiles = Object.entries(res || {}).map(([, value]) => ({
      fileId: value?.fileId,
    }));

    const param = {
      images: [...(unitListingDetail()?.images || []), ...addFiles],
    };
    await updateUnitLising(param, params.listingId);
  };

  const handleDelete = async (fileId: string) => {
    if (params.listingId == null) return;
    const images = unitListingDetail()?.images?.filter((item) => item.fileId !== fileId);
    await updateUnitLising({ images }, params.listingId);
  };

  const handleChange = (images: Photo[] | undefined) => {
    if (images) {
      setOrderImages(images);
    }
  };

  const handleSave = async () => {
    const orderedImages = orderImages();
    if (!orderedImages) return;

    const images = orderedImages.map((item, index) => ({
      fileId: item.id,
      signedUrl: item.url,
      default: index === 0,
    }));

    try {
      await updateUnitLising({ images }, params.listingId);
      toast.success('Operation successful');
      setHasOrderChanged(false);
    } catch (error) {
      toast.error('Failed to save photo arrangement' + error);
    }
  };

  return (
    <Panel
      title={
        <div>
          <div>{t('Unit listing photos')}</div>
          <p class="text-xs font-normal normal-case text-text-level03">{t('Please drag the image to reorder')}</p>
        </div>
      }>
      <PhotosEditor
        photos={images()}
        onUpload={handleUpload}
        onDelete={handleDelete}
        onChange={handleChange}
        onOrderChange={setHasOrderChanged}
      />
      <Show when={hasOrderChanged()}>
        <div class="mb-8 flex justify-end pr-8">
          <Button color="primary" onClick={handleSave}>
            {t('Save photo arrangement')}
          </Button>
        </div>
      </Show>
    </Panel>
  );
};
