import { Select } from '~/components/ui/Select';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';

export interface CategorySelectorProps {
  onChange: (value: string) => void;
  value?: string;
  options?: { label: string; value: string }[];
  class?: string;
}

export const CategorySelector = (props: CategorySelectorProps) => {
  const { t } = useLocalization();

  return (
    <div class={cn('inline-flex basis-[30%] items-center gap-3 [&>div]:grow', props.class)}>
      <span class="text-sm text-text-level03">{t('Category')}:</span>
      <Select
        popoverClass="max-w-[--reference-width] [&>li>span]:block [&>li>span]:truncate [&>li]:overflow-hidden"
        options={props.options ?? []}
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  );
};
