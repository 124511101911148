import { A } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import IconBook from '~/assets/images/work-order/book.svg?component-solid';
import { Button, LinkButton } from '~/components/common/Buttons';
import IconField from '~/components/common/IconField';
import { MaintenanceRequestStatus } from '~/components/maintenance-requests/MaintenanceRequestStatus';
import { IconHash, IconClock, Skeleton, IconMessage } from '~/components/ui';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import { ConvertToWorkOrderModal } from '~/pages/maintenance-request/maintenance-request-details/components/ConvertToWorkOrderModal';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';
import type { MaintenanceRequestDto, MaintenanceRequestListDto, HydratedWorkOrderDto, WorkOrderListDto } from '~/swagger/Api';

export interface MaintenanceItemSummaryProps {
  currentPage: 'workOrder' | 'maintenanceRequest';
  loading?: boolean;
  maintenanceRequest?: MaintenanceRequestDto | MaintenanceRequestListDto;
  workOrder?: HydratedWorkOrderDto | WorkOrderListDto;
}

export const MaintenanceWorkOrderSummary: Component<MaintenanceItemSummaryProps> = (props) => {
  const { t } = useLocalization();
  const [showConvertToWorkOrder, setShowConvertToWorkOrder] = createSignal<boolean>(false);
  const { workOrder } = useWorkOrders();

  const isWorkOrderPage = () => props.currentPage === 'workOrder';
  const isMaintenanceRequestPage = () => props.currentPage === 'maintenanceRequest';

  const getDisplayItem = () => {
    return isWorkOrderPage() ? props.maintenanceRequest : isMaintenanceRequestPage() ? props.workOrder : undefined;
  };

  const showConvertButton = () => {
    return isMaintenanceRequestPage() && !props.workOrder;
  };

  const getStatus = () => {
    return getDisplayItem()?.status;
  };

  const getLastUpdateDate = () => {
    const displayItem = getDisplayItem();
    return (displayItem as WorkOrderListDto)?.updated ?? (displayItem as MaintenanceRequestListDto)?.lastUpdated;
  };

  const getReference = () => {
    return (getDisplayItem() as WorkOrderListDto)?.reference;
  };

  const getCreatedDate = () => {
    return getDisplayItem()?.created;
  };

  const getChatId = () => {
    return (getDisplayItem() as HydratedWorkOrderDto)?.chatId ?? (getDisplayItem() as HydratedWorkOrderDto)?.groupChatId;
  };

  const getTitle = () => {
    return getDisplayItem()?.title ?? (isMaintenanceRequestPage() ? t('Work order') : t('Maintenance request'));
  };

  return (
    <>
      <div class="divide-y divide-partingline overflow-hidden rounded-lg border border-partingline bg-white">
        <div class="px-5 py-4">
          <div class="flex items-center gap-1 text-lg font-semibold text-title-gray">
            {getTitle()}
            <MaintenanceRequestStatus status={getStatus() ?? 'undefined'} />
          </div>
          <Show when={!!getLastUpdateDate()}>
            <div class="flex items-center gap-1 text-xs text-text-level03">
              {t('Last update')}:
              <Show when={!props.loading} fallback={<Skeleton class="h-4 w-32" />}>
                <span>{dateFormat(t('MM/DD/YYYY hh:mm A'), getLastUpdateDate() ?? emptyPlaceholder)}</span>
              </Show>
            </div>
          </Show>
          <div class="flex items-center gap-1 text-sm text-text-level03">
            <IconBook class="-ml-0.5 size-4" />
            {t('Reference')}:
            <Show when={!workOrder.loading} fallback={<Skeleton class="ml-2 w-16 rounded-full" />}>
              <div class="text-text-level01">#{workOrder()?.reference}</div>
            </Show>
          </div>
        </div>
        <div class="flex w-full flex-row flex-wrap gap-5 p-4 lg:flex-col">
          <Show when={!props.loading && getDisplayItem()}>
            <Show when={getReference()}>
              <IconField
                name={t('Reference number')}
                loading={props.loading}
                value={<div>#{getReference() || emptyPlaceholder}</div>}
                src={IconHash}
              />
            </Show>
            <IconField
              name={t('Created at')}
              loading={props.loading}
              value={dateFormat(t('MM/DD/YYYY hh:mm A'), getCreatedDate())}
              src={IconClock}
            />
          </Show>

          <Show when={!props.loading}>
            <Show when={getDisplayItem()}>
              <div class="h-px w-full bg-input-border" />

              <A
                href={`/${isMaintenanceRequestPage() ? 'maintenance/work-orders' : 'maintenance/maintenance-request'}/${
                  getDisplayItem()?.id
                }`}
                class="mx-auto flex cursor-pointer items-center gap-2 text-sm capitalize text-link">
                {isMaintenanceRequestPage() ? t('Work order') : t('Maintenance request')}
                <IconViewArrow class="size-5" />
              </A>
            </Show>
          </Show>

          <Show when={showConvertButton()}>
            <div class="my-0.5 text-center text-xs text-text-level03">{t('Click the button below to convert')}</div>
            <div class="h-px w-full bg-input-border" />

            <Button
              onClick={() => setShowConvertToWorkOrder(true)}
              variant="outlined"
              class="mx-auto max-w-fit text-base font-medium"
              color="primary">
              {t('Convert to work order')}
            </Button>
          </Show>

          <Show when={getChatId()}>
            <LinkButton
              href={`/${
                isMaintenanceRequestPage()
                  ? `maintenance/work-orders/${getDisplayItem()?.id}/communications`
                  : `maintenance/maintenance-request/${getDisplayItem()?.id}/communications`
              }`}
              variant="outlined"
              class="flex w-full items-center justify-center text-base font-medium"
              color="primary">
              <IconMessage class="size-5" />
              {isMaintenanceRequestPage() ? t('Work order chat') : t('Maintenance chat')}
            </LinkButton>
          </Show>
        </div>
      </div>
      <Show when={isMaintenanceRequestPage()}>
        <ConvertToWorkOrderModal visible={[showConvertToWorkOrder, setShowConvertToWorkOrder]} />
      </Show>
    </>
  );
};
