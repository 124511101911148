import { getLeaseFullname, leasePeriodString } from '~/components/leases/utils';
import { LevelLeaseTitle } from '~/components/search-select/LevelSearchComponents/LevelLeaseTitle';
import { LevelPortfolioTitle } from '~/components/search-select/LevelSearchComponents/LevelPortfolioTitle';
import { LevelPropertyTitle } from '~/components/search-select/LevelSearchComponents/LevelPropertyTitle';
import { LevelUnitTitle } from '~/components/search-select/LevelSearchComponents/LevelUnitTitle';
import { LevelSearch } from '~/swagger/Api';
import type { PortfolioDto, PropertyDto, UnitDto, HydratedLeaseDto } from '~/swagger/Api';

export const searchLevels = [
  {
    type: LevelSearch.Portfolio,
    renderMainSlot: (item: PortfolioDto) => <LevelPortfolioTitle feedbackIconReadonly portfolio={item} />,
    childItems: 'properties',
    renderSelected: (item: PortfolioDto) => item.name,
  },
  {
    type: LevelSearch.Property,
    renderMainSlot: (item: PropertyDto) => <LevelPropertyTitle feedbackIconReadonly property={item} />,
    childItems: 'units',
    renderSelected: (item: PropertyDto) => item.displayName,
  },
  {
    type: LevelSearch.Unit,
    renderMainSlot: (item: any) => <LevelUnitTitle unit={item} />,
    childItems: 'leases',
    renderSelected: (item: UnitDto) => item.name,
  },
  {
    type: LevelSearch.Lease,
    renderMainSlot: (item: HydratedLeaseDto) => <LevelLeaseTitle feedbackIconReadonly lease={item} />,
    childItems: 'tenants',
    renderSelected: (item: HydratedLeaseDto) => `${getLeaseFullname(item)}(${leasePeriodString(item)})`,
  },
];
