import { UseCase } from '~/pdfsigner/usecases/useCase';
import { propertiesRepository } from '~/repositories/propertiesRepository';
import type { PropertyDto } from '~/swagger/Api';

export class BatchGetPropertiesUseCase extends UseCase {
  public async runLogic(propertyIds?: string[]) {
    let properties: PropertyDto[] = [];
    if (propertyIds && propertyIds.length > 0) {
      properties = await propertiesRepository.batchGetPropertyByIds(propertyIds);
    }
    this.state.properties = properties;
  }
}
