import { Presenter } from './Presenter';
import type { PdfState } from '~/pdfsigner/usecases/state/pdfAppState';
import type { Annotation } from '~/pdfsigner/usecases/types/annotation';

type PresentableCurrentAnnotation = {
  selectedAnnotationType?: string;
  selectedAnnotation?: Annotation;
};

export class CurrentAnnotationPresenter extends Presenter<PresentableCurrentAnnotation> {
  createModel(state: PdfState): PresentableCurrentAnnotation {
    return {
      selectedAnnotationType: state.selectedAnnotationType,
      selectedAnnotation:
        state.selectedAnnotation &&
        ({
          ...state.selectedAnnotation,
        } as Annotation),
    };
  }
}
