import { Show, createEffect, createSignal, onCleanup, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { useLocalization } from '~/contexts/global';
import type { ComponentProps, JSX, ValidComponent } from 'solid-js';
import type { Icon } from '~/components/ui';
import type { Merge } from '~/utils/types';

export const Loader: Icon = (props) => (
  <svg viewBox="0 0 260 100" fill="currentColor" fill-opacity={0.3} xmlns="http://www.w3.org/2000/svg" {...props}>
    <style>{`
      @keyframes is-loader-move {
        0% { transform: none; opacity: 0 }
        20% { transform: none; opacity: 1 }
        50% { transform: translateY(-50px) scale(0.8) }
        100% { transform: translateY(-50px) scale(0.8); opacity: 0 }
      }
      [data-is-loader-item] { animation: is-loader-move 3s ease-in-out infinite; transform-origin: 50% 50% }
      [data-is-loader-item="1"] { animation-delay: -3s }
      [data-is-loader-item="2"] { animation-delay: -1.5s }
    `}</style>
    <g data-is-loader-item="1">
      <rect x="2" y="52" width="46" height="46" rx="4" />
      <rect x="52" y="52" width="99" height="26" rx="4" />
      <rect x="52" y="80" width="210" height="18" rx="4" />
    </g>
    <g data-is-loader-item="2">
      <rect x="2" y="52" width="46" height="46" rx="4" />
      <rect x="52" y="52" width="99" height="26" rx="4" />
      <rect x="52" y="80" width="210" height="18" rx="4" />
    </g>
  </svg>
);

export const InfiniteScroll = <T extends ValidComponent>(
  props: Merge<
    ComponentProps<T>,
    {
      as?: T;
      threshold?: number;
      children: JSX.Element;
      loader?: JSX.Element;
      endMessage?: JSX.Element;
      ended?: boolean;
      onReachEnd?: () => void;
    }
  >
) => {
  const { t } = useLocalization();
  const [params, rest] = splitProps(props, ['as', 'threshold', 'children', 'loader', 'endMessage', 'ended', 'onReachEnd']);

  const [trigger, setTrigger] = createSignal<Element>();

  createEffect(() => {
    const ref = trigger();
    if (ref == null || params.onReachEnd == null) return;
    const io = new IntersectionObserver(([e]) => e?.isIntersecting && !params.ended && params.onReachEnd?.(), {
      root: ref.parentElement,
      rootMargin: `${params.threshold || 0}px`,
    });
    io.observe(ref);
    onCleanup(() => io.disconnect());
  });

  const child = () => (params.as == null || params.as === 'ol' || params.as === 'ul' ? 'li' : 'div');

  return (
    <Dynamic component={params.as || 'ul'} {...rest}>
      {params.children}
      <Show
        when={params.ended}
        fallback={
          <Dynamic ref={setTrigger} component={child()}>
            {params.loader ?? <Loader class="mx-auto my-2 h-8 opacity-50" />}
          </Dynamic>
        }>
        <Show when={params.endMessage == null} fallback={params.endMessage}>
          <Dynamic component={child()} class="flex items-center gap-3 py-2">
            <hr class="flex-1 border-current opacity-5" />
            <span class="text-sm opacity-50">{t('No more results')}</span>
            <hr class="flex-1 border-current opacity-5" />
          </Dynamic>
        </Show>
      </Show>
    </Dynamic>
  );
};
