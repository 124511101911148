import { useSearchParams } from '@solidjs/router';
import { PortfolioSearch } from '~/components/portfolios';
import { PropertySearch } from '~/components/properties';
import { useLocalization } from '~/contexts/global';

export const PropertySelect = () => {
  const { t } = useLocalization();
  const [searchParams, setSearchParams] = useSearchParams<{
    portfolioIds?: string;
    propertyIds?: string;
  }>();

  const handlePortfolioChange = (value: string[]) => {
    const params = { portfolioIds: '', propertyIds: '' };

    if (value.includes('All')) {
      setSearchParams(params);
    } else {
      params.portfolioIds = value.toString();
      setSearchParams(params);
    }
  };

  const handlePropertyChange = (value: string[]) => {
    const params = {
      portfolioIds: '',
      propertyIds: '',
    };
    if (value.includes('All')) {
      setSearchParams(params);
    } else {
      params.propertyIds = value.toString();
      setSearchParams(params);
    }
  };

  return (
    <>
      <PortfolioSearch
        prefix={t('Portfolio')}
        multiple
        placeholder=""
        defaultSelectedIds={searchParams.portfolioIds ? searchParams.portfolioIds.split(',') : []}
        onSelect={(portfolios) => handlePortfolioChange(portfolios?.map((item) => item.id))}
      />
      <PropertySearch
        prefix={t('Property')}
        multiple
        placeholder=""
        defaultSelectedIds={searchParams.propertyIds ? searchParams.propertyIds.split(',') : []}
        onSelect={(property) => handlePropertyChange(property?.map((item) => item.id))}
      />
    </>
  );
};
