import { isUndefined } from '~/utils/tool';
import { UseCase } from './useCase';
import type { SetAnnotationDataPathKeyRequest } from './types/setAnnotationDataPathKeyRequest';

export class SetAnnotationDataPathKeyUseCase extends UseCase {
  public async runLogic(request: SetAnnotationDataPathKeyRequest) {
    if (request.id != this.state.selectedAnnotation?.id) {
      return;
    }
    if (this.state.selectedAnnotation) {
      this.state.selectedAnnotation.dataPathKey = request.value;
      const pageIndex = this.state.selectedDocument?.pdfPages.findIndex((page) =>
        page.annotations.some((annotation) => annotation.id === request.id)
      );
      if (isUndefined(pageIndex) || pageIndex === -1) return;
      const annotationIndex = this.state.selectedDocument?.pdfPages[pageIndex].annotations.findIndex(
        (annotation) => annotation.id === request.id
      );
      if (isUndefined(annotationIndex) || annotationIndex === -1) return;
      this.state.selectedDocument!.pdfPages[pageIndex].annotations[annotationIndex].dataPathKey = request.value;
    }
  }
}
