import { createContext } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { ParentProps } from 'solid-js';

export const FeedbackGroupContext = createContext(false);

export const FeedbackGroup = (props: ParentProps<{ class: string; style?: Record<string, string> }>) => {
  return (
    <FeedbackGroupContext.Provider value={true}>
      <div class={cn(props.class, 'group')} style={props.style}>
        {props.children}
      </div>
    </FeedbackGroupContext.Provider>
  );
};
