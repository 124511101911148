import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { searchSelectValuePropNames } from '~/components/ui/Select/SearchSelect';
import { useLocalization } from '~/contexts/global';
import { VendorRepository } from '~/repositories/vendorRepository';
import type { EntrySearchProps } from '~/components/ui';
import type { LabeledSearchSelectProps } from '~/components/ui/Select/SearchSelect';
import type { VendorFilter } from '~/repositories/vendorRepository';

const repo = new VendorRepository();

export const VendorSearch = <T extends boolean | undefined = undefined>(
  props: EntrySearchProps<MagicDoor.Api.HydratedVendorDto, T, VendorFilter>
) => {
  const { t } = useLocalization();

  const initialSelectedLoader = async (ids: string[]) => {
    return await repo.batchGetVendorsByIds(ids);
  };

  return (
    <SearchSelect
      fetcher={(query: string | undefined, page: number) => repo.getVendors({ pageSize: 20, ...props.filter, page, search: query })}
      placeholder={t('Search vendors')}
      renderSelected="name"
      renderItem={props.renderItem || ((item) => item.name)}
      initialSelectedLoader={initialSelectedLoader}
      {...props}
    />
  );
};

export const LabeledVendorSearch = <T extends boolean | undefined = undefined>(
  props: LabeledSearchSelectProps<MagicDoor.Api.HydratedVendorDto, T>
) => {
  const [searchProps, labeledProps] = splitProps(props, searchSelectValuePropNames);

  // TODO: get entries by ids???

  return (
    <LabeledGroup {...labeledProps}>
      <VendorSearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.(
            (Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string,
            selected
          );
        }}
      />
    </LabeledGroup>
  );
};
