import { A } from '@solidjs/router';
import { For } from 'solid-js';
import { LatestUpdatedAt } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { statusFilters } from './statusFilters';
import type { Component } from 'solid-js';

export const MaintenanceRequestStatusFilterTabs: Component<{ current?: keyof MagicDoor.Api.MaintenanceRequestsStatsDto }> = (props) => {
  const { t } = useLocalization();
  const { maintenanceRequestStats } = useMaintenanceRequests();

  return (
    <div class="flex flex-col rounded-lg border border-partingline bg-white px-5 py-3">
      <div class="flex flex-wrap gap-2" role="tablist">
        <For each={statusFilters}>
          {(item) => (
            <A
              href={`?status=${item.key}`}
              class="relative w-44 flex-1 cursor-pointer rounded-lg bg-gray-level01 from-[#cf82ff] to-[#a126ec] px-4 py-2.5 text-left text-text-level02 transition-colors hover:bg-gray-level02 aria-selected:bg-gradient-to-br aria-selected:text-white"
              aria-selected={item.key === props.current}
              role="tab">
              <span class="block text-sm font-medium">{t(item.label)}</span>
              <span class="mt-1 block text-2xl font-bold">{maintenanceRequestStats()?.[item.key] ?? '-'}</span>
              <item.mark class="absolute bottom-0 right-0 text-black/5" />
            </A>
          )}
        </For>
      </div>
      <LatestUpdatedAt class="-ml-1 mt-2 text-xs" time={maintenanceRequestStats()?.cacheDate ?? ''} />
    </div>
  );
};
