import { UseCase } from '~/pdfsigner/usecases/useCase';
import { leaseRepository } from '~/repositories/leaseRepository';
import { GetCompanyInfoUseCase } from './getCompanyInfoUseCase';
import type { LeaseListFilter } from '~/repositories/leaseRepository';

export class LeasesWithBalancesDueUseCase extends UseCase {
  public async runLogic(filters?: LeaseListFilter) {
    if (!this.state.companyInfo) {
      await new GetCompanyInfoUseCase().execute();
    }
    const finalFilter = { ...filters, balanceDue: true, page: 1, pageSize: 100 };
    const firstResult = await leaseRepository.getLeases(finalFilter);
    const totalPages = firstResult.totalPages;
    if (totalPages === 1) {
      this.state.reports.leasesWithBalancesDueReport = firstResult.items;
      return;
    }
    const promises = [];
    for (let i = 2; i <= totalPages; i++) {
      promises.push(leaseRepository.getLeases({ ...finalFilter, page: i }));
    }
    const otherResults = await Promise.all(promises);
    this.state.reports.leasesWithBalancesDueReport = [...firstResult.items, ...otherResults.flatMap((result) => result.items)];
  }
}
