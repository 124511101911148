import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const TENANTS_URL = '/api/tenants';

function validateTenant(
  tenant: Partial<MagicDoor.Api.HydratedTenantDto | MagicDoor.Api.TenantCreatedDto | MagicDoor.Api.UpdateTenantDto>
): void {
  if (!tenant) throw new ValidationError('Tenant object must be provided');
}

function validateId(tenantId: string): void {
  if (!tenantId) throw new ValidationError(`Tenant ID must be provided`);
}

export type TenantFilter = MagicDoor.UserFilter;

export type TenantPaymentFilter = MagicDoor.Filter;

export class TenantRepository extends BaseRestRepository {
  public async getTenants(filter?: TenantFilter): Promise<MagicDoor.Api.HydratedTenantListPaginationDto> {
    const url = urlWithQuery(TENANTS_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getTenant(tenantId: string): Promise<MagicDoor.Api.HydratedTenantDto> {
    validateId(tenantId);
    const url = `${TENANTS_URL}/${tenantId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getCounts(): Promise<MagicDoor.Api.TenantCountsDto> {
    const url = `${TENANTS_URL}/counts`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async createTenant(tenant: MagicDoor.Api.UpdateTenantDto): Promise<MagicDoor.Api.TenantCreatedDto> {
    validateTenant(tenant);
    const url = TENANTS_URL;
    const response = await this.fetchWithAuth(
      url,
      {
        method: 'POST',
        body: JSON.stringify(tenant),
      },
      false
    );
    return this.getJsonResponse(response);
  }

  public async updateTenant(tenantId: string, tenant: MagicDoor.Api.UpdateTenantDto): Promise<MagicDoor.Api.TenantDto> {
    validateId(tenantId);
    validateTenant(tenant);
    const url = `${TENANTS_URL}/${tenantId}`;
    const response = await this.fetchWithAuth(
      url,
      {
        method: 'PUT',
        body: JSON.stringify(tenant),
      },
      false
    );
    return this.getJsonResponse(response);
  }

  /** Unavailable. use deactivateTenant instead in most cases */
  public async deleteTenant(tenantId: string): Promise<void> {
    validateId(tenantId);
    const url = `${TENANTS_URL}/${tenantId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async deactivateTenant(tenantId: string, forceMoveOutFromLeases: boolean): Promise<void> {
    validateId(tenantId);
    const url = urlWithQuery(`${TENANTS_URL}/${tenantId}/deactivate`, { forceMoveOutFromLeases });
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async portalInvite(tenantId: string): Promise<MagicDoor.Api.PortalInviteResponseDto> {
    validateId(tenantId);
    const url = `${TENANTS_URL}/${tenantId}/portal-invite`;
    const response = await this.fetchWithAuth(url, { method: 'POST' }, false);
    return this.getJsonResponse(response);
  }

  public async portalInviteList(): Promise<MagicDoor.Api.HydratedTenantDto[]> {
    const url = `/api/tenants/portal-invite`;
    const response = await this.fetchWithAuth(url, { method: 'POST' });
    return this.getJsonResponse(response);
  }

  public async changeTenantPassword(tenantId: string, password: string): Promise<void> {
    validateId(tenantId);
    if (!password) throw new ValidationError('Password must be provided');
    const url = `${TENANTS_URL}/${tenantId}/password`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify({ password }),
    });
    if (!response.ok) {
      console.error(await response.json());
    }
  }

  public async getPayments(tenantId: string, filter?: TenantPaymentFilter): Promise<MagicDoor.Api.HydratedTransactionDtoPaginationDto> {
    validateId(tenantId);
    const url = urlWithQuery(`${TENANTS_URL}/${tenantId}/payments`, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getAutoPayments(tenantId: string): Promise<MagicDoor.Api.HydratedTenantAutoPayDto[]> {
    validateId(tenantId);
    const url = `${TENANTS_URL}/${tenantId}/auto-payments`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getPaymentAccounts(tenantId: string): Promise<MagicDoor.Api.PaymentAccountsDto> {
    validateId(tenantId);
    const url = `${TENANTS_URL}/${tenantId}/payment-accounts`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getTenantsByIds(filter: { tenantIds?: string[] }): Promise<MagicDoor.Api.HydratedTenantDto[]> {
    const url = urlWithQuery(`${TENANTS_URL}/batch`, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}
