import { A } from '@solidjs/router';
import { mergeProps, splitProps, Show } from 'solid-js';
import { RingLoader } from '~/components/common/Loaders';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

export type ButtonProps = {
  type?: 'submit' | 'reset' | 'button';
  href?: string;
  form?: string;
  variant?: 'outlined' | 'solid' | 'white' | 'text';
  color?: 'white' | 'primary' | 'link' | 'warning';
  rounded?: 'md' | 'lg' | 'full';
  loading?: boolean;
  class?: string;
  disabled?: boolean;
  children: JSX.Element;
  onClick?: (ev?: MouseEvent) => void;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  block?: boolean;
  onDblClick?: (ev?: MouseEvent) => void;
};

export const Button: Component<ButtonProps> = (originProps) => {
  const props = mergeProps(
    {
      type: 'button',
      color: 'primary',
      variant: 'solid',
      rounded: 'lg',
    } as ButtonProps,
    originProps
  );
  const [, tagProps] = splitProps(props, ['type', 'href', 'form', 'variant', 'color', 'rounded', 'loading', 'class']);

  const btnClass = () =>
    cn(
      'relative flex h-fit px-5 py-2.5 text-base leading-5',
      { border: props.variant !== 'text' },
      { 'border-essential-colour': props.color === 'primary' },
      { 'text-blue border-link hover:bg-blue/10': props.color === 'link' },
      { 'text-essential-colour hover:bg-essential-colour/10': props.color === 'primary' },
      { 'border-danger hover:border-danger/90 text-danger': props.variant === 'outlined' && props.color === 'warning' },
      { 'text-white': props.variant === 'solid' || (props.variant === 'outlined' && props.color === 'white') },
      { 'bg-essential-colour hover:bg-essential-colour/90': props.variant === 'solid' && props.color === 'primary' },
      { 'bg-danger hover:bg-danger/90 border-danger': props.variant === 'solid' && props.color === 'warning' },
      { 'bg-white hover:bg-white/90': props.variant === 'white' },
      { 'rounded-md': props.rounded === 'md' },
      { 'rounded-lg': props.rounded === 'lg' },
      { 'rounded-full': props.rounded === 'full' },
      { 'opacity-50 cursor-not-allowed': props.disabled },
      { 'flex h-[38px] items-center py-0': props.size === 'sm' },
      { 'flex h-[28px] items-center py-0 px-3 text-sm rounded-lg': props.size === 'xs' },
      { 'w-full justify-center': props.block },
      { 'text-danger': props.variant === 'text' && props.color === 'warning' },
      props.class
    );

  const children = () => [
    <span class={cn('flex items-center justify-center gap-2', { invisible: props.loading })}>{props.children}</span>,
    <Show when={props.loading}>
      <RingLoader
        color={props.variant === 'white' ? '#A126EC' : 'white'}
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    </Show>,
  ];

  return (
    <Show
      when={!props.href}
      fallback={
        <A href={props.href || ''} class={btnClass()} role="button" {...tagProps}>
          {children()}
        </A>
      }>
      <button class={btnClass()} type={props.type} form={props.form} {...tagProps}>
        {children()}
      </button>
    </Show>
  );
};
