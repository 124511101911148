import { For, Show } from 'solid-js';
import { PrintButton } from '~/components/common/Buttons';
import { PrintPageHeader } from '~/components/print';
import { hasRender } from '~/components/ui/Table/Table';
import { useCompanies, useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';
import type { IProps } from '~/components/common/Buttons/PrintButton';
import type { TableColumns } from '~/components/ui/Table/Table';
import type { TFunction } from '~/contexts/global';

type ExtraData = {
  label: string;
  value: string;
};

type Caption = {
  title: string;
  extraData?: ExtraData[];
};

type PrintTablePageButtonProps = {
  caption?: Caption;
  table: {
    class?: string;
    rowClass?: (row: any, index: number) => string | undefined | Record<string, string>;
    columns: TableColumns<any>;
    data: any[];
    total?: string;
  };
} & Omit<IProps, 'printContainer'>;

export const PrintTableContainer = (
  props: PrintTablePageButtonProps & {
    t: TFunction;
  }
) => {
  const t = props.t;

  return (
    <div>
      <table class={cn('w-full table-auto border-collapse text-sm', props.table.class)}>
        <Show when={props.caption}>
          <caption>
            <div class="mt-6 text-center text-lg font-medium text-title-gray">{props.caption?.title && t(props.caption?.title)}</div>
            <div class="my-5 flex flex-wrap gap-y-2 text-left text-[12px] text-[#6A6D85]">
              <For each={props.caption?.extraData}>
                {(extraData) => (
                  <div class="w-1/2">
                    {t(extraData.label)}: <span class="text-[#2C3045]">{extraData.value}</span>
                  </div>
                )}
              </For>
            </div>
          </caption>
        </Show>
        <thead class="bg-[#F7ECF8] px-3">
          <tr>
            <For each={props.table.columns}>
              {(col) => <th class={cn('p-3 py-2 text-left font-normal text-[#8C47B7]', col.headerClass)}>{col.title}</th>}
            </For>
          </tr>
        </thead>
        <tbody class="bg-white px-3">
          <For each={props.table.data}>
            {(row, index) => (
              <tr class={cn(props.table.rowClass?.(row, index()))}>
                <For each={props.table.columns}>
                  {(col) => (
                    <td class={cn('p-3 py-2 text-left text-text-level01', col.class)}>{hasRender(col) ? col.render(row) : row[col.key]}</td>
                  )}
                </For>
              </tr>
            )}
          </For>
        </tbody>
      </table>
      <Show when={props.table.total}>
        <div class="flex items-center justify-between border-t border-[#585868] py-1.5 text-xs font-semibold text-title-gray">
          <span>{t('Total')}</span>
          <span>{props.table.total}</span>
        </div>
      </Show>
    </div>
  );
};

export const PrintTablePageButton: Component<PrintTablePageButtonProps> = (props) => {
  const { companies } = useCompanies();
  const { t } = useLocalization();
  return (
    <PrintButton
      {...props}
      printHeader={PrintPageHeader({ companies: companies() }) as Element}
      printContainer={PrintTableContainer({ ...props, t }) as HTMLDivElement}
    />
  );
};
