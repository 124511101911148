import { Show, createMemo, onMount } from 'solid-js';
import IconQuestions from '~/assets/images/settings/rental/questions.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast, IconLoader } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const CompanyProfileSettings = () => {
  const { t } = useLocalization();

  const { companies, updateCompanyProfile } = useCompanies();

  const sections = createMemo<ItemProps[]>(() => [
    {
      field: 'name',
      label: 'Name',
      type: 'string',
    },
    {
      field: 'email',
      label: 'Email',
      type: 'string',
    },
    {
      field: 'address',
      label: 'Address',
      type: 'address',
    },
  ]);

  const onSave = async (data: MagicDoor.Api.UpdateCompanyDto) => {
    await updateCompanyProfile(data);
    toast.success(t('Company information settings saved successfully'));
    companies();
    return;
  };

  onMount(() => companies());

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Company profile')} icon={IconQuestions} />}
        middle={
          <div class="flex flex-col gap-5 p-4">
            <Show when={!companies.loading} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
              <div class="flex flex-col gap-4 p-2 text-left">
                <EditForm
                  sections={sections()}
                  onOk={(data) => onSave(data)}
                  model={
                    {
                      ...companies(),
                      address: companies()?.address || ({} as MagicDoor.Api.AddressDto),
                    } as MagicDoor.Api.UpdateCompanyDto
                  }
                />
              </div>
            </Show>
          </div>
        }
      />
    </>
  );
};
