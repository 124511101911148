import { useParams, Route, Routes } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { RentalApplicationDetails } from '~/components/rental-application/rental-application-details/RentalApplicationDetails';
import { useProperties } from '~/contexts/global';
import { MaintenanceRequestsProvider, ServiceBillProvider } from '~/contexts/local';
import { NotFoundError } from '~/errors';
import { UnitDetails } from '~/pages/units/unit-details';
import { PropertyDetailsRoutes } from './view-components/PropertyDetailsRoutes';

export const PropertyDetailsPage = () => {
  const params = useParams<{ propertyId: string; portfolioId: string }>();
  const { setCurrentId, current, loading } = useProperties();

  setCurrentId(params.propertyId);

  createEffect(() => {
    if (!loading() && current() == null) throw new NotFoundError();
  });

  return (
    <div class="flex w-full flex-col gap-y-4 pb-4 text-xs">
      <MaintenanceRequestsProvider>
        <ServiceBillProvider>
          <Routes>
            <Route path="/units/:unitId/*" component={UnitDetails} matchFilters={{ unitId: /[^addeditaddServiceBill]/ }} />
            <Route path="/application-details/:applicationId/*" component={RentalApplicationDetails} />
            <Route path="/*" component={PropertyDetailsRoutes} />
          </Routes>
        </ServiceBillProvider>
      </MaintenanceRequestsProvider>
    </div>
  );
};
