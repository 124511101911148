import { useNavigate } from '@solidjs/router';
import { createMemo } from 'solid-js';
import IconActicateLease from '~/assets/images/units/acticateLease.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconEditLease from '~/assets/images/units/lease/editLease.svg?component-solid';
import CnMoney from '~/components/common/CnMoney';
import { Empty } from '~/components/common/Empty';
import { Panel } from '~/components/common/Panels';
import { LeasePeriod, LeaseStatus } from '~/components/leases';
import { getDeposit } from '~/components/leases/utils';
import { formatTenantNames } from '~/components/tenants';
import { Table, TableActions } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type PastLeasesProps = {
  class?: string;
  pastLeases: MagicDoor.Api.HydratedLeaseDto[];
  isLoading: boolean;
};

export const PastLeases: Component<PastLeasesProps> = (props) => {
  const { t } = useLocalization();
  const { setLease } = useLease();
  const navigate = useNavigate();

  const columns = createMemo<TableColumns<MagicDoor.Api.HydratedLeaseDto>>(() => [
    {
      title: t('Lease term'),
      render: (item) => (
        <>
          <LeaseStatus lease={item} />
          <LeasePeriod lease={item} />
        </>
      ),
    },
    {
      title: t('Tenants'),
      render: (item) => {
        return <div>{formatTenantNames(item.tenants)}</div>;
      },
    },
    {
      title: t('Rent'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => <span class="text-sm">{currency(item.currentRent)}</span>,
    },
    {
      title: t('Deposit'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => <span class="text-sm">{item.securityDepositRequested ? getDeposit(item) : emptyPlaceholder}</span>,
    },
    {
      title: t('Last payment'),
      render: (item) => <span class="text-sm">{item.lastPaymentDate ? dateFormat('', item.lastPaymentDate) : emptyPlaceholder}</span>,
    },
    {
      title: t('Balances'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => <CnMoney money={item.balance} />,
    },
    {
      title: t('Posted balances'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => <CnMoney money={item.postedBalance} />,
    },
    {
      title: '',
      class: 'relative',
      render: (item) => (
        <TableActions
          actions={[
            {
              icon: IconActicateLease,
              label: t('Reactivate lease'),
              href: `/leasing/leases/${item.id}/reactivate-lease`,
            },
            {
              icon: IconEditLease,
              label: t('Edit lease'),
              href: `/leasing/leases/${item.id}/edit-lease`,
            },
            {
              icon: IconDelete,
              label: t('Delete lease'),
              onClick: () => {
                setLease(item);
                navigate('delete-lease', { scroll: false });
              },
            },
          ]}
        />
      ),
    },
  ]);

  return (
    <Panel title={t('Past leases')} class={props.class}>
      <div class="thinscroll overflow-x-auto">
        <Table
          columns={columns()}
          loading={props.isLoading}
          data={props.pastLeases}
          rowLink={(item) => `/leasing/leases/${item.id}`}
          empty={<Empty description={t('There are no past leases under this unit')} />}
        />
      </div>
    </Panel>
  );
};
