import { createMemo } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm/Form';
import { Section } from '~/components/common/FormSectionTitle';
import { MutiLabeledInputSearch } from '~/components/common/Inputs/MutiLabeledInputSearch';
import { TabNav } from '~/components/common/TabNav';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { TenantSearch } from '~/components/tenants';
import { useLocalization } from '~/contexts/global';
import { useNewRentalApplication } from '~/contexts/local';
import { TenantItem } from '~/pages/leases/add-tenant/TenantsItem';
import { NewApplicantForm } from '~/pages/rental-applications/add-rental-application/NewApplicantForm';
import { RentalBasicInfo } from '~/pages/rental-applications/add-rental-application/RentalBasicInfo';
import { LevelSearch } from '~/swagger/Api';
import type { MutiLabeledInputSearchProps } from '~/components/common/Inputs/MutiLabeledInputSearch';
import type { Variants } from '~/components/search-select/Context';

enum tenantTabs {
  newApplicant = 'new-applicant',
  existingTenant = 'existing-tenant',
}

export const TenantProfile = () => {
  const { t } = useLocalization();
  const { setStore: setContextStore, store, form, validFormItem } = useNewRentalApplication();

  const tabsMemo = createMemo(() => [
    { label: t('New Applicant'), key: tenantTabs.newApplicant, component: NewApplicantForm },
    {
      label: t('Existing Tenant'),
      key: tenantTabs.existingTenant,
      component: () => (
        <FormItem
          placeholder={t('Search for tenants')}
          formFieldName={['tenantId']}
          component={TenantSearch}
          onChangeMethodName="onSelect"
          rules={[{ required: true, message: t('Tenant is required') }]}
          getValueFromEvent={(val) => val?.id}
          onSelect={(item: MagicDoor.Api.HydratedTenantDto) => {
            if (!item?.id) return;
            const currentTenant = item;
            setContextStore('currentTenant', currentTenant);
            form.setFieldsValue({ phone: currentTenant?.phone, email: currentTenant?.email, dateOfBirth: currentTenant?.dateOfBirth });
          }}
        />
      ),
    },
  ]);

  return (
    <>
      <Section required border title={t('Unit information')}>
        <FormItem
          formFieldName="interestedUnitIds"
          rules={[{ required: true, message: t('Unit is required') }]}
          component={LabeledLeveledSearchSelect}
          enabledTypes={[LevelSearch.Unit]}
          multiple
          onChange={(items: any[], variants: Variants) => {
            setContextStore('selectedUnits', items);
            form.setFieldsValue({ interestedUnitIds: variants.ids.unitIds });
            validFormItem('interestedUnitIds');
          }}
          initialSelected={store.selectedUnits}
          label={t('Search unit')}
        />
      </Section>
      <Section required border title={t('Applicant Name')}>
        <TabNav tabs={tabsMemo()} activeTab={store.activeTab} setActiveTab={(key) => setContextStore('activeTab', key)} />
      </Section>
      <Section border title={t('Applicant information')}>
        <RentalBasicInfo />
      </Section>

      <Section border title={t('Apply with other tenants')}>
        <FormItem<MutiLabeledInputSearchProps<MagicDoor.Api.HydratedTenantDto>>
          placeholder={t('Search Tenant')}
          addText="Add Other Tenant"
          formFieldName="applyingWith"
          component={MutiLabeledInputSearch}
          renderSelected={(item) => <TenantItem tenant={item} />}
          onChangeMethodName="onSelect"
          searchComponent={TenantSearch}
          onSelect={(_, items) => {
            setContextStore('selectedApplyWith', items);
          }}
          selectedItems={store.selectedApplyWith}
        />
      </Section>
    </>
  );
};
