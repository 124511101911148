import ConvertToTenantBg from '~/assets/images/rental-application/convert-to-tenant.png';
import { Avatar } from '~/components/common/Avatar';
import { useLocalization } from '~/contexts/global';

interface ConvertToTenantModalProps {
  name: string;
}

export const ConvertToTenantModal = (props: ConvertToTenantModalProps) => {
  const { t } = useLocalization();

  return (
    <div class="w-96">
      <div class="w-full bg-light-pink">
        <img src={ConvertToTenantBg} class="h-24 w-full" alt="ConvertToLeaseBg" />
      </div>
      <div class="p-5">
        <div class="mb-2 flex h-14 w-full items-center gap-2 rounded-md bg-input px-3">
          <Avatar name={props.name} />
          <span class="text-sm font-medium text-text-level01">{props.name}</span>
        </div>
        {t("Do you want to convert the applicant '{name}' into a tenant?", {
          name: `${props.name}`,
        })}
      </div>
    </div>
  );
};
