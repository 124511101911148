import { onMount, createEffect } from 'solid-js';
import { createStore } from 'solid-js/store';
import { createMagicDoorContext } from '~/contexts/utils';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { PortfolioRepository } from '~/repositories/portfolioRepository';
import { TenantRepository } from '~/repositories/tenantRepository';

const leaseRepo = new LeaseRepository();
const portfolioRepo = new PortfolioRepository();
const tenantRepo = new TenantRepository();

export interface PortfolioWithCount extends MagicDoor.Api.HydratedPortfolioDto {
  unitCount: number;
}

export interface LeaseWithName extends MagicDoor.Api.HydratedLeaseDto {
  name?: string;
}

export const [TargetsProvider, useTargets] = createMagicDoorContext('Targets', () => {
  const [store, setStore] = createStore<{
    properties: MagicDoor.Api.PropertyDto[];
    error: string;
    portfolios: PortfolioWithCount[];
    overrides: any[];
    portfoliosMap: Record<string, any>;
    propertiesMap: Record<string, any>;
    unitsMap: Record<string, any>;
    leasesMap: Record<string, any>;
    leases: LeaseWithName[];
    leaseWithoutName: MagicDoor.Api.HydratedLeaseDto[];
    tenants: MagicDoor.Api.HydratedTenantDto[];
    tenantsMap: Record<string, any>;
  }>({
    tenantsMap: {},
    tenants: [],
    leaseWithoutName: [],
    error: '',
    properties: [],
    portfolios: [],
    overrides: [],
    portfoliosMap: {},
    propertiesMap: {},
    unitsMap: {},
    leasesMap: {},
    leases: [],
  });

  async function getPortfolios() {
    try {
      const res = await portfolioRepo.getPortfolios();
      const properties: MagicDoor.Api.PropertyDto[] = [];
      const portfolios: PortfolioWithCount[] = [];
      const portfoliosMap: Record<string, any> = {};
      const propertiesMap: Record<string, any> = {};
      res.forEach((item) => {
        const portfolio = { ...item, unitCount: 0 };
        item.properties?.forEach((property) => {
          portfolio.unitCount += property?.unitCount || 0;
          propertiesMap[property.id] = { ...property };

          properties.push(property);
        });
        portfoliosMap[portfolio.id] = { ...portfolio };

        portfolios.push(portfolio);
      });

      setStore({ portfolios, properties, portfoliosMap, propertiesMap });
    } catch (error: any) {
      setStore({ error: error.message });
    }
  }

  async function getLeases() {
    try {
      const res = await leaseRepo.getLeases();
      setStore({ leaseWithoutName: res.items });
    } catch (error: any) {
      setStore({ error: error.message });
    }
  }

  async function getTenants() {
    const response = await tenantRepo.getTenants();
    const tenantsMap: Record<string, any> = {};
    const list: MagicDoor.Api.HydratedTenantDto[] = [];
    response.items.forEach((v) => {
      const item = {
        ...v,
        name: v.firstName + ' ' + v.lastName,
      };
      tenantsMap[v.id] = item;
      list.push(item);
    });
    setStore({ tenants: list, tenantsMap });
  }

  createEffect(() => {
    const leases: LeaseWithName[] = [];

    const propertiesMap = store.propertiesMap;
    const unitsMap = store.unitsMap;
    const leasesMap: Record<string, any> = {};
    store.leaseWithoutName.forEach((item) => {
      const name = propertiesMap[item.propertyId]?.name + ', ' + unitsMap[item.unitId]?.name;
      leasesMap[item.id] = { name, ...item, property: propertiesMap[item.propertyId], unit: unitsMap[item.unitId] };
      const target = {
        ...item,
        name: name,
      };
      leases.push(target);
    });
    setStore({ leases, leasesMap });
  });

  onMount(() => {
    getPortfolios();
  });

  const setData = (data: any) => {
    setStore(data);
  };

  return {
    setData,
    getTenants,
    getLeases,
  };
});
