import { createMemo, createSignal, onMount, Show } from 'solid-js';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import { PropertyTitle } from '~/components/properties';
import { TransactionStatus } from '~/components/transactions';
import { ListView, TableActions, confirm, toast } from '~/components/ui';
import { useLocalization, usePortfolios } from '~/contexts/global';
import { useOwners, useOwnerTransfers } from '~/contexts/local';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { ListViewProps, FilterItems } from '~/components/ui';
import type { OwnersTransfersFilter } from '~/repositories/ownersRepository';

export type FilterValue = Omit<OwnersTransfersFilter, 'type' | 'page' | 'pageSize'>;

export const useFilter = (type: OwnersTransfersFilter['type']) => {
  const { t } = useLocalization();
  const { portfolios } = usePortfolios();
  const { filtered: owners, setFilter: onFetchOwners } = useOwners();
  const [portfolioId, setPortfolioId] = createSignal<string>();
  const [filterValue, setFilterValue] = createSignal<FilterValue>();

  const { setFilter } = useOwnerTransfers();

  const handleFilterChange = ({ page, pageSize, filter }: { page: number; pageSize: number; filter: FilterValue }) => {
    setFilter({ page, pageSize, type, ...filter });
  };

  const allOption = createMemo(() => ({ label: t('All'), value: undefined }));

  const filterItems = createMemo(
    () =>
      [
        {
          type: 'select',
          key: 'ownerId',
          label: t('Owner'),
          filterable: true,
          options: async () => {
            if (owners.loading) return new Promise<[]>(() => void 0);

            return [
              allOption(),
              ...(owners()
                ?.items?.filter((owner) => owner.firstName || owner.lastName)
                .map((owner) => ({ label: `${owner.firstName || ''} ${owner.lastName || ''}`, value: owner.id })) ?? []),
            ];
          },
          onChange: (value: string) => {
            setFilterValue({ ownerId: value });
          },
        },
        {
          type: 'select',
          key: 'portfolioId',
          label: t('Portfolio'),
          filterable: true,
          options: async () => {
            if (portfolios.loading) return new Promise<[]>(() => void 0);
            return [allOption(), ...(portfolios()?.map((item) => ({ value: item.id, label: item.name })) ?? [])];
          },
          onChange: (value: string) => {
            setFilterValue({ propertyId: undefined });
            setPortfolioId(value);
          },
        },
        {
          type: 'select',
          key: 'propertyId',
          label: t('Property'),
          filterable: true,
          options: async () => {
            if (portfolios.loading) return new Promise<[]>(() => void 0);

            if (!portfolioId()) {
              const opts = portfolios()?.flatMap((item) => item.properties?.map((item) => ({ value: item.id, label: item.name })) ?? []);
              return [allOption(), ...(opts ?? [])];
            }
            const portfolio = portfolios()?.find((item) => item.id === portfolioId());
            const opts = portfolio?.properties?.map((item) => ({ value: item.id, label: item.name })) ?? [];
            return [allOption(), ...opts];
          },
          onChange: (value: string) => {
            const portfolio = portfolios()?.find((item) => item.properties?.some((prop) => prop.id === value));
            setFilterValue({ portfolioId: portfolio?.id });
          },
        },
      ] as FilterItems<FilterValue>
  );

  const handleFilterReset = () => {
    setFilterValue({});
  };

  onMount(() => {
    onFetchOwners({});
  });

  return { filterItems, handleFilterReset, handleFilterChange, filterValue };
};

export const FundsListView = (
  props: Omit<
    ListViewProps<MagicDoor.Api.OwnerTransferListDto, FilterValue>,
    'filterValue' | 'filterItems' | 'onFilterReset' | 'columns'
  > & { type: OwnersTransfersFilter['type'] }
) => {
  const { t } = useLocalization();

  const { ownerTransfers, deleteOwnerTransfer, refetch } = useOwnerTransfers();
  const { filterItems, handleFilterReset, handleFilterChange, filterValue } = useFilter(props.type);

  const getProperty = (propertyId?: string) => {
    return ownerTransfers()?.properties?.find((property) => property.id === propertyId);
  };

  onMount(() => {
    handleFilterChange({ page: 1, pageSize: 50, filter: {} });
  });

  const onDelete = async (id: string) => {
    confirm({
      content: (
        <p class="mt-4 px-6">
          {t(props.type === 'distribution' ? 'Confirm to delete the owner distribution' : 'Confirm to delete the owner contribution')} ?
        </p>
      ),
      title: t(props.type === 'distribution' ? 'Delete owner distribution' : 'Delete owner contribution'),
      async onResolve(confirmed) {
        if (!confirmed) return;

        try {
          await deleteOwnerTransfer(id);
        } catch (error) {
          console.error(error);
        }
        refetch();
        toast(t('Owner contribution deleted successfully'), 'success');
      },
    });
  };

  return (
    <ListView
      {...props}
      filterValue={filterValue()}
      filterItems={filterItems()}
      loading={ownerTransfers.loading}
      onChange={handleFilterChange}
      onFilterReset={handleFilterReset}
      searchValue={undefined}
      searchPlaceholder={undefined}
      onRowClick={props.onRowClick}
      columns={[
        {
          title: t('Date'),
          render: (item) => dateFormat('MM/DD/YYYY', item.created),
        },
        {
          title: t('Status'),
          render: (item) => (
            <Show
              when={ownerTransfers()?.transactions?.find((transaction) => transaction.id === item.transactionId)}
              fallback={emptyPlaceholder}>
              <TransactionStatus
                status={
                  ownerTransfers()?.transactions?.find((transaction) => transaction.id === item.transactionId)
                    ?.status as MagicDoor.Api.TransactionStatus
                }
              />
            </Show>
          ),
        },
        {
          title: t('Property'),
          render: (item) => (
            <Show when={getProperty(item.propertyId)} fallback={emptyPlaceholder}>
              <PropertyTitle class="h-9 [&>img]:size-9 [&_h4]:text-xs [&_h4]:font-medium" property={getProperty(item.propertyId)} />
            </Show>
          ),
        },
        {
          title: t('Memo'),
          render: (item) => ownerTransfers()?.bills?.find((bill) => bill.id === item.billId)?.memo ?? emptyPlaceholder,
        },
        {
          title: t('Owner'),
          render: (item) => {
            const owner = ownerTransfers()?.owners?.find((owner) => owner.id === item.ownerId);
            return owner?.firstName || owner?.lastName ? `${owner.firstName || ''} ${owner.lastName || ''}` : emptyPlaceholder;
          },
        },
        {
          title: t('Amount'),
          render: (item) => (item.amount ? currency(item.amount) : emptyPlaceholder),
        },
        ...[
          {
            headerClass: 'w-[6%]',
            class: 'text-sm',
            render: (item: MagicDoor.Api.OwnerTransferListDto) => (
              <TableActions
                actions={[
                  {
                    label: t('Delete'),
                    icon: IconDelete,
                    onClick: () => onDelete(item.id!),
                  },
                ]}
              />
            ),
          },
        ],
      ]}
    />
  );
};
