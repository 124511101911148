import { Show } from 'solid-js';
import IconAiBot from '~/assets/images/chat/aiChatBot.svg';
import IconDocument from '~/assets/images/common/line-document.svg?component-solid';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import IconNotebook from '~/assets/images/maintenance/note.svg?component-solid';
import WorkOrderBg from '~/assets/images/work-order/workOrderBg.png';
import WorkOrderLine from '~/assets/images/work-order/workOrderLine.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { Button, LinkButton } from '~/components/common/Buttons';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import IconField from '~/components/common/IconField';
import { Panel } from '~/components/common/Panels';
import { ListFileThumbnailWithPreview } from '~/components/file-attachments/ListFileThumbnailWithPreview';
import { MagicTagsList } from '~/components/magic-tags';
import { MaintenanceRequestUrgency } from '~/components/maintenance-requests';
import {
  IconBriefcase,
  IconCalendar,
  IconFlag,
  IconLockKeyhole,
  IconLockKeyholeOpen,
  IconSquarePen,
  IconWrench,
  Skeleton,
  toast,
  confirm,
} from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { AIProcessing, AIProcessed } from '~/pages/maintenance-request/list-maintenance-request/list-components/StatusColumn';
import { StopAiBanner } from '~/pages/maintenance-request/maintenance-request-details/components/StopAiBanner';
import { emptyPlaceholder, getMaintenanceStatusOptions } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';

const PermissionToEnter: Component<{ permissionToEnter?: boolean }> = (props) => {
  const { t } = useLocalization();
  return (
    <Show when={props.permissionToEnter != null} fallback={<Skeleton class="h-9" />}>
      <Show
        when={props.permissionToEnter}
        fallback={
          <div class="flex items-center gap-2 rounded-md bg-current-alpha px-4 py-2 text-sm font-medium uppercase text-light-warning">
            <IconLockKeyhole class="size-5" />
            {t('Entry not granted')}
          </div>
        }>
        <div class="flex items-center gap-2 rounded-md bg-current-alpha px-4 py-2 text-base font-medium uppercase text-light-green">
          <IconLockKeyholeOpen class="size-5" />
          {t('Entry granted')}
        </div>
      </Show>
    </Show>
  );
};

export const MaintenanceOverviewTab: Component = () => {
  const { t } = useLocalization();

  const { maintenanceRequest, updateMaintenanceRequestStatus, maintenanceRequestActions } = useMaintenanceRequests();

  const furnitureList = [
    {
      key: 'TV',
      value: 'Samsung #SZA8291',
    },
    {
      key: 'Refrigerator',
      value: 'Samsung #AWS291',
    },
    {
      key: 'Gate Code',
      value: '#4234',
    },
  ];

  const handleStatusChange = async (value: string) => {
    const run = async () => {
      if (!value) return;
      await updateMaintenanceRequestStatus(maintenanceRequest()?.id || '', value as MagicDoor.Api.MaintenanceRequestStatus);
      toast(t('Maintenance Request status updated successfully'), 'success');
    };
    if (maintenanceRequest()?.status === 'aiProcessing' && value !== 'aiProcessing') {
      confirm({
        title: t('Modify Status'),
        content: t('After the status changes, the AI runbook will automatically close.'),
        async onResolve(confirmed) {
          if (!confirmed) {
            maintenanceRequestActions.mutate((prev) => prev && { ...prev });
            return;
          }
          run();
        },
      });
    } else {
      run();
    }
  };

  return (
    <div class="flex flex-col gap-5">
      <StopAiBanner />
      <Panel
        title={
          <Show when={!maintenanceRequest.loading} fallback={<Skeleton class="h-7 w-64" />}>
            <div class="lg:min-w-64">{maintenanceRequest()?.title}</div>
          </Show>
        }
        description={
          <Show when={!maintenanceRequest.loading} fallback={<Skeleton class="h-4 w-72 border-t-2 border-white" />}>
            {`${maintenanceRequest()?.property?.name} - ${maintenanceRequest()?.unit?.name}`}
          </Show>
        }
        actionClass="flex flex-wrap items-center gap-2"
        actions={
          <Show when={!maintenanceRequest.loading} fallback={<Skeleton class="h-10 w-48" />}>
            <DropdownMenu
              onChange={handleStatusChange}
              value={maintenanceRequest()?.status}
              options={getMaintenanceStatusOptions(maintenanceRequest()?.status as MagicDoor.Api.MaintenanceRequestStatus).map((item) => ({
                ...item,
                label: t(item.label),
              }))}
              class="shrink-0"
            />
            <Show when={maintenanceRequest()?.workOrder}>
              <Button
                class="hidden shrink-0 whitespace-nowrap font-medium capitalize xl:flex"
                variant="outlined"
                href={`/maintenance/work-orders/${maintenanceRequest()?.workOrder?.id}`}>
                {t('Work order')}
              </Button>
            </Show>

            <LinkButton href="edit" variant="solid" class="shrink-0 whitespace-nowrap font-medium capitalize">
              <IconSquarePen class="size-5 text-white" />
              {t('Edit')}
            </LinkButton>
          </Show>
        }>
        <div class="flex flex-col gap-6 px-5 py-6">
          <div class="rounded-lg bg-light-gray p-5">
            <Show
              when={maintenanceRequest.loading}
              fallback={<div class="break-all font-medium normal-case text-title-gray">{maintenanceRequest()?.description}</div>}>
              <Skeleton />
              <Skeleton class="mt-1 w-2/3" />
            </Show>
            <ListFileThumbnailWithPreview loading={maintenanceRequest.loading} files={maintenanceRequest()?.files} />
            <Show when={maintenanceRequest()?.internalDescription || maintenanceRequest.loading}>
              <hr class="mt-5 bg-input-border" />
              <div class="mt-4 flex items-center gap-1 text-sm text-text-level03">
                <IconWrench class="size-4" />
                {t('Internal notes')}:
                <Show when={!maintenanceRequest.loading} fallback={<Skeleton class="w-48" />}>
                  <span class="normal-case text-link">{maintenanceRequest()?.internalDescription}</span>
                </Show>
              </div>
            </Show>
          </div>
          <div class="flex items-center gap-1 text-sm text-text-level03">
            <IconFlag class="size-4" />
            {t('Priority')}
            <Show when={!maintenanceRequest.loading} fallback={<Skeleton class="ml-2 w-16 rounded-full" />}>
              <MaintenanceRequestUrgency class="ml-2" urgency={maintenanceRequest()?.urgency} />
            </Show>
          </div>

          <div class="flex items-center gap-1 text-sm text-text-level03">
            <Show
              when={
                (maintenanceRequest()?.status === 'aiProcessing' || maintenanceRequest()?.aiProcessed) && !maintenanceRequest()?.disableAi
              }>
              <AIProcessing />
            </Show>
            <Show when={maintenanceRequest()?.aiProcessed && maintenanceRequest()?.disableAi}>
              <AIProcessed />
            </Show>
          </div>

          <PermissionToEnter permissionToEnter={maintenanceRequest()?.permissionToEnter} />

          <div class="h-px bg-partingline" />
          <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <Show when={maintenanceRequest()?.assignedPropertyManagerId || maintenanceRequest.loading}>
              <div class="flex w-full items-center gap-2 rounded-lg border border-partingline px-4 py-3">
                <Avatar name={maintenanceRequest()?.propertyManager?.name ?? ''} class="size-10" loading={maintenanceRequest.loading} />
                <div class="flex-1">
                  <div class="text-xs uppercase text-text-level03">{t('Assignedto')}</div>
                  <Show when={!maintenanceRequest.loading} fallback={<Skeleton class="w-40" />}>
                    <div class="text-wrap break-all text-text-level01">{maintenanceRequest()?.propertyManager?.name}</div>
                  </Show>
                </div>
              </div>
            </Show>
            <Show when={maintenanceRequest()?.due || maintenanceRequest.loading}>
              <div class="flex w-full items-center gap-2 rounded-lg border border-partingline px-4 py-3">
                <IconField
                  iconRounded={true}
                  name={t('Due date')}
                  loading={maintenanceRequest.loading}
                  value={dateFormat('', maintenanceRequest()?.due ?? '')}
                  src={IconCalendar}
                />
              </div>
            </Show>
            <div
              class="flex w-full gap-2 rounded-lg border border-partingline px-4 py-3"
              style={{ background: 'linear-gradient(94deg, #587DFF 25.19%, #7F11ED 100%), #D9D9D9' }}>
              <img src={IconAiBot} class="size-12" />
              <div class="text-white">
                <div class="flex items-center gap-[2px] text-nowrap text-sm">{t('AI urgency score')}</div>
                <Show when={!maintenanceRequest.loading} fallback={<Skeleton class="h-6 w-7" />}>
                  <span class="text-base font-bold">{maintenanceRequest()?.aiUrgencyScore}</span>
                </Show>
              </div>
            </div>
          </div>
        </div>
      </Panel>
      <Show when={maintenanceRequest()?.workOrder}>
        <Panel
          title={t('Confidential details')}
          headerClass="text-title-gray"
          description={<div class="text-sm text-warning">{t('Staff only notes & work orders (not visible to tenant)')}</div>}>
          <div class="flex gap-6 p-5">
            <div class="flex h-[305px] w-[237px] flex-col  gap-y-3 px-6 pt-6" style={{ 'background-image': `url(${WorkOrderBg})` }}>
              <div class="flex items-center justify-between ">
                <div>
                  <div class="text-base font-semibold capitalize text-[#4A3218]">{t('Work order')}</div>
                  <div class="text-xxs text-[#867E78]">{t(maintenanceRequest()?.workOrder?.status)}</div>
                </div>
                <LinkButton
                  href={`/maintenance/work-orders/${maintenanceRequest()?.workOrder?.id}`}
                  class="gap-1 px-2 py-1 text-xs"
                  color="link"
                  rounded="full"
                  variant="outlined">
                  {t('View')}
                  <IconViewArrow class="size-4 text-link" />
                </LinkButton>
              </div>
              <WorkOrderLine class="mx-auto" />
              <IconField
                iconBoxClass="border-none bg-transparent"
                iconRounded={true}
                name={t('Title')}
                loading={maintenanceRequest.loading}
                value={maintenanceRequest()?.workOrder?.title || ''}
                src={IconDocument}
              />
              <IconField
                iconBoxClass="border-none bg-transparent"
                iconRounded={true}
                name={t('Vendor')}
                loading={maintenanceRequest.loading}
                value={maintenanceRequest()?.workOrder?.vendorId || ''}
                src={IconBriefcase}
              />
              <IconField
                iconBoxClass="border-none bg-transparent"
                iconRounded={true}
                name={t('Last updated')}
                loading={maintenanceRequest.loading}
                value={dateFormat('', maintenanceRequest()?.workOrder?.updated) || ''}
                src={IconCalendar}
              />
            </div>
            <div class="relative flex-1 rounded-lg border border-input-border">
              <div class="border-b border-input-border px-5 py-4 text-base uppercase text-title-gray">{t('Interal description')}</div>
              <div class="p-4">
                <div class="mb-4 flex items-center rounded-lg bg-light-gray py-3">
                  <IconNotebook class="mx-5 my-2 size-7 min-w-6 text-link" />
                  <div class="line-clamp-2 h-12.5 border-l border-[#E2E3F1] px-4 py-2 text-sm font-medium text-title-gray">
                    {maintenanceRequest()?.internalDescription || emptyPlaceholder}
                  </div>
                </div>

                <MagicTagsList defaultData={maintenanceRequest()?.unit?.magicTags} />
              </div>
            </div>
          </div>
        </Panel>
      </Show>
    </div>
  );
};
