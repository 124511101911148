import { createEffect, createMemo, createSignal, For } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { IconCirclePlus, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { transformKeyValuePairs } from '~/pages/maintenance-request/tools';
import { isEmptyData } from '~/utils/tool';
import type { Component } from 'solid-js';

type LineItem = {
  key: string;
  value: string;
};

export type KeyValuePair = Record<string, string>;

export const EditMagicTagsModal: Component<{
  type: 'unit' | 'property';
  defaultData?: KeyValuePair;
  class?: string;
  visible?: boolean;
  onSubmit: (data: KeyValuePair) => Promise<any>;
  onClose?: (tenant?: MagicDoor.Api.TenantCreatedDto) => void;
}> = (props) => {
  const { t } = useLocalization();
  const form = useForm();
  const [editMagicTagsLoading, setEditMagicTagsLoading] = createSignal<boolean>(false);
  const [lineItems, setLineItems] = createSignal<LineItem[]>([{ key: '', value: '' }]);
  let hasInit = true;

  const isDisabledSubmit = createMemo(() => {
    if (!hasInit) return false;
    return isEmptyData(form.formStore);
  });

  const addLineItem = () => {
    setLineItems([...lineItems(), { key: '', value: '' }]);
  };

  createEffect(() => {
    const defaultData = props.defaultData;
    if (hasInit && defaultData && Object.keys(defaultData).length > 0) {
      const result: Array<{ key: string; value: string }> = [];

      for (const [key, value] of Object.entries(defaultData)) {
        result.push({ key, value });
        form.setFieldsValue({ [`key_${result.length - 1}`]: key, [`value_${result.length - 1}`]: value });
      }

      setLineItems([...result, ...lineItems()]);
      hasInit = false;
    }
  });

  const onDone = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;

    try {
      const magicTags = transformKeyValuePairs(form.formStore as never as KeyValuePair);
      setEditMagicTagsLoading(true);
      await props.onSubmit(magicTags);
      toast.success(t('Magic tags has been saved successfully'));
      props.onClose?.();
    } catch (err: any) {
      toast.error(t('Operation failed, please try again later') + ' ' + err?.json?.detail);
    } finally {
      setEditMagicTagsLoading(false);
    }
  };

  return (
    <Modal
      title={t(props.type === 'unit' ? 'Edit unit magic tags' : 'Edit property magic tags')}
      doneText={t('Save')}
      visible={!!props.visible}
      class="w-11/12 lg:w-modal-lg"
      confirmation={false}
      disabled={isDisabledSubmit()}
      onDone={onDone}
      loading={editMagicTagsLoading()}
      onCancel={() => {
        props.onClose?.();
      }}>
      <div class="h-[calc(100%-151px)] overflow-auto">
        <Form class="flex flex-col gap-6 p-8" defaultForm={form}>
          <div class="grid grid-cols-2 gap-x-6 gap-y-2">
            <For each={lineItems()}>
              {(item, index) => (
                <>
                  <FormItem
                    class="col-span-full lg:col-span-1"
                    label={t(`Magic tag`) + ` ${index() + 1}`}
                    formFieldName={`key_${index()}`}
                    value={item.key}
                    component={LabeledTextInput}
                    placeholder={t('Please enter')}
                    getValueFromEvent={(value) => value.trim()}
                    rules={[
                      {
                        message: t('Please enter'),
                        validator: (value) => {
                          if (form.formStore[`value_${index()}`]) {
                            return !!value;
                          }
                          return true;
                        },
                      },
                    ]}
                  />
                  <FormItem
                    class="col-span-full lg:col-span-1"
                    label={t(`Value`) + ` ${index() + 1}`}
                    formFieldName={`value_${index()}`}
                    value={item.value}
                    component={LabeledTextInput}
                    placeholder={t('Please enter')}
                    getValueFromEvent={(value) => value.trim()}
                    rules={[
                      {
                        message: t('Please enter'),
                        validator: (value) => {
                          if (form.formStore[`key_${index()}`]) {
                            return !!value;
                          }
                          return true;
                        },
                      },
                    ]}
                  />
                </>
              )}
            </For>

            <Button size="sm" class="col-span-full mt-4 flex h-10 justify-center border-dashed" variant="outlined" onClick={addLineItem}>
              <IconCirclePlus />
              {t('Add more')}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
