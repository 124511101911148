import { onMount, Show } from 'solid-js';
import ThingsToDoBg from '~/assets/images/dashboard/thingsToDoBg.svg';
import { Table } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChatsList } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
import Card from '~/pages/dashboard/components/Card';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import type { Component } from 'solid-js';

type UnreadMessagesProps = {
  class?: string;
};

export const UnreadMessages: Component<UnreadMessagesProps> = (props) => {
  const { t } = useLocalization();
  const { page, pageSize } = useLocalPagination('UnreadMessageView');
  const { filtered, setFilter } = useChatsList();

  onMount(() => {
    setFilter({ page: page(), pageSize: pageSize() });
  });

  return (
    <Card
      class={props.class}
      title={t('Unread messages')}
      description={t('{count} in total', {
        count: filtered()?.totalCount.toString() || emptyPlaceholder,
      })}
      bgClass="bg-[#F9FFFE]"
      bgImgSrc={ThingsToDoBg}>
      <Table
        data={filtered()?.items}
        rowLink={(item) => `/communications/chats/${item.chatId}`}
        columns={[
          {
            title: t('Message'),
            render: (row) => (
              <Show when={row.files?.length > 0} fallback={row.translatedMessage || row.message}>
                <span>[{t('File attachment')}]</span>
              </Show>
            ),
          },
          {
            title: t('Sent at'),
            render: (row) => dateFormat('MM/DD/YYYY', row.textSentAt),
          },
        ]}
      />
    </Card>
  );
};
