import { PayOutsProvider } from '~/contexts/pay-outs';
import { RentalApplicationPaymentProvider } from './applications/PaymentContext';
import { AuthProvider } from './auth';
import { ChartOfAccountsProvider } from './chart-of-accounts';
import { GlobalChatProvider } from './chats';
import { CompaniesProvider } from './companies';
import { CustomLayoutProvider } from './custom-layout';
import { DepositSlipsProvider } from './deposit-slips';
import { EmailsProvider } from './emails';
import { SignalRProvider } from './infrastructures';
import { LeaseTemplateProvider, LeaseTemplateCategoryProvider } from './lease-templates';
import { ManagementFeesSettingsProvider } from './local';
import { ManagementFeeProvider } from './management-fee';
import { NotesProvider } from './notes';
import { OwnersProvider } from './owners';
import { PortfoliosProvider } from './portfolios';
import { PropertiesProvider } from './properties';
import { PropertyCreditProvider } from './property-credits';
import { RentalApplicationProvider } from './rental-application';
import { RunBooksProvider } from './run-books';
import { TenantsProvider } from './tenants';
import { TranslationsProvider } from './translations';
import { UnitListingsProvider } from './unit-listings';
import { UnitsProvider } from './units';
import { VendorsProvider } from './vendors';
import { WorkOrdersProvider } from './work-orders';
import type { Component, JSX } from 'solid-js';

type Provider = Component<{ children: JSX.Element }>;

// global providers registry (stacked)
const providers: Provider[] = [
  SignalRProvider,
  AuthProvider,
  GlobalChatProvider,
  CompaniesProvider,
  PortfoliosProvider,
  PropertiesProvider,
  UnitsProvider,
  NotesProvider,
  UnitListingsProvider,
  TenantsProvider,
  TranslationsProvider,
  ChartOfAccountsProvider,
  VendorsProvider,
  RentalApplicationProvider,
  ManagementFeeProvider,
  PayOutsProvider,
  ManagementFeesSettingsProvider,
  CustomLayoutProvider,
  OwnersProvider,
  WorkOrdersProvider,
  DepositSlipsProvider,
  RunBooksProvider,
  RentalApplicationPaymentProvider,
  EmailsProvider,
  PropertyCreditProvider,
  LeaseTemplateCategoryProvider,
  LeaseTemplateProvider,
  // more global providers can be added here
];

// export all global hooks
export { useSignalR } from './infrastructures';
export { useLocalization, type TFunction } from './localization';
export { useAuth, type Profile } from './auth';
export { useGlobalChat } from './chats';
export { useRentalApplication, type StatusType } from './rental-application';
export { useCompanies } from './companies';
export { usePortfolios } from './portfolios';
export { useProperties, type PropertyImage } from './properties';
export { useUnits } from './units';
export { useUnitListings } from './unit-listings';
export { useTenants } from './tenants';
export { useTranslations } from './translations';
export { useChartOfAccounts, chartOfAccountNameMap, type ChartOfAccountGroup } from './chart-of-accounts';
export { useVendors } from './vendors';
export { useManagementFee } from './management-fee';
export { usePayOuts } from './pay-outs';
export { useManagementFeesSettings } from './local';
export { useCustomLayout } from './custom-layout';
export { useOwners } from './owners';
export { useWorkOrders } from './work-orders';
export { useDepositSlips } from './deposit-slips';
export { useSearch } from '~/components/layouts/Navigation/MagicSearchBar/MagicSearchContext';
export { useRentalApplicationPayment } from './applications/PaymentContext';
export { useEmails } from './emails';
export { useNotes } from './notes';
export { usePropertyCredit } from './property-credits';
export { createMagicDoorContext } from './utils';

export type { MixedPayOutBankAccountDto } from './pay-outs';
export const GlobalProviders: Provider = (props) => {
  return providers.reduceRight(
    (acc, provider) => ({
      get children() {
        return provider(acc);
      },
    }),
    {
      get children() {
        return props.children;
      },
    }
  ).children;
};
