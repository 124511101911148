import { Show } from 'solid-js';
import { LeaseBillUpdateModal } from './LeaseBillUpdateModal';
import { LeaseDepositTransactionUpdateModal } from './LeaseDepositTransactionUpdateModal';

export interface LeaseTransactionUpdateModal {
  lease: MagicDoor.Api.HydratedLeaseDto;
  visible: boolean;
  transactionDetail?: MagicDoor.Api.HydratedLeaseTransactionDto;
  onCancel: () => void;
  onSuccess: () => void;
}

export const LeaseTransactionUpdateModal = (props: LeaseTransactionUpdateModal) => {
  return (
    <Show when={props.visible}>
      <Show when={props.transactionDetail?.bill} fallback={<LeaseDepositTransactionUpdateModal {...props} />}>
        <LeaseBillUpdateModal {...props} />
      </Show>
    </Show>
  );
};
