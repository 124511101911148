import { Route, Routes, useParams, useNavigate } from '@solidjs/router';
import { createEffect, createMemo, createSignal } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { TabLinks } from '~/components/ui';
import { useLocalization, useWorkOrders } from '~/contexts/global';
import { BindingMaintenanceRequestModal } from '~/pages/work-orders/list-work-orders/components/BindingMaintenanceRequestModal';
import { WorkOrderSummary } from './components/WorkOrderSummary';
import { BillsTab } from './tabs/BillsTab';
import { WorkOrderCommunicationsTab } from './tabs/WorkOrderCommunicationsTab';
import { WorkOrderNotesTab } from './tabs/WorkOrderNotesTab';
import { WorkOrderOverviewTab } from './tabs/WorkOrderOverviewTab';
import type { TabLinkItem } from '~/components/ui';

export const WorkOrderDetailsPage = () => {
  const { t } = useLocalization();
  const params = useParams<{ workOrderId: string }>();
  const navigate = useNavigate();

  const { getWorkOrder, workOrder } = useWorkOrders();
  const [visible, setVisible] = createSignal<boolean>(false);

  createEffect(() => {
    getWorkOrder(params.workOrderId);
  });

  const breadcrumbItems = createMemo(() => [
    { label: t('Work orders'), link: '/maintenance/work-orders' },
    { label: t('Work orders detail') },
  ]);

  const tabs = createMemo<TabLinkItem[]>(() => {
    return [
      { label: t('General'), href: '' },
      { label: t('Communications'), href: 'communications' },
      { label: t('Bills'), href: 'bills' },
      { label: t('Notes'), href: 'notes' },
    ];
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="my-5 flex flex-col-reverse gap-4 px-8 lg:flex-row">
        <div class="flex w-full flex-col gap-4 lg:w-3/4">
          <TabLinks boxClass="rounded-lg border border-partingline bg-white" items={tabs()} replace />
          <Routes>
            <Route path="/*" component={WorkOrderOverviewTab} />
            <Route path="/communications/*" component={WorkOrderCommunicationsTab} />
            <Route path="/bills/*" component={BillsTab} />
            <Route path="/notes/*" component={WorkOrderNotesTab} />
          </Routes>
        </div>
        <WorkOrderSummary class="w-full min-w-80 shrink-0 lg:w-1/4" loading={workOrder.loading} workOrder={workOrder()} />
        <BindingMaintenanceRequestModal visible={[visible, setVisible]} />
      </div>
    </>
  );
};
