import { createEffect, createMemo, For, onCleanup, onMount, splitProps, untrack, useContext } from 'solid-js';
import { InputLabel } from '~/components/common/BetterForm/Components';
import { copyStore, getFormContext, getValueFromStore, useForm, useFormContext } from '~/components/common/BetterForm/context';
import { FormListContext } from '~/components/common/BetterForm/formListContext';
import { combineNamePaths, isFalsy, isRequired, transFormNamePath } from '~/components/common/BetterForm/utils';
import { safeCall } from '~/utils/tool';
import type { Component } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm/type';

const defaultOnChangeMethodName = 'onInput';
const defaultGetValueFromEvent = (val: any) => val;

export const FormWrapper: Component<BetterForm.FormProps> = (_props) => {
  const [localProps, formProps] = splitProps(_props, ['onFormSubmit', 'children', 'initialValues', 'defaultForm']);

  let refContainer!: HTMLFormElement;

  const FormContext = getFormContext();

  const defaultForm = localProps.defaultForm || useForm();

  onMount(() => {
    defaultForm.setFormRef(refContainer);
    defaultForm.setFieldsValue(localProps.initialValues);
  });

  return (
    <FormContext.Provider value={defaultForm}>
      <form
        {...formProps}
        ref={refContainer}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();

          const { validateStatus } = defaultForm.validateForm();

          if (validateStatus) {
            localProps.onFormSubmit && localProps.onFormSubmit(defaultForm.formStore);
          }
        }}>
        {localProps.children}
      </form>
    </FormContext.Provider>
  );
};

export const FormItem = function <T>(_props: BetterForm.FormItemProps<T>) {
  const formContext = useFormContext();
  const formListContext = useContext(FormListContext);

  const [localProps, componentProps] = splitProps(_props, [
    'component',
    'formFieldName',
    'onChangeMethodName',
    'label',
    'rules',
    'removeValueOnClean',
    'customSyncValue',
    'customGetValue',
    'getValueFromEvent',
    'valuePropName',
  ]);
  if (!formContext) throw new Error('FormItem must be used within a FormWrapper');

  const realFormFieldName = createMemo(() => {
    if (formListContext && localProps.formFieldName) {
      return combineNamePaths(formListContext.formFieldName, [formListContext.index], localProps.formFieldName);
    }
    return localProps.formFieldName;
  });

  const getIdStr = (): string | undefined => {
    const name = realFormFieldName();
    if (name instanceof Array) {
      return name.join('_');
    }
    return name;
  };

  const labelMemo = createMemo(() =>
    localProps.label ? (
      <InputLabel
        id={getIdStr()}
        description={componentProps.description}
        label={localProps.label}
        required={!!isRequired(localProps.rules)}
      />
    ) : null
  );

  const onChangeMethodName = createMemo<string>(() => localProps.onChangeMethodName || defaultOnChangeMethodName);

  createEffect(() => {
    const _realFormFieldName = realFormFieldName();
    if (localProps.rules && _realFormFieldName) {
      formContext.setFormFieldRules(localProps.rules, _realFormFieldName);
    }
  });

  onCleanup(() => {
    const _realFormFieldName = realFormFieldName();
    if (_realFormFieldName) {
      formContext.removeFormField(_realFormFieldName);
    }
    if (localProps.removeValueOnClean && _realFormFieldName) {
      formContext.removeFormField(_realFormFieldName, { removeValue: true });
    }
  });

  const value = createMemo(() => {
    if (typeof localProps.customGetValue === 'function') {
      return localProps.customGetValue(formContext);
    }
    return getValueFromStore(formContext.formStore, realFormFieldName());
  });

  const getValueFromEvent = createMemo(() => {
    if (localProps.getValueFromEvent) return localProps.getValueFromEvent;
    return defaultGetValueFromEvent;
  });

  return (
    <localProps.component
      {...(componentProps as unknown as T)}
      label={localProps.label}
      labelJSX={labelMemo()}
      __do_not_use_if_you_are_not_sure__formFieldName={realFormFieldName()}
      {...{
        [localProps.valuePropName || 'value']: value(),
        [onChangeMethodName()]: (...args: any[]) => {
          const value = args[0];
          const isEmpty =
            isFalsy(value) ||
            (Array.isArray(value) && value.length === 0) ||
            (typeof value === 'object' && Object.keys(value).length === 0);

          const realValue = isEmpty ? undefined : getValueFromEvent()(value);

          if (localProps.customSyncValue) {
            localProps.customSyncValue(formContext, realValue);
          } else {
            formContext.setValueToStore(realFormFieldName(), realValue);
          }
          safeCall(componentProps[onChangeMethodName() as keyof typeof componentProps] as any, ...args);
        },
        error: formContext.getFormFieldError(realFormFieldName()),
      }}
    />
  );
};

export const FormList = (props: BetterForm.FormListProps) => {
  const formContext = useFormContext();
  if (!formContext) throw new Error('FormList must be used within a FormWrapper');
  const val = createMemo(() => (getValueFromStore(formContext.formStore, props.formFieldName) || []) as unknown[]);

  const onDelete = (index: number) => {
    const arr = copyStore(val() as any[]);
    if (index >= arr.length) return;
    arr.splice(index, 1);
    formContext.setValueToStore(props.formFieldName, arr, false);
  };

  const itemsGroups = createMemo(() => {
    if (!props.renderItemsGroup) return null;

    return (
      <For each={val()}>
        {(item, index) => (
          <FormListContext.Provider
            value={{
              formFieldName: props.formFieldName,
              autoCombineFieldName: props.autoCombineFieldName || true,
              index: untrack(() => index()),
            }}>
            {safeCall(props.renderItemsGroup, {
              remove: onDelete.bind(null, index()),
              item,
              index,
            })}
          </FormListContext.Provider>
        )}
      </For>
    );
  });

  const children = createMemo(() => {
    if (!props.children) return null;
    return props.children({
      val,
      add() {
        const arr = copyStore(val());
        arr.push({});
        formContext.setValueToStore(props.formFieldName, arr, false);
      },
      delete: onDelete,
      prefix: transFormNamePath(props.formFieldName),
    });
  });

  return (
    <div class={props.class}>
      <div class={props.itemsGroupsClass}>{itemsGroups()}</div>
      {children()}
    </div>
  );
};
