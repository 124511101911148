import fontkit from 'pdf-fontkit';
import { PDFDocument, rgb } from 'pdf-lib';
import BoldFont from '~/assets/fonts/NotoSansSC-Bold.ttf';
import RegularFont from '~/assets/fonts/NotoSansSC-Regular.ttf';
import { PageSize, Orientation, Alignment, WidthDistribution } from '~/pdfsigner/usecases/types/reportDocument';
import type { Color, PDFFont, PDFImage, PDFPage } from 'pdf-lib';
import type {
  ReportDocument,
  DocumentHeader,
  Section,
  DocumentFooter,
  Entry,
  Value,
  HeaderValue,
} from '~/pdfsigner/usecases/types/reportDocument';

class PdfReportGenerator {
  private font?: PDFFont = undefined;
  private boldFont?: PDFFont = undefined;
  private margin = 30;
  private padding = 7;
  private textFontSize = 8;
  private headerFontSize = 8;
  private titleFontSize = 16;
  private footerHeight = 30;
  private entryPadding = 3;
  private valuePadding = 5;

  private header?: DocumentHeader = undefined;
  private footer?: DocumentFooter = undefined;
  private embeddedLogo?: PDFImage = undefined;
  private currentPosition: number = 0;
  private currentPage?: PDFPage = undefined;
  private pdfDoc?: PDFDocument = undefined;
  private pageSize: PageSize = PageSize.LETTER;
  private lineHeight = 0.5;
  private orientation: Orientation = Orientation.PORTRAIT;
  private columnWidths: number[] = [];

  public async createReport(document: ReportDocument): Promise<Blob> {
    this.pdfDoc = await PDFDocument.create();
    this.pdfDoc.registerFontkit(fontkit);
    await this.embedFonts(this.pdfDoc);
    this.orientation = document.orientation ?? Orientation.PORTRAIT;
    this.pageSize = document.pageSize;
    if (document.header) {
      this.header = document.header;
      await this.getCompanyLogo();
    }
    if (document.footer) {
      this.footer = document.footer;
    }
    this.addPage();
    this.drawSections(document);
    this.addPageNumbers();
    const bytes = await this.pdfDoc.save();
    return new Blob([bytes], { type: 'application/pdf' });
  }

  private async embedFonts(document: PDFDocument) {
    const font = await fetch(RegularFont).then((res) => res.arrayBuffer());
    const boldFont = await fetch(BoldFont).then((res) => res.arrayBuffer());
    this.font = await document.embedFont(font, { subset: true });
    this.boldFont = await document.embedFont(boldFont, { subset: true });
  }

  private drawMemo(memo?: string) {
    if (!this.currentPage || !memo) {
      return;
    }
    const pageWidth = this.currentPage.getSize().width;
    const textWidth = pageWidth - 2 * this.margin;
    const wrappedValueLines = this.wrapText(memo, textWidth, this.font!, this.textFontSize);
    wrappedValueLines.forEach((line) => {
      this.currentPage?.drawText(line, {
        x: this.margin,
        y: this.currentPosition,
        size: this.textFontSize,
        font: this.font,
      });
      this.currentPosition -= this.textFontSize + 10;
    });
  }

  private drawSections(document: ReportDocument) {
    this.drawMemo(document.memo);
    this.columnWidths = this.calculateGlobalColumnWidths(document.sections, this.font!, this.textFontSize);
    for (const section of document.sections) {
      if (document.isEachSectionOnANewPage && section !== document.sections[0]) {
        this.addPage();
      }
      this.drawSection(section);
    }
  }

  private addPageNumbers() {
    const pages = this.pdfDoc?.getPages();
    if (!pages) {
      return;
    }
    pages.forEach((page, index) => {
      this.addPageNumber(page, index + 1, pages.length);
    });
  }

  private async getCompanyLogo() {
    if (this.header?.logoUrl) {
      const url = this.header.logoUrl;
      const response = await fetch(url);
      if (!response.ok) {
        this.embeddedLogo = undefined;
      }
      const blob = await response.blob();
      const arrayBuffer = await blob.arrayBuffer();
      if (blob.type === 'image/png') {
        this.embeddedLogo = await this.pdfDoc?.embedPng(arrayBuffer);
      } else if (blob.type === 'image/jpeg') {
        this.embeddedLogo = await this.pdfDoc?.embedJpg(arrayBuffer);
      } else {
        this.embeddedLogo = undefined;
      }
    }
  }

  private getPageSize(pageSize: PageSize, orientation: Orientation): [number, number] {
    const sizes: { [key in PageSize]: [number, number] } = {
      A4: orientation == Orientation.PORTRAIT ? [595.28, 841.89] : [841.89, 595.28],
      LETTER: orientation == Orientation.PORTRAIT ? [612, 792] : [792, 612],
    };
    return sizes[pageSize];
  }

  private addPage() {
    const page = this.pdfDoc?.addPage(this.getPageSize(this.pageSize, this.orientation));
    if (!page) {
      return;
    }
    this.currentPage = page;
    this.currentPosition = page.getHeight() - 100;
    if (this.header) {
      this.drawHeader(this.header);
    }
    if (this.footer) {
      this.drawFooter(this.footer);
    }
  }

  private addPageNumber(page: PDFPage, pageNumber: number, totalPages: number) {
    const { width } = page.getSize();
    const pageNumberText = `Page ${pageNumber} / ${totalPages}`;
    const textWidth = this.measureStringWidth(pageNumberText, this.font!, this.textFontSize);
    page.drawText(pageNumberText, {
      x: width - textWidth - this.margin,
      y: this.margin,
      size: this.textFontSize,
      font: this.font,
    });
  }

  private drawFooter(footer: DocumentFooter) {
    this.currentPage?.drawText(footer.text, {
      x: this.margin,
      y: this.margin,
      size: this.textFontSize,
      font: this.font,
    });
  }

  private drawHeader(header: DocumentHeader) {
    this.drawCompanyNameAndLogo(header.companyName);
    this.drawTitleAndSubtitle(header.title, header.subTitle);
    this.drawAdditionalInfo(header.additionalInfo);
  }

  private drawCompanyNameAndLogo(companyName?: string) {
    const { height } = this.currentPage!.getSize();
    const yPosition = height - 2 * this.margin;
    if (this.embeddedLogo) {
      const imgDims = this.embeddedLogo.scale(1);
      const aspectRatio = imgDims.width / imgDims.height;
      const scaledHeight = 50 / aspectRatio;
      this.currentPage?.drawImage(this.embeddedLogo, {
        x: this.margin,
        y: yPosition,
        width: 50,
        height: scaledHeight,
      });
    }
    if (companyName) {
      this.currentPage?.drawText(companyName, {
        x: this.margin,
        y: yPosition - 10,
        size: this.headerFontSize,
        font: this.font,
      });
    }
  }

  private drawTitleAndSubtitle(title: string, subTitle?: string) {
    const { width, height } = this.currentPage!.getSize();
    const yPosition = height - 2 * this.margin;
    const titleTextWidth = this.measureStringWidth(title, this.font!, this.titleFontSize);
    const titleX = width / 2 - titleTextWidth / 2;
    this.currentPage?.drawText(title, {
      x: titleX,
      y: yPosition,
      size: this.titleFontSize,
      font: this.font,
    });
    if (subTitle) {
      const subTitleTextWidth = this.measureStringWidth(subTitle, this.font!, this.headerFontSize);
      const subTitleX = width / 2 - subTitleTextWidth / 2;
      this.currentPage?.drawText(subTitle, {
        x: subTitleX,
        y: yPosition - 15,
        size: this.headerFontSize,
        font: this.font,
      });
    }
  }

  private drawAdditionalInfo(additionalInfo?: string[]) {
    const { width, height } = this.currentPage!.getSize();
    const yPosition = height - 2 * this.margin;
    if (additionalInfo) {
      additionalInfo.forEach((infoText, index) => {
        const infoTextWidth = this.measureStringWidth(infoText, this.font!, this.textFontSize);
        const infoTextX = width - infoTextWidth - this.margin;
        this.currentPage?.drawText(infoText, {
          x: infoTextX,
          y: yPosition - index * 10,
          size: this.textFontSize,
          font: this.font,
        });
      });
    }
  }

  private drawSection(section: Section) {
    this.drawSectionHeader(section);
    this.drawSectionEntries(section);
  }

  private drawSectionHeader(section: Section) {
    this.drawRow(section.header!, true, this.boldFont!, this.headerFontSize);
  }

  private calculateGlobalColumnWidths(sections: Section[], font: PDFFont, fontSize: number): number[] {
    let maxColumns = 0;
    sections.forEach((section) => {
      section.entries?.forEach((entry) => {
        if (entry.values) {
          maxColumns = Math.max(maxColumns, entry.values.length);
        }
      });
    });
    let columnWidths: number[] = Array(maxColumns).fill(-2);
    sections.forEach((section) => {
      const headerColumnWidths = this.calculateHeaderColumnWidths(section.header?.values || [], maxColumns, font, fontSize);
      columnWidths = columnWidths.map((oldWidth, index) => {
        const newWidth = headerColumnWidths[index];
        if (oldWidth === -2) {
          return newWidth;
        } else if (newWidth === -1) {
          return oldWidth;
        } else {
          return Math.max(oldWidth, newWidth);
        }
      });
      section.entries?.forEach((entry) => {
        const lineColumnWidths = this.calculateColumnWidths(entry.values || [], maxColumns, font, fontSize);
        columnWidths = columnWidths.map((oldWidth, index) => {
          const newWidth = lineColumnWidths[index];
          if (oldWidth !== -1) {
            return Math.max(oldWidth, newWidth);
          }
          return oldWidth;
        });
      });
    });
    const { width } = this.currentPage!.getSize();
    let remainingWidth = width - 2 * this.margin;
    let flexColumnCount = 0;
    columnWidths.forEach((columnWidth) => {
      if (columnWidth === -1) {
        flexColumnCount++;
      } else {
        remainingWidth -= columnWidth;
      }
    });
    const flexColumnWidth = flexColumnCount > 0 ? remainingWidth / flexColumnCount : 0;
    columnWidths.forEach((columnWidth, index) => {
      if (columnWidth === -1) {
        columnWidths[index] = flexColumnWidth;
      }
    });
    return columnWidths;
  }

  private calculateHeaderColumnWidths(values: HeaderValue[], maxColumns: number, font: PDFFont, fontSize: number) {
    const columnWidths: number[] = Array(maxColumns).fill(0);
    if (values) {
      for (let valueIndex = 0; valueIndex < values.length; valueIndex++) {
        const value = values[valueIndex];
        const finalFont = value.isBold ? this.boldFont! : font;
        if (value.width === WidthDistribution.fixed) {
          const lineWidth = this.calculateLineWidth(value, finalFont, fontSize);
          columnWidths[valueIndex] = Math.max(columnWidths[valueIndex], lineWidth);
        } else {
          columnWidths[valueIndex] = -1;
        }
      }
    }
    return columnWidths;
  }

  private calculateColumnWidths(values: Value[], maxColumns: number, font: PDFFont, fontSize: number) {
    const columnWidths: number[] = Array(maxColumns).fill(0);
    if (values) {
      for (let valueIndex = 0; valueIndex < values.length; valueIndex++) {
        const value = values[valueIndex];
        const finalFont = value.isBold ? this.boldFont! : font;
        const lineWidth = this.calculateLineWidth(value, finalFont, fontSize);
        columnWidths[valueIndex] = Math.max(columnWidths[valueIndex], lineWidth);
      }
    }
    return columnWidths;
  }

  private calculateLineWidth(value: Value, font: PDFFont, fontSize: number) {
    const finalFont = value.isBold ? this.boldFont! : font;
    const lineWidth = this.measureStringWidth(value.text, finalFont, fontSize);
    if (lineWidth === 0) {
      return 0;
    }
    return Math.ceil(lineWidth + 2 * this.valuePadding);
  }

  private measureStringWidth(text: string, font: PDFFont, fontSize: number): number {
    let totalWidth = 0;
    for (const char of text) {
      if (/[\u4e00-\u9fff]/.test(char)) {
        totalWidth += font.widthOfTextAtSize(char, fontSize);
      } else {
        totalWidth += font.widthOfTextAtSize(char, fontSize);
      }
    }
    return totalWidth;
  }

  private drawSectionEntries(section: Section) {
    section.entries?.forEach((entry, index) => {
      const shouldDrawBackground = !!(entry.hasBackgroundColor || (section.hasAlternatingRowColors && index % 2 === 1));
      this.drawRow(entry, shouldDrawBackground, this.font!, this.textFontSize, rgb(0.96, 0.96, 0.96));
    });
    this.currentPosition -= 10;
  }

  private drawRow(entry: Entry, shouldDrawBackground: boolean, font: PDFFont, fontSize: number, backgroundColor?: Color) {
    const { width } = this.currentPage!.getSize();
    const rightMargin = width - this.margin;
    const color = backgroundColor || rgb(0.9, 0.9, 0.9);
    let totalRowHeight = 0;
    const valueHeights = entry.values?.map((value, valueIndex) => {
      const columnWidth = this.columnWidths[valueIndex] - 2 * this.valuePadding;
      const wrappedValueLines = this.wrapText(value.text, columnWidth, font, fontSize);
      return wrappedValueLines.length * (this.textFontSize + this.entryPadding);
    });
    totalRowHeight = Math.max(...(valueHeights || [0]));
    totalRowHeight += this.entryPadding;
    if (this.currentPosition - totalRowHeight < this.footerHeight) {
      this.addPage();
    }
    if (shouldDrawBackground) {
      this.currentPage?.drawRectangle({
        x: this.margin,
        y: this.currentPosition + fontSize + this.entryPadding - totalRowHeight - 1.5,
        width: width - 2 * this.margin,
        height: totalRowHeight,
        color,
      });
    }
    let currentYPosition = this.currentPosition;
    let currentX = rightMargin;
    if (entry.values) {
      for (let i = entry.values.length - 1; i >= 0; i--) {
        const columnWidth = this.columnWidths[i];
        const finalFont = entry.values?.[i].isBold ? this.boldFont! : font;
        const wrappedValueLines = this.wrapText(entry.values[i].text, columnWidth - 2 * this.valuePadding, finalFont, fontSize);
        currentYPosition = this.currentPosition;
        currentX -= columnWidth;
        const totalTextWidth = wrappedValueLines.reduce((maxWidth, line) => {
          const lineWidth = this.measureStringWidth(line, font, fontSize);
          return Math.max(maxWidth, lineWidth);
        }, 0);
        let textXPosition = currentX + this.valuePadding;
        if (entry.values[i].horizontalAlignment === Alignment.center) {
          textXPosition = currentX + columnWidth / 2 - totalTextWidth / 2;
        } else if (entry.values[i].horizontalAlignment === Alignment.end) {
          textXPosition = currentX + columnWidth - totalTextWidth - this.valuePadding;
        }
        wrappedValueLines.forEach((line) => {
          this.currentPage?.drawText(line, {
            x: textXPosition + 10 * (entry.values?.[i].indentationLevel || 0),
            y: currentYPosition,
            size: fontSize,
            font: entry.values?.[i].isBold ? this.boldFont : font,
          });
          currentYPosition -= fontSize + this.entryPadding;
        });
      }
    }

    if (entry.hasTopLine || entry.hasBottomLine) {
      if (entry.hasTopLine) {
        this.drawLineAtPosition(this.currentPosition + fontSize + this.entryPadding, rightMargin, entry.isLineDoubled);
      }
      if (entry.hasBottomLine) {
        this.drawLineAtPosition(this.currentPosition + fontSize + this.entryPadding - totalRowHeight, rightMargin, entry.isLineDoubled);
      }
    }
    this.currentPosition -= totalRowHeight;
  }

  private wrapText(text: string, maxWidth: number, font: PDFFont, fontSize: number): string[] {
    const lines: string[] = [];
    let currentLine = '';
    try {
      if (text.includes(' ')) {
        const words = text.split(' ');
        words.forEach((word) => {
          const testLine = currentLine + (currentLine === '' ? '' : ' ') + word;
          const testWidth = this.measureStringWidth(testLine, font, fontSize);
          if (testWidth > maxWidth) {
            lines.push(currentLine);
            currentLine = word;
          } else {
            currentLine = testLine;
          }
        });
      } else {
        for (let i = 0; i < text.length; i++) {
          const testLine = currentLine + text[i];
          const testWidth = this.measureStringWidth(testLine, font, fontSize);
          if (testWidth > maxWidth) {
            lines.push(currentLine);
            currentLine = text[i];
          } else {
            currentLine = testLine;
          }
        }
      }
      if (currentLine) {
        lines.push(currentLine);
      }
      return lines;
    } catch (e) {
      console.log(e);
      return [text];
    }
  }

  private drawLineAtPosition(yPosition: number, rightMargin: number, isDoubled?: boolean) {
    const lineHeight = this.lineHeight;
    this.currentPage?.drawLine({
      start: { x: this.margin, y: yPosition - 1.5 },
      end: { x: rightMargin, y: yPosition - 1.5 },
      color: rgb(0, 0, 0),
      thickness: lineHeight,
    });
    if (isDoubled) {
      this.currentPage?.drawLine({
        start: { x: this.margin, y: yPosition + 1.5 },
        end: { x: rightMargin, y: yPosition + 1.5 },
        color: rgb(0, 0, 0),
        thickness: lineHeight,
      });
    }
  }
}

export const pdfGenerator = new PdfReportGenerator();
