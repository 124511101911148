import { useParams } from '@solidjs/router';
import { createMemo, For, onMount, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import { Empty } from '~/components/common/Empty';
import { Panel } from '~/components/common/Panels';
import { Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { BankAccountCard } from '~/pages/tenants/tenant-details/components/BankAccountCards';
import { TenantRepository } from '~/repositories/tenantRepository';
import { PaymentAccountProvider } from '~/swagger/Api';

const teantRepo = new TenantRepository();

const ContentWithStatus = (props: { isLoading: boolean; items: any[]; renderItem: (item: any) => any }) => {
  return (
    <Show
      when={!props.isLoading}
      fallback={
        <div class="flex flex-col gap-2 p-5">
          <Skeleton />
          <Skeleton class="w-2/3" />
          <Skeleton class="w-2/5" />
        </div>
      }>
      <Show when={props.items.length > 0} fallback={<Empty />}>
        <div class="flex flex-wrap gap-6 p-6">
          <For each={props.items}>{props.renderItem}</For>
        </div>
      </Show>
    </Show>
  );
};

export const TenantPaymentsMethod = () => {
  const [state, setState] = createStore<{
    accounts: MagicDoor.Api.PaymentAccountDto[];
    isLoading: boolean;
  }>({
    accounts: [],
    isLoading: false,
  });

  const { t } = useLocalization();

  const { tenantId } = useParams<{ tenantId: string }>();

  async function getPaymentsMethod() {
    try {
      setState({
        isLoading: true,
      });
      const res = await teantRepo.getPaymentAccounts(tenantId);
      setState({
        accounts: res.paymentAccounts,
      });
    } finally {
      setState({
        isLoading: false,
      });
    }
  }

  onMount(() => {
    getPaymentsMethod();
  });

  const plaidAccounts = createMemo(() => state.accounts.filter((account) => account.accountProvider === PaymentAccountProvider.Plaid));

  const othersAccounts = createMemo(() => state.accounts.filter((account) => account.accountProvider !== PaymentAccountProvider.Plaid));

  return (
    <div class="flex flex-col gap-5">
      <Panel title={t('Plaid linked accounts')}>
        <ContentWithStatus
          isLoading={state.isLoading}
          items={plaidAccounts()}
          renderItem={(account) => <BankAccountCard account={account} />}
        />
      </Panel>
      <Panel title={t('Credit/Debit Cards')}>
        <ContentWithStatus
          isLoading={state.isLoading}
          items={othersAccounts()}
          renderItem={(account) => <BankAccountCard account={account} />}
        />
      </Panel>
    </div>
  );
};
