/* eslint-disable solid/reactivity */
import { createSignal, mergeProps } from 'solid-js';
import { IconChevronDown } from '~/components/ui';
import { cn } from '~/utils/classnames';
import { isUndefined } from '~/utils/tool';
import type { JSX } from 'solid-js';
type ExpandableSectionProps = {
  btnClass?: string;
  title: JSX.Element;
  children: JSX.Element;
  headerBorder?: boolean;
  defaultOpen?: boolean;
  onExpand?: (isOpen: boolean) => void;
  action?: JSX.Element;
  ref?: any;
  noncollapsible?: JSX.Element;
} & Omit<JSX.HTMLAttributes<HTMLDivElement>, 'title'>;

const ExpandableSection = (originProps: ExpandableSectionProps) => {
  const props = mergeProps({ defaultOpen: false }, originProps);

  const [isOpen, setIsOpen] = createSignal<boolean>(props.defaultOpen);

  const handleClick = () => {
    props.onExpand?.(!isOpen());

    setIsOpen(!isOpen());
  };

  props.ref &&
    props.ref({
      toggleOpen(open?: boolean) {
        if (isUndefined(open)) {
          setIsOpen(!isOpen());
        } else {
          setIsOpen(open);
        }
      },
    });

  return (
    <div class={cn('flex w-full flex-col items-start px-4 text-sm text-text-level01', props.class)}>
      <div class="flex w-full justify-between">
        <button
          type="button"
          class={cn(
            'flex w-full justify-between py-4 text-left font-medium leading-5',
            props.headerBorder && 'mb-4 border-b border-partingline',
            props.btnClass
          )}
          onClick={handleClick}>
          {props.title}
        </button>
        <div
          class="flex items-center gap-1"
          classList={{
            'border-b border-partingline': props.headerBorder,
          }}>
          <div>{props.noncollapsible}</div>
          <div class="flex cursor-pointer items-center gap-3" onClick={handleClick}>
            <div class="rounded p-1 hover:bg-input-border">{props?.action}</div>
            <IconChevronDown classList={{ 'rotate-180': isOpen() }} class="size-5 transition-transform duration-300 ease-in-out" />
          </div>
        </div>
      </div>
      <div
        class="grid w-full transition-all duration-300 ease-in-out"
        classList={{ 'grid-rows-[1fr]': isOpen(), 'grid-rows-[0fr]': !isOpen() }}>
        <div class="overflow-y-hidden">{props.children}</div>
      </div>
    </div>
  );
};

export default ExpandableSection;
