import { createMemo, createSignal, For, Show } from 'solid-js';
import CircleArrowIcon from '~/assets/images/common/circleArrow.svg?component-solid';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { SetStoreFunction } from 'solid-js/store';
import type { Option } from '~/components/common/DropdownMenu';
import type { PayBillsForm, PayBillsFormError } from '~/pages/bills/pay-bill/types';

const paymentTypeOptions: Option[] = [
  { label: 'Print check', value: 'printCheck' },
  { label: 'Manual', value: 'manual' },
];

const paymentMethodOptions: Option[] = [
  { label: 'ACH', value: 'ach' },
  { label: 'Credit', value: 'credit' },
  { label: 'Debit card', value: 'debitCard' },
  { label: 'Wallet', value: 'wallet' },
  { label: 'Credit card', value: 'creditCard' },
  { label: 'Check', value: 'check' },
  { label: 'Cashiers check', value: 'cashiersCheck' },
  { label: 'Money order', value: 'moneyOrder' },
  { label: 'Other', value: 'other' },
];

interface BillItemProps {
  propertyData: any;
  vendors: any[];
  setFormData: SetStoreFunction<PayBillsForm>;
}

const BillItem: Component<BillItemProps> = (props) => {
  const { t } = useLocalization();
  const [activeIndex, setActiveIndex] = createSignal<number[]>([0]);
  const columnControl = createMemo(() => {
    const allVendorBills = props.propertyData.vendors.reduce((acc: any, cur: any) => {
      return [...acc, ...cur.bills];
    }, []);

    return {
      isAnyVendorBillIsManual:
        props.propertyData.paymentType === 'manual' || allVendorBills.some((bill: any) => bill.paymentType === 'manual'),
      isAnyVendorBillIsPrintCheck:
        props.propertyData.paymentType === 'printCheck' || allVendorBills.some((bill: any) => bill.paymentType === 'printCheck'),
    };
  });

  const changeActive = (index: number) => {
    setActiveIndex((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
  };

  const isOpen = (index: number) => activeIndex().includes(index);

  const onChangeAll = (propertyId: string, key: 'paymentType' | 'paymentMethod' | 'memo' | 'externalTransactionId', val: string) => {
    props.setFormData('billPayments', (billPayments) => {
      return billPayments.map((payment) => {
        if (payment.propertyId === propertyId) {
          const updatedPayment = { ...payment, [key]: val };
          if (key === 'paymentType') {
            if (val === 'manual') {
              updatedPayment.paymentMethod = paymentMethodOptions[0].value;
              updatedPayment.externalTransactionId = '';
            } else if (val === 'printCheck') {
              updatedPayment.paymentMethod = undefined;
              updatedPayment.externalTransactionId = undefined;
            }
          }
          updatedPayment.vendors = updatedPayment.vendors?.map((vendor) => ({
            ...vendor,
            bills: vendor.bills?.map((bill) => ({
              ...bill,
              [key]: val,
              paymentMethod: key === 'paymentType' ? (val === 'manual' ? paymentMethodOptions[0].value : undefined) : bill.paymentMethod,
              externalTransactionId: key === 'paymentType' ? (val === 'manual' ? '' : undefined) : bill.externalTransactionId,
            })),
          }));
          return updatedPayment;
        }
        return payment;
      });
    });
  };

  const onChange = (
    propertyId: string,
    billId: string,
    key: 'paymentType' | 'paymentMethod' | 'memo' | 'externalTransactionId',
    val: string
  ) => {
    props.setFormData('billPayments', (billPayments) => {
      return billPayments.map((payment) => {
        if (payment.propertyId === propertyId) {
          const updatedPayment = { ...payment };
          let allBillsHaveSameValue = true;

          updatedPayment.vendors = payment.vendors?.map((vendor) => ({
            ...vendor,
            bills: vendor.bills?.map((bill) => {
              if (bill.id === billId) {
                const updatedBill = { ...bill, [key]: val };
                if (key === 'paymentType') {
                  if (val === 'manual') {
                    updatedBill.paymentMethod = updatedBill.paymentMethod || paymentMethodOptions[0].value;
                    updatedBill.externalTransactionId = updatedBill.externalTransactionId || '';
                  } else if (val === 'printCheck') {
                    updatedBill.paymentMethod = undefined;
                    updatedBill.externalTransactionId = undefined;
                  }
                }
                return updatedBill;
              }
              if (bill[key] !== val) {
                allBillsHaveSameValue = false;
              }
              return bill;
            }),
          }));

          if (allBillsHaveSameValue) {
            (updatedPayment as any)[key] = val;

            if (key === 'paymentType') {
              if (val === 'manual') {
                updatedPayment.paymentMethod = paymentMethodOptions[0].value;
                updatedPayment.externalTransactionId = '';
              } else if (val === 'printCheck') {
                updatedPayment.paymentMethod = undefined;
                updatedPayment.externalTransactionId = undefined;
              }
            }
          }

          return updatedPayment;
        }
        return payment;
      });
    });
  };

  return (
    <div class="mb-4 w-full overflow-hidden rounded-lg border">
      <div
        onClick={() => changeActive(0)}
        class={cn('flex cursor-pointer items-center gap-2 rounded-lg bg-input px-3 py-4 text-xs', { 'border-b': isOpen(0) })}>
        <div class="w-4/12">
          <PropertyTitle
            class="[&_h4]:text-xs [&_h4]:font-medium [&_img]:size-9"
            property={props.propertyData.property}
            feedbackIconReadonly
          />
        </div>
        <div class="w-1/12 text-text-level01">
          {props.propertyData.vendors?.reduce((sum: any, vendor: { bills: string | any[] }) => sum + (vendor.bills?.length || 0), 0)}{' '}
          {t('Bills')}
        </div>
        <div class="w-2/12">
          <DropdownMenu
            buttonClass="bg-white text-xs"
            overlayClass="text-xs"
            value={props.propertyData.paymentType || 'printCheck'}
            options={paymentTypeOptions}
            placeholder={t('Select payment type')}
            onChange={(selected) => onChangeAll(props.propertyData.property.id, 'paymentType', selected)}
          />
        </div>
        <div class="w-2/12">
          <Show when={props.propertyData.paymentType === 'manual'}>
            <DropdownMenu
              buttonClass="bg-white text-xs"
              overlayClass="text-xs"
              value={props.propertyData.paymentMethod || paymentMethodOptions[0].value}
              options={paymentMethodOptions}
              placeholder={t('Select payment method')}
              onChange={(val) => onChangeAll(props.propertyData.property.id, 'paymentMethod', val)}
            />
          </Show>
        </div>
        <div class="flex w-2/12 items-center justify-end">
          <span class="ml-1 text-xs font-semibold text-text-level01">{currency(props.propertyData.paymentAmount)}</span>
        </div>
        <div class="inline-flex w-1/12 justify-end">
          <CircleArrowIcon
            class="ml-5 mr-1"
            classList={{
              'text-text-level02': !isOpen(0),
              'text-primary rotate-180': isOpen(0),
            }}
          />
        </div>
      </div>
      <Show when={isOpen(0)}>
        <div class="w-full px-5 py-6 transition-all">
          <div class="text-xs font-semibold text-text-level01">{t('Bills payment detail')}</div>
          <div class="not-prose relative mt-2 overflow-hidden rounded-lg bg-white">
            <div
              class="bg-grid-slate-100 absolute inset-0 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))]"
              style={{ 'background-position': '10px 10px' }}
            />
            <div class="relative rounded-lg">
              <div class="shadow-sm">
                <div class="flex w-full table-auto flex-col text-xs text-text-level03">
                  <div class="flex items-center border-b bg-[rgba(243,_200,_255,_0.40)] text-[rgba(161,_38,_236,_0.80)]">
                    <div class="w-2/12 px-4 py-[14px] text-left">{t('Vendor')}</div>
                    <div class="w-2/12 px-4 py-[14px] text-right">{t('Bill amount')}</div>
                    <div class="w-2/12 px-4 py-[14px] text-left">{t('Payment type')}</div>
                    <Show when={columnControl().isAnyVendorBillIsManual}>
                      <div class="w-2/12 px-4 py-[14px] text-left">{t('Payment method')}</div>
                    </Show>
                    <div class={cn('w-2/12 px-4 py-[14px] text-left', { 'w-3/12': !columnControl().isAnyVendorBillIsManual })}>
                      {t('Transaction ID')}
                    </div>
                    <div class={cn('w-2/12 px-4 py-[14px] text-left', { 'w-3/12': !columnControl().isAnyVendorBillIsManual })}>
                      {t('Memo')}
                    </div>
                  </div>
                  <div class="text-text-level01">
                    <For each={props.propertyData.vendors}>
                      {(vendor) => (
                        <For each={vendor.bills}>
                          {(bill) => (
                            <div class="flex items-center border-b border-slate-100">
                              <div class="w-2/12 p-4">
                                <div class="flex h-[38px] items-center">{vendor.name}</div>
                              </div>
                              <div class="w-2/12 p-4">
                                <div class="flex h-[38px] items-center justify-end">{currency(bill.amount)}</div>
                              </div>
                              <div class="w-2/12 p-4">
                                <div class="flex h-[38px] items-center">
                                  <DropdownMenu
                                    buttonClass="bg-light-gray text-xs"
                                    class="w-full"
                                    overlayClass="text-xs"
                                    value={bill.paymentType || props.propertyData.paymentType || 'printCheck'}
                                    options={paymentTypeOptions}
                                    placeholder={t('Select payment type')}
                                    onChange={(val) => onChange(props.propertyData.property.id, bill.id, 'paymentType', val)}
                                  />
                                </div>
                              </div>
                              <Show when={columnControl().isAnyVendorBillIsManual}>
                                <div class="w-2/12 p-4">
                                  <Show when={(bill.paymentType || props.propertyData.paymentType) === 'manual'}>
                                    <div class="flex h-[38px] items-center">
                                      <DropdownMenu
                                        buttonClass="bg-light-gray text-xs"
                                        class="w-full"
                                        overlayClass="text-xs"
                                        value={bill.paymentMethod || props.propertyData.paymentMethod || paymentMethodOptions[0].value}
                                        options={paymentMethodOptions}
                                        placeholder={t('Select payment method')}
                                        onChange={(val) => onChange(props.propertyData.property.id, bill.id, 'paymentMethod', val)}
                                      />
                                    </div>
                                  </Show>
                                </div>
                              </Show>
                              <div class={cn('w-2/12 p-4', { 'w-3/12': !columnControl().isAnyVendorBillIsManual })}>
                                <Show
                                  when={
                                    (bill.paymentType || props.propertyData.paymentType) === 'printCheck' ||
                                    ((bill.paymentType || props.propertyData.paymentType) === 'manual' &&
                                      (bill.paymentMethod || props.propertyData.paymentMethod) === 'check')
                                  }
                                  fallback={
                                    <div class="flex h-[38px] items-center">
                                      <LabeledTextInput
                                        inputContainerClass="bg-light-gray"
                                        inputClass="text-xs"
                                        class="w-full"
                                        value={bill.externalTransactionId || props.propertyData.externalTransactionId || ''}
                                        placeholder={t('Enter transaction ID')}
                                        onInput={(val) => onChange(props.propertyData.property.id, bill.id, 'externalTransactionId', val)}
                                      />
                                    </div>
                                  }>
                                  {t('It will be generated automatically')}
                                </Show>
                              </div>
                              <div class={cn('w-2/12 p-4', { 'w-3/12': !columnControl().isAnyVendorBillIsManual })}>
                                <LabeledTextInput
                                  maxlength={150}
                                  inputClass="text-xs"
                                  inputContainerClass="bg-light-gray"
                                  value={bill.memo || vendor.defaultMemo || ''}
                                  placeholder={t('Enter memo')}
                                  onInput={(e) => onChange(props.propertyData.property.id, bill.id, 'memo', e)}
                                  validationFunction={(value) => {
                                    const str = value.toString();
                                    return str.length > 150
                                      ? t('{name} must be {length} characters or less', { name: t('Memo'), length: '150' })
                                      : undefined;
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </For>
                      )}
                    </For>
                  </div>
                </div>
              </div>
            </div>
            <div class="pointer-events-none absolute inset-0 rounded-lg border border-black/5 dark:border-white/5" />
          </div>
        </div>
      </Show>
    </div>
  );
};

interface PaymentFormProps {
  store: any;
  form: PayBillsForm;
  formError: PayBillsFormError;
  setFormData: SetStoreFunction<PayBillsForm>;
}

const PaymentForm: Component<PaymentFormProps> = (props) => {
  const { t } = useLocalization();
  const [paymentDate, setPaymentDate] = createSignal<string | null>(new Date().toISOString().split('T')[0]);
  const isShowPaymentMethodColumn = createMemo(
    () => props.form.billPayments.filter((record) => record.paymentType === 'manual').length !== 0
  );

  const paymentTotal = createMemo(() => {
    return props.form.billPayments.reduce((total, payment) => total + (payment.paymentAmount || 0), 0);
  });

  const handleDateChange = (date: string | null) => {
    setPaymentDate(date);
    props.setFormData('paymentDate', date);
  };

  return (
    <div class="thinscroll mx-auto flex w-10/12 min-w-[710px] flex-col gap-5 overflow-x-auto">
      <div>
        <div class=" border-partingline py-3">
          <h4 class="text-base font-semibold">{t('Payment')}</h4>
          <div class="my-4">
            <DueDateInputField
              label={t('Payment date')}
              value={paymentDate()}
              class="w-1/2"
              onInput={handleDateChange}
              placeholder={t('Select payment date')}
            />
          </div>
        </div>
        <div class="my-4 flex w-full justify-between gap-2 px-3 text-sm">
          <span class="w-4/12 text-slate-400">{t('Property')}</span>
          <span class="w-1/12 text-slate-400">{t('Bills')}</span>
          <span class="w-2/12 text-slate-400">{t('Payment type')}</span>
          <span class="w-2/12 text-slate-400">
            <Show when={isShowPaymentMethodColumn()}>{t('Payment method')}</Show>
          </span>
          <span class="w-2/12 text-right text-slate-400">{t('Payment amount')}</span>
          <span class="w-1/12" />
        </div>
        <For each={props.form.billPayments}>
          {(propertyData) => <BillItem propertyData={propertyData} vendors={props.store.vendors || []} setFormData={props.setFormData} />}
        </For>
        <div class="my-8 flex items-center justify-end text-sm text-text-level02">
          <div class="text-sm uppercase text-text-level02">
            {t('Payment total')}: <span class="text-base font-semibold text-essential-colour">{currency(paymentTotal())}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
