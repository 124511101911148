import { DocumentIdRequiredError } from '~/pdfsigner/errors/idRequiredError';
import { DocumentSignersMissingError } from '~/pdfsigner/errors/signersMissingError';
import { DocumentTitleRequiredError } from '~/pdfsigner/errors/titleRequiredError';
import { AnnotationType } from './types/annotation';
import { UseCase } from './useCase';

export class ValidateDocumentUseCase extends UseCase {
  public async runLogic() {
    if (!this.state.selectedDocument?.title || this.state.selectedDocument.title === '') {
      throw new DocumentTitleRequiredError();
    }
    if (!this.state.selectedDocument.id) {
      throw new DocumentIdRequiredError();
    }
    if (!this.haveAllSignersBeenPlaced()) {
      throw new DocumentSignersMissingError();
    }
  }

  private haveAllSignersBeenPlaced(): boolean {
    const placedSigners = new Set();
    this.state.selectedDocument?.pdfPages.forEach((page) => {
      page.annotations.forEach((annotation) => {
        if (annotation.type === AnnotationType.SIGNATURE && !placedSigners.has(annotation.signerId)) {
          placedSigners.add(annotation.signerId);
        }
      });
    });
    return placedSigners.size === this.state.selectedDocument?.signers.length;
  }
}
