import { useNavigate, useParams } from '@solidjs/router';
import { createSignal, onMount } from 'solid-js';
import MiddleModal from '~/components/modals/MiddleModal';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { AddAndEditRunbook } from './AddAndEditRunbook';
import type { Component } from 'solid-js';

export const AddAndEditRunbookModal: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const params = useParams<{ runbookId: string }>();

  const { getRunBook } = useRunBooks();
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const handleClose = (newRunbookId?: string) => {
    if (newRunbookId) {
      navigate(`/maintenance/ai-runbooks/${newRunbookId}`);
    } else {
      navigate(-1);
    }
  };

  onMount(() => {
    if (params.runbookId) {
      getRunBook(params.runbookId);
    }
  });

  return (
    <MiddleModal
      confirmation={showCloseConfirmation()}
      onClose={handleClose}
      confirmationTitle={t('Are you sure you want to cancel?')}
      confirmationMessage={t('If you cancel the addition, the information already filled in cannot be saved')}>
      <AddAndEditRunbook
        isEdit={!!params.runbookId}
        onClose={handleClose}
        showCloseConfirmation={showCloseConfirmation()}
        setShowCloseConfirmation={setShowCloseConfirmation}
      />
    </MiddleModal>
  );
};
