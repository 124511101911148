import { UseCase } from './useCase';
import type { LeaseTemplateUpdateRequest } from './types/leaseTemplateUpdateRequest';

export class UpdateLeaseTemplateUseCase extends UseCase {
  public async runLogic(request: LeaseTemplateUpdateRequest) {
    if (!request) {
      return;
    }

    if (
      this.state.dataPathManager.doesDataPathWithKeyExist(request.dataPathKey) &&
      this.state.dataPathManager.getSelectedNodeKey() !== request.dataPathKey
    ) {
      this.state.dataPathManager.selectNodeWithKey(request.dataPathKey);
    }
    if (this.state.selectedDocument) {
      if (request.leaseCategoryId) {
        const desiredCategory = this.state.leaseTemplateCategories.find((item) => item.id === request.leaseCategoryId);
        this.state.selectedDocument.category = desiredCategory;
      }
      this.state.selectedDocument.title = request.title ? request.title : this.state.selectedDocument?.title;
    }
  }
}
