import { A } from '@solidjs/router';
import { Show, splitProps, createMemo, For } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { pickProps, TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { formatTenantNames } from '~/components/tenants/utils';
import { UnitImage } from '~/components/units';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { LeaseName } from './LeaseName';
import { LeasePeriod } from './LeasePeriod';
import { isMonthToMonth } from './utils';
import type { ComponentProps, ValidComponent } from 'solid-js';
import type { TitleWithFeedbackIconProps } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import type { CustomLink } from '~/types/display';
import type { Merge } from '~/utils/types';

export const LeaseTitle = <T extends ValidComponent>(
  props: Merge<
    ComponentProps<T>,
    {
      as?: T;
      class?: string;
      lease?: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto;
      property?: MagicDoor.Api.PropertyDto;
      unit?: MagicDoor.Api.UnitDto;
    } & Partial<TitleWithFeedbackIconProps> & {
        showTenants?: boolean;
        customLinks?: CustomLink[];
      }
  >
) => {
  const { t } = useLocalization();
  const [params, rest] = splitProps(props, ['as', 'class', 'lease']);

  const lease = createMemo(() => ({
    property: props.property,
    unit: props.unit,
    ...props.lease,
  }));

  return (
    <TitleWithFeedbackIcon
      class="h-fit"
      {...pickProps(rest)}
      type="leases"
      entityId={params.lease?.id}
      initalSelectedIcons={params.lease?.icons}>
      <Dynamic component={params.as || 'div'} class={cn('flex flex-col', params.class)} {...rest}>
        <div class="flex items-center gap-3">
          <UnitImage class="size-12 rounded-lg" unit={lease().unit} />
          <div class="line-clamp-2 max-w-[calc(100%-3rem)] space-y-1 text-sm">
            <LeaseName lease={lease() as any} />
            <div class="flex flex-col items-start text-xs text-text-level03 md:flex-row ">
              <div class="flex items-center md:border-r-2 md:border-partingline md:pr-2">
                <Show when={isMonthToMonth(params.lease)}>{t('Month-To-Month')}:</Show>
                <LeasePeriod class="text-xs" lease={params.lease} />
              </div>
              <Show
                when={
                  props.showTenants &&
                  params.lease?.tenants &&
                  params.lease.tenants.some((tenant: { isMovedOut: boolean }) => !tenant.isMovedOut)
                }>
                <span class="flex items-center text-xs text-text-level03 md:ml-2 md:mt-0">
                  {t('Tenants')}:
                  {formatTenantNames(
                    params.lease?.tenants.filter((tenant: { isMovedOut: any }) => !tenant.isMovedOut),
                    2
                  )}
                </span>
              </Show>
            </div>

            <Show when={props.customLinks && props.customLinks.length > 0}>
              <div class="flex flex-wrap items-start text-xs">
                <For each={props.customLinks}>
                  {(link, index) => (
                    <>
                      <A href={link.href} class="text-primary hover:underline">
                        {link.displayName}
                      </A>
                      <Show when={index() < props.customLinks!.length - 1}>
                        <span class="mx-2  border-r border-partingline">&nbsp;</span>
                      </Show>
                    </>
                  )}
                </For>
              </div>
            </Show>
          </div>
        </div>
      </Dynamic>
    </TitleWithFeedbackIcon>
  );
};
