import { UseCase } from '~/pdfsigner/usecases/useCase';
import { portfolioRepository } from '~/repositories/portfolioRepository';
import type { PortfolioDto } from '~/swagger/Api';

export class BatchGetPortfoliosUseCase extends UseCase {
  public async runLogic(portfolioIds?: string[]) {
    let portofolios: PortfolioDto[] = [];
    if (portfolioIds && portfolioIds.length > 0) {
      portofolios = await portfolioRepository.batchGetPortfolioByIds(portfolioIds);
    }
    this.state.portfolios = portofolios;
  }
}
