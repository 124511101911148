import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, createSignal, onMount } from 'solid-js';
import { FileUploadModal } from '~/components/file-attachments';
import { useLeaseRentTransactions } from '~/contexts/local';
import { LeaseDepositTransactionDetailsModal } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseDepositTransactionDetailsModal';
import type { Component } from 'solid-js';

type LeaseTransactionDetailModalProps = {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
};

export const LeaseTransactionDetailModal: Component<LeaseTransactionDetailModalProps> = (props) => {
  const navigate = useNavigate();
  const params = useParams<{ leaseId: string; transactionId: string }>();
  const [showUploadModal, setShowUploadModal] = createSignal<boolean>(false);
  const { transactions, loading, getLeaseRentTransactions } = useLeaseRentTransactions();

  const currentTransaction = createMemo(() => {
    return transactions().find((item) => item.id === params.transactionId);
  });

  const originalTransactionId = createMemo(() => {
    if (currentTransaction()?.type === 'return') {
      const originalId = transactions().find((item) => item?.transaction?.refundTransactionId === currentTransaction()!.id)?.id;
      if (originalId) {
        return originalId;
      } else {
        return '';
      }
    } else {
      return '';
    }
  });

  const handleOpenDetail = (refundTransactionId?: string | undefined) => {
    navigate(`/leasing/leases/${params.leaseId}/transactions/${refundTransactionId}`);
  };

  onMount(() => {
    if (!transactions().length) getLeaseRentTransactions(params.leaseId);
  });
  return (
    <>
      <LeaseDepositTransactionDetailsModal
        currentLease={props.currentLease}
        detailLoading={loading()}
        onCancel={() => navigate(-1)}
        transactionDetail={currentTransaction()!}
        originalTransactionId={originalTransactionId()}
        originalOpen={handleOpenDetail}
        showUpload={true}
        visible={true}
        onUpload={() => setShowUploadModal(true)}
      />
      <FileUploadModal
        entryId={currentTransaction()?.bill ? currentTransaction()?.bill?.id : currentTransaction()?.transaction?.id}
        entryType={currentTransaction()?.bill ? 'bill' : 'transaction'}
        open={showUploadModal()}
        onClose={() => setShowUploadModal(false)}
      />
    </>
  );
};
