import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, createSignal, Show } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { Modal } from '~/components/modals/Modal';
import { LabeledPropertyManagerSearch } from '~/components/property-managers/PropertyManagerSearch';
import { toast } from '~/components/ui';
import { LabeledVendorSearch } from '~/components/vendors';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import type { Component } from 'solid-js';

interface EditDefaultPmAndVendorModalProps {
  source: 'vendor' | 'propertyManager';
}

export const EditDefaultPmAndVendorModal: Component<EditDefaultPmAndVendorModalProps> = (props) => {
  const form = useForm();
  const { t } = useLocalization();
  const navigate = useNavigate();

  const params = useParams<{ runbookId: string }>();
  const [loading, setLoading] = createSignal<boolean>(false);
  const { runBook, updateDefaultPropertyManager, updateDefaultVendor } = useRunBooks();

  const handleClose = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    setLoading(true);
    const apiFunc = props.source === 'vendor' ? updateDefaultVendor : updateDefaultPropertyManager;
    const runbookId = runBook()?.id || params.runbookId;
    await apiFunc(runbookId, {
      ...(form.formStore as any),
    });
    setLoading(false);
    toast.success(t('{name} has been edited successfully', { name: t('AI runbook') }));
    handleClose();
  };

  createEffect(() => {
    if (props.source === 'vendor') {
      form.setFormStore('defaultVendorId', runBook()?.defaultVendorId);
    } else {
      form.setFormStore('defaultPropertyManagerId', runBook()?.defaultPropertyManagerId);
    }
  });

  return (
    <Modal
      visible
      class="animate-zoom-in capitalize"
      loading={loading()}
      title={t(props.source === 'vendor' ? 'Edit default vendor' : 'Edit default property manager')}
      doneText={t('Confirm')}
      onDone={handleSubmit}
      onCancel={handleClose}
      confirmation={false}>
      <Form defaultForm={form} class="mb-6 p-8" onFormSubmit={handleSubmit}>
        <Show
          when={props.source === 'vendor'}
          fallback={
            <FormItem
              class="col-span-full"
              label={t('Default property manager')}
              formFieldName="defaultPropertyManagerId"
              component={LabeledPropertyManagerSearch}
              defaultSelectedIds={runBook()?.defaultPropertyManagerId ?? undefined}
              placeholder={t('Please select property manager')}
              rules={[{ required: true, message: t('Please select property manager') }]}
            />
          }>
          <FormItem
            class="col-span-full"
            label={t('Default vendor')}
            formFieldName="defaultVendorId"
            component={LabeledVendorSearch}
            defaultSelectedIds={runBook()?.defaultVendorId ?? undefined}
            placeholder={t('Please select vendor')}
            rules={[{ required: true, message: t('Please select vendor') }]}
          />
        </Show>
      </Form>
    </Modal>
  );
};
