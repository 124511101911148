import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { MaintenanceRequestRepository } from '~/repositories/maintenanceRequestRepository';
import { MaintenanceRequestUrgency } from './MaintenanceRequestUrgency';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { EntrySearchProps } from '~/components/ui';
import type { MaintenanceRequestFilter } from '~/repositories/maintenanceRequestRepository';

const repo = new MaintenanceRequestRepository();

export const MaintenanceRequestSearch = <T extends boolean | undefined = undefined>(
  props: EntrySearchProps<MagicDoor.Api.MaintenanceRequestListDto, T, MaintenanceRequestFilter>
) => {
  const { t } = useLocalization();
  return (
    <SearchSelect
      fetcher={(query: string | undefined, page: number) =>
        repo.getMaintenanceRequests({ pageSize: 20, ...props.filter, page, searchText: query || undefined })
      }
      placeholder={t('Search maintenance requests')}
      renderSelected="title"
      renderItem={(item) => (
        <div>
          <div class="mb-1 cursor-pointer font-medium text-link">{item.title}</div>
          <MaintenanceRequestUrgency urgency={item.urgency} />
        </div>
      )}
      {...props}
    />
  );
};

export const LabeledMaintenanceRequestSearch = <T extends boolean | undefined = undefined>(
  props: Omit<EntrySearchProps<MagicDoor.Api.MaintenanceRequestListDto, T>, 'onSelect'> &
    LabeledGroupProps & {
      value?: T extends true ? string[] : string;
      onInput?: (value: T extends true ? string[] : string) => void;
    }
) => {
  const [searchProps, labeledProps] = splitProps(props, ['multiple', 'selected', 'placeholder', 'onInput', 'exclude']);

  // TODO: get entries by ids???

  return (
    <LabeledGroup {...labeledProps}>
      <MaintenanceRequestSearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.((Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string);
        }}
      />
    </LabeledGroup>
  );
};
