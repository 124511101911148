import { Show, For } from 'solid-js';
import { useSearchSelectContext } from '~/components/search-select/Context';
import { useLocalization } from '~/contexts/global';
import { stringToColor } from '~/utils/strings';
import { renderSelected } from './renderSelected';
export const MutiRender = () => {
  const { selected, handleRemove } = useSearchSelectContext();
  const { t } = useLocalization();
  return (
    <Show when={selected().length > 0}>
      <div class="flex select-none flex-wrap gap-1 py-1">
        <For each={selected()}>
          {(item) => {
            const label = renderSelected(item);
            return (
              <button
                type="button"
                class="flex items-center gap-1 rounded-md bg-current-alpha px-1.5 text-sm text-[--c] after:text-base after:content-['×']"
                style={{ '--c': stringToColor(typeof label === 'string' ? label : (item.id as string) || 'unknown') }}
                onClick={(e) => handleRemove(e, item)}
                title={t('Remove select')}>
                {label}
              </button>
            );
          }}
        </For>
      </div>
    </Show>
  );
};
