import { ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { KeyValuePair } from './EditMagicTagsModal';
import type { Component } from 'solid-js';

export const MagicTagsList: Component<{
  defaultData?: KeyValuePair;
  class?: string;
}> = (props) => {
  const { t } = useLocalization();

  return (
    <ListView
      class={`w-full flex-1 ${props.class}`}
      skeletonSize={10}
      hideFooter
      data={Object.entries(props?.defaultData ?? {}).map(([key, value]) => ({ key, value }))}
      columns={[
        {
          title: t('Magic tags'),
          render: (item) => item?.key,
        },
        {
          title: t('Value'),
          render: (item) => item?.value,
        },
      ]}
    />
  );
};
