import { For, Show, createSignal, onCleanup, onMount } from 'solid-js';
import IconNoResults from '~/assets/images/empty/noResults.png';
import IconRobotSearch from '~/assets/images/identity/robotSearch.png';
import { RobotLoader } from '~/components/common/Loaders/RobotLoader/RobotLoader';
import { Loader } from '~/components/ui';
import { StaticTabs, StaticTabsList, StaticTabsTrigger } from '~/components/ui/Tabs/StaticTabs';
import { useLocalization } from '~/contexts/global';
import type { Accessor } from 'solid-js';
import type { SearchResult, SearchResults } from '~/components/layouts/Navigation/MagicSearchBar/MagicSearchContext';

interface MagicSearchDropdownProps {
  searchResults: () => SearchResults;
  searchTerm: () => string | undefined;
  isTyping: () => boolean;
  handleResultClick: (result: SearchResult) => void;
  isLoading: () => boolean;
  loadMore: (type: string) => Promise<void>;
  hasMore: (type: string) => boolean;
  getResultHref: (result: SearchResult) => string;
  isFirstLoad: Accessor<boolean>;
}

export const MagicSearchDropdown = (props: MagicSearchDropdownProps) => {
  const { t } = useLocalization();

  const [selectedTab, setSelectedTab] = createSignal<string>('All');
  const [isLoadingMore, setIsLoadingMore] = createSignal<boolean>(false);

  let scrollContainerRef: HTMLDivElement | undefined;
  let scrollTimeout: ReturnType<typeof setTimeout>;

  const getTotalResultCount = () => {
    const total = Object.values(props.searchResults() || {}).reduce((total, results) => total + results.length, 0);
    const hasMore = Object.keys(props.searchResults() || {}).some((key) => props.hasMore(key.toLowerCase()));
    return hasMore ? `${total}+` : total;
  };

  const getResultsForTab = (tab: string): SearchResult[] => {
    const key = tab.toLowerCase() as keyof SearchResults;
    return props.searchResults()[key] || [];
  };

  const hasResults = () => {
    return Object.values(props.searchResults() || {}).some((results) => results.length > 0);
  };

  const handleScroll = async () => {
    if (!shouldHandleScroll()) return;

    const isNearBottom = isScrollNearBottom();
    if (!isNearBottom) return;

    if (!props.hasMore(selectedTab().toLowerCase())) return;

    await loadMoreResults();
  };

  const shouldHandleScroll = () => {
    return scrollContainerRef && selectedTab() !== 'All' && !isLoadingMore();
  };

  const isScrollNearBottom = () => {
    if (!scrollContainerRef) return false;
    const { scrollHeight, scrollTop, clientHeight } = scrollContainerRef;
    return scrollHeight - scrollTop <= clientHeight + 100;
  };

  const loadMoreResults = async () => {
    setIsLoadingMore(true);
    clearTimeout(scrollTimeout);

    scrollTimeout = setTimeout(async () => {
      const scrollPosition = scrollContainerRef?.scrollTop ?? 0;
      await props.loadMore(selectedTab().toLowerCase());
      setIsLoadingMore(false);

      setTimeout(() => {
        if (scrollContainerRef) {
          scrollContainerRef.scrollTop = scrollPosition;
        }
      }, 0);
    }, 200);
  };

  const handleTabChange = (value: string) => {
    setSelectedTab(value);
    if (scrollContainerRef) {
      scrollContainerRef.scrollTop = 0;
    }
  };

  const handleViewAll = (category: string, e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    handleTabChange(category);
  };

  onMount(() => {
    if (scrollContainerRef) {
      scrollContainerRef.addEventListener('scroll', handleScroll);
    }
  });

  onCleanup(() => {
    if (scrollContainerRef) {
      scrollContainerRef.removeEventListener('scroll', handleScroll);
    }
    clearTimeout(scrollTimeout);
  });

  return (
    <div class="absolute left-0 top-full w-full rounded-b-lg bg-white shadow-lg">
      <Show
        when={props.isLoading() && props.isFirstLoad()}
        fallback={
          <Show
            when={hasResults()}
            fallback={
              <Show
                when={props.searchTerm() && !props.isTyping()}
                fallback={
                  <div class="flex flex-col items-center justify-center py-10 text-center">
                    <img src={IconRobotSearch} alt="robotSearch" />
                    <div class="text-sm text-[#999999]">{t('Type to search')}...</div>
                  </div>
                }>
                <div class="flex flex-col items-center justify-center py-10 text-center">
                  <img src={IconNoResults} alt="noResults" />
                  <div class="text-sm text-[#999999]">{t('No results found')}...</div>
                </div>
              </Show>
            }>
            <StaticTabs defaultSelected="All" class="flex h-full flex-col" onChange={handleTabChange} selected={selectedTab()}>
              <div class="shrink-0 overflow-hidden border-b border-partingline px-4">
                <StaticTabsList class="flex gap-6 px-2">
                  <StaticTabsTrigger value="All" resultCount={getTotalResultCount()}>
                    {t('All')}
                  </StaticTabsTrigger>
                  <For each={Object.entries(props.searchResults() || {})}>
                    {([key, results]) => (
                      <Show when={results.length > 0}>
                        <StaticTabsTrigger
                          value={key}
                          resultCount={props.hasMore(key.toLowerCase()) ? `${results.length}+` : results.length}>
                          {key.charAt(0).toUpperCase() + key.slice(1)}
                        </StaticTabsTrigger>
                      </Show>
                    )}
                  </For>
                </StaticTabsList>
              </div>

              <div
                ref={scrollContainerRef}
                class="thinscroll max-h-[calc(100vh-300px)] grow overflow-y-auto rounded-b-lg px-4 pb-4"
                onScroll={handleScroll}>
                <Show when={selectedTab() === 'All'}>
                  <For each={Object.entries(props.searchResults() || {})}>
                    {([key, results]) => (
                      <Show when={results.length > 0}>
                        <SearchResultsList
                          title={key.charAt(0).toUpperCase() + key.slice(1)}
                          results={results}
                          onResultClick={props.handleResultClick}
                          getResultHref={props.getResultHref}
                        />
                        <Show when={props.hasMore(key.toLowerCase())}>
                          <div class="px-3 py-2 text-center">
                            <button class="text-sm text-primary hover:underline" onClick={(e) => handleViewAll(key, e)}>
                              {t('View more')} {key} ({results.length}+)
                            </button>
                          </div>
                        </Show>
                      </Show>
                    )}
                  </For>
                </Show>
                <Show when={selectedTab() !== 'All'}>
                  <SearchResultsList
                    results={getResultsForTab(selectedTab())}
                    onResultClick={props.handleResultClick}
                    getResultHref={props.getResultHref}
                  />
                  <Show when={isLoadingMore()}>
                    <div class="p-4 text-center text-sm text-text-level03">
                      <Loader class="mx-auto my-2 h-8 opacity-50" />
                    </div>
                  </Show>
                  <Show when={!props.hasMore(selectedTab().toLowerCase()) && !isLoadingMore()}>
                    <div class="p-4 text-center text-sm text-text-level03">{t('No more results')}</div>
                  </Show>
                </Show>
              </div>
            </StaticTabs>
          </Show>
        }>
        <div class="flex flex-col items-center justify-center py-10 text-center">
          <div class="text-sm text-[#999999]">
            <RobotLoader text={t('Loading')} class="p-4 " />
          </div>
        </div>
      </Show>
    </div>
  );
};

const SearchResultsList = (props: {
  title?: string;
  results: SearchResult[];
  onResultClick: (result: SearchResult) => void;
  getResultHref: (result: SearchResult) => string;
}) => {
  return (
    <div>
      {props.title && <h3 class="p-3 text-sm text-text-level03">{props.title}</h3>}
      <ul>
        <For each={props.results}>
          {(result: SearchResult) => (
            <li class="border border-transparent border-b-input-border hover:rounded-md hover:border hover:border-primary hover:bg-primary/10">
              <a href={props.getResultHref(result)} onClick={() => props.onResultClick(result)} class="block cursor-pointer px-3 py-2">
                {result.renderItem()}
              </a>
            </li>
          )}
        </For>
      </ul>
    </div>
  );
};
