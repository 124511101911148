import { leaseDraftRepository } from '~/pdfsigner/repositories/leaseDraftRepository';
import { GetDataPathsUseCase } from './getDataPathsUseCase';
import { UseCase } from './useCase';
import type { Document } from './types/document';

export class LoadLeaseDraftUseCase extends UseCase {
  public async runLogic(draftId?: string) {
    if (!draftId) {
      return;
    }
    const document: Document = await leaseDraftRepository.getLeaseDraft(draftId);
    await new GetDataPathsUseCase().execute(document.leaseId);
    this.state.selectedDocument = document;
    const selectedSigner = this.state.selectedDocument.signers.find((signer) => signer.id === this.state.selectedSigner?.id);
    if (!selectedSigner) {
      this.state.selectedSigner = this.state.selectedDocument.signers[0];
    }
  }
}
