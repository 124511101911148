import { createContext, useContext } from 'solid-js';
import { createStore, produce, unwrap } from 'solid-js/store';
import {
  compareTwoNamePath,
  getErrorMessage,
  isControlledByYourself,
  namePathToString,
  stringToNamePath,
  transFormNamePath,
} from '~/components/common/BetterForm/utils';
import { cleanData, cloneDeep } from '~/utils/tool';
import type { BetterForm } from '~/components/common/BetterForm/type';

const defaultFormContext = createContext<BetterForm.ContextValue>();

export const getFormContext = () => {
  return defaultFormContext;
};

export const useFormContext = (formContext?: ReturnType<typeof getFormContext>) => {
  const context = useContext(defaultFormContext || formContext);
  if (!context) {
    throw new Error('Form context is not found');
  }
  return context;
};

export const getValueFromStore = (store: BetterForm.FormStore, name?: BetterForm.NamePath) => {
  if (!name) {
    return;
  }
  const _namePath = transFormNamePath(name);
  return _namePath.reduce((acc, cur) => acc?.[cur], store);
};

export const copyStore = (store: BetterForm.FormStore) => {
  return cloneDeep(store);
};

export const useForm = () => {
  let formRef: HTMLFormElement;
  let cachedValue: any;

  const [configStore, setConfigStore] = createStore<BetterForm.ConfigStore>({
    fieldsErrors: {},
    rules: {},
  });

  const [formStore, setFormStore] = createStore<BetterForm.FormStore>({});

  function setFormError(namePath: BetterForm.NamePath, error: string | undefined) {
    const namePathStr = transFormNamePath(namePath);
    if (namePathStr.length > 0) {
      setConfigStore(
        produce((draft) => {
          const path = namePathStr.join('.');
          error ? (draft.fieldsErrors[path] = error) : delete draft.fieldsErrors[path];
        })
      );
    }
  }

  function validateForm(name?: BetterForm.NamePath, setError = true, specificFieldPath?: string, callback?: (errorList: string[]) => void) {
    const { rules, fieldsErrors } = unwrap(configStore);
    const _formStore = unwrap(formStore);
    const result: {
      errorFields: any[];
      validateStatus: boolean;
    } = {
      errorFields: [],
      validateStatus: true,
    };
    for (const key in rules) {
      const tempName = key.split('.')[key.split('.').length - 1];
      if (specificFieldPath && tempName !== 'address') continue;

      const namePath = stringToNamePath(key);
      if (name && !compareTwoNamePath(namePath, name)) {
        continue;
      }
      const value = getValueFromStore(_formStore, namePath);
      const rule = rules[key];
      if (isControlledByYourself(rule)) {
        if (fieldsErrors[key]) {
          result.validateStatus = false;
          result.errorFields?.push({
            name: namePath,
          });
        }
        continue;
      }
      const { isError, message } = getErrorMessage(value, rule, specificFieldPath);
      if (isError) {
        result.validateStatus = false;
        result.errorFields?.push({
          name: namePath,
        });
        if (setError) {
          let allErrorsMessages = null;
          const oldErrors: object | string = fieldsErrors[namePath.join('.')];
          if (message[0] === '{' && oldErrors) {
            const oldErrorsObj = typeof oldErrors === 'string' ? JSON.parse(oldErrors) : oldErrors;
            allErrorsMessages = JSON.stringify({ ...oldErrorsObj, ...JSON.parse(message) });
          } else {
            allErrorsMessages = message;
          }

          setFormError(namePath, allErrorsMessages);
        }
      } else {
        let message = '';
        if (
          setError &&
          specificFieldPath &&
          tempName === 'address' &&
          fieldsErrors[namePath.join('.')] &&
          fieldsErrors[namePath.join('.')].length !== 0
        ) {
          const oldErrors: object | string = fieldsErrors[namePath.join('.')];
          const oldErrorsObj = typeof oldErrors === 'string' ? JSON.parse(oldErrors) : oldErrors;
          delete oldErrorsObj[specificFieldPath];
          message = JSON.stringify(oldErrorsObj);
        }
        setFormError(namePath, message);
      }
    }
    return result;
  }

  function setFormFieldRules(rules: BetterForm.Rule[], namePath: BetterForm.NamePath) {
    const namePathStr = transFormNamePath(namePath);
    if (namePathStr.length > 0) {
      setConfigStore('rules', (_rules) => ({ ..._rules, [namePathStr.join('.')]: rules }));
    }
  }

  function getFormFieldError(namePath?: BetterForm.NamePath) {
    const namePathStr = transFormNamePath(namePath);
    if (namePathStr.length > 0) {
      return configStore.fieldsErrors[namePathStr.join('.')];
    }
  }

  function setValueToStore(name: BetterForm.NamePath | undefined, value: any, needClean = true) {
    if (!name) return;

    const cleanedValue = needClean ? cleanData(value) : value;

    const _namePath = transFormNamePath(name);
    setFormStore(
      produce((draft) => {
        _namePath.reduce((acc, cur, index) => {
          if (index === _namePath.length - 1) {
            if (cleanedValue !== undefined) {
              acc[cur] = cleanedValue;
            } else {
              delete acc[cur];
            }
          } else {
            const defaultValue = Number.isNaN(+_namePath[index + 1]) ? {} : [];
            acc[cur] = acc[cur] || defaultValue;
          }
          return acc[cur];
        }, draft);
      })
    );
    validateForm(name, false);
  }

  function removeFormField(name: BetterForm.NamePath, opts: BetterForm.RemoveFormFieldOpts = {}) {
    setConfigStore(
      produce((draft) => {
        delete draft.rules[namePathToString(name)];
        delete draft.fieldsErrors[namePathToString(name)];
      })
    );
    if (opts.removeValue) {
      setFormStore(
        produce((draft) => {
          const _namePath = transFormNamePath(name);
          _namePath.reduce((acc, cur, index) => {
            if (index === _namePath.length - 1) {
              delete acc[cur];
            }
            return acc[cur];
          }, draft);
        })
      );
    }
  }

  function scrollToField(name: BetterForm.NamePath) {
    let id = name;
    if (id instanceof Array) id = id.join('_');
    const targetElement: any = document.getElementById(id);
    if (targetElement) {
      targetElement.scrollIntoViewIfNeeded({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }

  return {
    formStore,
    setFormStore,
    configStore,
    setConfigStore,
    setFormRef(ref: HTMLFormElement) {
      formRef = ref;
    },
    get formRef() {
      return formRef;
    },
    setFieldsValue(values: any) {
      const meaningfulValues = cleanData(values);
      cachedValue = cloneDeep(meaningfulValues);

      if (formStore) {
        setFormStore(cachedValue);
      }
    },
    getFieldValue(name: BetterForm.NamePath) {
      return getValueFromStore(formStore, name);
    },
    resetFields(namepaths?: BetterForm.NamePath[]) {
      if (!namepaths) {
        setFormStore({});
        return;
      }
      namepaths.forEach((cur) => {
        setValueToStore(cur, undefined);
      });
    },
    get isValidate() {
      return Object.values(configStore.fieldsErrors || {}).every((cur) => !cur);
    },
    validateForm: validateForm,
    setFormError,
    setFormFieldRules,
    getFormFieldError,
    setValueToStore,
    removeFormField,
    scrollToField,
  };
};
