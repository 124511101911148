import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { getHomeAllComponent } from '~/pages/dashboard/constant';
import type { HomeLayout } from '~/pages/dashboard/type';

export const [CustomLayoutProvider, useCustomLayout] = createMagicDoorContext('CustomLayout', () => {
  const [enable, setEnable] = createSignal<boolean>(false);

  const getLocalLayout = (): HomeLayout[] => {
    let local: HomeLayout[] | undefined;
    if (localStorage.getItem('home-layout')) {
      local = JSON.parse(localStorage.getItem('home-layout') as string);
      return (
        local?.map((item) => ({
          ...item,
          component: getHomeAllComponent(item.key).at(0)?.component ?? (() => null),
        })) || []
      ).filter((item) => item.component);
    } else {
      return [];
    }
  };

  const [homeLayout, setHomeLayout] = createSignal<HomeLayout[]>(getLocalLayout().length ? getLocalLayout() : getHomeAllComponent());

  return {
    enable,
    setEnable,
    homeLayout,
    setHomeLayout,
    getLocalLayout,
  };
});
