import { For, Show, createContext, createEffect, createMemo, useContext, mergeProps } from 'solid-js';
import { createStore } from 'solid-js/store';
import { CircularProgress, IconTriangleAlert } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { useScrollSpy } from '~/utils/hooks';
import { FormWrapper } from './Form';
import type { BetterForm } from './type';
import type { Component, JSX } from 'solid-js';
import type { SetStoreFunction } from 'solid-js/store';

type SegmentItem = {
  id: string;
  label: string;
  progress: number;
  error?: boolean;
  required?: boolean;
};

const SegmentFormContext = createContext<{
  segments: SegmentItem[];
  setSegments: SetStoreFunction<SegmentItem[]>;
}>();

const useSegmentForm = () => {
  const context = useContext(SegmentFormContext);
  if (context != null) return context;
  throw new Error('`SegmentFromItem` must be used within `SegmentForm`');
};

export const SegmentForm: Component<BetterForm.FormProps> = (props) => {
  const [segments, setSegments] = createStore<SegmentItem[]>([]);

  return (
    <SegmentFormContext.Provider value={{ segments, setSegments }}>
      <FormWrapper {...props} />
    </SegmentFormContext.Provider>
  );
};

export const SegmentFormScrollSpy: Component<{ class?: string }> = (props) => {
  const { t } = useLocalization();
  const ctx = useSegmentForm();
  const [active, setActive] = useScrollSpy(() => ctx.segments.map((i) => i.id));

  return (
    <For each={ctx.segments}>
      {(item, i) => (
        <>
          <Show when={item.required !== ctx.segments[i() - 1]?.required}>
            <span class="my-3 mt-8 block text-sm text-title-gray first:mt-0">{t(item.required ? 'Required' : 'Optional')}</span>
          </Show>
          <a
            href={`#${item.id}`}
            class={cn(
              'my-2 flex select-none items-center gap-2 rounded-lg border bg-white px-4 py-5 text-sm font-medium transition-colors data-[active]:border-current data-[active]:text-primary',
              props.class
            )}
            data-active={item.id === active() ? '' : undefined}
            onClick={(e) => {
              e.preventDefault();
              const element = document.getElementById(item.id);
              element?.scrollIntoView({ behavior: 'smooth' });
              history.pushState({}, '', `#${item.id}`);
              setActive(item.id);
            }}>
            <CircularProgress class="size-6 text-primary" value={item.progress} />
            {item.label}
            <Show when={item.error}>
              <IconTriangleAlert class="ml-auto size-5 text-warning" />
            </Show>
          </a>
        </>
      )}
    </For>
  );
};

export const SegmentFormItem: Component<{
  id?: string;
  title: string;
  progress: number;
  error?: boolean;
  required?: boolean;
  class?: string;
  titleClass?: string;
  children: JSX.Element;
  autoScroll?: boolean;
}> = (segProps) => {
  const ctx = useSegmentForm();
  const props = mergeProps({ autoScroll: true }, segProps);
  const id = createMemo(() => props.id ?? props.title.replace(/\s+/g, '-').toLowerCase());

  createEffect(() => {
    const item = { id: id(), label: props.title, progress: props.progress, error: props.error, required: props.required };
    const idx = ctx.segments.findIndex((i) => i.id === id());
    if (idx === -1) {
      ctx.setSegments((prev) => [...prev, item]);
    } else {
      ctx.setSegments(idx, item);
    }
  });

  return (
    <section
      id={id()}
      class={props.class}
      onFocusIn={(e) => (props.autoScroll ? e.target.scrollIntoView({ behavior: 'smooth', block: 'center' }) : null)}>
      <h4 class={cn('sticky top-0 z-10 col-span-2 border-b bg-white px-8 py-4 text-lg font-semibold text-text-level01', props.titleClass)}>
        {props.title}
      </h4>
      {props.children}
    </section>
  );
};
