import { Router } from '@solidjs/router';
import { render } from 'solid-js/web';
import './index.css';
import { initSentry } from '~/utils/sentry';
import App from './App';
import { GlobalProviders } from './contexts/global';

initSentry();

const root = document.getElementById('root');

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error('Root element not found. Did you forget to add it to your index.html? Or maybe the id attribute got mispelled?');
}

render(
  () => (
    <Router>
      <GlobalProviders>
        <App />
      </GlobalProviders>
    </Router>
  ),
  root!
);
