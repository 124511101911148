import dayjs from 'dayjs';
import { Show, createSignal } from 'solid-js';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { DatePicker } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { LabeledGroup } from './LabeledGroup';
import type { LabeledGroupProps } from './LabeledGroup';
import type { DropdownProps } from '~/components/common/Dropdown';
import type { DatePickerProps } from '~/components/ui';

interface DueDateInputFieldProps extends LabeledGroupProps, Pick<DatePickerProps, 'yearRange' | 'disabledDate'> {
  /** @deprecated unnecessary   */
  contentPosition?: DropdownProps['contentPosition'];
  onInput?: (e: string | null) => void;
  placeholder?: string;
  value?: string | null | undefined;
  onClick?: (isMouseDown: boolean) => void;
  /** @deprecated unnecessary   */
  controlled?: boolean;
  disabled?: boolean;
  showUntil?: boolean;
}

const DueDateInputField = (props: DueDateInputFieldProps) => {
  const { t } = useLocalization();
  const [showDate, setShowDate] = createSignal<boolean>(true);
  return (
    <LabeledGroup label={props.label} class={props.class} error={props.error} required={props.required}>
      <div class="flex items-center">
        <Show when={showDate()}>
          <div class="flex-1">
            <DatePicker
              disabled={props.disabled}
              value={props.value ?? undefined}
              onChange={props.onInput}
              placeholder={props.placeholder}
              onMouseDown={() => props.onClick?.(true)}
              onMouseUp={() => props.onClick?.(false)}
              yearRange={props.yearRange}
              disabledDate={props.disabledDate}
            />
          </div>
        </Show>
        <Show when={props.showUntil}>
          <Checkbox
            checkBoxClass="ml-2 h-9 text-xs"
            label={t('Until now')}
            showLabel
            onInput={(checked) => {
              setShowDate(!checked);
              if (!checked) return;
              props.onInput?.(dayjs().format('YYYY-MM-DD'));
            }}
          />
        </Show>
      </div>
    </LabeledGroup>
  );
};

export default DueDateInputField;
