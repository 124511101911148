import { useLocalization } from '~/contexts/global';
import { useLeaseDeposits } from '~/contexts/local';
import { LeaseAddDepositTransactionModal } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseAddDepositTransactionModal';
import type { Component } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';
import type { AddDepositTransactionType } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseAddDepositTransactionModal';

type LeaseAddDepositModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
};

export const LeaseAddDepositModal: Component<LeaseAddDepositModalProps> = (props) => {
  const { t } = useLocalization();
  const { addLeaseDeposit } = useLeaseDeposits();

  const handleSubmit = async (data: AddDepositTransactionType) => {
    await addLeaseDeposit(props.currentLease.id, {
      dueDate: data.dueDate as string,
      billDate: data.billDate || null,
      chartOfAccountId: data.chartOfAccountId as string,
      amount: Number(data.amount),
      description: data.description || null,
    });
  };

  return (
    <LeaseAddDepositTransactionModal
      {...props}
      title={t('Add deposit')}
      submitText={t('Save')}
      onSubmit={handleSubmit}
      showBillDate
      chartOfAccountType="liability"
      formDefaultValues={{ description: 'Security deposit' }}
    />
  );
};
