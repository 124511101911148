import { useLocalization } from '~/contexts/global';
import { Presenter } from '~/pdfsigner/presenters/Presenter';
import { Alignment, Orientation, PageSize, WidthDistribution } from '~/pdfsigner/usecases/types/reportDocument';
import { commaNumber } from '~/utils/number';
import type { PdfState } from '~/pdfsigner/state/pdfAppState';
import type { DocumentFooter, DocumentHeader, Entry, ReportDocument, Section } from '~/pdfsigner/usecases/types/reportDocument';
import type { BalanceSheetReportDataDto, CompanyDto, PortfolioDto, PropertyDto } from '~/swagger/Api';

export interface PresentableBalanceSheet {
  balanceSheetData: BalanceSheetReportDataDto;
  balanceSheetReport: ReportDocument;
}

const { t, currentLanguage } = useLocalization();

export class BalanceSheetPresenter extends Presenter<PresentableBalanceSheet> {
  createModel(state: PdfState): PresentableBalanceSheet {
    return {
      balanceSheetData: state.reports.balanceSheet,
      balanceSheetReport: this.createBalanceSheetReport(state.portfolios, state.properties, state.reports.balanceSheet, state.companyInfo),
    };
  }

  private createBalanceSheetReport = (
    portfolios: PortfolioDto[],
    properties: PropertyDto[],
    balanceSheetData?: BalanceSheetReportDataDto,
    companyInfo?: CompanyDto
  ): ReportDocument => {
    const mergedArray = [...portfolios, ...properties];
    const memo = mergedArray.map((item) => item.name).join(', ');
    const additionalInfo: string[] = [];
    additionalInfo.push(`${companyInfo?.address?.streetAddress1} ${companyInfo?.address?.streetAddress2 || ''}`);
    additionalInfo.push(
      `${companyInfo?.address?.city}, ${companyInfo?.address?.zipCode}, ${companyInfo?.address?.state}, ${companyInfo?.address?.country}`
    );
    const header: DocumentHeader = {
      title: t('Balance sheet report'),
      subTitle: new Date().toLocaleDateString(),
      additionalInfo,
      companyName: companyInfo?.name,
    };
    const footer: DocumentFooter = {
      text: 'MagicDoor.com',
    };
    const assets = this.createEntries(balanceSheetData?.assets);
    assets.push({
      values: [
        { text: t('Total Assets'), isBold: true },
        { text: commaNumber(balanceSheetData?.totalAssets?.balance), isBold: true, horizontalAlignment: Alignment.end },
      ],
    });
    const liabilities = this.createEntries(balanceSheetData?.liabilities);
    liabilities.push({
      values: [
        { text: t('Total Liabilities'), isBold: true },
        { text: commaNumber(balanceSheetData?.totalLiabilities?.balance), isBold: true, horizontalAlignment: Alignment.end },
      ],
    });
    const capital = this.createEntries(balanceSheetData?.capital);
    capital.push({
      values: [
        { text: t('Total Capital'), isBold: true },
        { text: commaNumber(balanceSheetData?.totalCapital?.balance), isBold: true, horizontalAlignment: Alignment.end },
      ],
    });
    const sections: Section[] = [];
    if (assets.length > 1) {
      sections.push({
        header: {
          values: [
            { text: t('Assets'), isBold: true },
            { text: '', width: WidthDistribution.fixed },
          ],
          hasBottomLine: true,
          hasTopLine: true,
        },
        entries: assets,
      });
    }
    if (liabilities.length > 1) {
      sections.push({
        header: { values: [{ text: t('Liabilities'), isBold: true }, { text: '' }], hasBottomLine: true, hasTopLine: true },
        entries: liabilities,
      });
    }
    if (capital.length > 1) {
      sections.push({
        header: { values: [{ text: t('Capital'), isBold: true }, { text: '' }], hasBottomLine: true, hasTopLine: true },
        entries: capital,
      });
    }
    return {
      language: currentLanguage(),
      orientation: Orientation.PORTRAIT,
      memo,
      header,
      footer,
      sections,
      pageSize: PageSize.LETTER,
      shouldShowPageNumbers: true,
      isEachSectionOnANewPage: false,
    };
  };

  createEntries = (lines?: MagicDoor.Api.ChartOfAccountReportLineDto[], level = 1): Entry[] => {
    if (!lines || lines.length === 0) {
      return [];
    }
    return lines.flatMap<Entry>((line) => {
      const label = t(line.chartOfAccount?.name);
      const formattedBalance = line.groups?.balance !== undefined ? commaNumber(line.groups.balance) : '';
      const entry: Entry = {
        values: [
          { text: label, indentationLevel: level },
          { text: formattedBalance, horizontalAlignment: Alignment.end },
        ],
      };
      const formattedTotal = line.groupTotal?.balance !== undefined ? commaNumber(line.groupTotal.balance) : '';
      const entries: Entry[] = [entry, ...this.createEntries(line.children, level + 1)];
      if (line.groupTotal != undefined) {
        entries.push({
          values: [
            { text: `${t('Total')} ${label}`, indentationLevel: level },
            { text: formattedTotal, horizontalAlignment: Alignment.end },
          ],
          hasTopLine: true,
          hasBottomLine: true,
        });
      }
      return entries;
    });
  };
}
