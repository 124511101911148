import { Show, createSignal, onCleanup, onMount } from 'solid-js';
import { Portal } from 'solid-js/web';
import { useLockBodyScroll } from '~/utils/hooks';
import { debounce } from '~/utils/tool';
import { useSearch } from './MagicSearchContext';
import { MagicSearchDropdown } from './components/MagicSearchDropdown';
import { MagicSearchInput } from './components/MagicSearchInput';
import type { SearchResult } from './MagicSearchContext';

interface SearchBarProps {
  setIsSearchActive: (isSearchActive: boolean) => void;
}

const SearchBar = (props: SearchBarProps) => {
  const { searchResults, performSearch, resetSearchResults, isLoading, loadMore, hasMore } = useSearch();

  const [searchTerm, setSearchTerm] = createSignal<string | undefined>('');
  const [dropdownVisible, setDropdownVisible] = createSignal<boolean>(false);
  const [formElement, setFormElement] = createSignal<HTMLFormElement | null>(null);
  const [isTyping, setIsTyping] = createSignal<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = createSignal<boolean>(true);
  let dropdownRef: HTMLDivElement | undefined;

  useLockBodyScroll(dropdownVisible);

  const handleOutsideClick = (event: MouseEvent) => {
    const form = formElement();
    if (form && !form.contains(event.target as Node) && dropdownRef && !dropdownRef.contains(event.target as Node)) {
      setDropdownVisible(false);
      props.setIsSearchActive(false);
      if (!searchTerm()) {
        resetSearchResults();
      }
    }
  };

  onMount(() => {
    document.addEventListener('mousedown', handleOutsideClick);
  });

  onCleanup(() => {
    document.removeEventListener('mousedown', handleOutsideClick);
  });

  const debouncedSearch = debounce(async (term: string) => {
    if (term.trim() !== '') {
      await performSearch(term);
    } else {
      resetSearchResults();
    }
    setIsTyping(false);
    setIsFirstLoad(false);
  }, 1000);

  const handleSearchChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    setSearchTerm(target.value);
    setIsTyping(true);
    if (target.value) {
      debouncedSearch(target.value);
      props.setIsSearchActive(true);
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
      props.setIsSearchActive(false);
      resetSearchResults();
      setIsTyping(false);
      setIsFirstLoad(true);
    }
  };

  const handleFocus = () => {
    setDropdownVisible(true);
    props.setIsSearchActive(true);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const term = searchTerm()?.trim() || '';
      if (term !== '') {
        performSearch(term);
        setDropdownVisible(true);
      }
    }
  };

  const handleClear = () => {
    setSearchTerm('');
    setDropdownVisible(false);
    props.setIsSearchActive(false);
    resetSearchResults();
    setIsFirstLoad(true);
  };

  const getResultHref = (result: SearchResult): string => {
    switch (result.type) {
      case 'lease':
        return `/leasing/leases/${result.id}`;
      case 'property':
        return `/portfolios/${result.response.portfolioId}/properties/${result.id}`;
      case 'unit':
        return `/portfolios/${result.response.portfolioId}/properties/${result.response.propertyId}/units/${result.id}`;
      case 'tenant':
        return `/users/tenants/${result.id}`;
      case 'owner':
        return `/users/owners/${result.id}`;
      case 'vendor':
        return `/maintenance/vendors/${result.id}`;
      default:
        console.error('Unknown result type:', result.type);
        return '#';
    }
  };

  const handleResultClick = () => {
    setDropdownVisible(false);
    setSearchTerm('');
    props.setIsSearchActive(false);
    resetSearchResults();
    setIsFirstLoad(true);
  };

  return (
    <>
      <Portal>
        <div class={`fixed top-0 z-30 pt-2 ${dropdownVisible() ? 'left-2 z-50 md:left-72' : 'left-16 md:left-72'}`} ref={setFormElement}>
          <div
            class={`relative transition-all duration-300 ${
              dropdownVisible()
                ? 'w-[calc(100vw-1rem)] sm:w-[calc(100vw-2rem)] md:w-[500px] lg:w-[700px] xl:w-[840px]'
                : 'w-12 sm:w-2/3 md:w-64 lg:w-96'
            }`}
            style={{ 'transform-origin': 'left center' }}>
            <MagicSearchInput
              searchTerm={searchTerm() || ''}
              handleSearchChange={handleSearchChange}
              handleKeyDown={handleKeyDown}
              handleFocus={handleFocus}
              handleClear={handleClear}
              dropdownVisible={dropdownVisible}
              isLoading={(isLoading() || isTyping()) && !isFirstLoad()}
            />

            <Show when={dropdownVisible()}>
              <div ref={dropdownRef}>
                <MagicSearchDropdown
                  searchResults={searchResults}
                  searchTerm={searchTerm}
                  isTyping={isTyping}
                  handleResultClick={handleResultClick}
                  isLoading={isLoading}
                  loadMore={loadMore}
                  hasMore={hasMore}
                  getResultHref={getResultHref}
                  isFirstLoad={isFirstLoad}
                />
              </div>
            </Show>
          </div>
        </div>
      </Portal>
    </>
  );
};

export default SearchBar;
