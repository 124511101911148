import { createMemo, Show } from 'solid-js';
import { AIScoreCell } from '~/components/common/AiScoreCell';
import { Avatar } from '~/components/common/Avatar';
import { Panel } from '~/components/common/Panels';
import StatusCol from '~/components/rental-application/components/StatusCol';
import { getTenantFullName, getTenantShortName } from '~/components/tenants/utils';
import { Table } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import { RentalApplicationEmpty } from './RentalApplicationEmpty';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type RentalApplicationListProps = {
  class?: string;
  data: MagicDoor.Api.RentalApplicationListDto[];
  loading?: boolean;
  filterStatuses?: string[];
  onRowClick?: (item: MagicDoor.Api.RentalApplicationListDto) => void;
  generateRowLink?: (item: MagicDoor.Api.RentalApplicationListDto) => string;
  title?: string;
};

export const RentalApplicationList: Component<RentalApplicationListProps> = (props) => {
  const { t } = useLocalization();

  const filteredApplications = createMemo(() =>
    props.filterStatuses ? props.data?.filter((app) => props.filterStatuses?.includes(app.applicationDecision)) : props.data
  );

  const columns = createMemo<TableColumns<MagicDoor.Api.RentalApplicationListDto>>(() => [
    {
      title: t('Applicant'),
      render: (item) => (
        <div class="flex items-center space-x-2 text-sm">
          <Show when={item.firstName}>
            <Avatar name={getTenantShortName({ firstName: item.firstName, lastName: item.lastName })} />
          </Show>
          <div class="flex flex-col">
            <span>{getTenantFullName({ firstName: item.firstName, lastName: item.lastName })}</span>
            <span class="text-xs text-text-level03">{dateFormat('', item.createdAt)}</span>
          </div>
        </div>
      ),
    },
    {
      title: t('Status'),
      render: (item) => <StatusCol status={item.applicationDecision} />,
    },
    {
      title: t('Applied unit'),
      render: (item) => {
        const unitNamesStr = createMemo(() => {
          const unitNames =
            item.interests?.map((interest: { unit: { name: string } }) => interest.unit?.name).filter((name: string) => name) || [];
          return unitNames.length > 0 ? unitNames.join(', ') : emptyPlaceholder;
        });

        return <span class="text-sm">{unitNamesStr()}</span>;
      },
    },
    {
      title: t('AI score'),
      render: (item) => <AIScoreCell score={item.aiApplicationScore} />,
    },
    {
      title: t('Annual income'),
      render: (item) => <span class="text-sm">{currency(item.incomeInformation?.annualIncome || 0, { minimumFractionDigits: 2 })}</span>,
    },
  ]);

  return (
    <div class="text-left">
      <Panel title={props.title || t('Applications')} class={props.class}>
        <Show when={filteredApplications()?.length || props.loading}>
          <div class="thinscroll overflow-x-auto">
            <Table
              columns={columns()}
              loading={props.loading}
              data={filteredApplications()}
              rowLink={props.generateRowLink}
              onRowClick={props.onRowClick}
            />
          </div>
        </Show>
        <Show when={!filteredApplications()?.length && !props.loading}>
          <RentalApplicationEmpty />
        </Show>
      </Panel>
    </div>
  );
};
