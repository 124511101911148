import IconResizeHandler from '~/assets/images/lease-template/resizeHandler.svg';

export interface ResizeHandlerProps {
  onMouseDown: (e: MouseEvent) => void;
}

export const ResizeHandler = (props: ResizeHandlerProps) => {
  return (
    <div class="absolute inset-0 cursor-nwse-resize" onMouseDown={(e) => props.onMouseDown(e)}>
      <img src={IconResizeHandler} draggable={false} class="size-full -rotate-90 select-none" />
    </div>
  );
};
