import { createMemo, For, Show } from 'solid-js';
import IconShare from '~/assets/images/common/share.svg?component-solid';
import IconUsers from '~/assets/images/common/users.svg?component-solid';
import IconSign from '~/assets/images/lease-template/sign.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { Button } from '~/components/common/Buttons';
import { IconDownload, Popover } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import { ToolbarPresenter } from '~/pdfsigner/presenters/ToolbarPresenter';
import { downloadFile } from '~/utils/file';
import type { Component, JSX } from 'solid-js';
import type { Signer } from '~/pdfsigner/usecases/types/signer';

export interface LeaseSigningHeaderProps {
  shouldShowReminderButton: boolean;
  onSendRemindersClicked?: () => void;
}

const SignersList: Component<{
  signers?: Signer[];
}> = (props) => {
  const { t } = useLocalization();

  return (
    <ul class="flex w-[340px] min-w-fit cursor-pointer flex-col gap-1 divide-y divide-partingline rounded-lg border border-partingline bg-white p-2 shadow-lg">
      <For each={props.signers}>
        {(item) => (
          <li class="flex cursor-pointer items-center gap-2 p-3">
            <Avatar name={item.name} />
            <div class="flex-1">
              <div class="text-sm font-medium text-text-level01">{item.name}</div>
              <div class="text-xs text-text-level03">{item.isPropertyManager ? t('Property manager') : t('Tenant')}</div>
            </div>
            <div class="float-end flex items-center gap-1">
              <IconSign
                classList={{
                  'text-[#36CBAC]': item.hasSigned,
                  'text-[#8D9BBD]': !item.hasSigned,
                }}
              />
              <span
                class="text-xs font-medium"
                classList={{
                  'text-[#36CBAC]': item.hasSigned,
                  'text-[#8D9BBD]': !item.hasSigned,
                }}>
                {item.hasSigned ? t('Signed') : t('Unsigned')}
              </span>
            </div>
          </li>
        )}
      </For>
    </ul>
  );
};

export const LeaseSigningHeader = (props: LeaseSigningHeaderProps): JSX.Element => {
  const { model: presentableToolbar } = usePresenter(ToolbarPresenter);
  const { t } = useLocalization();
  const hasUnSigned = createMemo(() => presentableToolbar()?.signers?.some((s) => !s.hasSigned));

  return (
    <Show when={presentableToolbar()}>
      <div
        class="left-0 top-0 z-20 w-full bg-white px-6"
        style={{
          'box-shadow': '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
        }}>
        <div class="flex h-15 w-full items-center justify-center">
          <span class="text-xl font-medium text-[#333]">{presentableToolbar()?.title}</span>
        </div>
        <div class="flex h-15 w-full flex-row items-center justify-between border-t">
          <div class="mr-4 flex w-full items-center justify-between gap-2">
            <Popover class="relative">
              {({ setOpen, open }) => (
                <>
                  <div
                    classList={{
                      'border-[#A126EC]': open(),
                      'border-[#E5E5F3]': !open(),
                    }}
                    onClick={() => setOpen(true)}
                    class="flex cursor-pointer items-center gap-2 rounded-lg border p-2 text-base text-[#333]">
                    <IconUsers />
                    {`${t('Received signatures')}: ${presentableToolbar()?.receivedSignaturesText}`}
                  </div>
                  <Popover.Content as={SignersList} signers={presentableToolbar()?.signers} align="start" />
                </>
              )}
            </Popover>
            <Show when={props.shouldShowReminderButton}>
              <Button onClick={() => props.onSendRemindersClicked?.()} disabled={!hasUnSigned()}>
                <IconShare />
                {t('Send reminders')}
              </Button>
            </Show>
          </div>
          <div class="flex items-center gap-2">
            <Button variant="outlined" size="sm" onClick={() => downloadFile(presentableToolbar()?.signedUrl || '')}>
              <IconDownload />
            </Button>
          </div>
        </div>
      </div>
    </Show>
  );
};
