import { Show } from 'solid-js';
import { FormWrapper } from '~/components/common/BetterForm/Form';
import { useForm } from '~/components/common/BetterForm/context';
import { Empty } from '~/components/common/Empty';
import { ForWithGutter } from '~/components/common/Gutter';
import IconField from '~/components/common/IconField';
import { WorkHistoryModal } from '~/components/rental-application/rental-application-details/renter-report-tab/work-history/WorkHistoryModal';
import { IconBriefcase, IconBuilding, IconClock, IconMail } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { NewRentalApplicationProvider } from '~/contexts/local';
import { diffDates } from '~/utils/date';
import { showEmptyPlaceholderIfValueIsEmpty, validArr } from '~/utils/tool';

export const WorkHistory = () => {
  const { t } = useLocalization();
  const { applicationStore } = useRentalApplication();
  const form = useForm();

  return (
    <FormWrapper defaultForm={form}>
      <NewRentalApplicationProvider>
        <Show
          when={validArr(applicationStore.application?.employment)}
          fallback={<Empty description={t('Tenant has not report this information')} />}>
          <ForWithGutter each={applicationStore.application?.employment}>
            {(section, index) => (
              <div class="relative flex flex-wrap gap-y-6">
                <IconField
                  class="grow-1 h-auto basis-full lg:basis-1/2"
                  iconRounded
                  src={IconBuilding}
                  value={section.name}
                  name={t(index() === 0 ? `Current company` : `History company`)}
                />
                <IconField
                  class="grow-1 h-auto basis-full lg:basis-1/2"
                  iconRounded
                  src={IconClock}
                  name={t(`Duration`)}
                  value={diffDates(section.endDate, section.startDate, { withoutSuffix: true })}
                />
                <div class="flex w-full flex-wrap gap-y-6 rounded-lg bg-light-gray px-6 py-3">
                  <IconField
                    class="grow-1 h-auto basis-full lg:basis-1/2"
                    iconRounded
                    src={IconBriefcase}
                    value={showEmptyPlaceholderIfValueIsEmpty(section.position)}
                    name={t(`Position`)}
                  />
                  <IconField
                    class="grow-1 h-auto basis-full lg:basis-1/2"
                    iconRounded
                    src={IconMail}
                    name={t(`Employment contact`)}
                    value={showEmptyPlaceholderIfValueIsEmpty(section.phone)}
                  />
                </div>
              </div>
            )}
          </ForWithGutter>
        </Show>
        <WorkHistoryModal />
      </NewRentalApplicationProvider>
    </FormWrapper>
  );
};
