import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';

export const ActionButton: Component<{
  icon: Component;
  label: string;
  class: string;
  textClass?: string;
  dividerClass?: string;
  onClick?: () => void;
}> = (props) => {
  return (
    <div
      class={cn('flex h-10 cursor-pointer  select-none items-center rounded-lg', props.class)}
      onClick={() => {
        props.onClick?.();
      }}>
      <div class="flex min-w-10 items-center justify-center">
        <props.icon />
      </div>
      <div class={cn('h-4 min-w-px', props.dividerClass || 'bg-white/20')} />
      <div class={cn('min-w-36 pr-2 text-center text-sm font-medium', props.textClass || 'text-white')}>{props.label}</div>
    </div>
  );
};
