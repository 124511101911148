import { useNavigate } from '@solidjs/router';
import { createMemo, Show } from 'solid-js';
import EnabledIcon from '~/assets/images/aiRunBooks/enabledIcon.svg?component-solid';
import headerIcon from '~/assets/images/aiRunBooks/headerIcon.png';
import NotEnabledIcon from '~/assets/images/aiRunBooks/notEnabledIcon.svg?component-solid';
import IconDelete from '~/assets/images/common/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import InputSwitch from '~/components/common/Inputs/InputSwitch';
import { DropdownActions, Skeleton, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import type { Component } from 'solid-js';

type AIRunbookItemProps = {
  runbook?: MagicDoor.Api.ListRunBookDto;
  onChangeStatus?: (value: boolean) => void;
  loading?: boolean;
};

const RunbookHeader = (props: { isEnabled?: boolean; loading?: boolean; onChangeStatus?: (value: boolean) => void }) => {
  const { t } = useLocalization();
  return (
    <div class={cn('relative flex h-15 items-center justify-between p-4', props.isEnabled ? 'text-white' : 'text-text-level03')}>
      <div class="flex items-center gap-1.5">
        <Show when={props.isEnabled} fallback={<NotEnabledIcon />}>
          <EnabledIcon />
        </Show>
        <span>{t('Enable AI')}</span>
      </div>
      <div
        class="absolute right-0 z-0 h-full w-1/2"
        style={{
          background: props.isEnabled ? `url(${headerIcon})` : 'none',
        }}
      />
      <div>
        <Show when={!props.loading} fallback={<Skeleton class="w-10" />}>
          <InputSwitch checked={props.isEnabled} onChange={props.onChangeStatus} />
        </Show>
      </div>
    </div>
  );
};

export const AIRunbookItem: Component<AIRunbookItemProps> = (props) => {
  const { t } = useLocalization();
  const { deleteRunBook, runBooks } = useRunBooks();
  const navigate = useNavigate();

  const vendorName = createMemo(() => runBooks()?.vendors.find((v) => v.id === props.runbook?.defaultVendorId)?.name || emptyPlaceholder);

  const propertyManagerName = createMemo(
    () => runBooks()?.propertyManagers.find((pm) => pm.id === props.runbook?.defaultPropertyManagerId)?.name || emptyPlaceholder
  );

  const isEnabled = createMemo(() => props.runbook?.enabled);

  const handleDeleteRunBook = async () => {
    await deleteRunBook(props.runbook?.id as string);
    toast.success(t('{name} has been deleted successfully', { name: t('AI Runbooks') }));
  };
  return (
    <div class={cn('flex h-[320px] flex-col rounded-lg shadow-sm', isEnabled() ? 'bg-white bg-gradient-mysticViolet' : 'bg-input')}>
      <RunbookHeader isEnabled={isEnabled()} loading={props.loading} onChangeStatus={props.onChangeStatus} />

      <div class="flex flex-1 flex-col rounded-lg bg-white">
        <div
          class="flex flex-1 cursor-pointer flex-col gap-2 overflow-hidden p-4"
          onClick={() => navigate(`/maintenance/ai-runbooks/${props.runbook?.id}`)}>
          <Show when={!props.loading} fallback={<Skeleton class="w-20" />}>
            <div class="line-clamp-1 font-semibold text-text-level01">{props.runbook?.title}</div>
          </Show>
          <Show when={!props.loading} fallback={<Skeleton class="w-full" />}>
            <div class="line-clamp-4 text-xs text-text-level03">{props.runbook?.description}</div>
          </Show>
        </div>

        <div class="border-t p-4">
          <div class="flex flex-col gap-2 text-xs">
            <div class="flex flex-col gap-1">
              <div class="font-medium text-text-level01">
                <Show when={!props.loading} fallback={<Skeleton class="w-20" />}>
                  <span class="text-text-level03">{t('Default vendor')}:</span> {vendorName()}
                </Show>
              </div>
              <div class="font-medium text-text-level01">
                <Show when={!props.loading} fallback={<Skeleton class="w-20" />}>
                  <span class="text-text-level03">{t('Default property manager')}:</span> {propertyManagerName()}
                </Show>
              </div>
            </div>
            <div class="flex items-center justify-between">
              <div class="inline-flex items-center justify-center rounded-lg bg-input p-1 text-text-level03">
                {t('Usage')}: {props.runbook?.usageCount ?? 0}
              </div>
              <Show when={props.runbook}>
                <DropdownActions
                  actions={[
                    {
                      icon: IconDelete,
                      label: t('Delete'),
                      onClick: handleDeleteRunBook,
                    },
                    {
                      label: t('Edit'),
                      icon: IconPencil,
                      onClick: () => navigate(`${props.runbook?.id}/edit`),
                    },
                  ]}
                />
              </Show>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
