import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { TransactionsRepository } from '~/repositories/transactionRepository';
import type { DeleteBillDto } from '~/swagger/Api';

export const [LeaseRentTransactionsProvider, useLeaseRentTransactions] = createMagicDoorContext('LeaseRentTransactions', () => {
  const repo = new TransactionsRepository();
  const [leaseId, setLeaseId] = createSignal<string>('');
  const [transactions, setTransactions] = createSignal<MagicDoor.Api.HydratedLeaseTransactionDto[]>([]);
  const [loading, setLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);

  const getLeaseRentTransactions = async (id: string, take?: number) => {
    setLoading(true);
    try {
      setLeaseId(id);
      const response = await repo.getLeaseRentTransactions(id, take);
      setTransactions(response.transactions || []);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const setupCharge = async (id: string, payload: MagicDoor.Api.CreateLeaseChargeDto): Promise<void> => {
    try {
      await repo.setupCharge(id, payload);
      getLeaseRentTransactions(id);
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const offlinePayment = async (id: string, payload: MagicDoor.Api.OfflinePaymentDto): Promise<void> => {
    try {
      await repo.offlinePayment(id, payload);
      getLeaseRentTransactions(id);
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const addCredit = async (id: string, payload: MagicDoor.Api.CreateLeaseCreditDto): Promise<void> => {
    try {
      await repo.addCredit(id, payload);
      getLeaseRentTransactions(id);
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const deleteTransaction = async (leaseId: string, transactionId: string): Promise<boolean | undefined> => {
    try {
      await repo.deleteTransaction(transactionId);
      getLeaseRentTransactions(leaseId);
      return true;
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const deleteBill = async (leaseId: string, billId: string, params: DeleteBillDto): Promise<boolean | undefined> => {
    try {
      await repo.deleteBill(billId, params);
      getLeaseRentTransactions(leaseId);
      return true;
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const payoutTenant = async (leaseId: string, request: MagicDoor.Api.ReleaseLeaseBalanceRequestDto) => {
    try {
      await repo.payoutTenant(leaseId, request);
      getLeaseRentTransactions(leaseId);
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const markAsReturned = async (leaseId: string, transactionId: string, payload: MagicDoor.Api.ReturnLeasePaymentDto): Promise<void> => {
    try {
      await repo.markAsReturned(leaseId, transactionId, payload);
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  const updateTransaction = async (transactionId: string, payload: MagicDoor.Api.UpdateTransactionDto) => {
    await repo.updateTransaction(transactionId, payload);
  };

  const updateBill = async (billId: string, payload: MagicDoor.Api.UpdateBillDto) => {
    await repo.updateBill(billId, payload);
  };

  const batchAddOfflinePayment = async (id: string, payload: MagicDoor.Api.OfflinePaymentDto): Promise<void> => {
    try {
      await repo.offlinePayment(id, payload, false);
    } catch (err: any) {
      setError(err);
      throw err;
    }
  };

  return {
    leaseId,
    transactions,
    loading,
    error,
    getLeaseRentTransactions,
    payoutTenant,
    setupCharge,
    offlinePayment,
    addCredit,
    deleteTransaction,
    deleteBill,
    markAsReturned,
    updateTransaction,
    updateBill,
    batchAddOfflinePayment,
  };
});
