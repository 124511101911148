import { UseCase } from './useCase';

export class ChangeNumberOfSignersUseCase extends UseCase {
  public async runLogic(numberOfSigners: number) {
    if (!this.state.selectedDocument) {
      return;
    }
    let signers = [...this.state.selectedDocument.signers];
    if (numberOfSigners > this.state.selectedDocument.signers.length) {
      for (let i = this.state.selectedDocument.signers.length; i < numberOfSigners; i++) {
        signers.push({
          id: `${i + 1}`,
          name: `Signer ${i + 1}`,
          isPropertyManager: false,
        });
      }
    } else {
      signers = this.state.selectedDocument.signers.slice(0, numberOfSigners);
    }
    this.state.selectedDocument.signers = signers;
    let selectedSigner = this.state.selectedDocument.signers.find((signer) => signer.id === this.state.selectedSigner.id);
    if (!selectedSigner) {
      selectedSigner = this.state.selectedDocument.signers[0];
    }
    this.state.selectedSigner = selectedSigner;
    this.removeDeletedSignerAnnotations();
  }

  private removeDeletedSignerAnnotations() {
    const signers = new Set(this.state.selectedDocument?.signers.map((signer) => signer.id));
    this.state.selectedDocument?.pdfPages.forEach((page) => {
      page.annotations = page.annotations.filter((annotation) => {
        if (this.isClientInput(annotation.type) && annotation.signerId !== undefined && !signers.has(annotation.signerId)) {
          return false;
        }
        return true;
      });
    });
  }
}
