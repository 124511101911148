import IconTitle from '~/assets/images/lease-template/title.svg';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';

export interface TitleInputProps {
  class?: string;
  title?: string;
  onInput?: (title: string) => void;
}

export const TitleInput = (props: TitleInputProps) => {
  const { t } = useLocalization();

  return (
    <div class={cn('inline-flex basis-[70%] items-center gap-3', props.class)}>
      <div class="inline-flex items-center gap-1">
        <img src={IconTitle} />
        <span class="text-sm text-text-level03">{t('Title')}:</span>
      </div>
      <LabeledTextInput maxlength={150} class="grow font-semibold text-text-level01" value={props.title} onInput={props.onInput} />
    </div>
  );
};
