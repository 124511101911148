import { useParams } from '@solidjs/router';
import { createMemo, createSignal, onMount, Show } from 'solid-js';
import { Avatar } from '~/components/common/Avatar';
import IconField from '~/components/common/IconField';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { useBankAccount } from '~/contexts/local';
import { usePlaidUI } from '~/pages/bank-accounts/utils/usePlaidUi';
import { useStripeUI } from '~/pages/bank-accounts/utils/useStripeUi';
import BankSummaryActions from './BankSummaryActions';
import BankTransactionsTable from './BankTransactionsTable';
import { PlaidPanel } from './PlaidPanel';
import { StripePanel } from './StripePanel';
import IconFieldSkeleton from './skeleton/IconFieldSkeleton';
import PanelSkeleton from './skeleton/PanelSkeleton';

const BankDashboards = () => {
  const { t } = useLocalization();
  const params = useParams();
  const { bankAccount, fetchBankAccountById, setBankAccountId } = useBankAccount();
  const [bankLoaded, setBankLoaded] = createSignal<boolean>(false);

  onMount(async () => {
    setBankAccountId(params.bankAccountId);
    await fetchBankAccountById(params.bankAccountId);

    try {
      if (bankAccount()) {
        setBankLoaded(true);
      }
    } catch (e) {
      console.error('Failed to load bank account data', e);
    }
  });

  const plaidUI = createMemo(() => {
    if (bankAccount() && bankAccount()?.plaid) {
      return usePlaidUI(bankAccount());
    }
  });

  const stripeUI = createMemo(() => {
    if (bankAccount() && bankAccount()?.stripe) {
      return useStripeUI(bankAccount());
    }
  });

  return (
    <div class="grid gap-4">
      <Panel
        class="col-span-full h-full"
        title={
          <div class="flex gap-2">
            <Avatar name={bankAccount()?.name || ''} loading={!bankLoaded()} />
            <Show when={bankLoaded()} fallback={<div class="skeleton-shimmer w-48" />}>
              <div> {bankAccount()?.name}</div>
            </Show>
          </div>
        }
        actions={<BankSummaryActions />}>
        <div class="p-6">
          <Show when={bankLoaded()} fallback={<IconFieldSkeleton name={t('Description')} />}>
            <IconField name={t('Description')} value={bankAccount()?.description} />
          </Show>
        </div>
      </Panel>

      <div class="grid grid-cols-3 gap-4">
        <Show when={bankLoaded() && plaidUI()} fallback={<PanelSkeleton name={t('Plaid')} />}>
          <PlaidPanel plaidUI={plaidUI()} bankAccount={bankAccount()} />
        </Show>
        <Show when={bankLoaded() && stripeUI()} fallback={<PanelSkeleton name={t('Stripe')} />}>
          <StripePanel stripeUI={stripeUI()} bankAccount={bankAccount()} />
        </Show>
        <BankTransactionsTable />
      </div>
    </div>
  );
};

export default BankDashboards;
