import { createSignal, createEffect, Show } from 'solid-js';
import IconMap from '~/assets/images/identity/robotBook.png';
import { useLocalization } from '~/contexts/global';

interface IProps {
  address: string | null | undefined;
}

declare global {
  interface Window {
    google: any;
  }
}

export const GoogleMap = (props: IProps) => {
  let mapRef: HTMLDivElement | undefined;
  const { t } = useLocalization();
  const [mapStatus, setMapStatus] = createSignal<'loading' | 'success' | 'error'>('loading');
  const [errorMessage, setErrorMessage] = createSignal<string | null>(null);
  const [isScriptLoaded, setIsScriptLoaded] = createSignal<boolean>(false);

  const loadGoogleMapsScript = () => {
    if (window.google && window.google.maps) {
      setIsScriptLoaded(true);
      return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB89updWgLCG03Q81M-bZm0ESgVpSyCZ0I&libraries=places';
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      script.onload = () => {
        setIsScriptLoaded(true);
        resolve();
      };
      script.onerror = () => {
        setErrorMessage('Failed to load Google Maps API');
        console.error('Failed to load Google Maps API');
        reject();
      };
    });
  };

  const initMap = async () => {
    if (!props.address) {
      setMapStatus('error');
      setErrorMessage('No address provided or map reference not available');
      return;
    }

    try {
      if (!isScriptLoaded()) {
        await loadGoogleMapsScript();
      }

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: props.address }, (results: { geometry: { location: any } }[], status: any) => {
        if (status === window.google.maps.GeocoderStatus.OK && results && results[0]) {
          setMapStatus('success');
          setErrorMessage(null);
          const googleMap = new window.google.maps.Map(mapRef, {
            center: results[0].geometry.location,
            zoom: 15,
          });
          new window.google.maps.Marker({
            position: results[0].geometry.location,
            map: googleMap,
            title: 'Center Point',
          });
        } else {
          setMapStatus('error');
          setErrorMessage(t('The address provided is invalid'));
          console.error('Geocode was not successful:', status);
        }
      });
    } catch (error) {
      setMapStatus('error');
      setErrorMessage(t('Error initializing map'));
      console.error('Error initializing map:', error);
    }
  };

  createEffect(() => {
    if (props.address) {
      setMapStatus('loading');
      initMap();
    } else {
      setMapStatus('loading');
    }
  });

  return (
    <>
      <Show
        when={mapStatus() === 'success'}
        fallback={
          <div class="flex size-full flex-col items-center justify-center text-xs text-text-level03">
            <img src={IconMap} alt="Map Icon" />
            <p class="normal-case">{mapStatus() === 'error' ? errorMessage() : t('Loading')}...</p>
          </div>
        }>
        <div ref={mapRef} class="size-full" />
      </Show>
    </>
  );
};
