import { createSignal } from 'solid-js';
import enTranslations from '~/locales/en/translation.json';
import zhTranslations from '~/locales/zh/translation.json';
import { LanguageCode } from '~/types/languageCodes';

interface Translation {
  [key: string]: string;
}

interface TranslationsDictionary {
  [langCode: string]: Translation;
}

const dictionary: TranslationsDictionary = {
  en: enTranslations,
  zh: zhTranslations,
};

const getInitialLocale = (): LanguageCode => {
  let localeToUse = LanguageCode.ENGLISH;
  const storedLocale = localStorage.getItem('locale') as LanguageCode;
  if (storedLocale && storedLocale in dictionary) {
    localeToUse = storedLocale;
  }
  return localeToUse;
};

const [currentLanguage, setCurrentLanguage] = createSignal<LanguageCode>(getInitialLocale());

export type TFunction = (key?: string, params?: Record<string, any>) => string;

export const useLocalization = () => {
  const changeLanguage = (toLocale?: LanguageCode): void => {
    const newLocale = toLocale || LanguageCode.ENGLISH;
    if (typeof window !== 'undefined') {
      localStorage.setItem('locale', newLocale);
    }
    setCurrentLanguage(newLocale);
  };

  const t: TFunction = (key?, params?) => {
    if (!key) return '';
    let translation = dictionary[currentLanguage()][key] || key;
    if (params) {
      Object.entries(params).forEach(([paramKey, paramValue]) => {
        const regex = new RegExp(`{${paramKey}}`, 'g');
        translation = translation.replace(regex, paramValue.toString());
      });
    }
    if (!translation && import.meta.env.DEV) {
      console.warn(`[I18N] Translation not found: '${key}'`);
      return `[Translation: '${key}']`;
    }
    return translation;
  };

  return { t, changeLanguage, currentLanguage };
};
