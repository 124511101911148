import dayjs from 'dayjs';
import { createEffect, createSignal, For } from 'solid-js';
import { IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { currency } from '~/utils/number';
import { Bar } from './Bar';

const DRAG_THRESHOLD = 70;

export type Segment = {
  value: number;
  color: string;
  label?: string;
};

export type BarData = {
  axisLabel: string;
  segments: Segment[];
};

interface UpDownBarGraphProps {
  data?: BarData[];
  capHeight: number;
}

function UpDownBarGraph(props: UpDownBarGraphProps) {
  const [hoveredBar, setHoveredBar] = createSignal<BarData | null>(null);
  const [isDragging, setIsDragging] = createSignal<boolean>(false);
  const [startX, setStartX] = createSignal<number>(0);
  const [offset, setOffset] = createSignal<number>(0);
  const [data, setData] = createSignal<BarData[]>([]);

  const [selectedMonth, setSelectedMonth] = createSignal<BarData | null>(null);

  const { t } = useLocalization();

  createEffect(() => {
    const months = getLastFiveMonths(offset());
    setData(months.map((month) => props.data?.find((d) => d.axisLabel === month) || { axisLabel: month, segments: [] }));
  });

  createEffect(() => {
    setSelectedMonth(props.data?.find((d) => d.axisLabel === new Date().toLocaleString('default', { month: 'long' })) || null);
  });

  return (
    <div
      class={`relative flex h-full justify-center gap-5 p-4 ${isDragging() ? 'cursor-grabbing' : 'cursor-grab'} select-none`}
      onMouseDown={(e) => {
        setIsDragging(true);
        setStartX(e.clientX);
      }}
      onMouseMove={(e) => {
        if (isDragging()) {
          const deltaX = startX() - e.clientX;
          if (Math.abs(deltaX) > DRAG_THRESHOLD) {
            setOffset((prevOffset) => prevOffset + (deltaX > 0 ? -1 : 1));
            setStartX(e.clientX);
          }
        }
      }}
      onMouseUp={() => setIsDragging(false)}
      onMouseLeave={() => setIsDragging(false)}>
      <div class="absolute left-4 top-4 select-none bg-white text-left">
        <div class="text-2xl	font-bold">{currency(selectedMonth()?.segments?.[1]?.value ?? 0)}</div>
        <div class="text-text-level03">
          {t(selectedMonth()?.axisLabel || 'N/A')} {t('Bill')}
        </div>
      </div>

      <For each={data()} fallback={<IconLoader class="mx-auto my-32 animate-spin text-title-gray" />}>
        {(barData) => (
          <Bar
            barData={barData}
            hoveredBar={hoveredBar()}
            selectedMonth={selectedMonth()}
            capHeight={props.capHeight}
            onBarHover={(bar: BarData) => setHoveredBar(bar)}
            onBarLeave={() => setHoveredBar(null)}
            onBarClick={(bar: BarData) => {
              setSelectedMonth(bar);
            }}
          />
        )}
      </For>
    </div>
  );
}

export default UpDownBarGraph;

export function getLastFiveMonths(offset = 0) {
  const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const lastFiveMonths = [];
  const currentMonthIndex = (dayjs().month() - offset + 12) % 12;

  for (let i = 4; i >= 0; i--) {
    const monthIndex = (currentMonthIndex - i + 12) % 12;
    lastFiveMonths.push(MONTHS[monthIndex]);
  }

  return lastFiveMonths;
}
