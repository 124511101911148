import { signerRepository } from '~/pdfsigner/repositories/signerRepository';
import { UseCase } from './useCase';

export class GetSignersUseCase extends UseCase {
  public async runLogic(leaseId?: string) {
    if (!leaseId) {
      return;
    }
    this.state.leaseDocumentSigners = await signerRepository.getSignersForLease(leaseId);
  }
}
