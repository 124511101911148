import { useNavigate } from '@solidjs/router';
import { createMemo, onMount } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { TitlePresenter } from '~/pdfsigner/presenters/TitlePresenter';
import Composer from '~/pdfsigner/ui/screens/Composer';
import { ResetSelectedDocumentUseCase } from '~/pdfsigner/usecases/loadLeaseTemplateUseCase';

export const LeaseTemplateDetails = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { model: presentableTitle } = usePresenter(TitlePresenter);
  const { execute: resetSelectedDocument } = useUseCase(ResetSelectedDocumentUseCase);

  const breadcrumbItems = createMemo(() => [
    { label: t('Lease templates'), link: '/leasing/lease-templates' },
    { label: presentableTitle()?.title || t('Loading') },
  ]);

  onMount(() => {
    resetSelectedDocument();
  });
  return (
    <div>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <Composer />
    </div>
  );
};
