import { For } from 'solid-js';
import { Checkbox } from '~/components/common/Inputs/Checkbox';

export interface MultiSelectItem {
  label: string;
  value: string;
  isSelected: boolean;
}

export interface MultiSelectSectionProps {
  title: string;
  items: MultiSelectItem[];
  class?: string;
  onClick: (value: string) => void;
}

export const MultiSelectSection = (props: MultiSelectSectionProps) => {
  return (
    <div>
      <h3 class="mb-2 text-text-level02">{props.title}</h3>
      <ul class={props.class || ''}>
        <For each={props.items}>
          {(item) => (
            <div
              class={`mb-2 flex cursor-pointer items-center rounded-md border px-4 py-3 transition-colors duration-200 ${
                item.isSelected ? 'border-primary bg-primary/10' : 'bg-transparent'
              }`}
              onClick={() => props.onClick(item.value)}>
              <Checkbox label={item.label} class="size-4 rounded border-text-level03" showLabel checked={item.isSelected} />
            </div>
          )}
        </For>
      </ul>
    </div>
  );
};
