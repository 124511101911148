import { useNavigate } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import ImgMoveoutBg from '~/assets/images/tenant/moveoutBg.png';
import { MessageModal } from '~/components/modals/MessageModal';
import { getTenantFullName } from '~/components/tenants/utils';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import type { Component } from 'solid-js';
interface RemoveTenantProps {
  tenant: MagicDoor.Api.TenantDto | undefined;
  leaseId: string | undefined;
  moveoutTenant?: (tenantId: string) => Promise<void>;
}
export const RemoveTenant: Component<RemoveTenantProps> = (props) => {
  const [isMovingOut, setIsMovingOut] = createSignal<boolean>(false);
  const [modalVisible, setModalVisible] = createSignal<string | undefined>(props?.tenant?.id);
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { moveoutTenant, getLease } = useLease();

  const handleMoveOut = async () => {
    const id = props?.tenant?.id;
    try {
      setIsMovingOut(true);
      if (id) {
        await moveoutTenant(props.leaseId as string, id);
        getLease(props.leaseId);
        navigate(`/leasing/leases/${props.leaseId}`);
      }
    } catch (err: any) {
      console.error('Moving out tenant failed', err);
    } finally {
      setIsMovingOut(false);
    }
  };
  return (
    <Show when={modalVisible()}>
      <MessageModal
        title={t('Move out')}
        visible={true}
        onClose={() => {
          setModalVisible('');
          navigate(-1);
        }}
        loading={isMovingOut()}
        onDone={handleMoveOut}>
        <img class="h-24 w-full" src={ImgMoveoutBg} />
        <div class="p-6 text-sm leading-5 text-text-level02">
          {t('moveout_lease_modal_message_{name}', { name: getTenantFullName(props.tenant) })}
        </div>
      </MessageModal>
    </Show>
  );
};
