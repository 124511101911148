import { createMagicDoorContext } from '~/contexts/utils';
import { PortfolioRepository } from '~/repositories/portfolioRepository';
import { PropertiesRepository } from '~/repositories/propertiesRepository';
import { ReportRepository } from '~/repositories/reportRepository';
import { createTriggerResource } from '~/utils/resource';

const repo = new ReportRepository();
const propertyRepo = new PropertiesRepository();
const portfolioRepo = new PortfolioRepository();
export const [ReportsProvider, useReports] = createMagicDoorContext('Reports', () => {
  const [balanceSheetReport, getBalanceSheetReport] = createTriggerResource(repo.getBalanceSheetReport.bind(repo));
  const [cashFlowReport, getCashFlowReport] = createTriggerResource(repo.getCashFlowReport.bind(repo));
  const [generalLedgerReport, getGeneralLedgerReport] = createTriggerResource(repo.getGeneralLedgerReport.bind(repo));
  const [incomeStatementReport, getIncomeStatementReport] = createTriggerResource(repo.getIncomeStatementReport.bind(repo));
  const [ownerStatementReport, getOwnerStatementReport] = createTriggerResource(repo.getOwnerStatementReport.bind(repo));
  const [propertyDirectoryReport, getPropertyDirectoryReport] = createTriggerResource(repo.getPropertyDirectoryReport.bind(repo));
  const [ownerDirectoryReport, getOwnerDirectoryReport] = createTriggerResource(repo.getOwnerDirectoryReport.bind(repo));
  const [tenantDirectoryReport, getTenantDirectoryReport] = createTriggerResource(repo.getTenantDirectoryReport.bind(repo));
  const [bankAccountActivityReport, getBankAccountActivityReport] = createTriggerResource(repo.getBankAccountActivityReport.bind(repo));
  const [leaseUnpaidBillsReport, getLeaseUnpaidBillsReport] = createTriggerResource(repo.getLeaseUnpaidBillsReport.bind(repo));
  const [delinquencyReport, getDelinquencyReport] = createTriggerResource(repo.getDelinquencyReport.bind(repo));

  const dealMultipleFilter = (data: string[], current: string) => {
    if (current === 'All') return [];

    if (data?.includes('All')) return data.filter((item) => item !== 'All');
    return data;
  };

  const getFilterDisplay = async (propertyIds?: string, portfolioIds?: string) => {
    let propertiesStr = '';
    let portfoliosStr = '';

    if (propertyIds) {
      const property = await propertyRepo.batchGetPropertyByIds(propertyIds.split(','));
      propertiesStr = property.map((p) => p.name).join(', ') ?? '';
    }

    if (portfolioIds) {
      const portfolio = await portfolioRepo.batchGetPortfolioByIds(portfolioIds.split(','));
      portfoliosStr = portfolio?.map((p) => p.name).join(', ') ?? '';
    }
    return { propertiesStr, portfoliosStr };
  };

  const [rentRollReport, getRentRollReport] = createTriggerResource(repo.getRentRollReport.bind(repo));

  return {
    cashFlowReport,
    getCashFlowReport,
    generalLedgerReport,
    getGeneralLedgerReport,
    balanceSheetReport,
    getBalanceSheetReport,
    incomeStatementReport,
    getIncomeStatementReport,
    dealMultipleFilter,
    ownerStatementReport,
    getOwnerStatementReport,
    rentRollReport,
    getRentRollReport,
    propertyDirectoryReport,
    getPropertyDirectoryReport,
    ownerDirectoryReport,
    getOwnerDirectoryReport,
    tenantDirectoryReport,
    getTenantDirectoryReport,
    bankAccountActivityReport,
    getBankAccountActivityReport,
    leaseUnpaidBillsReport,
    getLeaseUnpaidBillsReport,
    getFilterDisplay,
    delinquencyReport,
    getDelinquencyReport,
  };
});
