import { Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { IconAlert } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

type BtnProps = {
  dirty?: boolean;
  onCancel: () => void;
  onSave: () => void;
  isChange?: boolean;
  magicAi?: boolean;
};
export const BottomBtns: Component<BtnProps> = (props) => {
  const { t } = useLocalization();
  return (
    <Show when={((props.isChange || props.dirty) && !props.magicAi) || (props.dirty && props.magicAi)}>
      <div class="flex gap-2 border-t border-input-border bg-white px-6 py-5">
        <Show when={props?.dirty}>
          <div class="flex items-center">
            <IconAlert class="size-5 text-warning" />
            <span class="ml-1 text-sm text-title-gray">
              {t('The listing has been updated. Click Save to update and replace the listing description.')}
            </span>
          </div>
        </Show>
        <Button class="ml-auto" variant="outlined" onClick={props.onCancel}>
          {t('Cancel')}
        </Button>
        <Button onClick={props.onSave}>{t('Save changes')}</Button>
      </div>
    </Show>
  );
};
