import { DocumentCategoryRequiredError } from '~/pdfsigner/errors/categoryRequiredError';
import { leaseTemplateRepository } from '~/pdfsigner/repositories/leaseTemplateRepository';
import { UseCase } from './useCase';
import { ValidateDocumentUseCase } from './validateDocumentUSeCase';

export class SaveLeaseTemplateUseCase extends UseCase {
  public async runLogic() {
    new ValidateDocumentUseCase().execute();

    if (!this.state.selectedDocument?.category) {
      throw new DocumentCategoryRequiredError();
    }

    const id = await leaseTemplateRepository.saveTemplate(this.state.selectedDocument);
    const shouldNavigate = !this.state.selectedDocument.id;
    this.state.selectedDocument.id = id;
    if (shouldNavigate) {
      this.eventEmitter?.emitNavigation(`/lease-templates/${id}`);
    }
  }
}
