import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { searchSelectValuePropNames } from '~/components/ui/Select/SearchSelect';
import { UnitListingTitle } from '~/components/units/UnitListingTitle';
import { useLocalization } from '~/contexts/global';
import { UnitListingsRepository } from '~/repositories/unitListingsRepository';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { EntrySearchProps } from '~/components/ui';
import type { UnitFilter } from '~/repositories/unitRepository';
import type { UnitListingDto } from '~/swagger/Api';

const repo = new UnitListingsRepository();

export const UnitListingSearch = <T extends boolean | undefined = undefined>(props: EntrySearchProps<UnitListingDto, T, UnitFilter>) => {
  const { t } = useLocalization();

  return (
    <SearchSelect
      fetcher={(query: string | undefined, page: number) =>
        repo.getUnitListings({ listed: true, pageSize: 20, ...props.filter, page, search: query || undefined })
      }
      placeholder={t('Search units')}
      renderSelected="title"
      renderItem={(item) => <UnitListingTitle unit={item} />}
      {...props}
    />
  );
};

export const LabeledUnitListingSearch = <T extends boolean | undefined = undefined>(
  props: Omit<EntrySearchProps<UnitListingDto, T>, 'onSelect'> &
    LabeledGroupProps & {
      value?: T extends true ? string[] : string;
      onInput?: (value: T extends true ? string[] : string) => void;
      filter?: UnitFilter;
    }
) => {
  const [searchProps, labeledProps] = splitProps(props, searchSelectValuePropNames);

  return (
    <LabeledGroup {...labeledProps}>
      <UnitListingSearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.((Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string);
        }}
      />
    </LabeledGroup>
  );
};
