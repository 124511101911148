import { createResource, createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { TenantRepository } from '~/repositories/tenantRepository';
import { TransactionsRepository } from '~/repositories/transactionRepository';
import { PortalStatus } from '~/swagger/Api';
import { createLazyResource, createMutation, createTriggerResource } from '~/utils/resource';
import type { TenantFilter, TenantPaymentFilter } from '~/repositories/tenantRepository';

const repo = new TenantRepository();
const transactionsRepo = new TransactionsRepository();

export const [TenantsProvider, useTenants] = createMagicDoorContext('Tenants', () => {
  const [triggerInvite, setTriggerInvite] = createSignal(false);

  const [filtered, setFilter, filteredActions] = createTriggerResource((filter: TenantFilter) =>
    repo.getTenants({ active: true, ...filter })
  );

  const [currentId, setCurrentId] = createSignal<string>();
  const [current, currentActions] = createResource(currentId, (id) => repo.getTenant(id));

  const [payments, getPayments, paymentsActions] = createTriggerResource(
    ({ tenantId, ...filter }: TenantPaymentFilter & { tenantId: string }) => repo.getPayments(tenantId, filter)
  );

  const [autoPayments, getAutoPayments] = createTriggerResource((tenantId: string) => repo.getAutoPayments(tenantId));

  const [counts, countsActions] = createLazyResource(() => repo.getCounts());

  const [notRequestedCount, { refetch: refetchNotRequestedCount }] = createResource(
    () => ({ portalStatus: PortalStatus.NotInvited } as TenantFilter),
    (filter) => repo.getTenants({ active: true, ...filter })
  );

  const addTenant = createMutation(async (model: MagicDoor.Api.TenantCreatedDto) => {
    const tenant = await repo.createTenant(model);
    filteredActions.refetch();
    return tenant;
  });

  const updateTenant = createMutation(async (tenantId: string, model: MagicDoor.Api.UpdateTenantDto) => {
    const updated = await repo.updateTenant(tenantId, model);
    if (tenantId === currentId()) currentActions.mutate(updated);
    filteredActions.refetch();
    countsActions.refetch();
    return updated;
  });

  /** Unavailable. use deactivateTenant instead in most cases */
  const deleteTenant = createMutation(async (tenantId: string) => {
    await repo.deleteTenant(tenantId);
    if (tenantId === currentId()) currentActions.mutate(undefined);
    filteredActions.refetch();
    countsActions.refetch();
  });

  const deactivateTenant = createMutation(async (tenantId: string, forceMoveOutFromLeases: boolean) => {
    await repo.deactivateTenant(tenantId, forceMoveOutFromLeases);
    if (tenantId === currentId()) currentActions.mutate((prev) => prev && { ...prev, active: false });
    filteredActions.refetch();
    countsActions.refetch();
  });

  const inviteTenant = createMutation(async (tenantId: string) => {
    const invite = await repo.portalInvite(tenantId);
    filteredActions.refetch();
    return invite;
  });

  const getInviteTenantList = createMutation(async () => {
    const invites = await repo.portalInviteList();
    filteredActions.refetch();
    return invites;
  });

  const [inviteList] = createResource(triggerInvite, async () => {
    filteredActions.refetch();
    return await repo.portalInviteList();
  });

  const changeTenantPassword = createMutation(async (tenantId: string, password: string) => {
    await repo.changeTenantPassword(tenantId, password);
    filteredActions.refetch();
  });

  const deletePayment = createMutation(async (transactionId: string) => {
    await transactionsRepo.deleteTransaction(transactionId);
    paymentsActions.refetch();
  });

  return {
    filtered,
    setFilter: setFilter,
    current,
    setCurrentId,
    payments,
    getPayments,
    autoPayments,
    getAutoPayments,
    get counts() {
      countsActions.fetch();
      return counts;
    },
    notRequestedCount,
    refetchNotRequestedCount,
    addTenant,
    updateTenant,
    deleteTenant,
    deactivateTenant,
    inviteTenant,
    getInviteTenantList,
    inviteList,
    setTriggerInvite,
    changeTenantPassword,
    deletePayment,
  };
});
