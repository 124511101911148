import { createEffect, createSignal } from 'solid-js';
import IconTranslation from '~/assets/images/settings/portal/portalTranslation.svg';
import { LabeledInputSwitch } from '~/components/common/Inputs/LabeledInputSwitch';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization, useTranslations, useCompanies } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';

type optionType = {
  label: string;
  value: string;
};
export const PortalTranslation = () => {
  const { t } = useLocalization();
  const { languages } = useTranslations();
  const { portalSettings, updatePortalSettings } = useCompanies();
  const [defaultLanguage, setDefaultLanguage] = createSignal();
  const [switchValue, setSwitchValue] = createSignal<boolean>();
  const options: optionType[] | any = async () => {
    if (languages.loading) return new Promise<any>(() => void 0);
    return languages()?.map((item) => ({ label: item.name || '', value: item.code || '' }));
  };

  const handleSwitch = (value: boolean) => {
    setSwitchValue(value);
    if (!value) {
      setDefaultLanguage([]);
      handleUpdate([]);
    }
  };
  const handleUpdate = (values?: any) => {
    try {
      updatePortalSettings({ translationLanguages: values });
    } catch (e) {
      console.log(e);
    }
  };
  createEffect(() => {
    if (portalSettings()?.translationLanguages?.length) {
      setSwitchValue(true);
      setDefaultLanguage(portalSettings()?.translationLanguages || []);
    } else {
      setSwitchValue(false);
    }
  });
  return (
    <ThreeSectionBox
      class="border-0 capitalize"
      topTitle={<SettingTitle title={t('Description translation')} icon={IconTranslation} />}
      middle={
        <div class="p-6">
          <div class="mb-6 flex justify-between">
            <p class="text-gray text-sm font-normal normal-case">
              {t('Whether the description needs to be translated into another language.（multiple choices）')}
            </p>
            <LabeledInputSwitch onInput={handleSwitch} value={switchValue()} />
          </div>
          <LabeledSelect
            options={options as optionType[]}
            value={defaultLanguage() as string}
            multiple={true}
            onInput={handleUpdate}
            disabled={!switchValue()}
            class="w-4/5"
            placeholder={t('Select language')}
          />
        </div>
      }
    />
  );
};
