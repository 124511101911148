import { Route, Routes } from '@solidjs/router';
import { LeaseDocumentDraftCreator } from '~/pages/lease-templates/lease-document-draft-creator/LeaseDocumentDraftCreator';
import { LeaseDetailsView } from './lease-details';
import { UpdatesProvider } from './lease-details/UpdatePagesContext';
import { ListLeases } from './list-leases';
import { NewLeaseView } from './new-lease';

export const LeasesRoutes = () => {
  return (
    <UpdatesProvider>
      <Routes>
        <Route path="/new" component={NewLeaseView} />
        <Route path="/create-lease-draft/:leaseId" component={LeaseDocumentDraftCreator} />
        <Route path="/:leaseId/*" component={LeaseDetailsView} matchFilters={{ leaseId: /^\d+$/ }} />
        <Route path="*" component={ListLeases} />
      </Routes>
    </UpdatesProvider>
  );
};
