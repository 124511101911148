import { createMemo } from 'solid-js';
import { toast } from '~/components/ui';
import { createMagicDoorContext } from '~/contexts/utils';
import { runBooksRepository } from '~/repositories/runBooksRepository';
import { createMutation, createTriggerResource } from '~/utils/resource';
import type { MaintenanceRequestFilter } from '~/repositories/maintenanceRequestRepository';

const repo = new runBooksRepository();

const formatRunBookData = (data: MagicDoor.Api.HydratedRunBookDto) => {
  return {
    ...data,
    defaultPropertyManager: data.propertyManagers.find((propertyManager) => propertyManager.id === data.defaultPropertyManagerId),
    defaultVendor: data.vendors.find((vendor) => vendor.id === data.defaultVendorId),
    vendorOverwrites: data.vendorOverwrites?.map((vendorOverwrite) => ({
      ...vendorOverwrite,
      vendor: data.vendors.find((vendor) => vendor.id === vendorOverwrite.vendorId),
      portfolio: data.portfolios.find((portfolio) => portfolio.id === vendorOverwrite.portfolioId),
      property: data.properties.find((property) => property.id === vendorOverwrite.propertyId),
    })),
    propertyManagerOverwrites: data.propertyManagerOverwrites?.map((propertyManagerOverwrite) => ({
      ...propertyManagerOverwrite,
      propertyManager: data.propertyManagers.find((propertyManager) => propertyManager.id === propertyManagerOverwrite.propertyManagerId),
      portfolio: data.portfolios.find((portfolio) => portfolio.id === propertyManagerOverwrite.portfolioId),
      property: data.properties.find((property) => property.id === propertyManagerOverwrite.propertyId),
    })),
  };
};

export const [RunBooksProvider, useRunBooks] = createMagicDoorContext('RunBooks', () => {
  const [runBook, getRunBook, runBookActions] = createTriggerResource(async (runBookId: string) => {
    const res = await repo.getRunBookById(runBookId);
    return formatRunBookData(res);
  });

  const [runBooks, getRunBooks, runBooksActions] = createTriggerResource(repo.getRunBooks.bind(repo));
  const [runBookMaintenanceRequest, getRunBookMaintenanceRequest] = createTriggerResource<
    Omit<MaintenanceRequestFilter, 'runBookId'> & { runBookId: string },
    MagicDoor.Api.MaintenanceRequestPaginationDto
  >((filter) => {
    return repo.getRunBookMaintenanceRequest({
      pageSize: 10,
      ...filter,
    });
  });

  const changeRunbookStatus = createMutation(async (runbookId: string, enable: boolean) => {
    if (enable) {
      await repo.enableRunBook(runbookId);
    } else {
      await repo.disableRunBook(runbookId);
    }

    toast.success('Modify success');

    runBooksActions.refetch();
  });

  const aiRunbooks = createMemo(() => {
    return runBooks()?.items.filter((runbook) => runbook.publicRunBook);
  });

  const customRunbooks = createMemo(() => {
    return runBooks()?.items.filter((runbook) => !runbook.publicRunBook);
  });

  const deleteRunBook = createMutation(async (runbookId: string) => {
    await repo.deleteRunBook(runbookId);
    runBooksActions.refetch();
  });

  const addRunBook = createMutation(async (payload: MagicDoor.Api.CreateRunBookDto) => {
    const response = await repo.addRunBook(payload);
    runBooksActions.refetch();
    return response;
  });

  const updateRunBook = createMutation(async (runbookId: string, payload: MagicDoor.Api.UpdateRunBookDto) => {
    await repo.updateRunBook(runbookId, payload);
    runBooksActions.refetch();
  });

  const updateDefaultVendor = createMutation(async (runbookId: string, payload: MagicDoor.Api.UpdateRunBookDefaultVendorDto) => {
    const res = await repo.updateDefaultVendor(runbookId, payload);
    runBookActions.mutate(formatRunBookData(res));
  });

  const updateDefaultPropertyManager = createMutation(
    async (runbookId: string, payload: MagicDoor.Api.UpdateRunBookDefaultPropertyManagerDto) => {
      const res = await repo.updateDefaultPropertyManager(runbookId, payload);
      runBookActions.mutate(formatRunBookData(res));
    }
  );

  const addVendorOverride = createMutation(async (runbookId: string, payload: MagicDoor.Api.AddVendorOverwriteForRunBookRequestDto) => {
    const res = await repo.addVendorOverride(runbookId, payload);
    runBookActions.mutate(formatRunBookData(res));
  });

  const addPropertyManagersOverride = createMutation(
    async (runbookId: string, payload: MagicDoor.Api.AddPropertyManagerOverwriteForRunBookRequestDto) => {
      const res = await repo.addPropertyManagersOverride(runbookId, payload);
      runBookActions.mutate(formatRunBookData(res));
    }
  );

  const deleteVendorOverride = createMutation(async (runbookId: string, overwriteId: string) => {
    const res = await repo.deleteVendorOverride(runbookId, overwriteId);
    runBookActions.mutate(formatRunBookData(res));
  });

  const deletePropertyManagersOverride = createMutation(async (runbookId: string, overwriteId: string) => {
    const res = await repo.deletePropertyManagersOverride(runbookId, overwriteId);
    runBookActions.mutate(formatRunBookData(res));
  });

  return {
    runBook,
    getRunBook,
    runBookActions,

    runBooks: runBooks,
    getRunBooks,
    runBooksActions,

    changeRunbookStatus,

    aiRunbooks,
    customRunbooks,

    deleteRunBook,
    addRunBook,
    updateRunBook,

    runBookMaintenanceRequest,
    getRunBookMaintenanceRequest,

    updateDefaultVendor,
    updateDefaultPropertyManager,

    addVendorOverride,
    addPropertyManagersOverride,

    deleteVendorOverride,
    deletePropertyManagersOverride,
  };
});
