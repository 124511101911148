import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { useSearchSelectContext } from '~/components/search-select/Context';
import { cn } from '~/utils/classnames';
import type { Accessor, Component } from 'solid-js';
import type { LevelSearch } from '~/swagger/Api';

export const SelectArea: Component<{
  item: Accessor<any>;
  type: LevelSearch;
  onClick: (e: MouseEvent) => void;
}> = (props) => {
  const { isSelected, isMultiple, isDisabled } = useSearchSelectContext();

  return (
    <div class="flex h-full basis-16 items-center justify-end" onClick={(e) => props.onClick(e)}>
      <Checkbox
        class={cn({
          'rounded-full border-essential-colour': !isMultiple(),
        })}
        checked={!!isSelected(props.item())}
        disabled={isDisabled(props.type)}
      />
    </div>
  );
};
