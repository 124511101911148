import { Show, createSignal, createMemo } from 'solid-js';
import IconCall from '~/assets/images/contact/call.svg?component-solid';
import IconEmail from '~/assets/images/contact/email.svg?component-solid';
import IconContact from '~/assets/images/settings/portal/portalContactInformation.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization, useCompanies } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { InformationForm } from '~/pages/settings/components/InformationForm';
import { InformationOverview } from '~/pages/settings/components/InformationOverview';
import type { Section } from '~/pages/settings/account-settings/types';

export const PortalContactInformation = () => {
  const { t } = useLocalization();
  const [editing, setEditing] = createSignal<boolean>(false);
  const { portalSettings, updatePortalSettings } = useCompanies();

  const sections = createMemo<Section[]>(() => {
    const model = portalSettings();
    if (model == null) return [];
    return [
      {
        field: 'phone',
        value: model.contact.phone,
        label: 'Phone',
        icon: IconCall,
        rules: [
          { required: true, message: t('Please input phone') },
          { type: 'phone', message: t('Phone is not valid') },
        ],
      },
      {
        field: 'email',
        value: model.contact.email,
        label: 'Email',
        icon: IconEmail,
        rules: [
          { required: true, message: t('Please input email') },
          { type: 'email', message: t('Email is not valid') },
        ],
      },
    ];
  });
  const handleSubmit = async (info: { phone?: string | undefined; email?: string | undefined }) => {
    const params = { ...portalSettings(), contact: { phone: info?.phone, email: info?.email } };
    try {
      await updatePortalSettings(params);
    } catch (err) {
      console.log(err);
    } finally {
      setEditing(false);
    }
  };
  return (
    <ThreeSectionBox
      class="border-0 capitalize"
      topTitle={<SettingTitle title={t('Contact information')} icon={IconContact} />}
      middle={
        <div class="p-6">
          <Show when={editing()} fallback={<InformationOverview sections={sections()} onEdit={() => setEditing(true)} />}>
            <InformationForm sections={sections()} onClose={() => setEditing(false)} onSubmit={handleSubmit} />
          </Show>
        </div>
      }
    />
  );
};
