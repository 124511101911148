import { A } from '@solidjs/router';
import { For, Show, createEffect } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons';
import { IconCirclePlus, Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { IconBuildLocation } from '~/pages/portfolios/components/Icons';
import type { Component } from 'solid-js';

export const PortfolioTabs: Component<{
  portfolios?: MagicDoor.Api.HydratedPortfolioDto[];
  selected?: MagicDoor.Api.HydratedPortfolioDto;
}> = (props) => {
  const { t } = useLocalization();

  let ref: HTMLDivElement | undefined;

  createEffect(() => {
    if (props.selected == null || ref == null) return;
    const selectedTab = ref.querySelector('[aria-selected=true]');
    if (selectedTab == null) return;
    setTimeout(() => selectedTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' }));
  });

  return (
    <>
      <div class="flex rounded-lg border border-partingline bg-white">
        <div
          ref={ref}
          class="ml-3 flex w-fit flex-1 select-none items-center gap-4 overflow-x-scroll whitespace-nowrap px-3 pb-1 pt-4 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-[#e3e6ef] [&::-webkit-scrollbar-thumb]:[border:3px_solid_#fff] [&::-webkit-scrollbar]:h-3"
          role="tablist">
          <Show when={props.portfolios} fallback={<For each={Array.from({ length: 5 })}>{() => <Skeleton class="h-10 w-36" />}</For>}>
            <For each={props.portfolios}>
              {(item) => (
                <A
                  class="group flex h-10 items-center gap-1 rounded-lg px-4 py-2 font-medium text-text-level02 transition-colors hover:bg-essential-colour/90 hover:text-white aria-selected:bg-essential-colour aria-selected:text-white"
                  href={`/listings/${item.id}`}
                  role="tab"
                  aria-selected={item === props.selected}>
                  <IconBuildLocation class="hidden size-0 transition-all group-aria-selected:size-6 md:flex" />
                  {item.name}
                </A>
              )}
            </For>
          </Show>
        </div>
        <div class="w-fit px-2 py-4 shadow-[-4px_0_4px_0_#00000014] md:px-6">
          <LinkButton href={`/listings/${props.selected?.id}/add`} class="font-medium" noScroll>
            <IconCirclePlus class="text-white" />
            <div class="hidden md:flex">{t('Set up a new Listing')}</div>
          </LinkButton>
        </div>
      </div>
    </>
  );
};
