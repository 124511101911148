import { createStore } from 'solid-js/store';
import { ManagementFeesRepository } from '~/repositories/managementFeesRepository';
import type { Option } from '~/components/common/DropdownMenu';

export interface ManagementFeeStore {
  submitLoading?: boolean;
  managementFees?: MagicDoor.Api.ManagementFeesResultDto['properties'];
  managementFeesLoading?: boolean;
}

export const dateTimeRangeOptions: Option[] = [
  { label: 'End Of Last Month', value: 'endOfLastMonth' },
  { label: 'Now', value: 'now' },
];

const managementFeesRepo = new ManagementFeesRepository();

const [store, setStore] = createStore<ManagementFeeStore>({});

const getManagementFees = async (payload: MagicDoor.Api.CalculateManagementFeesDto) => {
  setStore('managementFeesLoading', true);
  const response = await managementFeesRepo.calculateManagementFees(payload);
  setStore('managementFees', response.properties);
  setStore('managementFeesLoading', false);
  return response.calculateFeesUntil;
};

export default {
  store,
  getManagementFees,
};
