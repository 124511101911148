import { Routes, Route, useParams, useNavigate } from '@solidjs/router';
import { createEffect, Show } from 'solid-js';
import { useLease, useLeaseDeposits, AttachmentsProvider } from '~/contexts/local';
import { LeaseTransactionMarkAsReturnedModal } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseTransactionMarkAsReturnedModal';
import { LeaseAddDepositModal } from './LeaseAddDepositModal';
import { LeaseDepositDetailModal } from './LeaseDepositDetailModal';
import { LeaseDepositReceivePaymentModal } from './LeaseDepositReceivePaymentModal';
import { LeaseDepositsTable } from './LeaseDepositsTable';
import { LeaseReleaseDepositModal } from './LeaseReleaseDepositModal';

export const LeaseDepositRoutes = () => {
  const { lease } = useLease();
  const { setCurrentId } = useLeaseDeposits();
  const params = useParams();
  const navigate = useNavigate();

  createEffect(() => {
    setCurrentId(params.leaseId);
  });

  const backToTransactions = () => {
    navigate(`/leasing/leases/${params.leaseId}/deposits`, { scroll: false, replace: true });
  };

  return (
    <>
      <div class="text-left">
        <AttachmentsProvider>
          <LeaseDepositsTable lease={lease() as MagicDoor.Api.HydratedLeaseDto} />
        </AttachmentsProvider>
      </div>
      <Routes>
        <Route
          path="/add-deposit"
          element={
            <div>
              <Show when={lease()}>
                <LeaseAddDepositModal
                  onCancel={backToTransactions}
                  currentLease={lease() as MagicDoor.Api.HydratedLeaseDto}
                  visible={true}
                />
              </Show>
            </div>
          }
        />
        <Route
          path={'/receive-payment'}
          element={
            <Show when={lease()}>
              <LeaseDepositReceivePaymentModal
                currentLease={lease() as MagicDoor.Api.HydratedLeaseDto}
                visible={true}
                onCancel={backToTransactions}
              />
            </Show>
          }
        />
        <Route
          path={'/release-deposit'}
          element={
            <Show when={lease()}>
              <LeaseReleaseDepositModal
                currentLease={lease() as MagicDoor.Api.HydratedLeaseDto}
                visible={true}
                onCancel={backToTransactions}
              />
            </Show>
          }
        />
        <Route path={'/:transactionId/returned'} element={<LeaseTransactionMarkAsReturnedModal isDeposit={true} />} />
        <Route
          path={'/:transactionId'}
          element={
            <AttachmentsProvider>
              <LeaseDepositDetailModal currentLease={lease()!} />
            </AttachmentsProvider>
          }
        />
      </Routes>
    </>
  );
};
