import type { DataPathNode } from '~/pdfsigner/usecases/types/dataPathNode';

export class DataPathManager {
  private nodes: DataPathNode[];
  private selectedNode: DataPathNode | undefined;

  constructor(nodes: DataPathNode[]) {
    this.nodes = nodes;
    const node = this.findFirstSelectableDataPath();
    this.selectedNode = node ? { ...node } : undefined;
  }

  private findFirstSelectableDataPath(): DataPathNode | undefined {
    const queue: DataPathNode[] = [...this.nodes];
    while (queue.length > 0) {
      const currentNode = queue.shift();
      if (currentNode?.canSelect) {
        return currentNode;
      }
      if (currentNode?.children) {
        queue.push(...currentNode.children);
      }
    }
    return undefined;
  }

  public findDataPathWithKey(key: string): DataPathNode | undefined {
    const queue: DataPathNode[] = [...this.nodes];
    while (queue.length > 0) {
      const currentNode = queue.shift();
      if (currentNode?.key === key) {
        return currentNode;
      }
      if (currentNode?.children) {
        queue.push(...currentNode.children);
      }
    }
    return undefined;
  }

  public doesDataPathWithKeyExist(key?: string): boolean {
    if (!key) {
      return false;
    }
    return this.findDataPathWithKey(key) !== undefined;
  }

  public getDataPaths(): DataPathNode[] {
    return this.nodes;
  }

  public getSelectedNode(): DataPathNode | undefined {
    return this.selectedNode;
  }

  public getNameOfDataPathWithKey(key?: string): string | undefined {
    if (!key) {
      return undefined;
    }
    const node = this.findDataPathWithKey(key);
    return node?.name;
  }

  public getValueOfDataPathWithKey(key?: string): string | undefined {
    if (!key) {
      return undefined;
    }
    const node = this.findDataPathWithKey(key);
    return node?.value;
  }

  public selectNodeWithKey(key?: string): void {
    if (!key) {
      return;
    }
    const node = this.findDataPathWithKey(key);
    this.selectedNode = node ? { ...node } : this.selectedNode;
  }

  public getSelectedNodeKey(): string | undefined {
    return this.selectedNode?.key;
  }
}
