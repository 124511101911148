import { For, Show, createSignal } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { IconLoader } from '~/components/ui/Icons';
import { Modal } from '~/components/ui/Modal';
import { useLocalization } from '~/contexts/global';
import { cn, twc } from '~/utils/classnames';
import { useLockBodyScroll } from '~/utils/hooks';
import { createMutation } from '~/utils/resource';
import { dialogs } from './store';
import type { DialogItem, DialogType } from './store';
import type { Component } from 'solid-js';

const Button = twc.button`inline-flex h-10 items-center justify-center whitespace-nowrap rounded-md px-4 py-2 text-sm font-medium transition-colors focus-visible:outline-none disabled:opacity-50 disabled:pointer-events-none`;

const Dialog: Component<DialogItem<DialogType>> = (props) => {
  const { t } = useLocalization();
  const [input, setInput] = createSignal<string>('');

  const handleCancel = createMutation(() => props.resolve(props.type === 'prompt' ? undefined : false));
  const handleDone = createMutation(() => props.resolve(props.type === 'prompt' ? input() : true));

  return (
    <Modal
      class={cn(
        'min-w-80 max-w-2xl space-y-4 rounded-lg bg-white p-6 px-5 py-4 text-sm text-title-gray shadow-lg sm:min-w-96',
        props.class
      )}>
      <div class="flex gap-2">
        <Show when={props.icon}>
          <Dynamic component={props.icon} class="my-0.5 size-5 text-text-level03" />
        </Show>
        <div class="flex grow flex-col gap-2">
          <Show when={props.title}>
            <h2 class={cn('text-lg font-semibold capitalize', props.titleClass)}>{props.title}</h2>
          </Show>
          {typeof props.content === 'function' ? props.content() : props.content}
          <Show when={props.type === 'prompt'}>
            <input
              type="text"
              class="w-full rounded-md border bg-transparent px-3 py-2 text-sm transition-colors focus:border-primary focus:outline-none"
              value={input()}
              onInput={(e) => setInput(e.currentTarget.value)}
            />
          </Show>
        </div>
      </div>
      <div class={cn('flex justify-end gap-2', props.footerClass)}>
        <Show when={props.type !== 'alert'}>
          <Button
            type="button"
            class="border border-primary capitalize text-primary hover:bg-primary/10 active:bg-primary/20"
            onClick={handleCancel}
            disabled={handleCancel.pending}>
            <Show when={handleCancel.pending} fallback={props.cancelText ?? t('Cancel')}>
              <IconLoader class="size-5 animate-spin" />
            </Show>
          </Button>
        </Show>
        <Button
          type="button"
          class="min-w-24 bg-primary text-white hover:bg-primary/90 active:bg-primary/80"
          onClick={handleDone}
          disabled={handleDone.pending || props?.confirmDisabled?.()}>
          <Show when={handleDone.pending} fallback={props.doneText ?? t(props.type === 'alert' ? t('OK') : t('Yes'))}>
            <IconLoader class="size-5 animate-spin" />
          </Show>
        </Button>
      </div>
    </Modal>
  );
};

export const Dialogs: Component = () => {
  useLockBodyScroll(() => dialogs.length > 0);
  return (
    <Show when={dialogs.length}>
      <For each={dialogs}>{Dialog}</For>
    </Show>
  );
};
