import { useParams, useNavigate } from '@solidjs/router';
import { createEffect, createMemo, createSignal } from 'solid-js';
import { FileUploadModal } from '~/components/file-attachments';
import { getLeaseDateName, getUnitFullname } from '~/components/leases/utils';
import { PrintTablePageButton } from '~/components/print/PrintTablePageButton';
import { useLocalization, useUnits } from '~/contexts/global';
import { useLeaseRentTransactions, useLease } from '~/contexts/local';
import { useUpdates } from '~/pages/leases/lease-details//UpdatePagesContext';
import { QuickActionPanel } from '~/pages/leases/lease-details//components/QuickActionPanel';
import { IconAddFile, IconTower, IconFileFilter, IconRightPage, IconBill } from '~/pages/leases/lease-details/components/Icons';
import { LeaseDepositTransactionTable } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseDepositTransactionTable';
import { LeaseTransactionUpdateModal } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseTransactionUpdateModal';
import { Balances } from '~/pages/leases/lease-details/overview/Balances';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { QuickActionItem } from '~/pages/leases/lease-details//components/QuickActionPanel';

interface LeaseTransactionTableProps {
  lease: MagicDoor.Api.HydratedLeaseDto;
}

export const LeaseTransactionTable = (props: LeaseTransactionTableProps) => {
  const { t } = useLocalization();
  const params = useParams();

  const { updateSignal } = useUpdates();
  const { transactions, loading, getLeaseRentTransactions } = useLeaseRentTransactions();

  const [status, setStatus] = createSignal<string>('');
  const [showModal, setShowModal] = createSignal<string>('');
  const [transactionData, setTransactionData] = createSignal<MagicDoor.Api.HydratedLeaseTransactionDto | undefined>(undefined);
  const [showUploadModal, setShowUploadModal] = createSignal<boolean>(false);

  const leaseId = createMemo(() => params.leaseId);
  const { lease } = useLease();
  const { unit } = useUnits();
  const navigate = useNavigate();

  let leaseTransactionRef: any;

  const quickActions = createMemo<QuickActionItem[]>(() => {
    return [
      {
        title: t('Add a charge'),
        icon: IconAddFile,
        code: 'add-charge',
        bgColor: '#E8EFFF',
      },
      {
        title: t('Issue credit'),
        icon: IconFileFilter,
        code: 'issue-credit',
        bgColor: '#FAE8FF',
      },
      {
        title: t('Receive payment'),
        icon: IconTower,
        code: 'receive-payment',
        bgColor: '#DEF6E5',
      },
      {
        title: t('Add service bill'),
        icon: IconBill,
        code: 'add-service-bill',
        bgColor: '#FFEFE0',
      },
      {
        title: t('Payout tenant'),
        code: 'payout-tenant',
        icon: IconRightPage,
        bgColor: '#D3F6FF',
      },
    ];
  });
  const handleLink = (code: string) => {
    navigate(`/leasing/leases/${leaseId()}/transactions/${code}`);
  };

  const handleUpload = (transaction: MagicDoor.Api.HydratedLeaseTransactionDto) => {
    setTransactionData(transaction);
    setShowUploadModal(true);
  };

  const handleUpdateClick = (transaction: MagicDoor.Api.HydratedLeaseTransactionDto) => {
    setTransactionData(transaction);
    setShowModal('update');
  };

  const handleDetailClick = (transaction: MagicDoor.Api.HydratedLeaseTransactionDto) => {
    navigate(`${transaction.id}`);
  };

  const getTotal = () => {
    const data: MagicDoor.Api.HydratedLeaseTransactionDto[] = leaseTransactionRef.getData();
    const total = currency(data.reduce((previous, current) => previous + current.amount, 0));
    return total;
  };

  createEffect(() => {
    updateSignal();
    getLeaseRentTransactions(leaseId());
  });

  const onMarkAsReturnedClick = (trans: MagicDoor.Api.HydratedLeaseTransactionDto) => {
    navigate(`${trans.id}/returned`);
  };

  return (
    <>
      <div class="mb-5 grid grid-cols-3 gap-5 text-left">
        <QuickActionPanel data={quickActions()} onActionClick={handleLink} class="col-span-full lg:col-span-2" />
        <Balances lease={lease()} class="col-span-full lg:col-span-1" />
      </div>
      <LeaseDepositTransactionTable
        ref={leaseTransactionRef}
        data={transactions()}
        actions={() => getLeaseRentTransactions(leaseId())}
        loading={loading()}
        status={status()}
        setStatus={setStatus}
        showModal={showModal()}
        setShowModal={setShowModal}
        transactionData={transactionData()}
        onUploadClick={handleUpload}
        onDetailClick={handleDetailClick}
        onUpdateClick={handleUpdateClick}
        onDeleteClick={(trans) => {
          setTransactionData(trans);
          setShowModal('deleteTransaction');
        }}
        onMarkAsReturnedClick={onMarkAsReturnedClick}
        PanelActions={
          <PrintTablePageButton
            table={{
              columns: leaseTransactionRef.getColumns(),
              data: leaseTransactionRef.getData(),
            }}
            caption={{
              title: 'Transactions',
              extraData: [
                {
                  label: 'Lease',
                  value: getLeaseDateName(lease()!),
                },
                {
                  label: 'Unit',
                  value: getUnitFullname(unit()),
                },
                {
                  label: 'Total',
                  value: getTotal(),
                },
                {
                  label: 'Date',
                  value: dateFormat(),
                },
              ],
            }}
          />
        }
        tableTitle={t('Transactions')}
        updateModal={
          <LeaseTransactionUpdateModal
            lease={props.lease}
            visible={showModal() === 'update'}
            transactionDetail={transactionData() as MagicDoor.Api.HydratedLeaseTransactionDto}
            onCancel={() => setShowModal('')}
            onSuccess={() => getLeaseRentTransactions(leaseId())}
          />
        }
      />

      <FileUploadModal
        entryId={transactionData()?.bill ? transactionData()?.bill?.id : transactionData()?.transaction?.id}
        entryType={transactionData()?.bill ? 'bill' : 'transaction'}
        open={showUploadModal()}
        onClose={() => setShowUploadModal(false)}
      />
    </>
  );
};
