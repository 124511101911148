import { createMemo, For, Show } from 'solid-js';
import IconPolygon from '~/assets/images/common/polygon.svg';
import aiScoreBackground from '~/assets/images/rental-application/aiScoreBg.png';
import aiScoreLogo from '~/assets/images/rental-application/aiScoreLogo.png';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { clamp } from '~/utils/tool';
import type { Component, Accessor } from 'solid-js';

interface AIRatingIndicatorProps {
  index: number;
  step: RatingStepInfo;
}

interface RatingStepInfo {
  current: number;
  delta: number;
}

export const AIRatingSummary: Component = () => {
  const { t } = useLocalization();
  const { applicationStore } = useRentalApplication();

  return (
    <div
      style={{ 'background-image': `url(${aiScoreBackground})` }}
      class="flex h-fit w-full items-center gap-3	rounded-3xl bg-cover bg-center bg-no-repeat px-6 py-5 text-white 2xl:w-2/3">
      <img src={aiScoreLogo} alt="AI score" class="w-28" />
      <div class="flex flex-col items-center justify-start gap-6">
        <div class="flex flex-wrap justify-between gap-4 self-stretch">
          <div class="grow-1 flex basis-36 items-end justify-center gap-1.5">
            <h2 class="text-3xl font-semibold leading-none	">{applicationStore.application?.aiApplicationScore ?? 0}</h2>
            <span class="text-sm">{t('Overall rating')}</span>
          </div>
          <AIRatingBar score={applicationStore.application?.aiApplicationScore ?? 0} />
        </div>
        <div class="text-xs opacity-90">
          <span class="font-medium"> {t('Reason for ai score')}: </span> {applicationStore.application?.aiApplicationDescription ?? ''}
        </div>
      </div>
    </div>
  );
};

const AIRatingBar: Component<{ score: number }> = (props) => {
  const stepInfo: Accessor<RatingStepInfo> = createMemo(() => {
    const currentStep = Math.ceil(props.score / 20);
    return {
      current: currentStep,
      delta: clamp((props.score - (currentStep - 1) * 20) / 20, 0.2, 0.8) * 100,
    };
  });

  return (
    <div class="flex items-center gap-1">
      <For each={['#FF5C5C', '#FFAA6C', '#FFE769', '#87FF93', '#00D28D']}>
        {(color, index) => (
          <div class="relative">
            <div
              style={{ 'background-color': color }}
              class={cn('h-1 w-9 rounded-sm', index() + 1 === stepInfo().current && 'h-2 rounded-md')}
            />
            <AIRatingIndicator index={index()} step={stepInfo()} />
          </div>
        )}
      </For>
      <span class="text-xxs">100</span>
    </div>
  );
};

const AIRatingIndicator: Component<AIRatingIndicatorProps> = (props) => {
  const { t } = useLocalization();
  const labels = createMemo(() => ['Very bad', 'Bad', 'Good', 'Very good', 'Excellent'].map((label) => t(label)));

  return (
    <Show when={props.step.current === props.index + 1}>
      <img src={IconPolygon} alt="Indicator" class="absolute -top-3 -translate-x-1/2" style={{ left: `${props.step.delta}%` }} />
      <Show when={labels()[props.step.current - 1]}>
        <div class="absolute left-1/2 -translate-x-1/2 translate-y-1 whitespace-nowrap text-xxs font-medium opacity-90">
          {labels()[props.step.current - 1]}
        </div>
      </Show>
    </Show>
  );
};
