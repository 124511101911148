import { CompanyInformationSettings } from './CompanyInformationSettings';
import { CompanyProfileSettings } from './CompanyProfileSettings';

export const CompanyInformation = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <CompanyProfileSettings />
      <CompanyInformationSettings />
    </div>
  );
};
