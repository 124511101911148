import { Route, Routes, useLocation, useMatch, useNavigate, useParams } from '@solidjs/router';
import { createEffect, createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { getTenantFullName } from '~/components/tenants/utils';
import { TabLinks } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { AttachmentsProvider, LeaseRentTransactionsProvider, MaintenanceRequestsProvider } from '~/contexts/local';
import { TenantSummary } from './components/TenantSummary';
import { TenantCommunicationsTab } from './tabs/TenantCommunicationsTab';
import { TenantFilesTab } from './tabs/TenantFilesTab';
import { TenantMaintenanceTab } from './tabs/TenantMaintenanceTab';
import { TenantOverviewTab } from './tabs/TenantOverviewTab';
import { TenantPaymentsTab } from './tabs/TenantPaymentsTab';
import { TenantPortalAccessTab } from './tabs/TenantPortalAccessTab';
import { TenantsEmailsTab } from './tabs/TenantsEmailsTab';
import { TenantsNotesTab } from './tabs/TenantsNotesTab';
import type { BreadcrumbItem } from '~/components/common/Breadcrumb';

export const TenantDetailsPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ tenantId: `${number}` }>();

  const { current, setCurrentId } = useTenants();

  const isPaymentsMethodsPage = useMatch(() => `/users/tenants/:id/payments/methods`);

  const breadcrumbItems = createMemo(
    () =>
      [
        { label: t('Tenants'), link: '/users/tenants' },
        { label: getTenantFullName(current()) || t('Loading') },
        isPaymentsMethodsPage() ? { label: t('Payment method') } : undefined,
      ].filter(Boolean) as BreadcrumbItem[]
  );

  createEffect(() => setCurrentId(params.tenantId));

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="flex flex-col gap-4 px-8 py-5 lg:flex-row">
        <TenantSummary class="w-full shrink-0 lg:w-80" loading={current.loading} tenant={current()} />
        <div class="flex-1 overflow-hidden">
          <TabLinks
            class="mb-4 rounded-lg border border-partingline bg-white"
            items={[
              { label: t('Overview'), href: location.search },
              { label: t('Communications'), href: `communications${location.search}` },
              { label: t('Payments'), href: `payments${location.search}`, end: false },
              { label: t('Maintenance'), href: `maintenance${location.search}` },
              { label: t('Emails'), href: `emails${location.search}` },
              { label: t('Files'), href: `files${location.search}` },
              { label: t('Notes'), href: `notes` },
              { label: t('Activity & Portal access'), href: `portal-access${location.search}` },
            ]}
            replace
          />
          <LeaseRentTransactionsProvider>
            <MaintenanceRequestsProvider>
              <AttachmentsProvider>
                <Routes>
                  <Route path="/" component={TenantOverviewTab} />
                  <Route path="/communications" component={TenantCommunicationsTab} />
                  <Route path={['/payments/:paymentId?', '/payments/auto-payment-details/:autoPaymentId']} component={TenantPaymentsTab} />
                  <Route path="/maintenance" component={TenantMaintenanceTab} />
                  <Route path="/files" component={TenantFilesTab} />
                  <Route path="/portal-access" component={TenantPortalAccessTab} />
                  <Route path="/emails/*" component={TenantsEmailsTab} />
                  <Route path="/notes/*" component={TenantsNotesTab} />
                </Routes>
              </AttachmentsProvider>
            </MaintenanceRequestsProvider>
          </LeaseRentTransactionsProvider>
        </div>
      </div>
    </>
  );
};
