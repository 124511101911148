import { runWithOwner } from 'solid-js';
import { isEmptyData } from '~/utils/tool';
import {
  getCityValidationError,
  getStateValidationError,
  getStreetAddress1ValidationError,
  getZipCodeValidationError,
  getCountryValidationError,
} from '~/utils/validations';
import type { Owner } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm/type';

export function validateAddress(address: MagicDoor.Api.AddressDto, isRequired: boolean = true): boolean | string {
  const validationErrors: MagicDoor.Api.AddressDto = {
    streetAddress1: getStreetAddress1ValidationError(address.streetAddress1, isRequired),
    city: getCityValidationError(address.city, isRequired),
    state: getStateValidationError(address.state, isRequired),
    zipCode: getZipCodeValidationError(address.zipCode, isRequired),
    country: getCountryValidationError(address.country, isRequired),
  };

  if (isEmptyData(validationErrors)) {
    return true;
  }

  return JSON.stringify(validationErrors);
}

type getRules = (t: (str: string, options?: Record<string, string>) => string, ...opts: any[]) => BetterForm.Rule[];

export const email: getRules = (t) => [
  { message: t('Please input email'), required: true },
  { message: t('Email is not valid'), type: 'email' },
];

export const phone: getRules = (t) => [
  { message: t('Please input phone'), required: true },
  { type: 'phone', message: t('Phone is not valid') },
];

export const dateOfBirth: getRules = (t) => [
  {
    message: t('Please input date of birth'),
    required: true,
  },
  {
    message: t('Date of birth is not valid'),
    validator: (value) => {
      return /^\d{4}-\d{2}-\d{2}$/.test(value);
    },
  },
];

export const bankAccountName: getRules = (t) => [
  { length: 100, message: t('{name} must be {length} characters or less', { name: t('Bank name'), length: '100' }) },
];

export const bankAccountNumber: getRules = (t, isRequired) => [
  { required: isRequired, message: t('Please input account number') },
  { length: 100, message: t('{name} must be {length} characters or less', { name: t('Account number'), length: '100' }) },
];

export const bankAccountRoutingNumber: getRules = (t, isRequired) => [
  { message: t('Routing number should have 9 numbers'), regex: /^\d{9}/ },
  { required: isRequired, message: t('Please input routing number') },
];

export const bankAccountType: getRules = (t, isRequired) => [{ required: isRequired, message: t('Please select an account type') }];

export const getAddress1ValidError = (address1: string, t: Translate): string => {
  if (!address1) {
    return t('Street address1 is required');
  } else if (
    address1.length > 50 ||
    /^[A-Za-z0-9\s.,'#-]+(?: [A-Za-z0-9\s.,'#-]+)*,\s*[A-Za-z ]+(?:\s[A-Za-z ]+)*,\s*[A-Z]{2}(?:\s\d{5})?$/.test(address1)
  ) {
    return t('Street address1 is not valid');
  }
  return 'No errors';
};

export const getAddress2ValidError = (address2: string, t: Translate): string => {
  if (
    address2 &&
    (/^[A-Za-z0-9\s.,'#-]+(?: [A-Za-z0-9\s.,'#-]+)*,\s*[A-Za-z ]+(?:\s[A-Za-z ]+)*,\s*[A-Z]{2}(?:\s\d{5})?$/.test(address2) ||
      address2.length > 100)
  ) {
    return t('Street address2 is not valid');
  }
  return 'No errors';
};
export const getCityValidError = (city: string, t: Translate): string => {
  if (!city) {
    return t('City is required');
  } else if (city.length > 27 || city.length < 2 || /[><=$?|%"`]|&#+/.test(city)) {
    return t('City is not valid');
  }
  return 'No errors';
};
export const getStateValidError = (state: string, t: Translate): string => {
  if (!state) {
    return t('State is required');
  } else if (!/^[A-Za-z]{2}$/.test(state)) {
    return t('State must be 2 characters');
  }
  return 'No errors';
};
export const getZipCodeValidError = (zipCode: string, t: Translate): string => {
  if (!zipCode) {
    return t('Zip Code is required');
  } else if (!/^\d{5}$/.test(zipCode)) {
    return t('Zip Code must be 5 digits');
  }
  return 'No errors';
};

export const getCountryValidError = (country: string, t: Translate): string => {
  if (!country || country.trim() === '') {
    return t('Country is required');
  } else if (!/^[a-zA-ZÀ-ÖØ-öø-ÿ' -]{2,50}$/.test(country)) {
    return t('Country is not valid');
  }
  return 'No errors';
};

type ValidationFunction = (value: any, t: any) => string;
export const addressRequired = (t: Translate): BetterForm.Rule[] => {
  return [
    { required: false, message: JSON.stringify({ streetAddress1: t('Please input address') }) },
    {
      validator: (value: any = {}, specificFieldPath?: string) => {
        const errors: Record<string, string> = {};
        const validList: Record<string, ValidationFunction> = {
          streetAddress1: getAddress1ValidError,
          streetAddress2: getAddress2ValidError,
          state: getStateValidError,
          city: getCityValidError,
          zipCode: getZipCodeValidError,
          country: getCountryValidError,
        };

        if (specificFieldPath && Object.keys(validList).includes(specificFieldPath ?? '')) {
          const validateFn = validList[specificFieldPath];
          const attr = validateFn(value[specificFieldPath], t);

          if (attr === 'No errors') {
            return true;
          }
          errors[specificFieldPath] = attr;
        } else {
          const { streetAddress1, streetAddress2, city, state, zipCode, country } = value;
          const validators: Record<string, string> = {
            streetAddress1: getAddress1ValidError(streetAddress1, t),
            streetAddress2: getAddress2ValidError(streetAddress2, t),
            city: getCityValidError(city, t),
            state: getStateValidError(state, t),
            zipCode: getZipCodeValidError(zipCode, t),
            country: getCountryValidError(country, t),
          };

          Object.keys(validators).forEach((field) => {
            const error = validators[field];
            if (error !== 'No errors') {
              errors[field] = error;
            }
          });
        }

        if (Object.keys(errors).length > 0) {
          return JSON.stringify(errors);
        }
        return true;
      },
    },
  ];
};

type Translate = (s: string, params?: Record<string, string>) => string;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const addressFormat = (owner: Owner | null, t: Translate, isRequired?: boolean): BetterForm.Rule => {
  return {
    validator: (value) => {
      if (!value) {
        return true;
      }
      return runWithOwner(owner, () => validateAddress(value, isRequired)) as string;
    },
  };
};
