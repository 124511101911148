import { useNavigate } from '@solidjs/router';
import { createSignal, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { ConfirmationModal } from '~/components/modals';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { SignerListPresenter } from '~/pdfsigner/presenters/SignerListPresenter';
import { ToolbarPresenter } from '~/pdfsigner/presenters/ToolbarPresenter';
import { ChangeNumberOfSignersUseCase } from '~/pdfsigner/usecases/changeNumberOfSignersUseCase';
import { PMSelectionChangedUseCase } from '~/pdfsigner/usecases/pmSelectionChangedUseCase';
import { SelectSignerUseCase } from '~/pdfsigner/usecases/selectSignerUseCase';
import { SetAnnotationTypeUseCase } from '~/pdfsigner/usecases/setAnnotationTypeUseCase';
import { DataFieldsSelector } from './DataFieldsSelector';
import { SignersSelector } from './SignersSelector';

export interface LeaseTemplateToolbarProps {
  onSave: () => void;
}

export const LeaseTemplateToolbar = (props: LeaseTemplateToolbarProps) => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { model: toolbar } = usePresenter(ToolbarPresenter);
  const { model: signerList } = usePresenter(SignerListPresenter);
  const { execute: selectSigners } = useUseCase(SelectSignerUseCase);
  const { execute: pmSelectionChanged } = useUseCase(PMSelectionChangedUseCase);
  const { execute: changeNumberOfSigners } = useUseCase(ChangeNumberOfSignersUseCase);
  const { execute: setAnnotationType } = useUseCase(SetAnnotationTypeUseCase);
  const [isShowLeaveConfirmModal, setIsShowLeaveConfirmModal] = createSignal(false);

  const onConfirmToLeave = () => {
    setIsShowLeaveConfirmModal(false);
    navigate(-1);
  };

  return (
    <div class="thinscroll flex h-[calc(100%_-_16px)] w-[256px] shrink-0 flex-col gap-[18px] overflow-auto rounded-lg bg-white px-[28px] py-5">
      <div class="flex items-center gap-3">
        <LabeledSelect
          selectClass="bg-white"
          label={t('Signers')}
          labelClass="capitalize text-text-level03"
          options={toolbar()?.numberOfSignerOptions ?? []}
          value={toolbar()?.numberOfSelectedSigners}
          onInput={changeNumberOfSigners}
        />
      </div>
      <SignersSelector
        signerList={signerList()}
        onSelectSigner={(index) => selectSigners(index)}
        onSetSignerPm={(data) => pmSelectionChanged(data)}
      />
      <DataFieldsSelector selectedAnnotationType={toolbar()?.selectedAnnotationType} onChangeSelectedAnnotationType={setAnnotationType} />
      <div class="mt-auto flex items-center justify-center gap-2">
        <Button class="flex-1 justify-center" variant="outlined" color="primary" onClick={() => setIsShowLeaveConfirmModal(true)}>
          {t('Cancel')}
        </Button>
        <Button class="flex-1 justify-center" variant="solid" color="primary" onClick={props.onSave}>
          {t('Save')}
        </Button>
      </div>
      <Show when={isShowLeaveConfirmModal()}>
        <ConfirmationModal
          message={t('Do you confirm to cancel the editing, the changes you made will not be saved.')}
          onCancel={() => setIsShowLeaveConfirmModal(false)}
          onConfirm={onConfirmToLeave}
        />
      </Show>
    </div>
  );
};
