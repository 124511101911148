import { leaseDraftRepository } from '~/pdfsigner/repositories/leaseDraftRepository';
import { UseCase } from './useCase';
import type { LeaseDraftsLoadRequest } from './types/leaseDraftsLoadRequest';

export class LoadLeaseDraftsForLeaseUseCase extends UseCase {
  public async runLogic(request: LeaseDraftsLoadRequest) {
    if (!request || !request.leaseId) {
      return;
    }
    this.state.paginatedLeaseDrafts = await leaseDraftRepository.getLeaseDrafts(request.leaseId, request.page);
  }
}
