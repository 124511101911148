import { For, Show, createEffect, createMemo, createSignal, equalFn, on } from 'solid-js';
import { IconCheck, IconSearch, InfiniteScroll, Popover } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { HttpError } from '~/errors';
import { stringToColor } from '~/utils/strings';
import { debounce } from '~/utils/tool';
import type { JSX } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { Promisable } from '~/utils/types';

export const searchSelectValuePropNames = [
  'multiple',
  'selected',
  'placeholder',
  'onInput',
  'exclude',
  'defaultSelected',
  'filter',
  'defaultSelectedIds',
  'disabledItem',
  'renderItem',
] as const;

export type LabeledSearchSelectProps<T extends { id: unknown }, U extends boolean | undefined> = Omit<EntrySearchProps<T, U>, 'onSelect'> &
  LabeledGroupProps & {
    value?: U extends true ? string[] : string;
    onInput?: (value: U extends true ? string[] : string, selected: U extends true ? T[] : T) => void;
  };

export type EntrySearchProps<
  T extends { id: unknown },
  U extends boolean | undefined,
  F extends Record<string, unknown> = Record<string, unknown>
> = {
  placeholder?: string;
  multiple?: U;
  selected?: U extends true ? T[] : T;
  onSelect?: (selected: U extends true ? T[] : T) => void;
  exclude?: (item: T) => boolean | undefined;
  disabledItem?: (item: T) => JSX.Element;
  doNotRenderSelected?: boolean;
  filter?: F;
  minimumInputLength?: number;
  defaultSelected?: U extends true ? T[] : T;
  defaultSelectedIds?: U extends true ? string[] : string;
  initialSelectedLoader?: (ids: string[]) => Promise<T[]>;
  class?: string;
  prefix?: string;
  renderItem?: (item: T) => JSX.Element;
};

export const SearchSelect = <T extends { id: unknown }, U extends boolean | undefined = undefined>(
  props: {
    fetcher: (query: string | undefined, page: number) => Promisable<{ items: T[]; totalPages: number }>;
    renderItem: (
      item: T,
      meta: {
        disabledLabel?: JSX.Element;
      }
    ) => JSX.Element;
    renderSelected: keyof T | ((item: T) => JSX.Element);
  } & EntrySearchProps<T, U>
) => {
  const { t } = useLocalization();
  const [list, setList] = createSignal<T[]>([]);
  const [pagination, setPagination] = createSignal<{ page: number; totalPages: number }>();
  const [error, setError] = createSignal<unknown>();
  const [query, setQuery] = createSignal('');
  const [selected, setSelected] = createSignal<T[]>([]);

  let inputRef!: HTMLInputElement;

  createEffect(
    on(
      () => ({ selected: props.selected, defaultSelected: props.defaultSelected }),
      (current, prev) => {
        let _selected = current.selected;
        if (!_selected && current.defaultSelected && !equalFn(current.defaultSelected, prev?.defaultSelected)) {
          _selected = current.defaultSelected;
        }
        if (_selected) {
          setSelected((Array.isArray(_selected) ? _selected : [_selected]) as T[]);
        }
      }
    )
  );

  const loadInitialSelected = async (defaultSelectedIds: string[]) => {
    if (props.initialSelectedLoader && defaultSelectedIds) {
      const items = await props.initialSelectedLoader(defaultSelectedIds);
      setSelected(items);
    }
  };

  createEffect(
    on(
      () => props.defaultSelectedIds,
      (current, prev) => {
        if (equalFn(current, prev)) return;

        const array = (Array.isArray(current) ? current : [current]).filter((item) => !!item);

        if (array && array.length) {
          loadInitialSelected(array);
        } else {
          setSelected([]);
        }
      }
    )
  );

  const isSelected = (item: T) => selected().some((i) => i.id === item.id);

  const ended = () => {
    const c = pagination();
    return c != null && c.totalPages <= c.page;
  };

  const renderSelected = createMemo(() => {
    if (typeof props.renderSelected === 'function') return props.renderSelected;
    const key = props.renderSelected;
    return (item: T) => item[key] as string;
  });

  const errorMessage = () => {
    const err = error();
    if (err == null) return;
    if (err instanceof HttpError) {
      const msg = Object.values(err.getErrors()).flat();
      return msg.toString();
    }
    if (err instanceof Error) {
      return err.message;
    }
    return t('Operation failed, please try again later');
  };

  const handleQuery = debounce((q: string) => {
    if (
      !q ||
      props.minimumInputLength === undefined ||
      (q && props.minimumInputLength !== undefined && q.length >= props.minimumInputLength)
    ) {
      setQuery(q);
      setPagination(undefined);
      setError(undefined);
      setList([]);
    }
  }, 500);

  const handleSelect = (e: MouseEvent, item: T) => {
    if ((e.currentTarget as Element).ariaDisabled === 'true') {
      e.preventDefault();
      return;
    }
    if (props.multiple) {
      if (!props.doNotRenderSelected) {
        if (isSelected(item)) {
          setSelected((prev) => prev.filter((i) => i.id !== item.id));
        } else {
          setSelected((prev) => [...prev, item]);
        }
      }
      props.onSelect?.(selected() as U extends true ? T[] : T);
      e.preventDefault();
    } else {
      if (!props.doNotRenderSelected) {
        setSelected([item]);
      }
      props.onSelect?.(item as U extends true ? T[] : T);
    }
  };

  const handleRemove = (e: MouseEvent, item: T) => {
    e.stopImmediatePropagation();
    setSelected((prev) => prev.filter((i) => i.id !== item.id));
    props.onSelect?.(selected() as U extends true ? T[] : T);
  };

  const handleLoad = async () => {
    const page = (pagination()?.page || 0) + 1;
    try {
      const { items, totalPages } = await props.fetcher(query(), page);
      setPagination({ page, totalPages });
      setList((prev) =>
        [...prev, ...items].filter((i, index, self) => !props.exclude?.(i) && index === self.findIndex((t) => t.id === i.id))
      );
    } catch (err) {
      setError(err);
    }
  };

  const handleOpenOnly = (e: MouseEvent) => {
    const trigger = e.currentTarget as Element;
    inputRef.focus();
    if (trigger.ariaExpanded === 'true') {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  };

  return (
    <Popover
      onOpenChange={(open) => open || handleQuery('')}
      onOutsideClick={(e: MouseEvent) => (e.currentTarget as Node).contains(e.relatedTarget as Node) && e.preventDefault()}>
      <Popover.Trigger
        class="relative flex w-full flex-wrap items-center gap-2 rounded-md border bg-inputbox-bg px-2.5 py-0.5 text-sm text-title-gray outline-none placeholder:text-auxiliary-text focus-within:ring-1 focus-within:ring-primary aria-expanded:ring-1 aria-expanded:ring-primary"
        onClick={handleOpenOnly}>
        <Show when={props.prefix}>
          <span class="select-none whitespace-nowrap text-text-level03">{props.prefix}: </span>
        </Show>
        <Show when={selected().length > 0}>
          <div class="flex select-none flex-wrap gap-1 py-1">
            <For each={selected()}>
              {(item) => {
                const label = renderSelected()(item);
                return (
                  <button
                    type="button"
                    class="flex items-center gap-1 rounded-md bg-current-alpha px-1.5 text-sm text-[--c] after:text-base after:content-['×']"
                    style={{ '--c': stringToColor(typeof label === 'string' ? label : (item.id as string)) }}
                    onClick={(e) => handleRemove(e, item)}
                    title={t('Remove select')}>
                    {renderSelected()(item)}
                  </button>
                );
              }}
            </For>
          </div>
        </Show>
        <input
          ref={inputRef}
          class="flex-basis-[80px] flex-1 truncate bg-transparent px-1 py-1.5 outline-none"
          value={query()}
          onInput={(e) => handleQuery(e.currentTarget.value)}
          onKeyUp={(e) => {
            if (query() || e.key !== 'Backspace' || selected()?.length === 0) return;
            props.onSelect?.(setSelected((prev) => prev.slice(0, -1)) as U extends true ? T[] : T);
          }}
          placeholder={!props.multiple && selected().length !== 0 ? undefined : props.placeholder ?? t('Search')}
        />
        <IconSearch class="pointer-events-none absolute bottom-2.5 right-3 size-4 text-text-level03" />
      </Popover.Trigger>
      <Popover.Content
        class="thinscroll my-1  max-h-dropdown min-w-[--reference-width] space-y-0.5 overflow-auto overscroll-contain rounded-md border border-gray-300 bg-white p-2 py-3 text-sm text-text-level02 shadow-lg"
        as={InfiniteScroll}
        threshold={100}
        ended={error() != null || ended()}
        endMessage={errorMessage()}
        onReachEnd={handleLoad}>
        <For each={list()}>
          {(item) => {
            const disabledLabel = props.disabledItem?.(item);
            return (
              <Popover.Trigger
                as="li"
                class="flex cursor-pointer items-center justify-between rounded-md px-3 py-2.5 transition-colors hover:bg-light-pink aria-checked:bg-light-pink aria-disabled:cursor-not-allowed aria-disabled:opacity-50"
                onClick={(e) => handleSelect(e, item)}
                aria-checked={isSelected(item)}
                aria-disabled={!!disabledLabel}>
                {props.renderItem(item, { disabledLabel })}
                <Show when={isSelected(item)}>
                  <IconCheck class="size-5 text-primary" />
                </Show>
              </Popover.Trigger>
            );
          }}
        </For>
      </Popover.Content>
    </Popover>
  );
};
