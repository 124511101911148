import { Route, Routes, useParams } from '@solidjs/router';
import { NotesTableList } from '~/components/notes';
import { NoteModal } from '~/components/notes/NoteModal';

export const MaintenanceNotesTab = () => {
  const params = useParams<{ maintenanceRequestId: string }>();
  return (
    <>
      <Routes>
        <Route path="/add" element={<NoteModal type="maintenanceRequest" id={params.maintenanceRequestId} action="Add" />} />
        <Route path="/:noteId/edit" element={<NoteModal type="maintenanceRequest" id={params.maintenanceRequestId} action="Edit" />} />
      </Routes>
      <NotesTableList type="maintenanceRequest" id={params.maintenanceRequestId} />
    </>
  );
};
