import { useNavigate } from '@solidjs/router';
import { onMount } from 'solid-js';
import IconBacktrack from '~/assets/images/chat/backtrack.svg?component-solid';
import ImgCancelArchive from '~/assets/images/chat/cancelArchive.png';
import IconViewMessage from '~/assets/images/chat/viewMessage.svg?component-solid';
import { ListView, confirm } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChatsList } from '~/contexts/local';
import { categoryIcons, chatCategories } from '~/pages/chats/list-chats/list-components/chatUtils';
import { dateFormat } from '~/utils/date';
export const ArchiveMessagesListView = () => {
  const { t } = useLocalization();
  const { chatFiltered, setChatFilter, unarchiveChat } = useChatsList();
  const navigate = useNavigate();

  const cancelArchive = (data: MagicDoor.Api.HydratedMagicChatDto) => {
    confirm({
      title: t('Unarchive'),
      class: 'px-0 w-96',
      titleClass: 'px-5',
      footerClass: 'px-5',
      doneText: t('Confirm'),
      content: (
        <div>
          <img src={ImgCancelArchive} alt="ImgCancelArchive" />
          <div class="mt-4 px-5">
            {t('After clicking confirm, chat records will be restored. After successful restoration, it will jump to the chat window.')}
          </div>
        </div>
      ),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await unarchiveChat(data.id);
        navigate(`/communications/chats/${data.id}`);
      },
    });
  };

  onMount(() => {
    setChatFilter({
      closed: true,
    });
  });

  return (
    <div class="flex w-full gap-5 p-8 text-sm">
      <div class="flex w-full flex-col gap-5">
        <div class="relative">
          <ListView
            hideFooter
            title={t('Archive messages')}
            data={chatFiltered()}
            loading={chatFiltered.loading}
            skeletonSize={10}
            columns={[
              {
                title: t('Subject'),
                render: (row) => row.subject,
              },
              {
                title: t('type'),
                render: (row) => (
                  <div class="flex items-center gap-2">
                    <img src={categoryIcons[chatCategories[row.type]]} alt={`${chatCategories[row.type]} Icon`} />
                    <span>{chatCategories[row.type]}</span>
                  </div>
                ),
              },
              {
                title: t('Last message'),
                render: (row) => dateFormat('', row.lastMessageSentAt),
              },
            ]}
            rowLink={(item) => `/communications/archive-messages/${item.id}`}
            actions={(item) => [
              {
                label: t('View detail'),
                icon: IconViewMessage,
                onClick: () => {
                  navigate(`/communications/archive-messages/${item.id}`);
                },
              },

              {
                label: t('Unarchive'),
                icon: IconBacktrack,
                onClick: () => cancelArchive(item),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};
