import { mergeProps, splitProps, Show } from 'solid-js';
import { RingLoader } from '~/components/common/Loaders';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';
import type { ButtonProps } from '~/components/common/Buttons';

export const ViewAllButton: Component<ButtonProps> = (originProps) => {
  const props = mergeProps(
    {
      type: 'button',
      color: 'primary',
      variant: 'solid',
      rounded: 'lg',
    } as ButtonProps,
    originProps
  );
  const [, tagProps] = splitProps(props, ['type', 'href', 'form', 'variant', 'color', 'rounded', 'loading', 'class']);

  const btnClass = () =>
    cn(
      'absolute bottom-[-10px] left-1/2 -translate-y-1/2 cursor-pointer rounded-t-lg border bg-white px-2 text-xs text-essential-colour hover:bg-essential-colour/80 hover:text-white',
      props.class
    );

  const children = () => [
    <span class={cn('flex items-center justify-center gap-2', { invisible: props.loading })}>{props.children}</span>,
    <Show when={props.loading}>
      <RingLoader
        color={props.variant === 'white' ? '#A126EC' : 'white'}
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
      />
    </Show>,
  ];

  return (
    <button class={btnClass()} type={props.type} form={props.form} {...tagProps}>
      {children()}
    </button>
  );
};
