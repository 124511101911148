import { RenderingCancelledException } from 'pdfjs-dist';
import { For, createEffect, createSignal } from 'solid-js';
import { Annotation } from './Annotation';
import type { RenderTask } from 'pdfjs-dist';
import type { JSX } from 'solid-js';
import type { PresentableAnnotatedPdfPage } from '~/pdfsigner/ui/types/presentableAnnotatedPdfPage';
import type { PresentableAnnotation } from '~/pdfsigner/ui/types/presentableAnnotation';

interface PDFPageProps {
  page: PresentableAnnotatedPdfPage;
  scale: number;
  zoomFactor: number;
  onAnnotationInput?: (id: string, event: InputEvent, value: string) => void;
  onAnnotationStartEditing?: (id: string, event: MouseEvent, pageNumber: number) => void;
  onAnnotationStartResizing?: (id: string, event: MouseEvent, pageNumber: number) => void;
  onAnnotationStartDragging?: (id: string, event: MouseEvent, page: number) => void;
  onPageMouseDown?: (event: MouseEvent, pageNumber: number) => void;
  onPageRenderComplete?: (pageNumber: number) => void;
}

export const PdfPage = (props: PDFPageProps): JSX.Element => {
  let canvas: HTMLCanvasElement | undefined = undefined;
  let currentRenderTask: RenderTask | undefined = undefined;
  const [canvasRect, setCanvasRect] = createSignal<DOMRect | undefined>(undefined);

  const renderPage = async () => {
    const context = canvas?.getContext('2d');
    if (!canvas || !context) {
      return;
    }
    if (currentRenderTask) {
      currentRenderTask.cancel();
    }
    const viewport = props.page.page.getViewport({ scale: props.scale * props.zoomFactor * window.devicePixelRatio });
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    currentRenderTask = props.page.page.render({ canvasContext: context, viewport });
    try {
      await currentRenderTask.promise;
      setCanvasRect(canvas.getBoundingClientRect());
      props.onPageRenderComplete?.(props.page.pageNumber);
    } catch (error) {
      if (error instanceof RenderingCancelledException) {
        console.log('Rendering cancelled:', error);
      } else {
        console.error('Error during rendering:', error);
      }
    } finally {
      currentRenderTask = undefined;
    }
  };

  createEffect(() => {
    renderPage();
  });

  return (
    <div class="relative my-4 flex w-full flex-col overflow-hidden shadow-md first-of-type:mt-0">
      <canvas
        ref={(el) => (canvas = el)}
        id={'pdfPage' + props.page.pageNumber}
        onMouseDown={(event) => props.onPageMouseDown?.(event, props.page.pageNumber)}
      />
      <For each={props.page.annotations}>
        {(annotation: PresentableAnnotation) => (
          <Annotation
            scaleFactor={props.scale * 0.765}
            parentRect={canvasRect()}
            presentableAnnotation={annotation}
            onInput={props.onAnnotationInput}
            onStartEditting={(id: string, event: MouseEvent) => props.onAnnotationStartEditing?.(id, event, props.page.pageNumber)}
            onStartDragging={(id: string, event: MouseEvent) => props.onAnnotationStartDragging?.(id, event, props.page.pageNumber)}
            onStartResizing={(id: string, event: MouseEvent) => props.onAnnotationStartResizing?.(id, event, props.page.pageNumber)}
          />
        )}
      </For>
    </div>
  );
};
