import { createSignal, Show, For, createEffect } from 'solid-js';
import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import DepositAccountIcon from '~/assets/images/leases/transactions/depositAccount.svg';
import GroupIcon from '~/assets/images/leases/transactions/group.svg';
import ModalBgIcon from '~/assets/images/leases/transactions/modalBgIcon.svg';
import TimeIcon from '~/assets/images/leases/transactions/time.svg';
import IconDelete from '~/assets/images/transaction/delete.svg?component-solid';
import IconShowUpload from '~/assets/images/transaction/showUpload.svg?component-solid';
import IconUpload from '~/assets/images/transaction/uploadEssential.svg?component-solid';
import { RingLoader } from '~/components/common/Loaders';
import Tooltip from '~/components/common/Tooltip';
import { FilePreviewModal } from '~/components/file-attachments';
import { FileTitle } from '~/components/file-attachments/FileTitle';
import { LeaseOverviewBanner } from '~/components/leases';
import { getDescription } from '~/components/leases/utils';
import { Modal } from '~/components/modals/Modal';
import { confirm } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useAttachments } from '~/contexts/local';
import { createClipboard } from '~/utils/clipboard';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import { printDocument } from '~/utils/print';
import { upperFirst } from '~/utils/tool';
import type { Component } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';

type LeaseDepositTransactionDetailsModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
  transactionDetail: MagicDoor.Api.HydratedLeaseTransactionDto;
  showBillDate?: boolean;
  showUpload?: boolean;
  onUpload?: (transaction: MagicDoor.Api.HydratedLeaseTransactionDto) => void;
  originalTransactionId?: string;
  originalOpen?: (refundTransactionId: string | undefined) => void;
  detailLoading?: boolean;
};

export const LeaseDepositTransactionDetailsModal: Component<LeaseDepositTransactionDetailsModalProps> = (props) => {
  const { t } = useLocalization();
  const { getAttachments, attachments, deleteAttachment } = useAttachments();

  const [preview, setPreview] = createSignal<MagicDoor.Api.HydratedFileAttachmentDto>();

  const internalIdClipboard = createClipboard({
    tooltipMessages: {
      default: t('Click to copy'),
      copied: t('Copied'),
    },
  });

  const externalIdClipboard = createClipboard({
    tooltipMessages: {
      default: t('Click to copy'),
      copied: t('Copied'),
    },
  });

  const entryType = () => {
    if (!props.transactionDetail) return undefined;
    return props.transactionDetail.bill ? 'bill' : 'transaction';
  };

  const entryId = () => {
    if (!props.transactionDetail) return undefined;
    return props.transactionDetail.bill ? props.transactionDetail.bill.id : props.transactionDetail.transaction?.id;
  };

  const printContent = () => {
    return (
      <div class="flex items-center">
        <PrintIcon />
        {t('Print')}
      </div>
    );
  };

  let printContainer!: HTMLDivElement;

  const print = () => {
    printDocument(printContainer);
  };

  const handleShowPreview = (file: MagicDoor.Api.HydratedFileAttachmentDto) => {
    setPreview(file);
  };

  const handleDeleteFile = (e: any, file: MagicDoor.Api.HydratedFileAttachmentDto) => {
    e.stopPropagation && e.stopPropagation();
    confirm({
      title: t('Delete file'),
      content: t('Are you sure you want to delete this file?'),
      async onResolve(confirmed) {
        const type = entryType();
        const id = entryId();
        if (!confirmed || !type || !id) return;
        await deleteAttachment(type, id, file.id);
      },
    });
  };

  const handleOrignal = (refundTransactionId: string | undefined | null) => {
    refundTransactionId && props.originalOpen && props.originalOpen(refundTransactionId);
  };

  createEffect(() => {
    const type = entryType();
    const id = entryId();
    if (props.showUpload && type && id) {
      getAttachments(type, id);
    }
  });

  return (
    <Modal
      {...props}
      confirmation={false}
      title={
        !props.detailLoading && props.transactionDetail
          ? props.transactionDetail?.type === 'charge'
            ? t('Charge details')
            : t('Transaction details')
          : t('Loading')
      }
      doneText={printContent()}
      onDone={print}
      showFooter={!props.detailLoading}>
      <Show
        when={!props.detailLoading && props.transactionDetail}
        fallback={
          <div class="flex h-60 items-center justify-center">
            <RingLoader color="#a126ec" size={50} borderWidth={5} />
          </div>
        }>
        <div ref={printContainer}>
          <div class="px-8 py-6">
            <LeaseOverviewBanner lease={props.currentLease} />
            <Show when={props.transactionDetail.bill?.companyCharge}>
              <div class="mt-6 w-fit rounded-full bg-link/80 px-2 py-0.5 text-xs text-white">{t('Company bill')}</div>
            </Show>
            <div class="grid grid-cols-2 gap-x-7 gap-y-6 py-6">
              <div>
                <div class="text-xs text-text-level02">{t('Received from')}</div>
                <div class="mt-1.5 flex items-center">
                  <img class="size-8" src={GroupIcon} alt="ReceivedFromIcon" />
                  <span class="ml-2 text-sm text-text-level01">
                    {props.transactionDetail?.transaction?.tenant
                      ? `${props.transactionDetail?.transaction?.tenant?.firstName} ${props.transactionDetail?.transaction?.tenant?.lastName}`
                      : emptyPlaceholder}
                  </span>
                </div>
              </div>
              <div>
                <div class="text-xs text-text-level02">{t('Deposit account')}</div>
                <div class="mt-1.5 flex items-center">
                  <img class="size-8" src={DepositAccountIcon} alt="DepositAccountIcon" />
                  <span class="ml-2 text-sm text-text-level01">
                    {props.transactionDetail?.transaction?.bankAccount.name ||
                      props.transactionDetail?.bill?.lineItems[0].chartOfAccount.name}
                  </span>
                </div>
              </div>

              <div>
                <div class="text-xs text-text-level02">{t('Transaction date')}</div>
                <div class="mt-1.5 flex items-center">
                  <div
                    class="flex size-8 items-center justify-center rounded-lg"
                    style={{
                      background: '#F1F2FF',
                    }}>
                    <img class="size-[18px]" src={TimeIcon} alt="TransactionTimeIcon" />
                  </div>
                  <Show when={props.transactionDetail.transaction}>
                    <span class="ml-2 text-sm text-text-level01">
                      {dateFormat('MM/DD/YYYY', props.transactionDetail.transaction?.transactionDate)}
                    </span>
                  </Show>
                  <Show when={props.transactionDetail.bill}>
                    <span class="ml-2 text-sm text-text-level01">{dateFormat('MM/DD/YYYY', props.transactionDetail.bill?.billDate)}</span>
                  </Show>
                </div>
              </div>

              <div>
                <div class="text-xs text-text-level02">
                  {props.transactionDetail.type === 'charge' ? t('Bill date') : t('Transaction finalized date')}
                </div>
                <div class="mt-1.5 flex items-center">
                  <div
                    class="flex size-8 items-center justify-center rounded-lg"
                    style={{
                      background: '#F1F2FF',
                    }}>
                    <img class="size-[18px]" src={TimeIcon} alt="TransactionTimeIcon" />
                  </div>
                  <Show when={props.transactionDetail.transaction}>
                    <span class="ml-2 text-sm text-text-level01">
                      {props.transactionDetail.transaction?.transactionFinalizedDate
                        ? dateFormat('MM/DD/YYYY', props.transactionDetail.transaction?.transactionFinalizedDate)
                        : emptyPlaceholder}
                    </span>
                  </Show>
                  <Show when={props.transactionDetail.bill}>
                    <span class="ml-2 text-sm text-text-level01">
                      {props.transactionDetail.bill?.dueDate
                        ? dateFormat('MM/DD/YYYY', props.transactionDetail.bill?.dueDate)
                        : dateFormat('MM/DD/YYYY', props.transactionDetail.bill?.dueDate)}
                    </span>
                  </Show>
                </div>
              </div>

              <Show when={props.transactionDetail.description}>
                <div class="col-span-full">
                  <div class="text-xs text-text-level02">{t('Memo')}</div>
                  <div class="mt-1.5 flex items-center rounded-lg bg-inputbox-bg px-4 py-2.5 normal-case">
                    <div class="truncate">{getDescription(props.transactionDetail.description)}</div>
                  </div>
                </div>
              </Show>

              <Show when={props.transactionDetail?.id}>
                <div>
                  <div class="text-xs text-text-level02">{t('Internal ID')}</div>
                  <div class="mt-1.5 flex items-center text-sm">
                    <Tooltip message={internalIdClipboard.tooltipMessage()} class="cursor-pointer" align="top">
                      <div onClick={() => internalIdClipboard.copy(props.transactionDetail?.id || '')}>#{props.transactionDetail?.id}</div>
                    </Tooltip>
                  </div>
                </div>
              </Show>

              <div>
                <div class="text-xs text-text-level02">
                  {props.transactionDetail.transaction?.direction ? t('Transaction direction') : t('Charge direction')}
                </div>
                <div class="mt-1.5 flex items-center text-sm capitalize">
                  {props.transactionDetail.transaction?.direction
                    ? props.transactionDetail.transaction?.direction
                    : props.transactionDetail.bill?.direction}
                </div>
              </div>

              <Show when={props.transactionDetail?.transaction?.externalTransactionId}>
                <div>
                  <div class="text-xs text-text-level02">{t('External ID')}</div>
                  <div class="mt-1.5 flex items-center text-sm">
                    <Tooltip message={externalIdClipboard.tooltipMessage()} class="cursor-pointer" align="top">
                      <div onClick={() => externalIdClipboard.copy(props.transactionDetail?.transaction?.externalTransactionId || '')}>
                        #{props.transactionDetail?.transaction?.externalTransactionId}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </Show>

              <Show when={props.transactionDetail?.transaction?.externalTransactionType}>
                <div>
                  <div class="text-xs text-text-level02">{t('External transaction type')}</div>
                  <div class="mt-1.5 flex items-center text-sm capitalize">
                    {props.transactionDetail?.transaction?.externalTransactionType}
                  </div>
                </div>
              </Show>

              <Show when={props.transactionDetail?.transaction?.transactionPaymentMethod}>
                <div>
                  <div class="text-xs text-text-level02">{t('Transaction payment method')}</div>
                  <div class="mt-1.5 flex items-center text-sm capitalize">
                    {props.transactionDetail?.transaction?.transactionPaymentMethod}
                  </div>
                </div>
              </Show>

              <Show when={props.transactionDetail.transaction?.status}>
                <div class="col-start-1">
                  <div class="text-xs text-text-level02">{t('Transaction status')}</div>
                  <div class="mt-1.5 flex items-center text-sm capitalize">
                    {t(upperFirst(props.transactionDetail?.transaction?.status as string))}
                  </div>
                </div>
              </Show>
              <Show when={props.transactionDetail.transaction?.refundTransactionId}>
                <div onClick={() => handleOrignal(props.transactionDetail.transaction?.refundTransactionId)}>
                  <div class="text-xs text-text-level02">{t('Return transaction ID')}</div>
                  <Tooltip message={t('Open the original transaction details modal')} class="cursor-pointer" align="top">
                    <div class="mt-1.5 flex items-center text-sm text-link hover:underline">
                      #23232323{props.transactionDetail?.transaction?.refundTransactionId}
                    </div>
                  </Tooltip>
                </div>
              </Show>
              <Show when={props.originalTransactionId}>
                <div>
                  <div class="text-xs text-text-level02">{t('Original transaction ID')}</div>
                  <div class="mt-1.5 flex items-center text-sm">#{props.originalTransactionId}</div>
                </div>
              </Show>

              <Show when={props.transactionDetail.transaction?.failureReason}>
                <div>
                  <div class="text-xs text-text-level02">{t('Transaction failure reason')}</div>
                  <div class="mt-1.5 flex items-center text-sm capitalize">{props.transactionDetail?.transaction?.failureReason}</div>
                </div>
              </Show>

              <Show when={props.showUpload}>
                <div class="col-span-full">
                  <div class="col-span-full">
                    <div
                      onClick={() => props.onUpload && props.onUpload(props.transactionDetail)}
                      class="flex h-10 w-full cursor-pointer items-center justify-center gap-2 rounded-lg bg-light-gray text-center text-essential-colour">
                      <IconUpload class="text-essential-colour" />
                      {t('Upload a file')}
                    </div>
                  </div>
                  <Show when={attachments()?.length}>
                    <For each={attachments()}>
                      {(item) => {
                        return (
                          <div
                            onClick={() => handleShowPreview(item)}
                            class="group col-span-full !mt-[6px] flex cursor-pointer items-center justify-between  rounded-lg border border-input-border p-1 hover:border-essential-colour hover:bg-light-pink">
                            <FileTitle file={item} />
                            <div class="mr-[6px] hidden items-center gap-2 group-hover:flex">
                              <div onClick={(e) => handleDeleteFile(e, item)} class="cursor-pointer rounded-lg  p-[6px] hover:bg-[#F6E9FD]">
                                <IconDelete />
                              </div>
                              <div class="cursor-pointer rounded-lg  p-[6px] hover:bg-[#F6E9FD]">
                                <IconShowUpload />
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </For>
                  </Show>
                </div>
              </Show>
            </div>
          </div>
          <div class="flex w-full items-center justify-between bg-pink-100" style={{ height: '76px' }}>
            <img src={ModalBgIcon} class="mb-2 ml-7 h-20 w-52" />
            <div class="mr-12 flex items-center">
              <span class="mr-2 uppercase">{t('Total amount')}:</span>
              <span class="text-2xl font-bold text-essential-colour">{currency(props.transactionDetail?.amount)}</span>
            </div>
          </div>
        </div>

        <Show when={props.showUpload}>
          <FilePreviewModal file={preview()} onClose={setPreview} />
        </Show>
      </Show>
    </Modal>
  );
};
