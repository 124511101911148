import { useParams } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { useBankAccount } from '~/contexts/local';
import BankAccountDetailsView from './view-components/BankAccountDetailsView';

const BankAccountDetails = () => {
  const { bankAccountId } = useParams();
  const { setBankAccountId, error, fetchBankAccountById } = useBankAccount();

  createEffect(() => {
    if (bankAccountId) {
      setBankAccountId(bankAccountId);
      fetchBankAccountById(bankAccountId);
    }
  });

  createEffect(() => {
    if (error() instanceof Error) {
      throw error();
    }
  });

  return (
    <>
      <BankAccountDetailsView />
    </>
  );
};

export default BankAccountDetails;
