import { UseCase } from './useCase';
import type { AnnotationType } from './types/annotation';

export class SetAnnotationTypeUseCase extends UseCase {
  public async runLogic(type: AnnotationType) {
    if (type === undefined || !this.isPdfLoaded()) {
      return;
    }
    this.state.selectedAnnotation = undefined;
    if (this.state.selectedAnnotationType === type) {
      this.state.selectedAnnotationType = undefined;
      return;
    }
    this.state.selectedAnnotationType = type;
  }
}
