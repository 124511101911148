import { RentalApplicationSettingsProvider } from '~/contexts/local';
import { GeneralSettings } from './GeneralSettings';

export const GeneralSettingsWrap = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <RentalApplicationSettingsProvider>
        <GeneralSettings />
      </RentalApplicationSettingsProvider>
    </div>
  );
};
