import { BaseRestRepository } from '~/repositories/baseRestRepository';

const CHART_OF_ACCOUNT_SETTINGS_URL = '/api/settings/lease-chart-of-accounts';

export class chartOfAccountSettingRepository extends BaseRestRepository {
  public async addChartOfAccountSettings(payload: MagicDoor.Api.LeaseChartOfAccountSettingsDto) {
    const url = CHART_OF_ACCOUNT_SETTINGS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async addChartOfAccountSettingsByLevel(level: string, levelId: string, payload: MagicDoor.Api.LeaseChartOfAccountSettingsDto) {
    const url = `${CHART_OF_ACCOUNT_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getChartOfAccountSettings(): Promise<MagicDoor.Api.LeaseChartOfAccountSettingsDto> {
    const url = CHART_OF_ACCOUNT_SETTINGS_URL;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getChartOfAccountSettingsByLevel(level: string, levelId: string) {
    const url = `${CHART_OF_ACCOUNT_SETTINGS_URL}/${level}/${levelId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getAllChartOfAccountSettings(): Promise<MagicDoor.Api.LeaseChartOfAccountSettingsDto> {
    const url = `${CHART_OF_ACCOUNT_SETTINGS_URL}/all`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}
