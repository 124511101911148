export enum AnnotationType {
  TEXT = 'text',
  INITIALS = 'initials',
  SIGNATURE = 'signature',
  DATE = 'dateTime',
  AUTOFILL = 'autofill',
  CHECKMARK = 'flag',
}

export interface Annotation {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  type: AnnotationType;
  text: string;
  signerId: string | undefined;
  dataPathKey?: string;
  fontSize: number;
}
