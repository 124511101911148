import { createMagicDoorContext } from '~/contexts/utils';
import { CompaniesRepository } from '~/repositories/companiesRepository';
import { createLazyResource, createMutation } from '~/utils/resource';

const repo = new CompaniesRepository();

export const [CompaniesProvider, useCompanies] = createMagicDoorContext('Companies', () => {
  const [companies, { fetch, refetch, mutate }] = createLazyResource(() => repo.getCompanies());
  const [portalSettings, { fetch: psFetch, refetch: psRefetch, mutate: psMutate }] = createLazyResource(() => repo.getPortalSettings());

  const updateSubUrl = createMutation(async (model: MagicDoor.Api.CompanySubUrlDto) => {
    const updated = await repo.updateSubUrl(model);
    mutate(updated);
    return updated;
  });

  const validateSubUrl = createMutation(async (model: MagicDoor.Api.CompanySubUrlDto) => {
    return await repo.validateSubUrl(model);
  });

  const updateCompanyProfile = createMutation(async (model: MagicDoor.Api.UpdateCompanyDto) => {
    const updated = await repo.updateCompanyProfile(model);
    refetch();
    return updated;
  });

  const uploadFiles = createMutation(async (file: File | Blob, name: string, process: boolean = true) => {
    return await repo.uploadFiles(file, name, process);
  });

  const updatePortalSettings = createMutation(async (model: MagicDoor.Api.UpdatePortalSettingsDto) => {
    const updated = await repo.updatePortalSettings(model);
    // psRefetch();
    psMutate(updated);
  });

  return {
    get companies() {
      fetch();
      return companies;
    },
    refetch,
    updateSubUrl,
    validateSubUrl,
    updateCompanyProfile,
    get portalSettings() {
      psFetch();
      return portalSettings;
    },
    uploadFiles,
    updatePortalSettings,
  };
});
