import { Show, For, createMemo } from 'solid-js';
import { useSearchSelectContext } from '~/components/search-select/Context';
import { ExpandIcon } from '~/components/search-select/LevelSearchComponents/ExpandIcon';
import { SelectArea } from '~/components/search-select/LevelSearchComponents/SelectArea';
import { searchLevels } from './SearchLevels';

type SearchItemProps = {
  item: any;
  level?: number;
  parents?: Record<string, any>;
};

export const SearchItem = (props: SearchItemProps) => {
  const { isSelected, handleSelect, isExpanded, onRowClick, isLoading, isDisabled } = useSearchSelectContext();

  const currentLevel = createMemo(() => props.level || 0);

  const config = createMemo(() => searchLevels[currentLevel()]);
  const item = createMemo(() => props.item[config().type]);

  const childrens = createMemo(() => props.item[config().childItems] ?? []);

  const shouldShowItem = createMemo(() => {
    return childrens().length > 0 || item().__lazy;
  });

  const shouldShowChildren = createMemo(() => {
    return childrens().length > 0 && isExpanded(item());
  });

  return (
    <>
      <div
        class="group flex cursor-pointer select-none items-center justify-between rounded-md px-3 py-2.5 transition-colors hover:bg-light-pink aria-checked:bg-light-pink aria-disabled:opacity-50"
        style={{ 'padding-left': `${(currentLevel() + 1) * 16}px` }}
        aria-checked={isSelected(item()) || undefined}
        aria-disabled={isDisabled(config().type) || undefined}
        aria-loading={isLoading(item()) || undefined}
        aria-expanded={isExpanded(item()) || undefined}
        onClick={(e) => {
          e.stopPropagation();
          onRowClick(e, item());
        }}>
        <div class="flex flex-1 items-center gap-2">
          <Show when={shouldShowItem()} fallback={<div class="size-6" />}>
            <ExpandIcon />
          </Show>
          <div class="cursor-pointer">{config()?.renderMainSlot?.(item())}</div>
        </div>
        <SelectArea
          item={item}
          type={config().type}
          onClick={(e) => {
            e.stopPropagation();

            if (isDisabled(config().type)) {
              return;
            }

            handleSelect({
              __type: config().type,
              ...props.parents,
              ...item(),
            });
          }}
        />
      </div>
      <Show when={shouldShowChildren()}>
        <For each={childrens()}>
          {(child) => (
            <SearchItem
              item={child}
              level={currentLevel() + 1}
              parents={{
                ...props.parents,
                [config().type]: item(),
              }}
            />
          )}
        </For>
      </Show>
    </>
  );
};
