import { useNavigate } from '@solidjs/router';
import { createEffect, createSignal, Show } from 'solid-js';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { PropertyTitle, PropertyType } from '~/components/properties';
import { IconCirclePlus, ListView, LatestUpdatedAt, TableActions } from '~/components/ui';
import { IconMove } from '~/components/ui/Icons';
import { useLocalization, usePortfolios, useProperties } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import { PortfolioSummary } from './components/PortfolioSummary';
import type { Component } from 'solid-js';

type FilterValue = {
  types?: MagicDoor.Api.PropertyType[];
  balance?: 'outstanding';
};

export const PortfolioOverview: Component = () => {
  const { t } = useLocalization();
  const { current, portfolioOverview, getPortfolioOverview } = usePortfolios();
  const [filterValue, setFilterValue] = createSignal<FilterValue>({});
  const { setPage, setPageSize } = useLocalPagination('PortfolioOverviewListView');
  const { filtered, setFilter, loading, setCurrentId } = useProperties();
  const navigate = useNavigate();

  createEffect(() => {
    const portfolioId = current()?.id;
    if (portfolioId == null) return;
    setFilter({ portfolioId });
    getPortfolioOverview(portfolioId);
  });

  return (
    <>
      <LatestUpdatedAt time={portfolioOverview()?.cacheDate ?? ''} />
      <PortfolioSummary loading={getPortfolioOverview.loading} portfolioOverview={portfolioOverview()} />
      <ListView
        title={t('Properties')}
        titleActions={
          <LinkButton href={current()?.id ? `/portfolios/${current()?.id}/add` : '#'} class="px-2.5 font-medium" noScroll>
            <IconCirclePlus class="size-5 text-white" />
            <div class="hidden md:flex">{t('Add a property')}</div>
          </LinkButton>
        }
        totalPages={filtered()?.totalPages}
        page={filtered()?.currentPage}
        pageSize={filtered()?.pageSize}
        data={filtered()?.items}
        loading={filtered()?.items == null || loading()}
        skeletonSize={10}
        searchPlaceholder={t('Search properties')}
        filterValue={filterValue()}
        onChange={({ page, pageSize, search }) => {
          setPage(page);
          setPageSize(pageSize);
          setFilter({ page, pageSize, search, portfolioId: current()?.id });
        }}
        onFilterReset={() => setFilterValue({})}
        columns={[
          {
            title: t('Property'),
            render: (item) => <PropertyTitle class="max-w-[40vw]" property={item as MagicDoor.Api.HydratedPropertyDto} />,
          },
          {
            title: t('Type'),
            headerClass: 'w-32',
            render: (item) => <PropertyType type={item.type} />,
          },
          {
            title: t('Empty units'),
            headerClass: 'w-32',
            class: 'text-success',
            render: (item) => item.unitCount - item.occupiedUnits,
          },
          {
            key: 'activeUnits',
            title: t('Active units'),
            headerClass: 'w-32',
          },
          {
            title: t('Rental application'),
            headerClass: 'w-44',
            render: (item) => (
              <div class="group flex gap-2 px-2 py-3">
                {item.recentRentalApplications}
                {/* TODO: need API to get recent rental applications */}
                {/* <span class="rounded-full bg-success px-2 text-white opacity-0 transition-opacity group-hover:opacity-100">{t('New')}</span> */}
              </div>
            ),
          },
          {
            title: t('Outstanding balances'),
            headerClass: 'text-right w-44',
            class: 'text-right',
            render: (item) => <CnMoney money={item.currentBalance} showIcon />,
          },
          {
            title: t('Posted balances'),
            headerClass: 'text-right w-44',
            class: 'text-right',
            render: (item) => <CnMoney money={item.postedBalance} showIcon />,
          },
          {
            title: t('Operation'),
            render: (row) => (
              <TableActions
                actions={[
                  {
                    label: t('Edit'),
                    icon: IconPencil,
                    href: `/portfolios/${current()?.id}/properties/${row.id}/edit`,
                  },
                  {
                    label: t('Move portfolio'),
                    icon: IconMove,
                    onClick() {
                      setCurrentId(row.id);
                      navigate(`/portfolios/${current()?.id}/move`);
                    },
                  },
                ]}
              />
            ),
          },
        ]}
        rowLink={(item) => `/portfolios/${item.portfolioId}/properties/${item.id}`}
        footerSummary={
          <Show when={filtered()?.totalPages && (filtered()?.totalPages as number) > 1}>
            <div class="absolute text-text-level03" style={{ 'margin-left': '196px' }}>
              {t('In total')}: {filtered()?.totalCount}
            </div>{' '}
          </Show>
        }
      />
    </>
  );
};
