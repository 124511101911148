import { Show, createSignal, createMemo } from 'solid-js';
import IconDescription from '~/assets/images/settings/portal/portalDescription.svg?component-solid';
import IconSeo from '~/assets/images/settings/portal/portalSeo.svg';
import IconTitle from '~/assets/images/settings/portal/portalSeoTitle.svg?component-solid';
import IconTitleTemplate from '~/assets/images/settings/portal/portalTitleTemplate.svg?component-solid';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { IconPencilLine } from '~/components/ui/Icons';
import { useLocalization, useCompanies } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { InformationForm } from '~/pages/settings/components/InformationForm';
import { InformationOverview } from '~/pages/settings/components/InformationOverview';
import type { Section } from '~/pages/settings/account-settings/types';

type MetadataProps = {
  title?: string;
  titleTemplate?: string;
  description?: string;
  keywords?: string[] | string;
  themeColor?: string;
};
export const PortalSeoInformation = () => {
  const { t } = useLocalization();
  const [editing, setEditing] = createSignal<boolean>(false);
  const { portalSettings, updatePortalSettings } = useCompanies();
  const sections = createMemo<Section[]>(() => {
    const model = portalSettings()?.metadata;
    if (model == null) return [];
    return [
      {
        field: 'keywords',
        value: model.keywords,
        label: 'Keywords',
        icon: IconPencilLine,
      },
      {
        field: 'title',
        value: model.title,
        label: 'Title',
        icon: IconTitle,
      },
      {
        field: 'titleTemplate',
        value: model.titleTemplate,
        label: 'Title template',
        icon: IconTitleTemplate,
        placeholder: t('The %s is a placeholder and will be replaced with the page title'),
      },
      {
        field: 'description',
        value: model.description,
        label: 'Description',
        icon: IconDescription,
      },
    ];
  });
  const handleSubmit = async (info: MetadataProps) => {
    const params = { ...portalSettings(), metadata: { ...info, themeColor: portalSettings()?.metadata?.themeColor } };
    try {
      const keywords = Array.isArray(info?.keywords) ? info?.keywords : info?.keywords?.split(',');
      params.metadata.keywords = keywords;
      await updatePortalSettings(params);
    } catch (err) {
      console.log(err);
    } finally {
      setEditing(false);
    }
  };
  return (
    <ThreeSectionBox
      class="border-0 capitalize"
      topTitle={<SettingTitle title={t('Seo information')} icon={IconSeo} />}
      middle={
        <div class="p-6">
          <Show when={editing()} fallback={<InformationOverview sections={sections()} onEdit={() => setEditing(true)} />}>
            <InformationForm sections={sections()} onClose={() => setEditing(false)} onSubmit={handleSubmit} />
          </Show>
        </div>
      }
    />
  );
};
