import { Show, createMemo, onMount } from 'solid-js';
import IconCompanyTimezone from '~/assets/images/settings/company/companyTimezone.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { toast, IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useCompanySettings, useBankAccountsList } from '~/contexts/local';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import type { ItemProps } from '~/pages/settings/components/edit-form/EditForm';

export const CompanyInformationSettings = () => {
  const { t } = useLocalization();

  const { companySettings, getCompanySettings, changeCompanySettings, isLoading } = useCompanySettings();

  const { bankAccounts } = useBankAccountsList();
  const sections = createMemo<ItemProps[]>(() => [
    {
      field: 'companyBankAccountId',
      label: 'Company bank account',
      type: 'select',
      options: bankAccounts().map((account) => ({ label: account.name, value: account.id })),
      labelContainerClass: 'flex-row justify-between gap-5 flex-wrap',
      labelClass: 'w-1/2',
      class: 'col-span-2',
      rules: [],
      description: 'Is the ID of the bank account that payment to the property management company is moved into.',
    },
    {
      field: 'desiredLanguage',
      label: 'Desired language',
      type: 'languageSelect',
      onChangeMethodName: 'onChange',
    },
    {
      field: 'subscriptionChartOfAccountId',
      label: 'Subscription chart of account',
      type: 'chartOfAccountSelect',
    },
  ]);

  const onSave = async (data: MagicDoor.Api.CompanySettingsDto) => {
    await changeCompanySettings(data);
    toast(t('Company information settings saved successfully'), 'success');
    getCompanySettings();
    return;
  };

  onMount(() => getCompanySettings());

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topTitle={<SettingTitle title={t('Company information')} icon={IconCompanyTimezone} />}
        middle={
          <div class="flex flex-col gap-5 p-4">
            <Show when={!isLoading()} fallback={<IconLoader class="mx-auto my-20 animate-spin" />}>
              <div class="flex flex-col gap-4 p-2 text-left">
                <EditForm
                  sections={sections()}
                  onOk={(data) => onSave(data)}
                  model={companySettings() || ({} as MagicDoor.Api.CompanySettingsDto)}
                />
              </div>
            </Show>
          </div>
        }
      />
    </>
  );
};
