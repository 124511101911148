import { Show, createEffect } from 'solid-js';
import { useAuth, useLocalization } from '~/contexts/global';
import { NotFoundError, UnauthorizedError } from '~/errors';
import { sentryCapture } from '~/utils/sentry';

interface ErrorFallbackProps {
  error: Error | undefined;
  reset: () => void;
}

const ErrorFallback = (props: ErrorFallbackProps) => {
  const { t } = useLocalization();
  const { redirectToAuth } = useAuth();

  createEffect(() => {
    console.log('Got the error:', props.error);

    if (props.error instanceof UnauthorizedError) {
      redirectToAuth();
    }

    sentryCapture(props.error);
  });

  return (
    <div class="flex min-h-dvh flex-col items-center justify-center  text-3xl text-gray-700">
      <h1>{t('Error page')}</h1>
      <Show
        when={props.error instanceof NotFoundError}
        fallback={
          <>
            <div>{t('An error occurred')}</div>
            <div>{props.error?.message}</div>
          </>
        }>
        <h1>{t('Not found')}</h1>
      </Show>
    </div>
  );
};

export default ErrorFallback;
