import { leaseDraftRepository } from '~/pdfsigner/repositories/leaseDraftRepository';
import { LoadLeaseDraftUseCase } from './loadLeaseDraftUseCase';
import { UseCase } from './useCase';
import { ValidateDocumentUseCase } from './validateDocumentUSeCase';

export class SendDraftForSigningUseCase extends UseCase {
  public async runLogic() {
    new ValidateDocumentUseCase().execute();
    if (this.state.selectedDocument && this.state.selectedDocument.id) {
      const status = await leaseDraftRepository.sendForSigning(this.state.selectedDocument.id);
      this.state.selectedDocument.status = status;
      await new LoadLeaseDraftUseCase().execute(this.state.selectedDocument.id);
    }
  }
}
