import { FormItem, FormWrapper } from '~/components/common/BetterForm/Form';
import { Button } from '~/components/common/Buttons';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import { BankAccountType } from '~/swagger/Api';
import { bankAccountName, bankAccountNumber, bankAccountRoutingNumber, bankAccountType } from '~/utils/formRules';
import type { BetterForm } from '~/components/common/BetterForm';

interface BankAccountFormProps {
  onSubmit: () => Promise<void> | void;
  title?: string;
  form: BetterForm.form;
}

function BankAccountForm(props: BankAccountFormProps) {
  const { t } = useLocalization();

  return (
    <FormWrapper
      defaultForm={props.form}
      class="flex  size-full flex-col items-center justify-center"
      onFormSubmit={() => {
        props.onSubmit();
      }}>
      <div class="flex w-full justify-center gap-4 py-4">
        <div class="flex w-2/3 flex-col gap-6">
          <FormItem
            formFieldName="name"
            label={t('Name')}
            placeholder={t('Please enter the name')}
            component={LabeledTextInput}
            rules={[
              {
                required: true,
                message: t('Please enter the name'),
              },
              ...bankAccountName(t),
            ]}
          />
          <FormItem
            formFieldName="description"
            placeholder={t('Please enter')}
            label={t('Description')}
            rows={5}
            component={LabeledTextArea}
          />
          <FormItem
            formFieldName="accountNumber"
            placeholder={t('Please enter')}
            label={t('Account number')}
            rules={bankAccountNumber(t, true)}
            component={LabeledTextInput}
          />
          <FormItem
            formFieldName="routingNumber"
            placeholder={t('Please enter')}
            label={t('Routing number')}
            component={LabeledTextInput}
            rules={bankAccountRoutingNumber(t, true)}
          />
          <FormItem
            formFieldName="type"
            placeholder={t('Please enter')}
            label={t('Type')}
            component={DropdownMenu}
            rules={bankAccountType(t, true)}
            options={Object.entries(BankAccountType).map(([key, value]) => ({ label: t(key), value }))}
            onChangeMethodName="onChange"
          />
        </div>
      </div>
      <div class="sticky bottom-0 mt-auto flex w-full justify-end gap-2 border-t border-partingline bg-white px-6 py-5">
        <Button disabled={!props.form.isValidate} type="submit">
          {t('Submit')}
        </Button>
      </div>
    </FormWrapper>
  );
}

export default BankAccountForm;
