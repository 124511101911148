import { For, createEffect, createSignal } from 'solid-js';
import { ChartOfAccountSelect } from '~/components/chart-of-accounts';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import { LeaseSearch } from '~/components/leases';
import { MaintenanceRequestSearch } from '~/components/maintenance-requests';
import { PortfolioSearch } from '~/components/portfolios';
import { PropertySearch, PropertyTitle } from '~/components/properties';
import { TenantSearch } from '~/components/tenants';
import { LabeledTenantSearch } from '~/components/tenants/TenantSearch';
import { Image, InfiniteScroll, SearchSelect, Tabs } from '~/components/ui';
import { UnitSearch } from '~/components/units';
import { VendorSearch } from '~/components/vendors';
import { useChartOfAccounts } from '~/contexts/global';
import { PropertiesRepository } from '~/repositories/propertiesRepository';
import { pagianate } from '~/utils/resource';
import { stringToColor } from '~/utils/strings';

const InfiniteScrollDemo = () => {
  const tenants = Array.from({ length: 1000 }).flatMap((_, i) => [
    { id: Math.random().toString(36).slice(2), name: 'Wade Cooper ' + i, email: 'wade.cooper@zce.me' },
    { id: Math.random().toString(36).slice(2), name: 'Arlene Mccoy ' + i, email: 'arlene.mccoy@zce.me' },
    { id: Math.random().toString(36).slice(2), name: 'Devon Webb ' + i, email: 'devon.webb@zce.me' },
    { id: Math.random().toString(36).slice(2), name: 'Tom Cook ' + i, email: 'tom.cook@zce.me' },
    { id: Math.random().toString(36).slice(2), name: 'Tanya Fox ' + i, email: 'tanya.fox@zce.me' },
    { id: Math.random().toString(36).slice(2), name: 'Hellen Schmidt ' + i, email: 'hellen.schmidt@zce.me' },
    { id: Math.random().toString(36).slice(2), name: 'Caroline Fox ' + i, email: 'caroline.fox@zce.me' },
    { id: Math.random().toString(36).slice(2), name: 'Mason Heaney ' + i, email: 'mason.heaney@zce.me' },
    { id: Math.random().toString(36).slice(2), name: 'Claudie Smitham ' + i, email: 'claude.smitham@zce.me' },
    { id: Math.random().toString(36).slice(2), name: 'Emil Schaefer ' + i, email: 'emil.schaefer@zce.me' },
  ]);

  const getPaginationTenants = async (page: number, pageSize: number) => {
    await new Promise((r) => setTimeout(r, Math.random() * 3000));
    return pagianate(tenants, page, pageSize);
  };

  const [list, setList] = createSignal<typeof tenants>([]);
  const [cur, setCur] = createSignal<{ page: number; totalPages: number }>();

  const ended = () => {
    const c = cur();
    return c != null && c.totalPages <= c.page;
  };

  const handleLoad = async () => {
    const page = (cur()?.page || 0) + 1;
    const { items, totalPages } = await getPaginationTenants(page, 50);
    setCur({ page, totalPages });
    setList((prev) => [...prev, ...items]);
  };

  return (
    <div>
      <h2 class="mb-2 text-center text-xl font-medium text-title-gray">Infinite scroll (basic)</h2>
      <InfiniteScroll
        class="thinscroll h-48 overflow-auto rounded-lg border p-2 text-sm"
        threshold={100}
        ended={ended()}
        onReachEnd={handleLoad}>
        <For each={list()}>
          {(tenant) => (
            <li class="flex cursor-pointer items-center gap-1 rounded-md px-3 py-2.5 text-title-gray transition-colors hover:bg-light-pink">
              {tenant.name}
            </li>
          )}
        </For>
      </InfiniteScroll>
    </div>
  );
};

const SearchSelectDemo = () => {
  const propertyRepo = new PropertiesRepository();
  const fetchProperties = async (query: string | undefined, page: number) => {
    return propertyRepo.getProperties({ page, pageSize: 20, nameLike: query || undefined });
  };
  return (
    <>
      <div>
        <h2 class="mb-2 text-center text-xl font-medium text-title-gray">Search select (common)</h2>
        <SearchSelect
          fetcher={fetchProperties}
          renderSelected={(item) => (
            <span class="rounded bg-current-alpha px-1.5 py-0.5 text-[--c]" style={{ '--c': stringToColor(item.name || '') }}>
              {item.name}
            </span>
          )}
          renderItem={(item) => <PropertyTitle feedbackIconReadonly property={item} />}
          onSelect={console.log}
          multiple
        />
      </div>
      <div>
        <h2 class="mb-2 text-center text-xl font-medium text-title-gray">Portfolio search</h2>
        <PortfolioSearch onSelect={console.log} multiple />
      </div>
      <div>
        <h2 class="mb-2 text-center text-xl font-medium text-title-gray">Property search</h2>
        <PropertySearch onSelect={console.log} multiple />
      </div>
      <div>
        <h2 class="mb-2 text-center text-xl font-medium text-title-gray">Unit search</h2>
        <UnitSearch onSelect={console.log} multiple />
      </div>
      <div>
        <h2 class="mb-2 text-center text-xl font-medium text-title-gray">Tenant search</h2>
        <TenantSearch onSelect={console.log} multiple />
      </div>
      <div>
        <h2 class="mb-2 text-center text-xl font-medium text-title-gray">Lease search</h2>
        <LeaseSearch onSelect={console.log} multiple />
      </div>
      <div>
        <h2 class="mb-2 text-center text-xl font-medium text-title-gray">Vendor search</h2>
        <VendorSearch onSelect={console.log} multiple />
      </div>
      <div>
        <h2 class="mb-2 text-center text-xl font-medium text-title-gray">MaintenanceRequest search</h2>
        <MaintenanceRequestSearch onSelect={console.log} multiple />
      </div>
    </>
  );
};

const LabeledSearchDemo = () => {
  const form = useForm();
  form.setFormStore({ tenantId: '1240796813622517760' });
  const handleSubmit = () => {
    console.log(form.formStore);
  };
  return (
    <Form defaultForm={form}>
      <div>
        <h2 class="mb-2 text-center text-xl font-medium text-title-gray">Labeled Tenant search</h2>
        <FormItem
          formFieldName="tenantId"
          class="mb-6"
          label="Search tenants"
          placeholder={'Please enter the tenantId'}
          component={LabeledTenantSearch}
        />
      </div>

      <button onClick={handleSubmit}>Submit</button>
    </Form>
  );
};

const TabDemo = () => {
  return (
    <div>
      <h2 class="mb-2 text-center text-xl font-medium text-title-gray">Tabs for add Communication Modal</h2>
      <Tabs defaultSelected="1">
        <Tabs.List class="border-b-2">
          <Tabs.Trigger
            value="1"
            class="-mb-0.5 cursor-pointer border-b-2 px-5 py-2.5 font-medium text-text-level03 outline-0 transition-colors aria-selected:border-primary aria-selected:text-primary">
            Tab A
          </Tabs.Trigger>
          <Tabs.Trigger
            value="2"
            class="-mb-0.5 cursor-pointer border-b-2 px-5 py-2.5 font-medium text-text-level03 outline-0 transition-colors aria-selected:border-primary aria-selected:text-primary">
            Tab B
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content value="1">
          <div class="p-5">Tab A content</div>
        </Tabs.Content>
        <Tabs.Content value="2">
          <div class="p-5">Tab B content</div>
        </Tabs.Content>
      </Tabs>
    </div>
  );
};

const ChartOfAccountSelectDemo = () => {
  const form = useForm();
  form.setFormStore({ chartOfaccountId: '1120' });
  const handleSubmit = () => {
    console.log(form.formStore);
  };

  const { chartOfAccountTree } = useChartOfAccounts();
  createEffect(() => console.log(chartOfAccountTree()));
  return (
    <>
      <div>
        <h2 class="mb-2 text-center text-xl font-medium text-title-gray">ChartOfAccountSelect</h2>
        <ChartOfAccountSelect onChange={console.log} filterable multiple />
      </div>
      <Form defaultForm={form}>
        <h2 class="mb-2 text-center text-xl font-medium text-title-gray">LabeledChartOfAccountSelect</h2>
        <FormItem
          formFieldName="chartOfaccountId"
          class="mb-6"
          label="chart of account"
          placeholder={'Please select the chartOfaccount'}
          types={'asset' as any}
          component={LabeledChartOfAccountSelect}
        />
        <button onClick={handleSubmit}>Submit</button>
      </Form>
    </>
  );
};

const ImageDemo = () => {
  const [src, setSrc] = createSignal('/image.png');
  return (
    <div>
      <h2 class="mb-2 text-center text-xl font-medium text-title-gray">Image Component</h2>
      <Image src={'/logo.png'} />
      <Image src={src()} />
      <Button onClick={() => setSrc('/logo.png')}>Change</Button>
    </div>
  );
};

export const PlaygroundPage = () => {
  return (
    <div class="relative mx-auto max-w-xl space-y-10 overflow-hidden bg-white p-20">
      {/* <ImageDemo />
      <InfiniteScrollDemo />
      <hr />
      <SearchSelectDemo />
      <hr />
      <LabeledSearchDemo />
      <hr />
      <TabDemo />
      <hr /> */}
      <ChartOfAccountSelectDemo />
    </div>
  );
};
