export function transformKeyValuePairs(temp: Record<string, string>): Record<string, string> {
  const obj: Record<string, string> = {};

  for (const key in temp) {
    if (key.startsWith('key_')) {
      const index = key.split('_')[1];
      const valueKey = `value_${index}`;
      if (valueKey in temp) {
        obj[temp[key]] = temp[valueKey];
      }
    }
  }
  return obj;
}

type KeyValuePair = { key: string; value: string };
export function objectToKeyValueArray(obj: Record<string, string>): KeyValuePair[] {
  return Object.entries(obj).map(([key, value]) => ({
    key,
    value: String(value),
  }));
}
