import dayjs from 'dayjs';
import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

export const MAINTENANCE_REQUEST_URL = '/api/maintenance-requests';
export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}
export type MaintenanceRequestFilter = {
  page?: number;
  pageSize?: number;
  portfolioId?: string;
  propertyId?: string;
  unitId?: string;
  leaseId?: string;
  tenantId?: string;
  runBookId?: string;
  status?: `${MagicDoor.Api.Status}` | `${MagicDoor.Api.Status}`[];
  urgency?: `${MagicDoor.Api.Urgency}`;
  searchText?: string;
  created?: { start?: string; end?: string };
  new?: boolean;
};

export class MaintenanceRequestRepository extends BaseRestRepository {
  public async getMaintenanceRequests(filter?: MaintenanceRequestFilter): Promise<MagicDoor.Api.MaintenanceRequestPaginationDto> {
    const url = urlWithQuery(MAINTENANCE_REQUEST_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
  public async getMaintenanceRequest(maintenanceRequestId: string): Promise<MagicDoor.Api.MaintenanceRequestDto> {
    validateId(maintenanceRequestId);
    const url = `${MAINTENANCE_REQUEST_URL}/${maintenanceRequestId}`;
    const response = await this.fetchWithAuth(url);

    return this.getJsonResponse(response);
  }

  public async addMaintenanceRequest(
    maintenanceRequest: MagicDoor.Api.MaintenanceRequestDto
  ): Promise<MagicDoor.Api.MaintenanceRequestDto> {
    maintenanceRequest.due = dayjs(maintenanceRequest.due).format('YYYY-MM-DD');
    const response = await this.fetchWithAuth(MAINTENANCE_REQUEST_URL, {
      method: 'POST',
      body: JSON.stringify(maintenanceRequest),
    });
    return this.getJsonResponse(response);
  }

  public async deleteMaintenanceRequest(maintenanceRequestId: string): Promise<void> {
    validateId(maintenanceRequestId);
    const url = `${MAINTENANCE_REQUEST_URL}/${maintenanceRequestId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async disableMaintenanceRequestAi(maintenanceRequestId: string): Promise<void> {
    validateId(maintenanceRequestId);
    const url = `${MAINTENANCE_REQUEST_URL}/${maintenanceRequestId}/ai`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async updateMaintenanceRequest(
    maintenanceRequestId: string,
    maintenanceRequest: MagicDoor.Api.MaintenanceRequestDto
  ): Promise<MagicDoor.Api.MaintenanceRequestDto> {
    validateId(maintenanceRequestId);
    const url = `${MAINTENANCE_REQUEST_URL}/${maintenanceRequestId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(maintenanceRequest),
    });
    return this.getJsonResponse(response);
  }

  public async updateMaintenanceRequestStatus(maintenanceRequestId: string, status: MagicDoor.Api.MaintenanceRequestStatus) {
    validateId(maintenanceRequestId);
    const url = `${MAINTENANCE_REQUEST_URL}/${maintenanceRequestId}/status`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify({ status }),
    });
  }

  public async updateMaintenanceRequestUrgency(maintenanceRequestId: string, urgency: MagicDoor.Api.Urgency) {
    validateId(maintenanceRequestId);
    const url = `${MAINTENANCE_REQUEST_URL}/${maintenanceRequestId}/urgency`;

    await this.fetchWithAuth(url, { method: 'PUT', body: JSON.stringify({ urgency }) });
  }

  public async getMaintenanceRequestStats(): Promise<MagicDoor.Api.MaintenanceRequestsStatsDto> {
    const url = `${MAINTENANCE_REQUEST_URL}/stats`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getMaintenanceRequestCategory(): Promise<MagicDoor.Api.MaintenanceRequestCategoryDto[]> {
    const url = `${MAINTENANCE_REQUEST_URL}/categories`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async convertToWorkOrder(
    maintenanceRequestId: string,
    payload: MagicDoor.Api.CreateWorkOrderFromMaintenanceRequestRequestDto
  ): Promise<MagicDoor.Api.HydratedWorkOrderDto> {
    validateId(maintenanceRequestId);
    const url = `${MAINTENANCE_REQUEST_URL}/${maintenanceRequestId}/convert-to-work-order`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
}
