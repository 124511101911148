import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { useLocalization } from '~/contexts/global';

export interface FontSizeSelectorProps {
  onInput: (value: number | string) => void;
  options?: { value: number; label: string }[];
  value?: number;
}

export const FontSizeSelector = (props: FontSizeSelectorProps) => {
  const { t } = useLocalization();

  return (
    <LabeledSelect
      prefix={t('Font size')}
      selectClass="bg-white"
      labelClass="capitalize text-text-level03"
      class="w-36"
      options={props.options ?? []}
      value={props.value}
      onInput={props.onInput}
    />
  );
};
