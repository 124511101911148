import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, createSignal, onCleanup, onMount, Show } from 'solid-js';
import IconTrash from '~/assets/images/maintenance/trash.svg?component-solid';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeaseTemplateCategory } from '~/contexts/local';
import { formConfirmationContent } from '~/utils/constant';
import type { Component } from 'solid-js';

export const AddLeaseTemplateCategoryModal: Component<{ categoryId?: string }> = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const form = useForm();
  const { current, setCurrentId, addLeaseTemplateCategory, updateLeaseTemplateCategory } = useLeaseTemplateCategory();
  const [isEditMode, setIsEditMode] = createSignal<boolean>(false);

  onMount(() => {
    if (categoryId) {
      setCurrentId(categoryId);
      setIsEditMode(true);
    }
  });

  onCleanup(() => {
    setCurrentId(undefined);
    setIsEditMode(false);
  });

  const handleClose = () => {
    navigate(`/leasing/lease-templates`, { scroll: false, replace: true });
  };

  const handleSubmit = async () => {
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;

    const payload = {
      name: form.formStore.category,
    };
    if (isEditMode()) {
      await updateLeaseTemplateCategory(current()?.id ?? '', payload);
      toast.success(`Category "${form.formStore.category}" has been updated successfully`);
    } else {
      const created = await addLeaseTemplateCategory(payload);
      toast.success(`Category "${created.name}" has been added successfully`);
    }
    handleClose();
  };

  createEffect(() => {
    if (isEditMode() && current()) {
      form.setFieldsValue({ category: current()?.name });
    }
  });

  return (
    <Modal
      visible
      class="animate-zoom-in"
      loading={addLeaseTemplateCategory.loading || updateLeaseTemplateCategory.loading}
      title={isEditMode() ? 'Edit category' : 'Create a category'}
      doneText="Yes"
      onDone={handleSubmit}
      onCancel={handleClose}
      confirmation={form.formStore.category ? formConfirmationContent(t) : false}>
      <Form defaultForm={form} class="mb-6 p-8" onFormSubmit={handleSubmit}>
        <FormItem
          component={LabeledTextInput}
          formFieldName="category"
          rules={[
            { required: true, message: 'Category name is required' },
            {
              validator: (value: string) => !value || value.length < 151,
              message: t('{name} must be {length} characters or less', { name: t('Category name'), length: '150' }),
            },
          ]}
          label="Category name"
          placeholder="Please enter"
        />
      </Form>

      <Show when={isEditMode()}>
        <div class="absolute bottom-6 left-6 flex">
          <Button
            variant="white"
            class="border-text-level03 text-text-level03"
            href={current()?.id ? `/leasing/lease-templates/delete-category/${current()?.id}` : '#'}>
            <IconTrash class="size-4" />
            <div class="hidden md:flex">{t('Delete')}</div>
          </Button>
        </div>
      </Show>
    </Modal>
  );
};
