import dayjs from 'dayjs';
import { createSignal, For, onMount, Show } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import IconSubmit from '~/assets/images/add-offline-payment/submit.svg?component-solid';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { RingLoader } from '~/components/common/Loaders';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { getTenantFullName } from '~/components/tenants';
import { confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeaseRentTransactions } from '~/contexts/local';
import { TenantRepository } from '~/repositories/tenantRepository';
import { LevelSearch } from '~/swagger/Api';
import { MAX_INPUT_AMOUNT, paymentTypes } from '~/utils/constant';
import { isEmptyData, isObject, toLowerCaseFirstLetter } from '~/utils/tool';
import type { Option } from '~/components/common/DropdownMenu';

const teantRepo = new TenantRepository();

const MIN_AMOUNT = 1;

type LeaseOfflinePaymentDto = Omit<MagicDoor.Api.OfflinePaymentDto, 'amount' | 'date'> & {
  leaseId?: string;
  amount?: number;
  date?: string;
};

type RequestError = {
  Date?: string;
  Amount?: string;
  Description?: string;
  TenantId?: string;
  PaymentMethod?: string;
  Reference?: string;
} & { [key in keyof LeaseOfflinePaymentDto]: string };

interface LeaseOfflinePaymentForm {
  data: LeaseOfflinePaymentDto;
  requestError?: RequestError | true;
  validationError?: {
    [key in keyof LeaseOfflinePaymentDto]: string;
  };
}
interface Store {
  form: LeaseOfflinePaymentForm[];
  loading: boolean;
  isScrollAtBottom: boolean;
}

interface TenantOption {
  [key: string]: Option[];
}

export const AddOfflinePaymentView = () => {
  const { t } = useLocalization();
  const { batchAddOfflinePayment } = useLeaseRentTransactions();

  const [tenants, setTenants] = createSignal<TenantOption>();
  const [tableRef, setTableRef] = createSignal<HTMLDivElement>();
  const [store, setStore] = createStore<Store>({
    form: [],
    loading: false,
    isScrollAtBottom: false,
  });

  const handleTableScroll = () => {
    if (!tableRef()) return false;
    const tableWidth = tableRef()?.clientWidth || 0;
    const scrollLeft = tableRef()?.scrollLeft || 0;
    const scrollWidth = tableRef()?.scrollWidth || 0;
    setStore('isScrollAtBottom', scrollLeft + tableWidth >= scrollWidth - 10);
  };

  const onAddRow = () => {
    setStore('form', [
      ...store.form,
      {
        data: {
          date: dayjs().format('YYYY-MM-DD'),
        },
        requestError: {},
        validationError: {},
      },
    ]);
    handleTableScroll();
  };
  const onDeleteRow = (index: number) => {
    setStore('form', store.form.toSpliced(index, 1));
    handleTableScroll();
  };

  const setForm = (value: any, key: keyof LeaseOfflinePaymentDto, index: number) => {
    setStore(
      produce((store) => {
        switch (key) {
          case 'amount':
            store.form[index].data[key] = value;
            break;
          default:
            store.form[index].data[key] = value;
        }
      })
    );
  };

  const onSelectLease = async (lease: MagicDoor.Api.LeaseDto, index: number) => {
    setForm(lease.id, 'leaseId', index);
    setForm('', 'tenantId', index);
    if (lease.tenants.length) {
      const res = await teantRepo.getTenantsByIds({
        tenantIds: lease.tenants.map((tenant) => tenant.tenantId),
      });
      const options: Option[] = res.map((tenant) => ({
        label: getTenantFullName(tenant),
        value: tenant.id,
      }));
      setTenants({ ...tenants(), [lease.id]: options });
    }
  };

  const validator = (): boolean => {
    let result = true;
    const requiredFields: any = {
      date: 'Date',
      leaseId: 'Lease',
      amount: 'Amount',
    };
    for (const index in store.form) {
      const item = store.form[index];
      for (const key in requiredFields) {
        const value = item.data[key as keyof LeaseOfflinePaymentDto];
        if (!value) {
          setStore(
            produce((store) => {
              store.form[index].validationError![key as keyof LeaseOfflinePaymentDto] = t('{name} is required', {
                name: t(requiredFields[key]),
              });
            })
          );
          result = false;
        } else {
          setStore(
            produce((store) => {
              store.form[index].validationError![key as keyof LeaseOfflinePaymentDto] = '';
            })
          );
        }
      }
    }
    return result;
  };

  const removeSuccessfulData = () => {
    const newForm = store.form.filter((item) => item.requestError !== true);
    setStore('form', newForm);
  };

  const getError = (item: LeaseOfflinePaymentForm, key: string) => {
    if (item.requestError === true) return '';
    return (
      item?.validationError?.[toLowerCaseFirstLetter(key) as keyof LeaseOfflinePaymentDto] ||
      item?.requestError?.[key as keyof RequestError] ||
      item?.requestError?.[toLowerCaseFirstLetter(key) as keyof RequestError] ||
      item?.requestError?.[`$.${toLowerCaseFirstLetter(key)}` as keyof RequestError] ||
      ''
    );
  };

  const onSubmit = async () => {
    const verified = validator();
    if (!verified) return;
    setStore('loading', true);
    let successNum = 0;
    let failedNum = 0;
    for (const key in store.form) {
      const item = store.form[key];
      try {
        await batchAddOfflinePayment(item.data.leaseId as string, {
          date: item.data.date as string,
          amount: Number(item.data.amount),
          description: item.data.description || null,
          tenantId: item.data.tenantId || null,
          paymentMethod: item.data.paymentMethod,
          reference: item.data.reference || null,
        });
        setStore(
          produce((store) => {
            store.form[key].requestError = true;
          })
        );
        successNum += 1;
      } catch (error: any) {
        setStore(
          produce((store) => {
            store.form[key].requestError = error.getErrors() || error;
          })
        );
        failedNum += 1;
      }
    }
    setStore('loading', false);
    removeSuccessfulData();
    toast.success(`${successNum} ${t('success')}, ${failedNum} ${t('failed')}`);
  };

  const showErrorInfo = (item: LeaseOfflinePaymentForm) => {
    confirm({
      title:
        isObject(item.requestError) && item.requestError['request' as keyof RequestError]
          ? item.requestError['request' as keyof RequestError]
          : 'Error',
      content: () => {
        const errors = [];
        if (item.requestError === true) return;
        for (const key in item.requestError) {
          if (key !== 'request') errors.push(`${key}: ${item.requestError[key as keyof RequestError]?.toString()}`);
        }
        return (
          <div class="flex flex-col gap-2">
            <For each={errors}>{(error) => <div>{error}</div>}</For>
          </div>
        );
      },
    });
  };

  onMount(() => {
    handleTableScroll();
  });

  return (
    <div class="relative h-[calc(100dvh-80px)] overflow-hidden p-8">
      <div class="flex h-[70px] items-center justify-between rounded-t-lg bg-white px-6">
        <div class="text-lg font-semibold text-text-level01">{t('Batch add offline payment')}</div>
        <div class="flex gap-2">
          <Button disabled={store.loading || store.form.length === 0} size="sm" onClick={onSubmit}>
            <IconSubmit /> {t('Verify and submit')}
          </Button>
        </div>
      </div>
      <div class="relative h-[calc(100%-71px)] ">
        <div ref={setTableRef} onScroll={handleTableScroll} class="thinscroll relative max-h-[calc(100%-71px)] overflow-auto">
          <table class="relative w-full table-auto border-collapse text-sm">
            <thead class="sticky top-0 z-20 bg-input text-xs text-text-level03">
              <tr>
                <th class="px-5 py-3 text-left">{t('Date')}</th>
                <th class="px-5 py-3 text-left">{t('Lease')}</th>
                <th class="px-5 py-3 text-left">{t('Received from')}</th>
                <th class="px-5 py-3 text-left">{t('Amount')}</th>
                <th class="px-5 py-3 text-left">{t('Method')}</th>
                <th class="px-5 py-3 text-left">{t('Refrence')}</th>
                <th class="px-5 py-3 text-left">{t('Description')}</th>
                <th
                  class="sticky right-0 z-10 bg-input px-5 py-3 text-left"
                  classList={{
                    "before:content-[''] before:absolute before:top-0 before:left-0 before:bottom-[-1px] before:transition-all before:w-[5px] before:shadow-table-col-fixed-right":
                      !store.isScrollAtBottom,
                  }}>
                  {t('Operation')}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <For each={store.form}>
                {(item, index) => (
                  <tr>
                    <td class="px-5 py-3 text-left">
                      <DueDateInputField
                        class="!mb-0"
                        error={getError(item, 'Date')}
                        value={item.data.date}
                        onInput={(value) => setForm(value, 'date', index())}
                      />
                    </td>
                    <td class="px-5 py-3 text-left">
                      <LabeledLeveledSearchSelect
                        groupClass="!mb-0"
                        error={getError(item, 'LeaseId')}
                        enabledTypes={[LevelSearch.Lease]}
                        onChange={(selected: any[]) => onSelectLease(selected[0], index())}
                      />
                    </td>
                    <td class="px-5 py-3 text-left">
                      <DropdownMenu
                        class="!mb-0 max-w-44"
                        error={getError(item, 'TenantId')}
                        value={item.data.tenantId}
                        options={tenants()?.[item.data.leaseId as string] || []}
                        onChange={(value) => setForm(value, 'tenantId', index())}
                        placeholder={t('Please select')}
                      />
                    </td>
                    <td class="px-5 py-3 text-left">
                      <LabeledNumberInput
                        error={getError(item, 'Amount')}
                        class="!mb-0 w-32"
                        value={item.data.amount}
                        step={0.01}
                        onInput={(value) => setForm(value, 'amount', index())}
                        placeholder={t('Please enter')}
                        isBlur={false}
                        min={MIN_AMOUNT}
                        max={MAX_INPUT_AMOUNT}
                      />
                    </td>
                    <td class="px-5 py-3 text-left">
                      <DropdownMenu
                        disabled={!item.data.leaseId}
                        class="!mb-0"
                        error={getError(item, 'PaymentMethod')}
                        value={item.data.paymentMethod}
                        options={paymentTypes(t)}
                        onChange={(value) => setForm(value, 'paymentMethod', index())}
                        placeholder={t('Please select')}
                      />
                    </td>
                    <td class="px-5 py-3 text-left">
                      <LabeledTextInput
                        maxlength={150}
                        error={getError(item, 'Reference')}
                        class="!mb-0 w-32"
                        value={item.data.reference}
                        onInput={(value) => setForm(value, 'reference', index())}
                        placeholder={t('Please enter')}
                      />
                    </td>
                    <td class="px-5 py-3 text-left">
                      <LabeledTextInput
                        maxlength={255}
                        error={getError(item, 'Description')}
                        class="!mb-0 w-32"
                        value={item.data.description}
                        onInput={(value) => setForm(value, 'description', index())}
                        placeholder={t('Please enter')}
                      />
                    </td>
                    <td
                      class="sticky right-0 z-10  bg-white px-5 py-3 text-left"
                      classList={{
                        "before:content-[''] before:absolute before:top-0 before:left-0 before:bottom-[-1px] before:transition-all before:w-[5px] before:shadow-table-col-fixed-right":
                          !store.isScrollAtBottom,
                      }}>
                      <div class="flex items-center gap-1">
                        <Button variant="outlined" class="rounded-full" color="warning" size="xs" onClick={() => onDeleteRow(index())}>
                          {t('Delete')}
                        </Button>
                        <Show when={!isEmptyData(item.requestError)}>
                          <Button
                            class="text-nowrap rounded-full"
                            variant="outlined"
                            color="primary"
                            size="xs"
                            onClick={() => showErrorInfo(item)}>
                            {t('View errors')}
                          </Button>
                        </Show>
                      </div>
                    </td>
                  </tr>
                )}
              </For>
            </tbody>
          </table>
        </div>
        <div class="flex h-[70px] items-center rounded-b-lg bg-white px-6">
          <Button class="flex w-full items-center justify-center border-dashed font-medium" size="sm" variant="outlined" onClick={onAddRow}>
            <IconPlus class="size-5 text-primary" />
            {t('Add row')}
          </Button>
        </div>
      </div>
      <Show when={store.loading}>
        <div class="absolute inset-0 z-20 flex size-full items-center justify-center bg-white/60">
          <RingLoader color="#a126ec" />
        </div>
      </Show>
    </div>
  );
};
