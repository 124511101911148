import dayjs from 'dayjs';
import { Show, createEffect, createSignal, For, createMemo, on } from 'solid-js';
import IconRemove from '~/assets/images/common/remove.svg?component-solid';
import FixedTermIcon from '~/assets/images/leases/fixedTerm.svg?component-solid';
import MonthToMonthIcon from '~/assets/images/leases/monthToMonth.svg?component-solid';
import { Avatar } from '~/components/common/Avatar';
import { Button } from '~/components/common/Buttons';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { OptionButton } from '~/components/common/OptionButton';
import { Panel } from '~/components/common/Panels';
import { LeasePeriod } from '~/components/leases';
import { LeaseName } from '~/components/leases/LeaseName';
import { isMonthToMonth } from '~/components/leases/utils';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { AddTenantModal, TenantSearch, TenantTitle } from '~/components/tenants';
import { getTenantFullName } from '~/components/tenants/utils';
// import { IconAlert } from '~/components/ui';
import { IconCirclePlus } from '~/components/ui';
import { UnitImage } from '~/components/units';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import UnitItem from '~/pages/bills/components/UnitItem';
import { LevelSearch } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import { debounce } from '~/utils/tool';
import { dateFormatValidator, verifyRequiredValidator, arrayRequiredValidator } from '~/utils/validations';
import type { NewLeaseStepProps } from './types';
import type { Component } from 'solid-js';
import type { Variants } from '~/components/search-select/Context';

export const BasicProfile: Component<NewLeaseStepProps> = (props) => {
  const { t } = useLocalization();
  const { suggestLeaseEndDate, getLeaseOverlapping } = useLease();

  const [selectedTenants, setSelectedTenants] = createSignal<MagicDoor.Api.HydratedTenantDto[]>([]);

  const [addNewTenantsModal, showAddNewTenantsModal] = createSignal(false);

  const [suggestedEndDate, setSuggestedEndDate] = createSignal<string>('');
  const [suggestedEndDateLoading, setSuggestedEndDateLoading] = createSignal<boolean>(false);
  const [userSelectedEndDate, setUserSelectedEndDate] = createSignal<string>('');

  const [currentUnit, setCurrentUnit] = createSignal<MagicDoor.Api.HydratedUnitDto>();
  const [overlapLease, setOverlapLease] = createSignal<MagicDoor.Api.HydratedLeaseDto[]>([]);

  const hasSubmit = createMemo(() => props.isSubmit);
  const unitIdError = createMemo(() => verifyRequiredValidator(props.model.unitId, 'Unit', true));
  const tenantError = createMemo(() => arrayRequiredValidator(props.model.tenantIds, 'Tenant', true));
  const startError = createMemo(() => dateFormatValidator(props.model.start, 'Start date', 'YYYY-MM-DD', true));
  const endError = createMemo(() => {
    if (!props.model.end) {
      return t('{name} is required', { name: t('End date') });
    }

    if (props.model.start && props.model.end && dayjs(props.model.end).isBefore(dayjs(props.model.start))) {
      return t('{date1} cannot be earlier than {date2}', { date1: t('End date'), date2: t('Start date') });
    }

    return undefined;
  });

  const onFixedTerm = () => {
    props.onUpdate({ isFixedTerm: true });
  };

  const onMonthToMonth = () => {
    props.onUpdate({ isFixedTerm: false });
  };

  const handleEndDateInput = (end: any) => {
    props.onUpdate({ end });
    setUserSelectedEndDate(end);
  };

  const displayEndDate = () => userSelectedEndDate() || suggestedEndDate();

  const fetchSuggestedEndDate = debounce(async () => {
    if (props.model.start && !userSelectedEndDate() && !suggestedEndDate()) {
      try {
        setSuggestedEndDateLoading(true);
        const response = await suggestLeaseEndDate(props.model.start);
        const endDate = response.suggestedEndDate;
        props.onUpdate({ end: endDate });
        setSuggestedEndDate(endDate);
      } catch (error) {
        console.error('Failed to fetch suggested end date:', error);
      } finally {
        setSuggestedEndDateLoading(false);
      }
    }
  }, 100);

  const removeUnit = () => {
    setCurrentUnit(undefined);
    props.onUpdate({ portfolioId: undefined });
    props.onUpdate({ propertyId: undefined });
    props.onUpdate({ unitId: undefined });
  };

  const getLeaseOverlappingList = async () => {
    const unitId = props.model.unitId,
      start = props.model.start;
    if (unitId && start) {
      const result = await getLeaseOverlapping(unitId, start, props.model.end || undefined);
      setOverlapLease(result);
    }
  };

  createEffect(() => setCurrentUnit(props.initUnit));

  createEffect(() => {
    if (props.model.start && !userSelectedEndDate() && !suggestedEndDate()) {
      fetchSuggestedEndDate();
    }
  });

  createEffect(() => {
    if (props.initTenant) {
      setSelectedTenants([props.initTenant]);
    }
  });

  createEffect(
    on(
      () => [props.model.start, props.model.end, props.model.unitId],
      () => {
        getLeaseOverlappingList();
      }
    )
  );

  return (
    <div class="flex flex-col gap-4">
      <div>
        <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Property information')}</h4>
        <div class="py-4">
          <Show
            when={!currentUnit()}
            fallback={
              <div class="flex items-center">
                <div class="flex w-[calc(100%-28px)] items-center overflow-hidden rounded-lg bg-light-gray px-4 py-3">
                  <UnitItem unit={currentUnit()} />
                </div>
                <div class="ml-2 cursor-pointer" onClick={removeUnit}>
                  <IconRemove class="size-5 text-primary" />
                </div>
              </div>
            }>
            <LabeledLeveledSearchSelect
              enabledTypes={[LevelSearch.Unit]}
              onChange={(selected: any[], variants: Variants) => {
                props.onUpdate({
                  portfolioId: variants.leveledIds.at(0)?.portfolioId,
                  propertyId: variants.leveledIds.at(0)?.propertyId,
                  unitId: variants.leveledIds.at(0)?.unitId,
                });
                setCurrentUnit(selected.at(0));
              }}
            />
            <Show when={hasSubmit() && unitIdError()}>
              <span class="text-xs text-red-500">{unitIdError()}</span>
            </Show>
          </Show>
        </div>
      </div>
      <div>
        <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Tenants')}</h4>
        <TenantSearch
          placeholder={t('Search for tenants')}
          multiple
          selected={selectedTenants()}
          onSelect={(tenants) => {
            setSelectedTenants(tenants);
            props.onUpdate({ tenantIds: selectedTenants().map((item) => item.id) });
          }}
          renderItem={(item) => {
            const lease = item.currentLeases?.[0];
            return (
              <>
                <TenantTitle feedbackIconReadonly tenant={item} />
                <div class="flex flex-1 flex-col items-end">
                  <Show when={lease} fallback={<span class="text-xs capitalize text-text-level03">{t('No lease')}</span>}>
                    <LeaseName lease={item as any} />
                    <LeasePeriod
                      class={cn('text-xs capitalize text-[#36CBAC]', { 'text-[#8D9BBD]': lease?.eviction || lease?.ended })}
                      lease={lease}
                    />
                  </Show>
                </div>
              </>
            );
          }}
        />
        <Button
          size="sm"
          class="mt-4 h-10"
          variant="outlined"
          onClick={() => {
            showAddNewTenantsModal(true);
          }}>
          <IconCirclePlus />
          {t('Add new tenant')}
        </Button>
        <span class="text-xs text-red-500">{hasSubmit() ? tenantError() : undefined}</span>
      </div>
      <div>
        <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Terms')}</h4>
        <div class="grid grid-cols-2 gap-4 py-4">
          <OptionButton
            class="h-20"
            Icon={FixedTermIcon}
            label={t('Fixed term')}
            isActive={props.model.isFixedTerm}
            onClick={onFixedTerm}
          />
          <OptionButton
            class="h-20"
            Icon={MonthToMonthIcon}
            label={t('Month to month')}
            isActive={!props.model.isFixedTerm}
            onClick={onMonthToMonth}
          />
          <DueDateInputField
            class="col-span-2 lg:col-span-1"
            contentPosition={['top']}
            label={t('Start date')}
            required
            value={props.model.start}
            onInput={(start) => props.onUpdate({ start: start ? start : '' })}
            error={hasSubmit() ? startError() : undefined}
            placeholder={t('MM/DD/YYYY')}
          />
          <Show when={props.model.isFixedTerm}>
            <DueDateInputField
              class="col-span-2 lg:col-span-1"
              contentPosition={['top', 'right']}
              label={t('End date')}
              required
              value={displayEndDate()}
              onInput={handleEndDateInput}
              error={hasSubmit() ? endError() : undefined}
              placeholder={t('MM/DD/YYYY')}
            />
            <Show when={!suggestedEndDateLoading()} fallback={<span class="mt-10 text-xs text-red-500">loading...</span>}>
              <div
                class="cursor-pointer text-sm text-link"
                onClick={() => {
                  setUserSelectedEndDate('');
                  setSuggestedEndDate('');
                }}>
                {t('Refresh end date')}
              </div>
            </Show>
          </Show>
          <Show when={props.model.isFixedTerm}>
            <span class="col-span-full col-start-1 mt-4 flex items-center gap-2 text-sm text-text-level02">
              <Checkbox checked={props.model.monthToMonth} onInput={(monthToMonth) => props.onUpdate({ monthToMonth })} />
              {t('Transfer to month-to-month after the term is over')}
            </span>
          </Show>

          <Show when={overlapLease().length}>
            <Panel
              class="col-span-full bg-[#EE39641A]"
              header={
                <div class="flex items-center border-b border-[#FFDEE6] px-5 py-3 text-sm font-normal text-[#EE3964]">
                  {/* <IconAlert class="mr-2" /> */}
                  {t('This lease is overlapping with the following lease')}
                </div>
              }>
              <div class="srcollbar  w-full overflow-x-auto px-5 pb-3">
                <For each={overlapLease()}>
                  {(lease) => (
                    <div class="mt-3  w-auto  rounded-lg bg-white p-3 lg:flex lg:flex-row lg:items-center lg:justify-between">
                      <div class="flex items-center">
                        <UnitImage class="mr-2 size-11 rounded-lg" unit={lease?.unit as { id: string; imageId?: string }} />
                        <div class=" text-sm">
                          <LeaseName lease={lease} />
                          <div class="flex items-center text-xs text-text-level03">
                            <Show when={isMonthToMonth(lease)}>{t('Month-To-Month')}：</Show>
                            <LeasePeriod class="text-xs" lease={lease} />
                          </div>
                        </div>
                      </div>

                      <div class="text-level03 text-text-level01">
                        <span class="text-sm text-text-level03">{t('Balance')}：</span>
                        <span>{currency(lease.balance)}</span>
                      </div>
                      <div class="flex items-center">
                        <span class="text-sm text-text-level03">{t('Tenant')}：</span>
                        <Show when={lease.tenants[0].tenant} fallback={<div>--</div>}>
                          <Avatar size="default" name={getTenantFullName(lease.tenants[0].tenant)} />
                          <span class="ml-1 truncate text-sm font-medium text-text-level01">
                            {getTenantFullName(lease.tenants[0].tenant)}
                          </span>
                        </Show>
                      </div>
                    </div>
                  )}
                </For>
              </div>
            </Panel>
          </Show>
        </div>
      </div>

      <Show when={addNewTenantsModal()}>
        <AddTenantModal
          visible={addNewTenantsModal()}
          onClose={(tenant?: MagicDoor.Api.TenantCreatedDto) => {
            if (tenant) {
              props.onUpdate({
                tenantIds: selectedTenants()
                  .map((item) => item.id)
                  .concat([tenant.id]),
              });
              setSelectedTenants(() => [...selectedTenants(), tenant]);
            }

            showAddNewTenantsModal(false);
          }}
        />
      </Show>
    </div>
  );
};
