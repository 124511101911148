import { portfolioRepository } from '~/repositories/portfolioRepository';
import { unitRepository } from '~/repositories/unitRepository';
import { BaseRestRepository } from './baseRestRepository';

const SEARCH_URL = '/api/search';

export class SearchRepository extends BaseRestRepository {
  public async search(query: MagicDoor.Api.SelectSearchRequestDto): Promise<MagicDoor.Api.SelectSearchResultDto> {
    const url = `${SEARCH_URL}/select`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(query),
    });
    return this.getJsonResponse(response);
  }

  public async getPortfolios(): Promise<MagicDoor.Api.HydratedPortfolioDto[]> {
    return await portfolioRepository.getPortfolios({
      active: true,
    });
  }

  public async getUnits(propertyId: string) {
    const res: any = await unitRepository.getUnits({
      propertyId,
      active: true,
      pageSize: 100,
    });

    res.items = res.items.map((item: any) => {
      const property = item.property;
      item.leases = (item.activeLeases ?? []).map((lease: any) => ({
        ...lease,
        property: property,
        unit: {
          name: item.name,
        },
      }));
      delete item.activeLeases;
      return item;
    });

    return res;
  }
}
