import { createMemo, createSignal, mergeProps, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { ListView, IconDownload, IconTrash, confirm } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { AttachmentsProvider, useWorkOrders } from '~/contexts/local';
import { downloadFile } from '~/utils/file';
import { FilePreviewModal } from './FilePreviewModal';
import { FileTitle } from './FileTitle';
import { FileUploadModal } from './FileUploadModal';
import type { Component } from 'solid-js';

export const WorkOrderBillFileListView: Component<{
  billId: string;
  workOrderId: string;
  class?: string;
  onlyUpload?: boolean;
  autoUpload?: boolean;
}> = (originProps) => {
  const props = mergeProps(
    {
      autoUpload: true,
    },
    originProps
  );

  const { t } = useLocalization();
  const { workOrder, addBillFile, deleteBillFile, fileList, setFileList } = useWorkOrders();

  const [preview, setPreview] = createSignal<MagicDoor.Api.WorkOrderDraftBillFileDto>();
  const [showUploadModal, setShowUploadModal] = createSignal<boolean>(false);
  const [selected, setSelected] = createSignal<MagicDoor.Api.WorkOrderDraftBillFileDto[]>([]);

  const loading = () => workOrder.loading;

  const data = createMemo(() =>
    props.autoUpload
      ? workOrder()?.bills?.find((bill) => bill.id === props.billId)?.files ?? []
      : (fileList() as unknown as MagicDoor.Api.WorkOrderDraftBillFileDto[])
  );

  const handleDownloadSelect = async () => {
    const files = selected();
    if (files.length === 0) return;
    files.forEach((file) => downloadFile(file.signedUrl, file.fileName));
  };

  const onUploadConfirm = async (categoryId: string, file: File[], name: string, description?: string) => {
    const newFiles = file.map((f) => ({ type: f.type, fileName: f.name, description, file: f }));

    if (props.autoUpload && props.billId) {
      for (const f of newFiles) {
        await addBillFile(props.workOrderId, props.billId, f.file, f.description);
      }
    }
    setFileList((prev) => [...prev, ...newFiles]);
  };

  const deleteFile = async (item: MagicDoor.Api.WorkOrderDraftBillFileDto, index: number) => {
    if (props.autoUpload) {
      confirm({
        title: t('Delete file'),
        content: t('Are you sure you want to delete this file?'),
        async onResolve(confirmed) {
          if (!confirmed || props.billId == null) return;
          await deleteBillFile(props.workOrderId, props.billId, item.fileId);
        },
      });
      return;
    }
    setFileList((prev) => prev.toSpliced(index, 1));
  };

  const tableActions = (item: MagicDoor.Api.WorkOrderDraftBillFileDto, index: number) => {
    const actions = [
      {
        label: t('Download'),
        icon: IconDownload,
        onClick: () => downloadFile(item.signedUrl, item.fileName),
      },
      {
        label: t('Delete'),
        icon: IconTrash,
        onClick: () => deleteFile(item, index),
      },
    ];
    return props.autoUpload ? actions : actions.toSpliced(0, 1);
  };

  return (
    <>
      <ListView
        class={props.class}
        title={t('Files')}
        titleActions={
          <>
            <Show when={!props.onlyUpload}>
              <Button variant="outlined" class="p-2.5" disabled={selected().length === 0} onClick={handleDownloadSelect}>
                <IconDownload class="size-5" />
              </Button>
            </Show>
            <Button onClick={() => setShowUploadModal(true)}>{t('Upload file')}</Button>
          </>
        }
        selected={props.onlyUpload ? undefined : selected()}
        onSelect={setSelected}
        onRowClick={setPreview}
        loading={loading()}
        data={data()}
        columns={[
          {
            title: t('File'),
            render: (item) => <FileTitle file={item} />,
          },

          {
            title: t('From'),
            headerClass: 'w-40',
            class: 'space-y-1 text-xs',
            render: (item) => (
              <>
                <Show when={item.fileName}>
                  <p class="text-text-level03">
                    {t('by')} {item.fileName}
                  </p>
                </Show>
              </>
            ),
          },
        ]}
        actions={(item, index) => tableActions(item, index)}
      />
      <AttachmentsProvider>
        <FileUploadModal
          pending={addBillFile.pending}
          entryType={undefined}
          entryId={undefined}
          open={showUploadModal()}
          onClose={() => setShowUploadModal(false)}
          onConfirm={onUploadConfirm}
        />
      </AttachmentsProvider>
      <FilePreviewModal file={preview() as any as MagicDoor.Api.HydratedFileAttachmentDto} onClose={setPreview} />
    </>
  );
};
