import { useParams, useNavigate } from '@solidjs/router';
import { createMemo, onMount, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { useAnnouncements } from '~/contexts/local';
import { renderAttachmentLists, renderField, TargetsSection } from '~/pages/announcements/components/RenderAnnouncement';
import { dateFormat } from '~/utils/date';

export const AnnouncementDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { t } = useLocalization();
  const { announcement, getAnnouncement, isLoading } = useAnnouncements();

  onMount(() => {
    getAnnouncement(id);
  });

  const breadcrumbItems = createMemo(() => [
    { label: t('Announcements'), link: '/communications/announcements' },
    { label: t('Announcement detail') },
  ]);

  return (
    <Show when={!isLoading()} fallback={<RingLoader />}>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />

      <div class="p-8 text-left">
        <Panel title={t('Announcement detail')} class="thinscroll h-section1 overflow-auto ">
          <div class="px-4 py-8 text-base lg:px-20">
            <div class="flex flex-col gap-4">
              <h4 class="title-gray border-b py-2 text-lg font-semibold uppercase">{t('Details')}</h4>

              <div class="w-1/2 rounded-md border p-3">
                <h5 class="text-sm font-semibold uppercase text-text-level02">{t('Subject')}</h5>
                <p>{announcement()?.subject}</p>
              </div>

              {renderField('Sent at', dateFormat('', announcement()?.sentAt), t)}
              {renderField('Chat message', announcement()?.chatMessage, t)}
              {renderAttachmentLists(announcement()?.chatAttachments, 'Chat attachments', t)}
              {renderField('Email message', announcement()?.emailMessage, t)}
              {renderAttachmentLists(announcement()?.emailAttachments, 'Email attachments', t)}

              <TargetsSection announcement={announcement()} />
            </div>
          </div>
        </Panel>
      </div>
    </Show>
  );
};
