import { Route, Routes, useParams } from '@solidjs/router';
import { NotesTableList } from '~/components/notes';
import { NoteModal } from '~/components/notes/NoteModal';

export const TenantsNotesTab = () => {
  const params = useParams<{ tenantId: string }>();
  return (
    <>
      <Routes>
        <Route path="/add" element={<NoteModal type="tenant" id={params.tenantId} action="Add" />} />
        <Route path="/:noteId/edit" element={<NoteModal type="tenant" id={params.tenantId} action="Edit" />} />
      </Routes>
      <NotesTableList type="tenant" id={params.tenantId} />
    </>
  );
};
