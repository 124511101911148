import { useNavigate } from '@solidjs/router';
import { createSignal } from 'solid-js';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { Modal } from '~/components/modals/Modal';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { toast } from '~/components/ui';
import { useLocalization, usePortfolios, useProperties } from '~/contexts/global';
import { LevelSearch } from '~/swagger/Api';
import type { Component } from 'solid-js';

export const MovePortfolioModal: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { current } = usePortfolios();
  const { movePropertyToPortfolio, current: currentProperty } = useProperties();
  const { setFilter } = useProperties();
  const form = useForm();
  const [portfolioId, setPortfolioId] = createSignal<string>();

  const handleSubmit = async () => {
    form.setFieldsValue({
      portfolioId: portfolioId(),
    });
    const { validateStatus } = form.validateForm();
    if (!validateStatus) return;
    await movePropertyToPortfolio(currentProperty()?.id as string, form.formStore.portfolioId);
    toast.success(t('Success'));
    setFilter({ portfolioId: current()?.id });
    handleClose();
  };
  const handleClose = () => {
    const currentId = current()?.id;
    navigate(currentId ? `/portfolios/${currentId}` : '/portfolios', { scroll: false, replace: true });
  };

  return (
    <Modal
      visible
      class="animate-zoom-in"
      title={t('Move a property')}
      doneText={t('Confirm')}
      onDone={handleSubmit}
      onCancel={handleClose}
      confirmation={form.formStore.portfolioId}>
      <Form defaultForm={form} class="mb-6 p-8" onFormSubmit={handleSubmit}>
        <FormItem
          component={LabeledLeveledSearchSelect}
          formFieldName="portfolioId"
          enabledTypes={[LevelSearch.Portfolio]}
          rules={[{ required: true, message: t('Portfolio name is required') }]}
          label={t('Portfolio')}
          onChange={(selected: any[]) => setPortfolioId(selected[0].id)}
        />
      </Form>
    </Modal>
  );
};
