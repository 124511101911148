import type { LanguageCode } from '~/types/languageCodes';

export enum PageSize {
  A4 = 'A4',
  LETTER = 'LETTER',
}

export enum Orientation {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
}

export interface DocumentHeader {
  logoUrl?: string;
  companyName?: string;
  title: string;
  subTitle?: string;
  additionalInfo?: string[];
}

export interface DocumentFooter {
  text: string;
}

export enum Alignment {
  start,
  center,
  end,
}

export enum WidthDistribution {
  fixed,
  flex,
}

export interface Value {
  text: string;
  isBold?: boolean;
  horizontalAlignment?: Alignment;
  indentationLevel?: number;
}

export interface HeaderValue extends Value {
  width?: WidthDistribution;
}

export interface Entry {
  values?: Value[];
  hasTopLine?: boolean;
  hasBottomLine?: boolean;
  isLineDoubled?: boolean;
  hasBackgroundColor?: boolean;
}

export interface HeaderEntry extends Entry {
  values?: HeaderValue[];
}

export interface Section {
  header?: HeaderEntry;
  entries?: Entry[];
  hasAlternatingRowColors?: boolean;
}

export interface ReportDocument {
  language: LanguageCode;
  orientation: Orientation;
  memo?: string;
  header?: DocumentHeader;
  footer?: DocumentFooter;
  sections: Section[];
  isEachSectionOnANewPage?: boolean;
  pageSize: PageSize;
  shouldShowPageNumbers?: boolean;
}
