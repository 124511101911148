import { createEffect, createMemo, createSignal } from 'solid-js';
import { unwrap } from 'solid-js/store';
import { createMagicDoorContext, useLocalization } from '~/contexts/global';
import { SearchRepository } from '~/repositories/searchRepository';
import { LevelSearch } from '~/swagger/Api';
import { createTriggerResource } from '~/utils/resource';
import { cloneDeep, debounce } from '~/utils/tool';
import { searchLevels } from './LevelSearchComponents/SearchLevels';
import type { Accessor, Resource } from 'solid-js';
import type { SelectSearchResultDto } from '~/swagger/Api';

const searchRepository = new SearchRepository();

export type Variants = {
  ids: Record<string, string[]>;
  leveledIds: Record<string, string>[];
  type?: LevelSearch;
};

export type SearchSelectProps = {
  multiple?: boolean;
  onChange: (selected: unknown[], varians: Variants) => void;
  enabledTypes?: LevelSearch[];
  singleTypeOnly?: boolean;
  initialSelected?: any[];
  initialExpandedKeys?: string[];
  placeholder?: string;
};

export type SearchNode<T = Record<string, any>> = T & { __lazy?: boolean; __type?: LevelSearch; __keyPath?: string[]; __loaded?: boolean };

export const [SearchSelectProvider, useSearchSelectContext] = createMagicDoorContext<
  {
    selected: Accessor<any[]>;
    handleSelect: (item: any) => void;
    isSelected: (item: any) => boolean;
    enabledTypes?: Accessor<LevelSearch[] | undefined>;
    handleRemove: (e: MouseEvent, item: any) => void;
    searchText: Accessor<string>;
    searchResults: Resource<SelectSearchResultDto>;
    onSearchInput: (e: any) => void;
    hasSearched: Accessor<boolean>;
    loadPortfolios: () => void;
    globalLoading: Accessor<boolean>;
    onNodeExpandedChange: (node: SearchNode, expanded: boolean) => void;
    isExpanded: (node?: SearchNode) => boolean;
    isLoading: (node: SearchNode) => boolean;
    isMultiple: () => boolean | undefined;
    onRowClick: (e: MouseEvent, item: any) => void;
    loadUnits: (node: SearchNode) => void;
    isDisabled: (type: LevelSearch) => boolean;
    getPlaceholder: () => string;
  },
  SearchSelectProps
>('searchSelect', (props) => {
  const [selected, setSelected] = createSignal<any[]>([]);
  const [searchText, setSearchText] = createSignal('');
  const [hasSearched, setHasSearched] = createSignal(false);
  const [expandedKeys, setExpandedKeys] = createSignal<string[]>([]);
  const [portfolioLoading, setPortfolioLoading] = createSignal(false);
  const [loadingKeys, setLoadingKeys] = createSignal<string[]>([]);

  const enabledTypes = createMemo(() => props.enabledTypes);
  const singleTypeOnly = createMemo(() => props.singleTypeOnly);

  const [searchResults, fetch, { mutate: mutateSearchResults }] = createTriggerResource(searchRepository.search.bind(searchRepository));

  const handleRemove = (e: MouseEvent, item: any) => {
    e.stopImmediatePropagation();
    setSelected((prev) => prev.filter((i: any) => i.id !== item.id));
  };

  const createVariantsFromSelected = (selected: any[]) => {
    const _selected = selected ?? [];

    const variants: Variants = { ids: {}, leveledIds: [], type: props.singleTypeOnly ? selected[0]?.__type : undefined };

    for (const item of _selected) {
      const type = item.__type;

      if (!type) continue;
      let tempIdMap = {};
      const idsKey = `${type}Ids`;

      switch (type) {
        case LevelSearch.Portfolio:
          tempIdMap = { portfolioId: item.id };
          break;
        case LevelSearch.Property:
          tempIdMap = { propertyId: item.id, portfolioId: item.portfolioId };
          break;
        case LevelSearch.Unit:
          tempIdMap = { unitId: item.id, propertyId: item.propertyId, portfolioId: item.portfolioId };
          break;
        default:
          tempIdMap = { leaseId: item.id, unitId: item.unitId, propertyId: item.propertyId, portfolioId: item.portfolioId };
      }

      variants.leveledIds.push(tempIdMap);

      if (!variants.ids[idsKey]) {
        variants.ids[idsKey] = [];
      }

      variants.ids[idsKey].push(item.id);
    }
    return variants;
  };

  const handleSelect = (item: any) => {
    if (isSelected(item)) {
      setSelected((prev) => prev.filter((i: any) => i.id !== item.id));
    } else {
      setSelected(props.multiple ? (prev: any[]) => [...prev, item] : [item]);
    }

    setSearchText('');

    props.onChange?.(unwrap(selected()), createVariantsFromSelected(unwrap(selected())));
  };

  const isSelected = (item: any) => {
    return selected().some((selectedItem: any) => selectedItem.id === item.id);
  };

  createEffect(() => {
    if (props.initialSelected) {
      setSelected(props.initialSelected);
    }
  });

  createEffect(() => {
    setExpandedKeys((prev) => {
      return [...prev, ...(props.initialExpandedKeys || [])];
    });
  });

  const handleSearch = debounce((search: string) => {
    if (!search.length) {
      setHasSearched(false);
      return;
    }
    setHasSearched(true);
    fetch({ search });
  }, 500);

  const onSearchInput = (e: any) => {
    const _value = e.target?.value;
    setSearchText(_value);

    if (!_value) {
      setHasSearched(false);
      mutateSearchResults(undefined);
      loadPortfolios(true);
      return;
    }

    handleSearch(_value);
  };

  const loadPortfolios = async (hard = false) => {
    if (searchResults() && !hard) return;

    setPortfolioLoading(true);

    let portfolios = await searchRepository.getPortfolios();

    setPortfolioLoading(false);

    if (!enabledTypes()?.includes(LevelSearch.Portfolio)) {
      portfolios = portfolios.filter((portfolio) => portfolio.properties?.length);
    }

    mutateSearchResults({
      portfolios: portfolios.map((portfolio) => {
        const properties = portfolio.properties?.filter((pro) => pro.active);
        return {
          portfolio: Object.assign(portfolio, {
            __type: LevelSearch.Portfolio,
            __keyPath: [portfolio.id],
            propertyCount: properties?.length,
          } as SearchNode),
          properties: properties.map((property) => ({
            property: Object.assign(property, {
              __lazy: true,
              __type: LevelSearch.Property,
              __keyPath: [portfolio.id, property.id],
            } as SearchNode),
          })),
        };
      }),
    });
  };

  const globalLoading = createMemo(() => searchResults.loading || portfolioLoading());

  const onNodeExpandedChange = async (node: SearchNode, expanded: boolean) => {
    setExpandedKeys((prev) => {
      if (expanded) {
        return [...prev, node.id];
      }
      return prev.filter((key) => key !== node.id);
    });
  };

  const loadUnits = async (node: SearchNode) => {
    if (loadingKeys().includes(node.id)) return;
    setLoadingKeys((prev) => [...prev, node.id]);
    try {
      const children = await searchRepository.getUnits(node.id);
      node.__loaded = true;
      mutateSearchResults(cloneDeep(updateNodeChildren(searchResults(), node, children.items)));
    } finally {
      setLoadingKeys((prev) => prev.filter((key) => key !== node.id));
    }
  };

  const isExpanded = (node?: SearchNode) => Boolean(node && expandedKeys().includes(node.id));
  const isLoading = (node: SearchNode) => loadingKeys().includes(node.id);

  const updateNodeChildren = (state: any, node: SearchNode, children: any[]) => {
    if (!node.__keyPath) return state;

    const [_nodeInState, _childrenKey] = (function findNode(index = 0, parent = state.portfolios) {
      const config = searchLevels[index];
      const currentId = node.__keyPath[index];
      const childrenKey = config.childItems;
      const currentNode = parent?.find((node: any) => node[config.type].id === currentId);

      if (index === node.__keyPath.length - 1) {
        return [currentNode, childrenKey];
      } else {
        return findNode(index + 1, currentNode[childrenKey]);
      }
    })();

    _nodeInState[_childrenKey] = (function updateNode(_node = node, _chilren: any[] = children): SearchNode[] {
      const level = searchLevels.findIndex((config) => config.type === _node.__type);
      const config = searchLevels[level];
      const nextConfig = searchLevels[level + 1];

      if (!config || !nextConfig || !_chilren.length) return [];

      return _chilren.map((child) => {
        const childNode = Object.assign(child, {
          __type: nextConfig.type,
          __keyPath: [...(_node?.__keyPath || []), child.id],
        } as SearchNode);

        const childNodeChildren = child[nextConfig.childItems];

        return {
          [nextConfig.type]: childNode,
          [nextConfig.childItems]: childNodeChildren.length ? updateNode(childNode, childNodeChildren) : [],
        };
      });
    })();

    return state;
  };

  const onRowClick = (e: MouseEvent, item: any) => {
    onNodeExpandedChange(item, !isExpanded(item));

    if (item.__type === LevelSearch.Property && !item.__loaded) {
      loadUnits(item);
    }
  };

  const isDisabled = (type: LevelSearch) => {
    if (!type) {
      return true;
    }

    const normalizedType = type.toUpperCase();

    let _disabled = false;

    const enabledTypesList = enabledTypes?.();
    if (enabledTypesList?.length) {
      const normalizedEnabledTypes = enabledTypesList.map((t) => t.toUpperCase());
      _disabled = !normalizedEnabledTypes.includes(normalizedType);
    }

    if (singleTypeOnly?.() && selected().length) {
      const selectedType = selected()[0].__type?.toUpperCase();
      _disabled = normalizedType !== selectedType;
    }

    return _disabled;
  };

  const { t } = useLocalization();

  const getPlaceholder = () => {
    if (props.placeholder) return props.placeholder;

    const types = enabledTypes?.() ?? [];

    if (types.length === 0) return t('Please enter');

    const typeNames = types
      .map((type) => {
        switch (type) {
          case LevelSearch.Portfolio:
            return t('Portfolios');
          case LevelSearch.Property:
            return t('Properties');
          case LevelSearch.Unit:
            return t('Units');
          case LevelSearch.Lease:
            return t('Leases');
          default:
            return '';
        }
      })
      .filter(Boolean);

    if (typeNames.length === 1) {
      return t('Search {{type}}', { type: typeNames[0].toLowerCase() });
    }

    if (typeNames.length === 2) {
      return t('Search {{first}} and {{second}}', {
        first: typeNames[0].toLowerCase(),
        second: typeNames[1].toLowerCase(),
      });
    }

    const lastType = typeNames.pop()!.toLowerCase();
    return t('Search {{list}}, and {{last}}', {
      list: typeNames.map((name) => name.toLowerCase()).join(', '),
      last: lastType,
    });
  };

  return {
    selected,
    handleSelect,
    isSelected,
    enabledTypes,
    handleRemove,
    searchText,
    searchResults,
    onSearchInput,
    hasSearched,
    loadPortfolios,
    globalLoading,
    onNodeExpandedChange,
    isExpanded,
    isLoading,
    isMultiple: () => props.multiple,
    onRowClick,
    loadUnits,
    isDisabled,
    getPlaceholder,
  };
});
