import { A } from '@solidjs/router';
import { createMemo, For, Show } from 'solid-js';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels/Panel';
import ExpandableSection from '~/components/common/expandables/ExpandableSection';
import { IconTrash, Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { RunbookTitle } from '~/pages/ai-runbooks/runbooks-details/components/RunbookTitle';
import type { Component } from 'solid-js';

enum RunBookActionExits {
  AskQuestion = 'askQuestion',
  CloseRequest = 'closeRequest',
  CreateWorkOrder = 'createWorkOrder',
  HandOverToHuman = 'handOverToHuman',
  ReselectRunBook = 'reselectRunBook',
}

const ACTION_EXIT_LABELS: Record<RunBookActionExits, string> = {
  [RunBookActionExits.AskQuestion]: 'Ask question',
  [RunBookActionExits.CloseRequest]: 'Close request',
  [RunBookActionExits.CreateWorkOrder]: 'Create work order',
  [RunBookActionExits.HandOverToHuman]: 'Hand over to human',
  [RunBookActionExits.ReselectRunBook]: 'Reselect runbook',
};

export const RunbookOverviewTab: Component = () => {
  const { t } = useLocalization();

  const { runBook } = useRunBooks();

  const sumAIResults = createMemo(() => {
    let total = 0;
    const groupsByTypes = Object.entries(RunBookActionExits).reduce((acc, [key, value]) => {
      const count = runBook()?.actionExitCounts[value] ?? '-';
      if (typeof count === 'number') {
        total += count;
      }
      acc.push({
        type: key,
        label: t(ACTION_EXIT_LABELS[value as RunBookActionExits]),
        count,
      });
      return acc;
    }, [] as Array<{ type: string; label: string; count: string | number }>);
    return {
      total,
      groupsByTypes,
    };
  });

  return (
    <div class="flex flex-col gap-5">
      <RunbookTitle />
      <Panel
        title={t(`The results of AI`)}
        actions={
          <span class="text-text-level03">
            {t('Total')}: <span class="text-lg font-medium text-text-level01">{sumAIResults().total}</span>
          </span>
        }>
        <div class="grid grid-cols-2 gap-5 px-5 py-6 sm:grid-cols-3 lg:grid-cols-5">
          <For each={sumAIResults().groupsByTypes}>
            {(item) => (
              <div class="col-span-1 flex flex-col items-center justify-center gap-2">
                <div class="text-lg font-medium">{item.count}</div>
                <div class="text-center text-sm text-text-level02">{item.label}</div>
              </div>
            )}
          </For>
        </div>
      </Panel>
      <Panel
        title={t('Default vendor')}
        actions={
          <div class="flex gap-2">
            <LinkButton href="vendor" noScroll size="sm">
              <IconEdit />
              <span class="hidden lg:block"> {t('Edit')}</span>
            </LinkButton>
            <LinkButton href="vendor-overrides" noScroll size="sm">
              <IconPlus />
              <span class="hidden lg:block">{t('Add new overrides')}</span>
            </LinkButton>
          </div>
        }>
        <div class="flex flex-col gap-5 px-5 py-2.5">
          <div>
            <Show when={!runBook.loading} fallback={<Skeleton class="h-6" />}>
              <Show when={runBook()?.defaultVendor} fallback={<div class="break-words font-medium">{t('Not assigned')}</div>}>
                <A href={`/maintenance/vendors/${runBook()?.defaultVendor?.id}`} class="hover:underline">
                  {runBook()?.defaultVendor?.name}
                </A>
              </Show>
            </Show>
          </div>
          <Show when={runBook()?.vendorOverwrites && runBook()?.vendorOverwrites?.length}>
            <ExpandableSection class="px-0" defaultOpen={true} btnClass="mb-0" title={t('Overrides')}>
              <div>
                <For each={runBook()?.vendorOverwrites}>
                  {(item) => (
                    <div class="mt-2.5 flex items-center justify-between rounded-lg bg-input p-3">
                      <div class="flex items-center gap-5">
                        <div class="text-sm text-text-level01">
                          <span>{t(item.portfolioId ? 'Portfolio' : 'Property')}: </span>
                          <span>{item.portfolioId ? item.portfolio?.name : item.property?.displayName}</span>
                        </div>
                        <div class="text-sm text-text-level02">
                          <span>{t('Vendor')}: </span>
                          <span>{item.vendor?.name}</span>
                        </div>
                      </div>
                      <A href={`vendor-overrides/${item.overwriteId}/delete`}>
                        <IconTrash class="size-4 cursor-pointer text-title-gray hover:text-primary" />
                      </A>
                    </div>
                  )}
                </For>
              </div>
            </ExpandableSection>
          </Show>
        </div>
      </Panel>

      <Panel
        title={t('Default property manager')}
        actions={
          <div class="flex gap-2">
            <LinkButton href="property-manager" noScroll size="sm">
              <IconEdit />
              <span class="hidden lg:block">{t('Edit')}</span>
            </LinkButton>
            <LinkButton href="property-manager-overrides" noScroll size="sm">
              <IconPlus />
              <span class="hidden lg:block">{t('Add new overrides')}</span>
            </LinkButton>
          </div>
        }>
        <div class="flex flex-col gap-3 px-5 py-2.5">
          <div>
            <Show when={!runBook.loading} fallback={<Skeleton class="h-6" />}>
              <Show when={runBook()?.defaultPropertyManager} fallback={<div class="break-words font-medium">{t('Not assigned')}</div>}>
                {runBook()?.defaultPropertyManager?.name}
              </Show>
            </Show>
          </div>
          <Show when={runBook()?.propertyManagerOverwrites && runBook()?.propertyManagerOverwrites?.length}>
            <ExpandableSection class="px-0" defaultOpen={true} btnClass="mb-0" title={t('Overrides')}>
              <div>
                <For each={runBook()?.propertyManagerOverwrites}>
                  {(item) => (
                    <div class="mt-2.5 flex items-center justify-between rounded-lg bg-input p-3">
                      <div class="flex items-center gap-5">
                        <div class="text-sm text-text-level01">
                          <span>{t(item.portfolioId ? 'Portfolio' : 'Property')}: </span>
                          <span>{item.portfolioId ? item.portfolio?.name : item.property?.displayName}</span>
                        </div>

                        <div class="text-sm text-text-level02">
                          <span>{t('Property manager')}: </span>
                          <span>{item.propertyManager?.name}</span>
                        </div>
                      </div>

                      <A href={`property-manager-overrides/${item.overwriteId}/delete`}>
                        <IconTrash class="size-4 cursor-pointer text-title-gray hover:text-primary" />
                      </A>
                    </div>
                  )}
                </For>
              </div>
            </ExpandableSection>
          </Show>
        </div>
      </Panel>

      <Panel title={t('Description')}>
        <div class="flex flex-col px-3 py-6 lg:px-5">
          <div class="rounded-lg bg-light-gray p-3 lg:p-5">
            <Show
              when={runBook.loading}
              fallback={<div class="break-words font-medium normal-case text-title-gray">{runBook()?.description}</div>}>
              <Skeleton />
              <Skeleton class="mt-1 w-2/3" />
            </Show>
          </div>
        </div>
      </Panel>
    </div>
  );
};
