import { useParams } from '@solidjs/router';
import { createMemo, For, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBills } from '~/contexts/local';
import { DeleteBillPaymentMethod } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { upperFirst } from '~/utils/tool';
import type { Component } from 'solid-js';

export interface Payment {
  payDate?: string;
  memo?: string;
  vendor?: string;
  paymentMethod?: string;
  paymentAmount?: string;
  status?: string;
  transactionId?: string;
}
interface PaymentTableProps {
  data: Payment[];
}
export const PaymentTable: Component<PaymentTableProps> = (props) => {
  const { t } = useLocalization();
  const { billId } = useParams();

  const { deleteBillItem } = useBills();

  const onDeleteBillItem = async (id: string, transactionId: string) => {
    await deleteBillItem(id, transactionId, DeleteBillPaymentMethod.AdjustTransaction);
    toast.success(t('{name} has been deleted successfully', { name: 'Payment' }));
  };

  const columns = createMemo(() => [
    {
      title: t('Pay date'),
      key: 'payDate',
      class: 'w-2/12',
    },
    {
      title: t('Status'),
      key: 'status',
      class: 'w-2/12',
    },
    {
      title: t('Memo'),
      key: 'memo',
      class: 'w-2/12',
    },
    {
      title: t('Vendor'),
      key: 'vendor',
      class: 'w-2/12',
    },
    {
      title: t('Payment method'),
      key: 'paymentMethod',
      class: 'w-2/12',
    },
    {
      title: t('Payment amount'),
      key: 'paymentAmount',
      class: 'w-2/12 text-right text-base',
    },
  ]);

  const statusCol = (status: `${MagicDoor.Api.TransactionStatus}`) => {
    const colors = {
      pending: 'bg-[#FFF4D7] text-light-warning',
      failed: 'bg-danger-light text-light-danger',
      success: 'bg-green-level01 text-light-green',
      cancelled: 'text-text-level04 bg-gray-level02',
      returned: 'text-text-level04 bg-gray-level02',
    };
    return <div class={cn('w-18 rounded-xl py-1 text-center text-xs', (colors as any)[status])}>{t(upperFirst(status))}</div>;
  };

  return (
    <div class="overflow-hidden border-t">
      <div class="flex items-center gap-1 px-3 pb-3  pt-4">
        <For each={columns()}>{(col) => <div class={cn(col.class, ' bg-white text-xs uppercase text-text-level02')}>{col.title}</div>}</For>
      </div>
      <div class="flex flex-col gap-4">
        <For each={props.data}>
          {(row) => (
            <div class="flex h-18 w-full items-center rounded-lg border border-input-border bg-input px-3">
              <For each={columns()}>
                {(col) => (
                  <Show
                    when={col.key === 'status'}
                    fallback={<div class={cn('text-xs text-text-level01', col.class)}>{row[col.key as keyof typeof row]}</div>}>
                    <div class={col.class}>{statusCol(row[col.key as keyof typeof row] as `${MagicDoor.Api.TransactionStatus}`)}</div>
                  </Show>
                )}
              </For>
              <Button
                onClick={() => {
                  onDeleteBillItem(billId, row.transactionId || '');
                }}
                variant="text"
                color="warning">
                {t('Delete')}
              </Button>
            </div>
          )}
        </For>
      </div>
    </div>
  );
};
