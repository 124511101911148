import { createSignal, Show } from 'solid-js';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { PdfPresenter } from '~/pdfsigner/presenters/PdfPresenter';
import { AddPdfUseCase } from '~/pdfsigner/usecases/addPdfUseCase';
import { cn } from '~/utils/classnames';
import { PdfDocument } from './PdfDocument';
import { LeaseTemplateToolbarHeader } from './header/LeaseTemplateToolbarHeader';
import type { JSX } from 'solid-js';

const DocumentUpload = (): JSX.Element => {
  let pdfDocumentRef!: any;

  const { execute: addPdf } = useUseCase(AddPdfUseCase);
  const { model: presentablePdf } = usePresenter(PdfPresenter);

  const [pdfSize, setPdfSize] = createSignal<string>('w-full');

  const handleDrop = async (event: DragEvent) => {
    event.preventDefault();
    await addPdf(event.dataTransfer?.files);
  };

  const preventDefaultBehavior = (event: DragEvent) => {
    event.preventDefault();
  };

  const isPdfLoaded = () => {
    const pdf = presentablePdf();
    return pdf !== undefined && pdf?.length > 0;
  };

  const onChangePdfSize = (val: any) => {
    setPdfSize(val);
    pdfDocumentRef.handleResize();
  };

  return (
    <div class="flex h-full min-w-0 grow flex-col rounded-lg bg-[#E6E6E6]">
      <LeaseTemplateToolbarHeader pdfSize={pdfSize()} onChangePdfSize={onChangePdfSize} />
      <div
        class="thinscroll h-[calc(100%-60px)] max-h-full overflow-hidden overflow-y-auto p-1"
        onDrop={handleDrop}
        onDragOver={preventDefaultBehavior}
        onDragLeave={preventDefaultBehavior}>
        <Show
          when={isPdfLoaded()}
          fallback={<div class="flex h-full items-center justify-center bg-gray-100">Drag and drop a PDF file here</div>}>
          <div class={cn('mx-auto', pdfSize())}>
            <PdfDocument pages={presentablePdf() || []} ref={pdfDocumentRef} />
          </div>
        </Show>
      </div>
    </div>
  );
};

export default DocumentUpload;
