import IconMoveHandler from '~/assets/images/lease-template/moveHandler.svg';

export interface MoveHandlerProps {
  onMouseDown: (e: MouseEvent) => void;
}

export const MoveHandler = (props: MoveHandlerProps) => {
  return (
    <div class="absolute inset-0 cursor-grab" onMouseDown={(e) => props.onMouseDown(e)}>
      <img src={IconMoveHandler} draggable={false} class="size-full select-none" />
    </div>
  );
};
