import { useParams } from '@solidjs/router';
import { createMemo, Show } from 'solid-js';
import { unwrap } from 'solid-js/store';
import IconAdd from '~/assets/images/bill/add.svg';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, FormItem, FormList } from '~/components/common/BetterForm';
import { FormListRemoverWrapper, SimpleRemover } from '~/components/common/BetterForm/FormListRemover';
import { LinkButton } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { BillFileListView } from '~/components/file-attachments/BillFileListView';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { LabeledVendorSearch } from '~/components/vendors';
import { useLocalization } from '~/contexts/global';
import { AttachmentsProvider } from '~/contexts/local';
import { LevelSearch } from '~/swagger/Api';
import { currency } from '~/utils/number';
import { PaymentTable } from './PaymentTable';
import type { Payment } from './PaymentTable';
import type { Component } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm';
import type { Variants } from '~/components/search-select/Context';
interface BillDetailsProps {
  form: BetterForm.ContextValue;
  bill?: MagicDoor.Api.HydratedBillDto;
  payment: Payment[];
}

const BillDetailsView: Component<BillDetailsProps> = (props) => {
  const { t } = useLocalization();
  const { billId } = useParams();

  const billTotal = createMemo(() =>
    props.form.formStore.lineItems?.reduce((previous: any, current: any) => previous + Number(current.amount || 0), 0)
  );
  const paymentTotal = createMemo(() =>
    props.form.formStore.lineItems?.reduce((previous: any, current: any) => previous + Number(current.paid || 0), 0)
  );

  const onLeveledSearchSelectChange = (_: unknown, variants: Variants) => {
    props.form.setFormStore({
      unitId: variants.leveledIds.at(0)?.unitId,
      leaseId: variants.leveledIds.at(0)?.leaseId,
      propertyId: variants.leveledIds.at(0)?.propertyId,
    });
  };

  const initialSelected = createMemo(() => {
    if (props.bill?.leaseId)
      return [
        unwrap({
          __type: LevelSearch.Lease,
          id: props.bill.leaseId,
          ...props.bill.lease,
          property: props.bill?.property,
          unit: props.bill?.unit,
        }),
      ];
    if (props.bill?.unitId)
      return [unwrap({ __type: LevelSearch.Unit, id: props.bill.unitId, ...props.bill.unit, property: props.bill?.property })];
    return [];
  });

  return (
    <div class="mx-auto my-8 w-10/12 min-w-[730px]">
      <Form defaultForm={props.form}>
        <Section title={`${t('Vendor')}/${t('Payee')}`} border>
          <FormItem
            class="w-full"
            label={t(`Vendor`)}
            formFieldName="vendorId"
            component={LabeledVendorSearch}
            rules={[{ required: true, message: t(`Please select vendor`) }]}
            placeholder={t('Please select vendor')}
            valuePropName="defaultSelectedIds"
          />
        </Section>
        <Section title={t('Lease/Unit')}>
          <div class="flex flex-col gap-5">
            <FormItem
              rules={[
                {
                  validator() {
                    return props.form.formStore.unitId || props.form.formStore.leaseId;
                  },
                  message: t(`Please select property, unit or lease`),
                },
              ]}
              class="w-full"
              label={t('Unit or lease')}
              component={LabeledLeveledSearchSelect}
              enabledTypes={[LevelSearch.Unit, LevelSearch.Lease]}
              onChange={onLeveledSearchSelectChange}
              initialSelected={initialSelected()}
            />
            <div class="grid grid-cols-2 gap-5">
              <FormItem
                rules={[{ required: true, message: t(`Please enter the {name}`, { name: t('Bill date') }) }]}
                required
                class="col-start-1"
                formFieldName="billDate"
                label={t('Bill date')}
                component={DueDateInputField}
              />
              <FormItem
                rules={[{ required: true, message: t(`Please enter the {name}`, { name: t('Due date') }) }]}
                required
                class="col-span-1"
                formFieldName="dueDate"
                label={t('Due date')}
                component={DueDateInputField}
              />
            </div>
          </div>
        </Section>
        <Section title={t('Bill detail')}>
          <div class="w-full">
            <div class="flex items-center justify-between border-b bg-[rgba(161,_38,_236,_0.15)] text-xs ">
              <div class="text-lef w-3/12 px-6 py-2.5 text-[rgba(161,_38,_236,_0.80)]">{t('Chart of account')}</div>
              <div class="w-3/12 whitespace-nowrap px-6 py-2.5 text-left text-[rgba(161,_38,_236,_0.80)]">{t('Description')}</div>
              <div class="w-2/12 whitespace-nowrap px-6 py-2.5 text-right text-[rgba(161,_38,_236,_0.80)]">{t('Unpaid balance')}</div>
              <div class="w-3/12 whitespace-nowrap px-6 py-2.5 text-right text-[rgba(161,_38,_236,_0.80)]">{t('Amount')}</div>
              <div class="w-1/12 min-w-fit whitespace-nowrap py-2.5" />
            </div>
            <FormList
              formFieldName={'lineItems'}
              renderItemsGroup={({ index, remove }) => {
                return (
                  <FormListRemoverWrapper customRemover={SimpleRemover} index={index} class="mb-0 py-3" remove={remove}>
                    <div class="flex items-start justify-between text-xs">
                      <div class="w-3/12 px-6">
                        <FormItem
                          placeholder={t('Please select chart of account')}
                          formFieldName={['chartOfAccountId']}
                          component={LabeledChartOfAccountSelect}
                          types={['expense'] as MagicDoor.Api.ChartOfAccountType[]}
                          rules={[{ message: t(`Please select chart of account`), required: true }]}
                        />
                      </div>
                      <div class="w-3/12 px-6">
                        <FormItem
                          placeholder={t('Please input description')}
                          formFieldName={['description']}
                          component={LabeledTextInput}
                        />
                      </div>
                      <div class="mt-2.5 w-2/12 px-6 text-right text-sm font-medium text-essential-colour">
                        {currency((props.bill?.lineItems[index()]?.amount || 0) - (props.bill?.lineItems[index()]?.paid || 0))}
                      </div>
                      <div class="w-3/12 px-6">
                        <FormItem
                          inputContainerClass="text-right"
                          inputClass="text-right"
                          prepend="$"
                          placeholder={t('Please input the amount')}
                          formFieldName={['amount']}
                          component={LabeledNumberInput}
                          rules={[{ message: t(`Please input the amount`), required: true }]}
                        />
                      </div>
                      <div class="inline-flex w-1/12 min-w-fit justify-end">
                        <Show when={index() !== 0}>
                          <SimpleRemover index={index} remove={remove} class="ml-0 mt-0" />
                        </Show>
                      </div>
                    </div>
                  </FormListRemoverWrapper>
                );
              }}
              autoCombineFieldName={false}
            />
          </div>
          <div class="mt-3 flex items-center justify-between text-sm uppercase text-text-level02">
            <LinkButton
              href=""
              variant="outlined"
              rounded="full"
              class="ml-6 border-0 !bg-transparent p-0 text-xs text-link hover:text-light-link [&_span]:gap-1 [&_span]:whitespace-nowrap"
              onClick={() => {
                props.form.setFormStore('lineItems', (prev: any) => {
                  return [...prev, {}];
                });
              }}>
              <img src={IconAdd} />
              <span>{t('Add line item')}</span>
            </LinkButton>

            <span>
              {t('Bill total')}：<span class="text-base text-text-level01">{currency(billTotal())}</span>
            </span>
          </div>
        </Section>
        <Show when={props.payment && props.payment.length}>
          <Section title={t('Payment')}>
            <PaymentTable data={props.payment} />
            <Show when={props.payment.length > 0}>
              <div class="mt-3 text-right text-sm uppercase text-text-level02">
                {t('Payment total')}: <span class="text-base text-text-level01">{currency(paymentTotal())}</span>
              </div>
            </Show>
          </Section>
        </Show>
        <AttachmentsProvider>
          <BillFileListView billId={props.bill?.id ?? billId} />
        </AttachmentsProvider>
      </Form>
    </div>
  );
};

export default BillDetailsView;
