import { createMemo, createEffect, createSignal } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { Select } from '~/components/ui/Select';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { statesAndProvincesList } from '~/utils/constant';
import type { Component } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';

export interface RentalApplicationRegions {
  name: string;
  code: string;
  regions: {
    name: string;
    code: string;
  }[];
}

type FormFieldType = 'dropdown' | 'input';

export interface InputProps extends LabeledGroupProps {
  value: MagicDoor.Api.AddressDto;
  onInput?: (value: MagicDoor.Api.AddressDto) => void;
  required?: boolean;
  onClick?: (isMouseDown: boolean) => void;
  onChange?: (value: MagicDoor.Api.AddressDto, name: string) => void;
  regions?: RentalApplicationRegions[];
  stateAndCountryType: FormFieldType;
  disabled?: boolean;
}

interface InputForLabelGroupProps {
  value: string | undefined | null;
  onInput: (value: string) => void;
  hasError: boolean;
  id: string | undefined;
  name: string;
  placeholder: string | undefined;
  type: string | undefined;
  onClick?: (isMouseDown: boolean) => void;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

const InputForLabelGroup: Component<InputForLabelGroupProps> = (props: InputForLabelGroupProps) => {
  const handleInput = (value: string) => {
    props.onChange && props.onChange(value);
  };

  const isInvalid = createMemo(() => !!props.hasError);
  return (
    <input
      id={props.id}
      class="w-full rounded-md border bg-inputbox-bg px-3 py-2 text-sm text-black  placeholder:text-auxiliary-text focus:outline-none focus:ring-1 focus:ring-essential-colour "
      classList={{
        'border-red-300': isInvalid(),
        'ring-0': isInvalid(),
      }}
      name={props.name}
      value={props.value || ''}
      onInput={(e) => props.onInput(e.target.value)}
      onChange={(e) => {
        handleInput(e.target.value);
      }}
      type={props.type || 'text'}
      placeholder={props.placeholder}
      onMouseDown={() => props.onClick && props.onClick(true)}
      onMouseUp={() => props.onClick && props.onClick(false)}
      disabled={props?.disabled}
    />
  );
};

interface SelectionValue {
  value: string;
  label: string;
}

const LabeledAddressInput: Component<InputProps> = (props) => {
  const safeAddress = createMemo(() => props.value || {});
  const { t } = useLocalization();

  const [countryPlaceholder, setCountryPlaceholder] = createSignal<string>('loading');
  const [statePlaceholder, setStatePlaceholder] = createSignal<string>('loading');

  const getCountry = (regions?: RentalApplicationRegions[]) => {
    if (regions && regions.length === 0) {
      setCountryPlaceholder(t('Loading'));
      return [];
    }
    setCountryPlaceholder(t('Country'));
    let regionsData: RentalApplicationRegions[] = [];
    if (!regions) {
      regionsData = statesAndProvincesList;
    }
    if (regions) {
      regionsData = regions;
    }
    if (safeAddress().country !== regionsData[0].code && props.required) {
      props.onInput && handleChange('country', regionsData[0].code);
      props.onChange && handleInput('country', regionsData[0].code);
    }

    return regionsData.map((region) => {
      return {
        label: region.name,
        value: region.code,
      };
    });
  };

  const getState = (selectedCountry: string, regions?: RentalApplicationRegions[]) => {
    if (selectedCountry === '' || selectedCountry === '-1') {
      setStatePlaceholder(t('Please select a country first'));
      return [];
    }
    setStatePlaceholder(t('State'));
    let regionsData: RentalApplicationRegions[] = [];
    if (!regions) {
      regionsData = statesAndProvincesList;
    }
    if (regions && regions.length > 0) {
      regionsData = regions;
    }
    const states: SelectionValue[] = [];
    const targetCountry = selectedCountry || regionsData[0].code;
    const stateArr = regionsData.filter((region) => region.code === targetCountry)[0]?.regions ?? [];
    if (stateArr.length === 0) {
      return [];
    }
    safeAddress().state = stateArr[0].code;
    stateArr.forEach((state) => {
      states.push({
        value: state.code,
        label: state.name,
      });
    });
    if (!safeAddress().state && safeAddress().state !== states[0].value && props.required) {
      props.onInput && handleChange('state', states[0].value);
      props.onChange && handleInput('state', states[0].value);
    }
    return states;
  };

  const handleChange = (name: string, value: string | undefined | number) => {
    const updatedValue = { ...safeAddress(), [name]: value };
    props.onInput && props.onInput(updatedValue);
  };

  const handleInput = (name: string, value: string | undefined | number) => {
    const updatedValue = { ...safeAddress(), [name]: value };
    props.onChange && props.onChange(updatedValue, name);
  };

  const errors = createMemo(() => {
    let _errors = {} as MagicDoor.Api.AddressDto;
    if (props.error) {
      try {
        _errors = JSON.parse(props.error);
      } catch (e) {
        _errors = { streetAddress1: props.error };
      }
    }
    return _errors;
  });

  createEffect(() => {
    // FIXME 临时解决方案，只对部分 required 的字段进行默认值填充
    if (!safeAddress().country && props.required) {
      handleChange('country', 'USA');
    }
  });

  return (
    <>
      <LabeledGroup
        required={props.required}
        label={t('Street address')}
        labelJSX={props.labelJSX}
        class={props.class}
        error={errors().streetAddress1 || errors().streetAddress2}>
        <div class="flex flex-col gap-2">
          <InputForLabelGroup
            id={props.id}
            hasError={!!errors()?.streetAddress1}
            value={safeAddress().streetAddress1}
            name="streetAddress1"
            onInput={(value: string | undefined) => handleChange('streetAddress1', value)}
            onChange={(value: string | undefined) => handleInput('streetAddress1', value)}
            type={props.type}
            onClick={props.onClick}
            placeholder={t('Street address')}
            disabled={props?.disabled}
          />
          <InputForLabelGroup
            id={props.id}
            name="streetAddress2"
            hasError={!!errors()?.streetAddress2}
            value={safeAddress().streetAddress2}
            onInput={(value: string | undefined) => handleChange('streetAddress2', value)}
            onChange={(value: string | undefined) => handleInput('streetAddress2', value)}
            type={props.type}
            placeholder={t('Alt')}
            onClick={props.onClick}
            disabled={props?.disabled}
          />
        </div>
      </LabeledGroup>
      <div class={cn('flex gap-7', props.class)}>
        <div class="w-1/3">
          <LabeledGroup label={t('City')} error={errors().city} required={props.required}>
            <InputForLabelGroup
              id={props.id}
              name="city"
              hasError={!!errors()?.city}
              value={safeAddress().city}
              onInput={(value: string | undefined) => handleChange('city', value)}
              onChange={(value: string | undefined) => handleInput('city', value)}
              type={props.type || 'text'}
              placeholder={t('City')}
              onClick={props.onClick}
              disabled={props?.disabled}
            />
          </LabeledGroup>
        </div>
        <div class="w-1/3">
          <LabeledGroup label={t('State')} error={errors().state} required={props.required}>
            <Select
              filterable
              freeInput={props.stateAndCountryType !== 'dropdown'}
              value={safeAddress().state ?? undefined}
              options={getState(safeAddress().country ?? '', props.regions)}
              placeholder={statePlaceholder()}
              onChange={(value: string | undefined) => (props.onChange ? handleInput('state', value) : handleChange('state', value))}
              disabled={props?.disabled}
            />
          </LabeledGroup>
        </div>
        <div class="w-1/3">
          <LabeledGroup label={t('Zip code')} error={errors().zipCode} required={props.required}>
            <InputForLabelGroup
              id={props.id}
              name="zipCode"
              value={safeAddress().zipCode}
              hasError={!!errors()?.zipCode}
              onInput={(value: string | undefined) => handleChange('zipCode', value)}
              onChange={(value: string | undefined) => handleInput('zipCode', value)}
              type={props.type || 'text'}
              placeholder={t('Zip code')}
              onClick={props.onClick}
              disabled={props?.disabled}
            />
          </LabeledGroup>
        </div>
      </div>
      <LabeledGroup label={t('Country')} class={props.class} error={errors().country} required={props.required}>
        <Select
          filterable
          freeInput={props.stateAndCountryType !== 'dropdown'}
          value={safeAddress().country ?? undefined}
          options={getCountry(props.regions)}
          placeholder={countryPlaceholder()}
          onChange={(value: string | undefined) => (props.onChange ? handleInput('country', value) : handleChange('country', value))}
          disabled={props?.disabled}
        />
      </LabeledGroup>
    </>
  );
};

export default LabeledAddressInput;
