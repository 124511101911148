import { createEffect, createSignal, For } from 'solid-js';
import { FormItem, useFormContext } from '~/components/common/BetterForm';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { IconCirclePlus, IconExclamationMark } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useAddOrEditMaintenanceModalContext } from '~/pages/maintenance-request/add-maintenance-request/Context';

type LineItem = {
  key: string;
  value: string;
};

export const MagicTags = () => {
  let hasInitialized = false;

  const [lineItems, setLineItems] = createSignal<LineItem[]>([{ key: '', value: '' }]);
  const { t } = useLocalization();
  const { store } = useAddOrEditMaintenanceModalContext();
  const form = useFormContext();

  const addLineItem = () => {
    setLineItems([...lineItems(), { key: '', value: '' }]);
  };

  createEffect(() => {
    if (!hasInitialized && store.maintenanceInfo?.unit.magicTags && Object.keys(store.maintenanceInfo?.unit.magicTags).length > 0) {
      hasInitialized = true;
      const result: Array<{ key: string; value: string }> = [];
      const magicTags = store.maintenanceInfo?.unit.magicTags ?? {};
      for (const [key, value] of Object.entries(magicTags)) {
        result.push({ key, value });
        form.setFieldsValue({ [`key_${result.length - 1}`]: key, [`value_${result.length - 1}`]: value });
      }

      setLineItems([...result, ...lineItems()]);
    }
  });

  return (
    <div class="flex flex-col gap-8">
      <div class="grid grid-cols-2 gap-x-8 gap-y-2">
        <For each={lineItems()}>
          {(item, index) => (
            <>
              <FormItem
                class="col-span-full lg:col-span-1"
                label={t(`Magic tags`) + ` ${index() + 1}`}
                formFieldName={`key_${index()}`}
                value={item.key}
                component={LabeledTextInput}
                placeholder={t('Please enter')}
              />
              <FormItem
                class="col-span-full lg:col-span-1"
                label={t(`Value`) + ` ${index() + 1}`}
                formFieldName={`value_${index()}`}
                value={item.value}
                component={LabeledTextInput}
                placeholder={t('Please enter')}
              />
            </>
          )}
        </For>
        <p class="col-span-full flex items-center gap-x-1">
          <IconExclamationMark fill="none" class="min-w-5" />
          <span class="text-text-level02">{t('The Key value pairs will be added to Unit Information as well')}</span>
        </p>
        <div class="flex cursor-pointer items-center gap-x-1 pt-2 text-sm text-link" onClick={addLineItem}>
          <IconCirclePlus class="size-5" />
          {t('Add line item')}
        </div>
      </div>
      <FormItem
        class="w-full"
        rows={3}
        label={t(`Internal description`)}
        formFieldName="internalDescription"
        component={LabeledTextArea}
        placeholder={t('You can add internal memos here')}
      />
    </div>
  );
};
