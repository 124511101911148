import { createMemo, createSignal, For, Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import DeleteIcon from '~/assets/images/common/delete.svg?component-solid';
import IconExit from '~/assets/images/custom-layout/exit.svg?component-solid';
import IconLayout from '~/assets/images/custom-layout/layout.svg?component-solid';
import IconModule from '~/assets/images/custom-layout/module.svg?component-solid';
import IconSave from '~/assets/images/custom-layout/save.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { useCustomLayout, useLocalization } from '~/contexts/global';
import { ChatsListProvider, LeasesListProvider, MaintenanceRequestsProvider } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { CustomModuleModal } from './components/CustomModuleModal';
import { getHomeAllComponent } from './constant';
import type { HomeLayout, ModuleSizeProps } from './type';
import type { Component } from 'solid-js';

const ModuleSize: Component<ModuleSizeProps> = (props) => {
  const checked = createMemo(() => props.current === props.value);
  return (
    <div class="flex rounded-md border border-essential-colour px-1">
      <Checkbox checked={checked()} htmlType="radio" class="mx-2" value={props.value} onInput={() => props.onChange(props.value)} />
      <div class="border-l border-[#CCC5E6] py-1  pl-1 text-center">
        <div class="relative h-5 w-8 overflow-hidden rounded-sm bg-[#E0DBF4]">
          <div class={cn('absolute right-0 h-full  bg-essential-colour', `w-${props.size}`)} />
        </div>
        <span class="text-xxs text-title-gray">{props.size}</span>
      </div>
    </div>
  );
};

const DashboardPage = () => {
  const { t } = useLocalization();

  const { setEnable, enable, homeLayout, setHomeLayout, getLocalLayout } = useCustomLayout();
  const [customModuleModalShow, setCustomModuleModalShow] = createSignal(false);

  const [currentModule, setCurrentModule] = createSignal<HomeLayout>();
  const [ending, setEnding] = createSignal<HomeLayout>();

  const disabledRemoveModule = createMemo<boolean>(() => homeLayout().length <= 1);

  const selectModule = (event: MouseEvent, current: HomeLayout) => {
    if (!enable()) return;
    event.preventDefault();
    if (currentModule()?.key === current.key) {
      setCurrentModule(undefined);
    } else {
      setCurrentModule(current);
    }
  };

  const onChangeModuleSize = (val: string) => {
    setCurrentModule((prev) => ({
      ...(prev as HomeLayout),
      width: val,
    }));
    setHomeLayout((prev) =>
      prev.map((item) => ({
        ...item,
        width: item.key === currentModule()?.key ? val : item.width,
      }))
    );
  };

  const removeModule = () => {
    setHomeLayout((prev) => {
      const index = prev.findIndex((item) => item.key === currentModule()?.key);
      return prev.toSpliced(index, 1);
    });
    setCurrentModule(undefined);
  };
  const saveLayout = () => {
    localStorage.setItem('home-layout', JSON.stringify(homeLayout()));
    setEnable(false);
    setCurrentModule(undefined);
  };

  const onDragStart = (event: DragEvent, item: HomeLayout) => {
    event.dataTransfer && (event.dataTransfer.effectAllowed = 'move');
    const img = new Image();
    img.src = '';
    event.dataTransfer?.setDragImage(img, 0, 0);
    setCurrentModule(item);
  };
  const onDragOver = (event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer && (event.dataTransfer.dropEffect = 'move');
  };
  const onDragEnter = (item: HomeLayout) => {
    setEnding(item);
  };
  const onDragEnd = () => {
    if (ending()?.key === currentModule()?.key) return;
    const newItems = [...homeLayout()];
    const src = newItems.indexOf(currentModule() as HomeLayout);
    const dst = newItems.indexOf(ending() as HomeLayout);

    const srcWidth = newItems[src].width;
    newItems[src].width = newItems[dst].width;
    newItems[dst].width = srcWidth;

    newItems.splice(src, 1, ...newItems.splice(dst, 1, newItems[src]));
    setHomeLayout(newItems);
    setTimeout(() => {
      setEnding(undefined);
    });
  };

  return (
    <LeasesListProvider>
      <MaintenanceRequestsProvider>
        <ChatsListProvider>
          <div class="w-full">
            <div class="grid grid-cols-12 gap-5 px-8 py-6 text-left">
              <For each={homeLayout()}>
                {(item) => (
                  <div
                    data-key={item.key}
                    class={cn('relative col-span-full cursor-pointer select-none rounded-lg', item.width)}
                    onClick={(event) => selectModule(event, item)}
                    draggable={enable()}
                    onDragStart={(event) => onDragStart(event, item)}
                    onDragOver={(event) => onDragOver(event)}
                    onDragEnter={() => onDragEnter(item)}
                    onDragEnd={onDragEnd}>
                    <Dynamic component={item.component} />

                    <Show when={enable()}>
                      <div
                        class="absolute inset-0 z-10 rounded-lg"
                        classList={{
                          'border border-essential-colour bg-essential-colour/60': currentModule()?.key === item.key,
                          'border border-dashed border-essential-colour': ending()?.key === item.key && currentModule()?.key !== item.key,
                        }}
                      />
                    </Show>
                  </div>
                )}
              </For>
            </div>
            <Show when={enable()}>
              <div class="h-[150px]" />
              <div class="fixed bottom-0 z-40 w-full border-t bg-white px-8 md:w-layout-right">
                <div class="flex items-center justify-between gap-2 border-b py-4">
                  <div class="flex items-center">
                    <IconLayout />
                    <span class="text-base font-medium text-title-gray">{t('Edit the dashboard layout')}</span>
                  </div>

                  <Show when={disabledRemoveModule()}>
                    <span class="text-xs text-warning">{t('Keep at least one module')}</span>
                  </Show>
                </div>
                <div class="flex items-center justify-between py-4">
                  <div class="flex items-center gap-2">
                    <Show when={currentModule()}>
                      <span class="text-sm text-text-level03">{t('Select module size')}：</span>
                      <div class="flex items-center gap-2">
                        <ModuleSize size="1/3" value="xl:col-span-4" onChange={onChangeModuleSize} current={currentModule()?.width} />
                        <ModuleSize size="2/3" value="xl:col-span-8" onChange={onChangeModuleSize} current={currentModule()?.width} />
                        <ModuleSize size="1/2" value="xl:col-span-6" onChange={onChangeModuleSize} current={currentModule()?.width} />
                        <ModuleSize size="full" value="xl:col-span-full" onChange={onChangeModuleSize} current={currentModule()?.width} />
                      </div>
                    </Show>
                  </div>
                  <div class="flex items-center gap-2">
                    <Show when={currentModule()}>
                      <Button
                        disabled={disabledRemoveModule()}
                        variant="outlined"
                        color="warning"
                        class="capitalize"
                        onClick={removeModule}>
                        <DeleteIcon class="text-danger" />
                        {t('Remove module')}
                      </Button>
                    </Show>

                    <Button variant="outlined" class="capitalize" onClick={() => setCustomModuleModalShow(true)}>
                      <IconModule />
                      {t('Custom module')}
                    </Button>
                    <Button
                      variant="outlined"
                      class="capitalize"
                      onClick={() => {
                        const localLayout = getLocalLayout();
                        setHomeLayout(localLayout.length ? localLayout : getHomeAllComponent());
                        setEnable(false);
                        setCurrentModule(undefined);
                      }}>
                      <IconExit />
                      {t('Cancel')}
                    </Button>
                    <Button class="capitalize" onClick={saveLayout}>
                      <IconSave />
                      {t('Save')}
                    </Button>
                  </div>
                </div>
              </div>
              <CustomModuleModal visible={[customModuleModalShow, setCustomModuleModalShow]} />
            </Show>
          </div>
        </ChatsListProvider>
      </MaintenanceRequestsProvider>
    </LeasesListProvider>
  );
};

export default DashboardPage;
