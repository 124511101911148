import { Route, Routes, useParams } from '@solidjs/router';
import { NotesTableList } from '~/components/notes';
import { NoteModal } from '~/components/notes/NoteModal';

export const PropertyNotes = () => {
  const params = useParams<{ propertyId: string }>();
  return (
    <>
      <Routes>
        <Route path="/add" element={<NoteModal type="property" id={params.propertyId} action="Add" />} />
        <Route path="/:noteId/edit" element={<NoteModal type="property" id={params.propertyId} action="Edit" />} />
      </Routes>
      <div class="px-8 text-left">
        <NotesTableList type="property" id={params.propertyId} />
      </div>
    </>
  );
};
