import { createMagicDoorContext } from '~/contexts/utils';
import { MaintenanceRequestRepository } from '~/repositories/maintenanceRequestRepository';
import { createLazyResource, createMutation, createTriggerResource } from '~/utils/resource';
import type { MaintenanceRequestFilter } from '~/repositories/maintenanceRequestRepository';

const repo = new MaintenanceRequestRepository();

export type CrossMaintenanceRequestListDto = MagicDoor.Api.MaintenanceRequestListDto & {
  unit?: MagicDoor.Api.UnitDto;
  property?: MagicDoor.Api.PropertyDto;
  propertyManager?: MagicDoor.Api.PropertyManagerDto;
};

export const [MaintenanceRequestsProvider, useMaintenanceRequests] = createMagicDoorContext('MaintenanceRequests', () => {
  const [maintenanceRequests, getMaintenanceRequests, maintenanceRequestsActions] = createTriggerResource(
    async (filter?: MaintenanceRequestFilter) => {
      const res = await repo.getMaintenanceRequests(filter);
      const items: CrossMaintenanceRequestListDto[] = res.items.map((item) => ({
        ...item,
        unit: res?.units?.find((unit) => unit.id === item.unitId),
        property: res?.properties?.find((property) => property.id === item.propertyId),
        propertyManager: res?.propertyManagers?.find((propertyManager) => propertyManager.id === item.assignedPropertyManagerId),
      }));
      return {
        ...res,
        items,
      };
    }
  );
  const [maintenanceRequest, getMaintenanceRequest, maintenanceRequestActions] = createTriggerResource(
    repo.getMaintenanceRequest.bind(repo)
  );
  const [maintenanceRequestCategory, maintenanceRequestCategoryActions] = createLazyResource(repo.getMaintenanceRequestCategory.bind(repo));
  const [maintenanceRequestStats, maintenanceRequestStatsActions] = createLazyResource(repo.getMaintenanceRequestStats.bind(repo));

  const addMaintenanceRequest = createMutation(async (payload: MagicDoor.Api.MaintenanceRequestDto) => {
    const response = await repo.addMaintenanceRequest(payload);
    maintenanceRequestsActions.refetch();
    maintenanceRequestStatsActions.refetch();
    return response;
  });

  const deleteMaintenanceRequest = createMutation(async (id: string) => {
    await repo.deleteMaintenanceRequest(id);
    id === maintenanceRequest()?.id && maintenanceRequestActions.mutate(undefined);
    maintenanceRequestsActions.refetch();
    maintenanceRequestStatsActions.refetch();
  });

  const updateMaintenanceRequest = createMutation(async (id: string, payload: MagicDoor.Api.MaintenanceRequestDto) => {
    await repo.updateMaintenanceRequest(id, payload);
    id === maintenanceRequest()?.id && maintenanceRequestActions.refetch();
    maintenanceRequestsActions.refetch();
    maintenanceRequestStatsActions.refetch();
  });

  const updateMaintenanceRequestStatus = createMutation(async (id: string, status: MagicDoor.Api.MaintenanceRequestStatus) => {
    await repo.updateMaintenanceRequestStatus(id, status);
    id === maintenanceRequest()?.id && maintenanceRequestActions.refetch();
    maintenanceRequestsActions.refetch();
    maintenanceRequestStatsActions.refetch();
  });

  const updateMaintenanceRequestUrgency = createMutation(async (id: string, urgency: MagicDoor.Api.Urgency) => {
    await repo.updateMaintenanceRequestUrgency(id, urgency);
    id === maintenanceRequest()?.id && maintenanceRequestActions.refetch();
    maintenanceRequestsActions.refetch();
    maintenanceRequestStatsActions.refetch();
  });

  const disableMaintenanceRequestAi = createMutation(async () => {
    const maintenanceRequestId = maintenanceRequest()?.id;
    if (!maintenanceRequestId) return;
    await repo.disableMaintenanceRequestAi(maintenanceRequestId);
    maintenanceRequestActions.refetch();
  });

  const convertToWorkOrder = createMutation(async (id: string, payload: MagicDoor.Api.CreateWorkOrderFromMaintenanceRequestRequestDto) => {
    const response = await repo.convertToWorkOrder(id, payload);
    maintenanceRequestActions.refetch();
    return response;
  });

  return {
    maintenanceRequests,
    getMaintenanceRequests,
    maintenanceRequest,
    getMaintenanceRequest,
    get maintenanceRequestStats() {
      maintenanceRequestStatsActions.fetch();
      return maintenanceRequestStats;
    },
    addMaintenanceRequest,
    deleteMaintenanceRequest,
    updateMaintenanceRequest,
    updateMaintenanceRequestStatus,
    updateMaintenanceRequestUrgency,
    get maintenanceRequestCategory() {
      maintenanceRequestCategoryActions.fetch();
      return maintenanceRequestCategory;
    },

    disableMaintenanceRequestAi,

    maintenanceRequestActions,
    convertToWorkOrder,
  };
});
