import { LabeledGroup } from '~/components/common/Inputs';
import { Radio } from '~/components/common/Inputs/Radio';

export const LabeledRadio: typeof Radio = (props) => {
  return (
    <LabeledGroup {...props}>
      <div class="flex h-9 items-center px-4">
        <Radio {...props} />
      </div>
    </LabeledGroup>
  );
};
