import { Select } from '~/components/ui';
import { LabeledGroup } from './LabeledGroup';
import type { LabeledGroupProps } from './LabeledGroup';
import type { Component } from 'solid-js';

export interface SelectProps extends LabeledGroupProps {
  placeholder?: string;
  value?: string | number | undefined;
  onInput?: (e: string | number) => void;
  onClick?: (isMouseDown: boolean) => void;
  validationFunction?: (value: string | number) => string | undefined;
  labelClass?: string;
  suffix?: string;
  inputClass?: string;
  prepend?: string;
  options: any[];
  multiple?: boolean;
  selectClass?: string;
  prefix?: string;
}

export const LabeledSelect: Component<SelectProps> = (props) => {
  const handleChange = (value: string | number) => {
    props.onInput && props.onInput(value);
  };

  return (
    <LabeledGroup {...props} labelClass={props.labelClass}>
      <Select
        prefix={props.prefix}
        class={props.selectClass}
        value={props.value}
        onChange={handleChange}
        options={props.options}
        multiple={props.multiple}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
    </LabeledGroup>
  );
};
export default LabeledSelect;
