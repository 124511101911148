import { useLocation } from '@solidjs/router';
import { createMemo, Show } from 'solid-js';
import IconLayout from '~/assets/images/custom-layout/layout.svg?component-solid';
import Tooltip from '~/components/common/Tooltip';
import { useCustomLayout, useLocalization } from '~/contexts/global';
import { getHomeAllComponent } from '~/pages/dashboard/constant';
export const CustomLayoutButton = () => {
  const { t } = useLocalization();

  const { setEnable, enable, getLocalLayout, setHomeLayout } = useCustomLayout();
  const show = createMemo(() => ['/'].some((item) => item === useLocation().pathname));

  return (
    <Show when={show()}>
      <Tooltip message={t('Instrument panel layout')} align="bottom">
        <div
          onClick={() => {
            const localLayout = getLocalLayout();
            setHomeLayout(localLayout.length ? getLocalLayout() : getHomeAllComponent());
            setEnable(!enable());
          }}
          class="flex size-12 cursor-pointer items-center justify-center rounded-full bg-[#FAFAFA]">
          <IconLayout />
        </div>
      </Tooltip>
    </Show>
  );
};
