import { PortalColorTheme } from './PortalColorTheme';
import { PortalContactInformation } from './PortalContactInformation';
import { PortalSeoInformation } from './PortalSeoInformation';
import { PortalSocialMedia } from './PortalSocialMedia';
import { PortalTranslation } from './PortalTranslation';

export const ListingWebsite = () => {
  return (
    <div class="flex w-full flex-col gap-3">
      <PortalColorTheme />
      <PortalTranslation />
      <PortalContactInformation />
      <PortalSocialMedia />
      <PortalSeoInformation />
    </div>
  );
};
