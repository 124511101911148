import { createMemo } from 'solid-js';
import magicBg from '~/assets/images/units/listing/bg.jpg';
import magicStarPerson from '~/assets/images/units/listing/magic_star_person.png';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import LabeledTextarea from '~/components/common/Inputs/LabeledTextArea';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { OperateTags } from './OperateTags';
import type { Component } from 'solid-js';

type DefaultEditProps = {
  tags: string[] | null | undefined;
  toggle: (type: string) => void;
};
export const DefaultEdit: Component<DefaultEditProps> = (props) => {
  const { t } = useLocalization();
  const form = useFormContext();
  const { unitListingDetail } = useUnitListings();
  const tags = createMemo(() => props.tags ?? []);

  const handleTags = (tags: string[]) => {
    handleFormSet(tags);
  };
  const handleToggle = () => {
    props.toggle('ai');
    form.setFieldsValue({
      tags: unitListingDetail()?.tags,
      description: unitListingDetail()?.description,
    });
  };
  const handleFormSet = (tags: string[] | undefined) => {
    form.setFieldsValue({ tags });
  };
  return (
    <>
      <div class="mb-6">
        <h2 class="mb-2 text-sm uppercase text-label">{t('Features')}</h2>
        <div class="rounded-lg border border-input-border bg-inputbox-bg p-3">
          <OperateTags tags={tags()} onChange={handleTags} />
        </div>
      </div>
      <FormItem
        label={t('Description')}
        formFieldName="description"
        component={LabeledTextarea}
        rows={5}
        placeholder={t('Please enter a listing description')}
      />
      <div
        class="mt-4 flex h-20 w-full items-center justify-between rounded-lg py-4 pl-4 pr-7"
        style={{
          'background-image': `url(${magicBg})`,
          'background-size': 'cover',
          'background-position': 'center',
          'background-repeat': 'no-repeat',
        }}>
        <div class="flex items-center">
          <img src={magicStarPerson} alt="img" class="h-15" />
          <div class="ml-2">
            <div class="text-base text-white">{t('Unleash the magic power of AI')}</div>
            <div class="mt-1 text-sm text-white">{t('Customized your listing!')}</div>
          </div>
        </div>
        <span class="cursor-pointer rounded-full border border-white px-3 py-1 text-sm capitalize text-white" onClick={handleToggle}>
          {t('Generate listing with AI')}
        </span>
      </div>
    </>
  );
};
