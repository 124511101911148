import { createSignal, createEffect, createContext, useContext, splitProps, Show, onCleanup, on } from 'solid-js';
import { Dynamic, Portal } from 'solid-js/web';
import { cn } from '~/utils/classnames';
import { computePosition, observeMove, observeSize } from '~/utils/floating';
import { useOutsideClick } from '~/utils/hooks';
import type { ComponentProps, Component, Accessor, Setter, JSX, ValidComponent } from 'solid-js';
import type { Merge } from '~/utils/types';

type PopoverContextValue = {
  id: string;
  open: Accessor<boolean>;
  setOpen: Setter<boolean>;
  container: Accessor<HTMLElement | undefined>;
  portal: Accessor<HTMLElement | undefined>;
  setPortal: Setter<HTMLElement | undefined>;
};

const PopoverContext = createContext<PopoverContextValue>();

const usePopoverContext = () => {
  const context = useContext(PopoverContext);
  if (!context) throw new Error('usePopoverContext must be used within a Popover component');
  return context;
};

type PopoverRenderFunction = (context: Pick<PopoverContextValue, 'open' | 'setOpen'>) => JSX.Element;

const isRenderFunction = (children: JSX.Element | PopoverRenderFunction): children is PopoverRenderFunction => {
  return typeof children === 'function' && children.length > 0;
};

export type PopoverProps = Merge<
  ComponentProps<'div'>,
  {
    defaultOpen?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    onOutsideClick?: (e: MouseEvent) => void;
    children: JSX.Element | PopoverRenderFunction;
  }
>;

const Popover: Component<PopoverProps> = (props) => {
  const [params, rest] = splitProps(props, ['defaultOpen', 'open', 'onOutsideClick', 'children']);
  const [open, setOpen] = createSignal<boolean>(params.defaultOpen ?? false);
  const [container, setContainer] = createSignal<HTMLElement>();
  const [portal, setPortal] = createSignal<HTMLElement>();

  createEffect(() => params.open != null && setOpen(params.open));
  createEffect(on(open, (value) => props.onOpenChange?.(value), { defer: true }));

  useOutsideClick(portal, open, (e, start) => {
    const event = new MouseEvent('click', { bubbles: true, cancelable: true });
    Object.defineProperties(event, {
      target: { value: e.target, writable: false },
      currentTarget: { value: container(), writable: false },
      relatedTarget: { value: start, writable: false },
    });
    params.onOutsideClick?.(event);
    event.defaultPrevented || event.target !== event.relatedTarget || setOpen(false);
  });

  const id = Math.random().toString(36).slice(2);

  const context = { id, open, setOpen, container, portal, setPortal };

  const inner = () => {
    const child = params.children;
    return isRenderFunction(child) ? child(context) : child;
  };

  return (
    <PopoverContext.Provider value={context}>
      <div ref={setContainer} {...rest} data-open={open() ? '' : undefined} aria-controls={id}>
        {inner()}
      </div>
    </PopoverContext.Provider>
  );
};

type PopoverTriggerProps<T extends ValidComponent> = Omit<ComponentProps<T>, 'onClick'> & {
  as?: T;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void;
};

const PopoverTrigger = <T extends ValidComponent>(props: PopoverTriggerProps<T>) => {
  const [params, rest] = splitProps(props, ['as', 'onClick']);
  const { open, setOpen } = usePopoverContext();
  const handleClick = (e: MouseEvent) => {
    if (props.disabled) return;
    params.onClick?.(e);
    e.defaultPrevented || setOpen((prev) => !prev);
  };
  return <Dynamic type="button" role="button" {...rest} component={params.as ?? 'button'} onClick={handleClick} aria-expanded={open()} />;
};

type PopoverContentProps<T extends ValidComponent> = Omit<ComponentProps<T>, 'align'> & {
  as?: T;
  align?: 'start' | 'end';
  class?: string;
};

const PopoverContent = <T extends ValidComponent>(props: PopoverContentProps<T>) => {
  const [params, rest] = splitProps(props, ['as', 'align', 'class']);
  const { id, open, container, portal, setPortal } = usePopoverContext();

  createEffect(() => {
    if (!open()) return;

    const reference = container();
    const floating = portal();
    if (reference == null || floating == null) return;

    floating.id = id;
    floating.role = 'dialog';
    floating.style.position = 'absolute';
    floating.style.zIndex = '99';
    floating.style.left = '-9999px';
    floating.style.top = '-9999px';

    const update = () => {
      const alignment =
        params.align ||
        (() => {
          const { left, right } = reference.getBoundingClientRect();
          return left > window.innerWidth - right ? 'end' : 'start';
        })();
      const position = computePosition(reference, floating, { alignment, allowPlacements: ['top', 'bottom'] });
      floating.style.top = position.top + 'px';
      floating.style.left = position.left + 'px';
      const align = position.alignment == null ? 'center' : position.alignment === 'start' ? 'left' : 'right';
      floating.style.setProperty('--transform-origin', `${align} ${position.placement === 'top' ? 'bottom' : 'top'}`);
      floating.style.setProperty('--reference-width', `${reference.offsetWidth}px`);
    };

    const cleanupMove = observeMove(reference, update);
    const cleanupSize = observeSize([reference, floating], update);

    onCleanup(() => {
      cleanupMove();
      cleanupSize();
    });
  });

  return (
    <Show when={open()}>
      <Portal ref={setPortal}>
        <Dynamic component={params.as ?? 'div'} {...rest} class={cn('origin-[--transform-origin] animate-zoom-in', params.class)} />
      </Portal>
    </Show>
  );
};

const PopoverWrap = Object.assign(Popover, { Trigger: PopoverTrigger, Content: PopoverContent });

export { PopoverWrap as Popover, PopoverTrigger, PopoverContent };
