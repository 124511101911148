import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { OwnersRepository } from '~/repositories/ownersRepository';
import { OwnerTitle } from './OwnerTitle';
import { getOwnerName } from './utils';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { EntrySearchProps } from '~/components/ui';
import type { OwnersFilter } from '~/repositories/ownersRepository';

const repo = new OwnersRepository();

export const OwnerSearch = <T extends boolean | undefined = undefined>(
  props: EntrySearchProps<MagicDoor.Api.OwnerDto, T, OwnersFilter>
) => {
  const { t } = useLocalization();

  const ownerFetcher = (query: string | undefined, page: number) => {
    return repo.getOwners({ active: true, pageSize: 10, ...props.filter, page, search: query || undefined });
  };

  return (
    <SearchSelect
      fetcher={ownerFetcher}
      renderSelected={(item) => getOwnerName(item)}
      placeholder={t('Select owner')}
      renderItem={(item) => <OwnerTitle owner={item} />}
      {...props}
    />
  );
};

export const LabeledOwnerSearch = <T extends boolean | undefined = undefined>(
  props: Omit<EntrySearchProps<MagicDoor.Api.OwnerDto, T>, 'onSelect'> &
    LabeledGroupProps & {
      value?: T extends true ? string[] : string;
      onInput?: (
        value: T extends true ? string[] : string,
        selected: T extends true ? MagicDoor.Api.OwnerDto[] : MagicDoor.Api.OwnerDto
      ) => void;
      filter?: OwnersFilter;
    }
) => {
  const [searchProps, labeledProps] = splitProps(props, ['multiple', 'selected', 'placeholder', 'onInput', 'exclude', 'filter']);

  return (
    <LabeledGroup {...labeledProps}>
      <OwnerSearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.(
            (Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string,
            selected
          );
        }}
      />
    </LabeledGroup>
  );
};
