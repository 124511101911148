import dayjs from 'dayjs';
import { createMemo, For, Show } from 'solid-js';
import { TinToggle } from '~/components/TinToggle';
import { Avatar } from '~/components/common/Avatar';
import IconField from '~/components/common/IconField';
import { getTenantFullName } from '~/components/tenants/utils';
import { IconCalendar, IconCircleDollar, IconHash, IconMail, IconPawPrint, IconPhone } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import { currency } from '~/utils/number';
import { validArr } from '~/utils/tool';
import { RentalInfomationModal } from './RentalInfomationModal';
import type { JSX } from 'solid-js';
import type { Icon } from '~/components/ui';

const LabelWithIcon = (props: { label: string; icon: Icon; value: JSX.Element }) => {
  return (
    <div class="flex items-center gap-1">
      <div class="flex items-center gap-2 text-text-level03">
        <props.icon class="size-4" stroke-width={1.5} />
        <div class="text-sm capitalize">
          {props.label}
          <Show when={props.value}>:</Show>
        </div>
      </div>
      <div class="text-sm text-text-level02">{props.value}</div>
    </div>
  );
};

function getYesIfTrueOtherwiseNo(value: boolean | undefined | null) {
  return value ? 'Yes' : 'No';
}

export const RentalInformation = () => {
  const { t } = useLocalization();
  const { applicationStore } = useRentalApplication();

  const memoInfo = createMemo(() => {
    const result = [
      { label: t('Date of birth'), icon: IconCalendar, value: dayjs(applicationStore.application?.dateOfBirth).format('MMMM DD, YYYY') },
      {
        label: t('Annual income'),
        icon: IconCircleDollar,
        value: currency(applicationStore.application?.incomeInformation?.annualIncome ?? undefined),
      },
      { label: t('No pets'), icon: IconPawPrint, value: getYesIfTrueOtherwiseNo(!validArr(applicationStore.application?.pets)) },
      {
        label: t('Social security number (ssn)'),
        icon: IconHash,
        value: (
          <Show when={applicationStore.application?.ssn} fallback={undefined}>
            <TinToggle number={applicationStore.application?.ssn || ''} type="ssn" />
          </Show>
        ),
      },
    ];

    return result;
  });

  return (
    <div class="grid grid-cols-2 gap-3">
      <div class="col-span-2 flex  gap-3 rounded-lg border border-input-border bg-input p-4 text-text-level01">
        <div class="flex w-1/2 flex-col">
          <div class="flex items-center gap-2">
            <Avatar class="size-9" name={getTenantFullName(applicationStore.application)} />
            <div class="text-base font-medium">{getTenantFullName(applicationStore.application)}</div>
          </div>
          <IconField class="mt-2.5 flex" name={t('Email')} iconRounded src={IconMail} value={applicationStore.application?.email || ''} />
          <IconField class="mt-2.5 flex" name={t('Cell')} iconRounded src={IconPhone} value={applicationStore.application?.phone || ''} />
        </div>
        <div class="w-1/2 text-xs">{emptyPlaceholder}</div>
      </div>
      <div class="col-span-full grid grid-cols-8 gap-4">
        <div class="col-span-8 flex flex-col gap-1 rounded-lg border border-input-border p-4 lg:col-span-5">
          <For each={memoInfo()}>
            {(section) => <LabelWithIcon label={section.label} icon={section.icon} value={section.value || ''} />}
          </For>
        </div>

        <div class="col-span-8 rounded-lg border border-input-border p-4 lg:col-span-3">
          <Show
            when={Object.keys(applicationStore.application?.questions ?? {}).length > 0}
            fallback={<div class="text-sm text-text-level03">{t('No questions asked')}</div>}>
            <For each={Object.entries(applicationStore.application?.questions ?? {})}>
              {([key, value]) => (
                <div class="flex gap-1 text-sm text-text-level03">
                  <span>{key}:</span>
                  <span class="text-text-level02">{value ?? emptyPlaceholder}</span>
                </div>
              )}
            </For>
          </Show>
        </div>
      </div>
      <RentalInfomationModal />
    </div>
  );
};
