import { createSignal, createEffect } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { TransactionsRepository } from '~/repositories/transactionRepository';
import type { Accessor } from 'solid-js';

interface TransactionsContextValue {
  transactions: Accessor<MagicDoor.VendorTransaction[] | undefined>;
  fetchTransactions: () => Promise<void>;
  isLoading: Accessor<boolean>;
}

export const [TransactionsProvider, useTransactions] = createMagicDoorContext<TransactionsContextValue>('Transactions', () => {
  const [transactions, setTransactions] = createSignal<MagicDoor.VendorTransaction[] | undefined>(undefined);
  const [isLoading, setIsLoading] = createSignal<boolean>(true);

  const repo = new TransactionsRepository();

  const fetchTransactions = async () => {
    try {
      setIsLoading(true);
      const data = await repo.getTransactions();
      setTransactions(data);
    } catch (err: any) {
      console.log('Error in TransactionsContext fetchTransactions', err);
    } finally {
      setIsLoading(false);
    }
  };

  createEffect(() => {
    setTransactions(undefined);
    fetchTransactions();
  });

  return {
    transactions,
    fetchTransactions,
    isLoading,
  };
});
