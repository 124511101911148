import { upperFirst } from '~/utils/tool';
type TenantLike = { firstName?: string | null; lastName?: string | null };

export function getTenantShortName<T extends TenantLike>(tenant?: T | null) {
  if (!tenant) return '';
  const f = tenant.firstName?.slice(0, 1) || '';
  const l = tenant.lastName?.slice(0, 1) || '';
  return f + l;
}

export function filterTenantByName<T extends MagicDoor.Api.HydratedTenantDto | MagicDoor.Api.TenantDto>(tenant: T, name: string) {
  const tenantName = getTenantFullName(tenant);
  return !!tenantName && tenantName.toLowerCase().includes(name.toLowerCase());
}

export function getTenantFullName<T extends TenantLike>(tenant?: T | null) {
  if (!tenant || (!tenant.firstName && !tenant.lastName)) return '';
  return `${upperFirst(tenant.firstName || '')} ${upperFirst(tenant.lastName || '')}`.trim();
}

type TenantContainer<T extends TenantLike> = {
  tenant: T;
};

export function formatTenantNames<T extends TenantLike>(tenants?: TenantContainer<T>[] | null, numberOfTenants: number = 3) {
  if (!tenants || tenants.length === 0) return '';
  const tenantNames = tenants.map((tenantContainer) => getTenantFullName(tenantContainer.tenant));
  if (tenantNames.length <= numberOfTenants) {
    return tenantNames.join(', ');
  } else {
    return `${tenantNames.slice(0, numberOfTenants).join(', ')}... +${tenantNames.length - numberOfTenants}`;
  }
}

export function formatTenantNamesWithoutContainer<T extends TenantLike>(tenants?: T[] | null) {
  if (!tenants || tenants.length === 0) return '';
  const tenantNames = tenants.map((item) => getTenantFullName(item));
  if (tenantNames.length <= 3) {
    return tenantNames.join(', ');
  } else {
    return `${tenantNames.slice(0, 3).join(', ')}... +${tenantNames.length - 3}`;
  }
}
