import { UseCase } from './useCase';
import type { PMSelectionRequest } from './types/pmSelectionChangeRequest';

export class PMSelectionChangedUseCase extends UseCase {
  public async runLogic(request: PMSelectionRequest) {
    if (!request || !this.state.selectedDocument) {
      return;
    }
    this.state.selectedDocument.signers[request.index].isPropertyManager = request.isPropertyManager;
  }
}
