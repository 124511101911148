import { For, Show, Switch, createMemo, Match, onMount } from 'solid-js';
import IconMinus from '~/assets/images/common/minus.svg?component-solid';
import IconLease from '~/assets/images/maintenance/selectLease.png';
import IconTenant from '~/assets/images/maintenance/selectTenant.png';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Gutter } from '~/components/common/Gutter';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import { LabeledLeaseSearch } from '~/components/leases';
import { LeaseItem } from '~/components/leases/LeaseItem';
import { getLeaseDateName } from '~/components/leases/utils';
import { LabeledTenantSearch } from '~/components/tenants/TenantSearch';
import { useLocalization } from '~/contexts/global';
import { useLease } from '~/contexts/local';
import { ShortCut, TenantItem } from '~/pages/leases/add-tenant/TenantsItem';
import { cn } from '~/utils/classnames';
import { safeCall } from '~/utils/tool';
import { LeaseOrTenant, useAddOrEditMaintenanceModalContext } from './Context';
import type { JSX } from 'solid-js';
import type { LabeledInputSearchProps } from '~/components/common/Inputs/LabeledInputSearch';
import type { HydratedLeaseDto, HydratedTenantDto } from '~/swagger/Api';

enum Status {
  Start = 'start',
  Com1Selected = 'com1Selected',
}

const SelectionBox = (props: { label: string; icon: string; active?: boolean; onClick?: () => void }) => {
  return (
    <div
      onClick={() => safeCall(props.onClick)}
      class={cn('flex h-20 cursor-pointer items-center justify-between rounded-lg border border-partingline px-6 py-4 text-text-level01', {
        'border-essential-colour bg-essential-colour/5 text-essential-colour': props.active,
      })}>
      <img src={props.icon} alt={props.label} class="w-9" />
      <Gutter direction="horizontal" />
      <span class="flex-1 text-sm font-medium ">{props.label}</span>
    </div>
  );
};

const ComponentSelectionWrapper = (props: {
  com1: JSX.Element;
  com2: JSX.Element;
  comSelected: JSX.Element;
  status: unknown;
  remove?: () => void;
}) => {
  const _status = createMemo(() => (props.status ? Status.Com1Selected : Status.Start));
  const { setStore } = useAddOrEditMaintenanceModalContext();

  return (
    <>
      <Show when={_status() === Status.Start}>{props.com1}</Show>
      <Show when={_status() === Status.Com1Selected}>
        <div class="flex items-center gap-2">
          <div class={cn('flex h-16 grow cursor-pointer select-none items-center justify-between  rounded bg-inputbox-bg p-3', {})}>
            {props.comSelected}
          </div>
          <div
            class="cursor-pointer p-2"
            onClick={() => {
              setStore({ isSelected: undefined });
              props.remove && props.remove();
            }}>
            <IconMinus />
          </div>
        </div>
        {props.com2}
      </Show>
    </>
  );
};

export const SelectLeaseOrTenant = (props: { initialLeaseId?: string }) => {
  const { t } = useLocalization();

  const form = useFormContext();

  const types: Array<{ label: string; icon: string; key: LeaseOrTenant }> = [
    { label: t('Select lease'), icon: IconLease, key: LeaseOrTenant.Lease },
    { label: t('Select tenant'), icon: IconTenant, key: LeaseOrTenant.Tenant },
  ];

  const { store, setStore } = useAddOrEditMaintenanceModalContext();
  const { getLease, lease } = useLease();

  const tenantOptions = createMemo(() => {
    const tenants = store.leases.find((lease) => lease.id === form.formStore.leaseId)?.tenants || [];
    return tenants.map((tenant) => ({
      ...tenant,
      ...tenant.tenant,
    }));
  });

  const LeaseSelect = () => (
    <FormItem<any>
      label={t(`Select lease`)}
      formFieldName="leaseId"
      component={LabeledLeaseSearch}
      rules={[{ required: true, message: t(`Please select lease`) }]}
      placeholder={t('Please select lease')}
      onInput={(_: any, entry: HydratedTenantDto) => {
        if (store.isLeaseOrTenant === LeaseOrTenant.Lease && entry?.id) setStore({ isSelected: entry });
      }}
      filter={{
        ended: false,
        tenantIds: store.isLeaseOrTenant === LeaseOrTenant.Lease ? [] : [form.formStore.requesterId],
      }}
    />
  );

  const TenantSelect = () => (
    <Switch>
      <Match when={store.isLeaseOrTenant === LeaseOrTenant.Lease}>
        <FormItem<LabeledInputSearchProps<MagicDoor.Api.HydratedTenantDto>>
          label={t(`Select tenant`)}
          formFieldName="requesterId"
          options={tenantOptions() as any}
          onChangeMethodName="onSelect"
          renderItem={(item) => <TenantItem tenant={item} />}
          component={LabeledInputSearch}
          filter={(item, keyword) => getLeaseDateName(item)?.toLowerCase().includes(keyword.toLowerCase())}
          placeholder={t('Please select tenant')}
          rules={[{ required: true, message: t(`Please select tenant`) }]}
        />
      </Match>
      <Match when={store.isLeaseOrTenant === LeaseOrTenant.Tenant}>
        <FormItem<any>
          label={t(`Select tenant`)}
          formFieldName="requesterId"
          component={LabeledTenantSearch}
          rules={[{ required: true, message: t(`Please select tenant`) }]}
          placeholder={t('Please select tenant')}
          onInput={(_: any, entry: HydratedLeaseDto) => {
            if (store.isLeaseOrTenant === LeaseOrTenant.Tenant && entry?.id) setStore({ isSelected: entry });
          }}
        />
      </Match>
    </Switch>
  );

  const onTypeChange = (item: any) => {
    setStore({ isLeaseOrTenant: item.key, isSelected: undefined });
    form.resetFields(['requesterId', 'leaseId']);
  };

  onMount(() => {
    if (props.initialLeaseId) {
      setStore({ isLeaseOrTenant: LeaseOrTenant.Lease });
      form.setValueToStore('leaseId', props.initialLeaseId);
      fetchLeaseDetails(props.initialLeaseId);
    }
  });

  const fetchLeaseDetails = async (leaseId: string) => {
    try {
      await getLease(leaseId);
      setStore({ isSelected: lease() });
    } catch (error) {
      console.error('Error fetching lease details:', error);
    }
  };

  return (
    <div class="flex flex-col gap-8">
      <div class="grid grid-cols-2 gap-4">
        <For each={types}>
          {(item) => (
            <SelectionBox
              label={item.label}
              icon={item.icon}
              active={store.isLeaseOrTenant === item.key}
              onClick={() => onTypeChange(item)}
            />
          )}
        </For>
      </div>

      <Show when={store.isLeaseOrTenant === LeaseOrTenant.Lease}>
        <ComponentSelectionWrapper
          comSelected={<LeaseItem lease={store.isSelected as MagicDoor.Api.HydratedLeaseDto} />}
          com1={LeaseSelect()}
          com2={TenantSelect()}
          status={store.isSelected}
          remove={() => form.setValueToStore('leaseId', undefined)}
        />
      </Show>

      <Show when={store.isLeaseOrTenant === LeaseOrTenant.Tenant}>
        <ComponentSelectionWrapper
          comSelected={<ShortCut tenant={store.isSelected as MagicDoor.Api.HydratedTenantDto} />}
          com1={TenantSelect()}
          com2={LeaseSelect()}
          status={store.isSelected}
          remove={() => form.setValueToStore('requesterId', undefined)}
        />
      </Show>
    </div>
  );
};
