import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import { useLocalization } from '~/contexts/global';
import { printDocument } from '~/utils/print';
import { printReports } from '~/utils/printReports';
import { Button } from './Button';
import type { ButtonProps } from './Button';

export interface IProps extends Pick<ButtonProps, 'color' | 'variant'> {
  printHeader?: Element;
  printWrapClass?: string;
  printContainer: HTMLDivElement;
  extraStyle?: string;
  class?: string;
  isReport?: boolean;
  size?: ButtonProps['size'];
  orientation?: 'portrait' | 'landscape';
  disabled?: boolean;
}

export const PrintButton = (props: IProps) => {
  const { t } = useLocalization();
  const onPrint = () => {
    if (props.isReport) {
      printReports(props.printContainer, props.printWrapClass, props.printHeader, props.extraStyle, props.orientation);
    } else {
      printDocument(props.printContainer, props.printWrapClass, props.printHeader, props.extraStyle);
    }
  };

  return (
    <Button onClick={onPrint} disabled={props.disabled} class={props.class} color={props.color} variant={props.variant} size={props.size}>
      <div class="flex items-center">
        <PrintIcon class="mr-1 size-5" />
        {t('Print')}
      </div>
    </Button>
  );
};
