import { For, createSignal, createMemo, Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { LeaseSearch } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { PortfolioSearch } from '~/components/portfolios';
import { PropertySearch } from '~/components/properties';
import { UnitSearch } from '~/components/units';
import { useLocalization } from '~/contexts/global';
import { IconLeaseColored, IconPortfolioColored, IconPropertyColored, IconUnitColored } from '~/pages/settings/components/Icons';
import ImgAddOverride from './add-override-modal.svg';

export interface AddOverridesProps {
  onClose: () => void;
  onDone: (level: string, levelId: string) => void;
  loading?: boolean;
  overrides: any[];
}

export const AddOverridesModal = (props: AddOverridesProps) => {
  const { t } = useLocalization();
  const [selectLevel, setLevel] = createSignal('');
  const [selectLevelId, setSelectLevelId] = createSignal<string>();

  const levels = [
    { icon: IconPortfolioColored, label: 'Portfolios', value: 'portfolio' },
    { icon: IconPropertyColored, label: 'Property', value: 'property' },
    { icon: IconUnitColored, label: 'Unit', value: 'unit' },
    { icon: IconLeaseColored, label: 'Lease', value: 'lease' },
  ];

  const handleClickLevel = (level: string) => {
    setLevel(level);
    setSelectLevelId('');
  };

  const onSelect = (id: string) => {
    setSelectLevelId(id);
  };

  const getSearchSelectByLevel = createMemo(() => {
    switch (selectLevel()) {
      case 'portfolio':
        return PortfolioSearch;
      case 'property':
        return PropertySearch;
      case 'unit':
        return UnitSearch;
      case 'lease':
        return LeaseSearch;
      default:
        return undefined;
    }
  });

  return (
    <Modal
      class="min-w-[766px]"
      visible
      title={t('Add new override')}
      doneText={t('Save')}
      onCancel={props.onClose}
      onDone={() => props.onDone(selectLevel(), selectLevelId() || '')}
      loading={props.loading}>
      <img src={ImgAddOverride} />
      <div class="mt-6 px-8 pb-8">
        <div class="grid grid-cols-4 gap-2">
          <For each={levels}>
            {(level) => (
              <div
                class="mb-4 flex cursor-pointer items-center rounded-lg border border-auxiliary-text p-3 transition-all hover:border-essential-colour hover:bg-light-pink"
                classList={{
                  'bg-[#FBF1FF]': level.value === selectLevel(),
                  'border-essential-colour': level.value === selectLevel(),
                  'text-essential-colour': level.value === selectLevel(),
                }}
                onClick={() => handleClickLevel(level.value)}>
                <div class="relative">
                  <level.icon />
                  <i class="absolute inset-y-1 -right-2 block w-px bg-input-border" />
                </div>
                <span class="ml-6">{t(level.label)}</span>
              </div>
            )}
          </For>
        </div>
        <Show when={selectLevel()}>
          <div class="mt-5 uppercase text-text-level03">Select {selectLevel()}</div>

          <Dynamic component={getSearchSelectByLevel()} placeholder="Search by name" onSelect={(v: any) => onSelect(v.id as string)} />
        </Show>
      </div>
    </Modal>
  );
};
