import { createSignal, createMemo, createEffect } from 'solid-js';
import { toast } from '~/components/ui';
import { formDataKeys } from './types';
import type { Model } from './types';

export const useAnnouncementLogic = (
  initialModel: Model,
  addAnnouncement: (announcement: FormData) => Promise<MagicDoor.Api.HydratedAnnouncementDto | undefined>,
  fetchAnnouncementTargets: (
    filter?: MagicDoor.Api.GetAnnouncementTargetsRequestDto
  ) => Promise<MagicDoor.Api.HydratedAnnouncementTargetsDto | undefined>,
  navigate: (path: string, options?: { replace?: boolean }) => void,
  error: () => Error | null,
  t: (key: string) => string
) => {
  const [currentStep, setCurrentStep] = createSignal<number>(0);
  const [model, setModel] = createSignal<Model>(initialModel);
  const [announcementReview, setAnnouncementReview] = createSignal<any>();

  const [errors, setErrors] = createSignal<Partial<Record<keyof Model, string>>>({});
  const [validationErrors, setValidationErrors] = createSignal<Partial<Record<keyof Model, string>>>({});

  const steps = createMemo(() => [t('Select target'), t('Content'), t('Review')]);

  const updateModel = (updates: Partial<Model>) => {
    setModel((prev) => ({ ...prev, ...updates }));
  };

  const addIfNotEmpty = <T extends keyof Model>(key: T, value: Model[T] | undefined, target: FormData | Record<string, any>) => {
    if (value !== undefined && value !== null && value !== '') {
      const targetKey = target instanceof FormData ? formDataKeys[key] : key;
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (target instanceof FormData) {
            value.forEach((item) => target.append(targetKey, item.toString()));
          } else {
            target[targetKey] = value;
          }
        }
      } else if (typeof value === 'boolean') {
        if (target instanceof FormData) {
          target.append(targetKey, value.toString());
        } else {
          target[targetKey] = value;
        }
      } else if (value instanceof File) {
        if (target instanceof FormData) {
          target.append(targetKey, value);
        }
      } else {
        if (target instanceof FormData) {
          target.append(targetKey, value.toString());
        } else {
          target[targetKey] = value;
        }
      }
    }
  };

  createEffect(() => {
    getAnnouncementTargets();
  });

  const getAnnouncementTargets = async () => {
    if (currentStep() === 2) {
      const currentModel = model();
      const filter: Partial<MagicDoor.Api.GetAnnouncementTargetsRequestDto> = {};

      addIfNotEmpty('targetType', currentModel.targetType as MagicDoor.Api.TargetType, filter);
      addIfNotEmpty('hasBalanceDue', currentModel.hasBalanceDue, filter);
      addIfNotEmpty('includeEndedLease', currentModel.includeEndedLease, filter);
      addIfNotEmpty('portfolioIds', currentModel.portfolioIds, filter);
      addIfNotEmpty('propertyIds', currentModel.propertyIds, filter);
      addIfNotEmpty('unitIds', currentModel.unitIds, filter);

      if (Object.keys(filter).length > 0) {
        const response = await fetchAnnouncementTargets(filter as MagicDoor.Api.GetAnnouncementTargetsRequestDto);
        setAnnouncementReview(response);
      }
    }
  };

  const validateStep = (step: number): boolean => {
    const currentModel = model();
    const newErrors: Partial<Record<keyof Model, string>> = {};

    if (step === 0) {
      if (!currentModel.targetType) {
        newErrors.targetType = t('Target type is required');
      }
      if (['lease', 'tenant', 'owner'].includes(currentModel.targetType)) {
        if (
          !currentModel.portfolioIds?.length &&
          !currentModel.propertyIds?.length &&
          currentModel.targetType !== 'owner' &&
          !currentModel.unitIds?.length
        ) {
          newErrors.portfolioIds = t('Please select a portfolio, property, or unit');
        } else if (currentModel.targetType === 'owner' && !currentModel.portfolioIds?.length && !currentModel.propertyIds?.length) {
          newErrors.portfolioIds = t('Please select a portfolio or property');
        }
      }
    } else if (step === 1) {
      if (!currentModel.chatMessage && !currentModel.emailMessage) {
        newErrors.chatMessage = t('Either chat or email message is required');
        newErrors.emailMessage = t('Either chat or email message is required');
      }
      if (!currentModel.chatMessage && currentModel.chatAttachments) {
        newErrors.chatMessage = t('Chat message must exist to upload chat attachments');
      }

      if (!currentModel.emailMessage && currentModel.emailAttachments) {
        newErrors.emailMessage = t('Email message must exist to upload email attachments');
      }
      if (currentModel.chatAttachments?.length && currentModel.chatAttachments?.length > 5) {
        newErrors.chatAttachments = t('Maximum 5 chat attachments are allowed');
        toast.error(t('Maximum 5 chat attachments are allowed'));
      }
      if (currentModel.emailAttachments?.length && currentModel.emailAttachments?.length > 5) {
        newErrors.chatAttachments = t('Maximum 5 email attachments are allowed');
        toast.error(t('Maximum 5 email attachments are allowed'));
      }
    }

    setValidationErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleStepChange = (step: number): boolean => {
    if (step > currentStep()) {
      if (!validateStep(currentStep())) {
        setErrors(validationErrors());
        return false;
      }
    }

    if (step < steps().length) {
      setCurrentStep(step);
      setErrors({});
      return step < steps().length - 1;
    }
    return false;
  };

  const handleSubmit = async () => {
    if (!validateStep(currentStep())) {
      return;
    }

    const formData = new FormData();
    const currentModel = model();

    const requiredFields: (keyof Model)[] = [
      'chatMessage',
      'emailMessage',
      'subject',
      'targetType',
      'hasBalanceDue',
      'includeEndedLease',
      'portfolioIds',
      'propertyIds',
      'unitIds',
      'chatAttachments',
      'emailAttachments',
    ];

    requiredFields.forEach((key) => {
      const value = currentModel[key];
      const formKey = formDataKeys[key];

      if (value !== undefined && value !== null && value !== '') {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            if (key === 'chatAttachments' || key === 'emailAttachments') {
              value.forEach((file) => {
                formData.append(`${formKey}`, file);
              });
            } else {
              value.forEach((item) => {
                formData.append(formKey, item.toString());
              });
            }
          }
        } else if (typeof value === 'boolean') {
          formData.append(formKey, value.toString());
        } else {
          formData.append(formKey, value.toString());
        }
      }
    });

    try {
      const response = await addAnnouncement(formData);
      toast.success(t('Announcement has been sent successfully'));
      navigate(`/communications/announcements/${response?.id}`, { replace: true });
    } catch (err) {
      console.log('New Announcement Error:', err);
      throw err;
    }
  };

  return {
    currentStep,
    model,
    steps,
    updateModel,
    handleStepChange,
    handleSubmit,
    errors,
    announcementReview,
  };
};
