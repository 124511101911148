import { Route, Routes } from '@solidjs/router';
import { LeaseRentTransactionsProvider } from '~/contexts/local';
import { AddOfflinePaymentView } from './AddOfflinePaymentView';

export const AddOfflinePaymentRoutes = () => {
  return (
    <LeaseRentTransactionsProvider>
      <Routes>
        <Route path="*" component={AddOfflinePaymentView} />
      </Routes>
    </LeaseRentTransactionsProvider>
  );
};
