import { useNavigate } from '@solidjs/router';
import { createEffect, createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { AuthRepository } from '~/repositories/authRepository';
import { MeRepository } from '~/repositories/meRepository';
import { getImageUrl } from '~/utils/file';
import { createLazyResource, createMutation } from '~/utils/resource';
import { tokenStorage } from '~/utils/storage';

export type Profile = {
  name: string;
  firstName: string;
  lastName?: string;
  jobTitle?: string;
  email: string;
  phone?: string;
  address?: string;
  memo?: string;
  avatar: string;
  keywords?: string;
};

const authRepo = new AuthRepository();
const meRepo = new MeRepository();

const dto2Profile = (dto: MagicDoor.Api.PropertyManagerDto): Profile => {
  return {
    name: dto.name ?? `${dto.firstName} ${dto.lastName ?? ''}`.trim(),
    firstName: dto.firstName,
    lastName: dto.lastName ?? '',
    // jobTitle: 'Manager',
    email: dto.email,
    // phone: '(270)685-0957',
    // address: '123 Example st, Las Vegas NV 91234',
    // memo: 'Monthly rent',
    avatar: getImageUrl('avatar', dto.id, dto.imageId ?? ''),
    // avatar: `https://ui-avatars.com/api/?name=${dto.firstName}+${dto.lastName}&background=a126ec&color=fff`,
  };
};

export const [AuthProvider, useAuth] = createMagicDoorContext('Auth', () => {
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = createSignal<boolean>(tokenStorage.get() != null);

  const checkUrlForToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const state = urlParams.get('state');
    const expires = urlParams.get('expires');

    if (token && state && expires) {
      if (Number(expires) > Date.now()) {
        tokenStorage.set(token);
        setIsAuthenticated(true);
        urlParams.delete('token');
        urlParams.delete('state');
        urlParams.delete('expires');
        const newUrl = window.location.pathname + (urlParams.toString() ? '?' + urlParams.toString() : '');
        window.history.replaceState({}, '', newUrl);
        localStorage.setItem('authChange', Date.now().toString());
      } else {
        console.error('Token has expired');
      }
    }
  };

  createEffect(() => {
    checkUrlForToken();
  });

  const [me, { fetch, refetch, mutate }] = createLazyResource<Profile>(async () => {
    const data = await meRepo.getProfile();
    return dto2Profile(data);
  });

  const updateProfile = createMutation(async (profile: Profile) => {
    const result = await meRepo.updateProfile(profile);
    mutate(dto2Profile(result));
  });

  const updateAvatar = createMutation(async (avatar: File) => {
    const result = await meRepo.updateProfileImage(avatar);
    const updated = dto2Profile(result);
    updated.avatar += `?t=${Date.now()}`; // refresh bff cache
    mutate(updated);
  });

  const changePassword = createMutation(async (currentPassword: string, newPassword: string) => {
    const result = await meRepo.changePassword({ currentPassword, clearTextPassword: newPassword });
    mutate(dto2Profile(result));
  });

  const signIn = createMutation(async (email: string, password: string, companyId?: string, remember?: boolean) => {
    const result = await authRepo.auth(email, password, companyId);

    if (result.success) {
      tokenStorage.set(result.token, remember);
      setIsAuthenticated(true);
      localStorage.setItem('authChange', Date.now().toString());
    }

    return result;
  });

  const signOut = () => {
    setIsAuthenticated(false);
    tokenStorage.clear();
    localStorage.setItem('authChange', Date.now().toString());
  };

  const redirectToAuth = () => {
    tokenStorage.clear();
    const authUrl = '/auth/signin?redirect=' + location.pathname + location.search;
    navigate(authUrl, { replace: true });
  };

  createEffect(() => {
    if (isAuthenticated() || /^\/auth/.test(location.pathname)) return;
    redirectToAuth();
  });

  window.addEventListener('storage', (event) => {
    if (event.key === 'authChange') {
      window.location.reload();
    }
  });

  return {
    get me() {
      fetch();
      return me;
    },
    refetch,
    updateProfile,
    updateAvatar,
    changePassword,
    signIn,
    signOut,
    redirectToAuth,
    isAuthenticated,
    checkUrlForToken,
  };
});
