import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, createSignal, For, Show } from 'solid-js';
import IconFilledArrow from '~/assets/images/common/filledArrow.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { RingLoader } from '~/components/common/Loaders';
import { ListFileThumbnailWithPreview } from '~/components/file-attachments/ListFileThumbnailWithPreview';
import { Modal } from '~/components/modals/Modal';
import { DynamicHtml } from '~/components/ui';
import { useEmails, useLocalization } from '~/contexts/global';
import { dateFormat, formatAgo } from '~/utils/date';

export const EmailDetailsModal = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const params = useParams<{ emailId: `${number}` }>();
  const { getEmail, email } = useEmails();
  const [expanded, setExpanded] = createSignal(false);

  createEffect(() => {
    getEmail(params.emailId);
  });

  const onCancel = () => {
    navigate(-1);
  };

  const toggleExpand = () => {
    setExpanded(!expanded());
  };

  const renderEmailList = () => {
    const targets = email()?.targets || [];
    const visibleCount = 1;

    return (
      <div class="group flex w-full cursor-pointer items-start justify-start p-2 hover:bg-gray-200" onClick={toggleExpand}>
        <div class="flex gap-2">
          <h3 class="font-semibold">{t('To')}</h3>
          <div>
            <For each={expanded() ? targets : targets.slice(0, visibleCount)}>{(target) => <div>{`<${target.email}>`}</div>}</For>
          </div>
          <Show when={!expanded() && targets.length > visibleCount}>
            <div class="flex w-full">
              <div>...</div>
              <div>({targets.length - visibleCount})</div>
            </div>
          </Show>
        </div>
        <Show when={targets.length > visibleCount}>
          <button class="w-full text-sm text-primary">
            <Show
              when={expanded()}
              fallback={
                <div class="flex w-full justify-between">
                  <IconFilledArrow
                    class="size-5 flex-none text-primary transition-all duration-300 ease-in-out"
                    classList={{ 'rotate-180': expanded() }}
                  />
                  <div class="group-hover:underline">{t('Show all')}</div>
                </div>
              }>
              <div class="flex w-full justify-between">
                <IconFilledArrow
                  class="size-5 flex-none text-primary transition-all duration-300 ease-in-out"
                  classList={{ 'rotate-180': !expanded() }}
                />
                <div class="group-hover:underline">{t('Show less')}</div>
              </div>
            </Show>
          </button>
        </Show>
      </div>
    );
  };

  const formatEmailBody = (body: string) => {
    return body.replace(/\n/g, '<br>');
  };

  return (
    <Modal
      size="lg"
      title={t('Email content')}
      loading={email?.loading}
      visible
      footer={
        <div class="flex justify-end gap-2 border-t border-partingline p-6" data-slot="footer">
          <Button class="capitalize" type="button" onClick={onCancel}>
            {t('Close')}
          </Button>
        </div>
      }>
      <Show
        when={!email.loading}
        fallback={
          <div class="relative h-64">
            <RingLoader color={'#A126EC'} class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
          </div>
        }>
        <div class="space-y-4 overflow-auto p-4">
          <div>
            <div class="flex justify-between">
              <h3 class="font-semibold">{t('Subject')}</h3>
              <div class="text-sm text-text-level02">
                {dateFormat('', email()?.sent)} <span>({formatAgo(email()?.sent)})</span>
              </div>
            </div>
            <div class="rounded-md bg-input">
              <p class=" p-2 text-xl">{email()?.subject}</p>
              <div class="text-sm text-text-level02">{renderEmailList()}</div>
            </div>
          </div>
          <div>
            <h3 class="font-semibold">{t('Body')}</h3>
            <DynamicHtml as="div" class="rounded-md bg-input p-2 text-text-level01">
              {formatEmailBody(email()?.body || '')}
            </DynamicHtml>
          </div>

          <Show when={email()?.files?.length ?? 0 > 0}>
            <h3 class="font-semibold">{t('Attachments')}</h3>
            <ListFileThumbnailWithPreview files={email()?.files} />
          </Show>
        </div>
      </Show>
    </Modal>
  );
};
