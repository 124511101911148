import { createSignal, onMount, Show, createMemo } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconManagementFees from '~/assets/images/settings/property/propertyManagementFees.svg';
import { Button } from '~/components/common/Buttons';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { confirm, toast } from '~/components/ui';
import { useLocalization, useManagementFeesSettings } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import Overrides from '~/pages/settings/components/Overrides';
import { AddOverridesModal } from '~/pages/settings/components/add-overrides-modal';
import { EditForm } from '~/pages/settings/components/edit-form/EditForm';
import { ChartOfAccountType } from '~/swagger/Api';

export const ManagementFeesSetting = () => {
  const { t } = useLocalization();

  const {
    isLoading,
    allManagementFeesSettings,
    getAllManagementFeesSettings,
    deleteManagementFeesSettingsByLevel,
    updateManagementFeesSettings,
    updateManagementFeesSettingsByLevel,
  } = useManagementFeesSettings();

  const [showModal, setShowModal] = createSignal<boolean>(false);

  onMount(async () => {
    await getAllManagementFeesSettings();
  });

  const onDone = async (level: string, levelId: string) => {
    await updateManagementFeesSettingsByLevel(level, levelId, {
      ...(allManagementFeesSettings()?.settings as MagicDoor.Api.ManagementFeeSettingsDto),
    });
    setShowModal(false);
    getAllManagementFeesSettings();
    toast(t('Management fees settings saved successfully'), 'success');
  };

  const handleSave = async (data: MagicDoor.Api.ManagementFeeSettingsDto, level?: string, levelId?: string) => {
    if (!level || !levelId) {
      try {
        await updateManagementFeesSettings(data);
        toast(t('Management fees settings saved successfully'), 'success');
        getAllManagementFeesSettings();
      } catch (error) {
        toast(t('Failed to save late fees settings'), 'error');
      }
    } else {
      try {
        await updateManagementFeesSettingsByLevel(level, levelId, data);
        toast(t('Management fees settings saved successfully'), 'success');
        getAllManagementFeesSettings();
      } catch (error) {
        toast(t('Failed to save late fees settings'), 'error');
      }
    }
  };

  const onDelete = async (level: string, levelId: string) => {
    confirm({
      content: t('Are you sure you want to delete this overwrite?'),
      title: t(`Delete overrite`),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await deleteManagementFeesSettingsByLevel(level, levelId);
        toast(t('Management fees settings deleted successfully'), 'success');
        getAllManagementFeesSettings();
      },
    });
  };

  const onClose = () => {
    setShowModal(false);
  };

  const sections = createMemo(() => {
    return [
      {
        field: 'includeDebits',
        label: 'Include debits',
        type: 'boolean',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between',
        rules: [],
      },
      {
        field: 'createSupplementaryBills',
        label: 'Create supplementary bills',
        type: 'boolean',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between',
        rules: [],
      },

      {
        field: 'managementFeePropertyChartOfAccountId',
        label: 'Management fee property chart of account id',
        description: 'Is the expense chart of account that will be used on the property bill.',
        type: 'chartOfAccountSelect',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between',
      },

      {
        field: 'managementFeeCompanyChartOfAccountId',
        label: 'Management fee company chart of account id',
        description: 'Is the income chart of account that will be used on the company bill.',
        type: 'chartOfAccountSelect',
        class: 'col-span-2',
        labelContainerClass: 'flex-row justify-between',
      },

      {
        field: 'ignoreChartOfAccounts',
        label: 'Ignore chart of accounts',
        type: 'chartOfAccountSelect',
        multiple: true,
      },
      {
        field: 'includeChartOfAccounts',
        label: 'Include chart of accounts',
        type: 'chartOfAccountSelect',
        multiple: true,
        types: ChartOfAccountType.Revenue,
      },
      {
        field: 'feeType',
        label: 'Fee type',
        type: 'select',
        options: [
          { label: t('Fixed'), value: 'fixed' },
          { label: t('Percentage'), value: 'percentage' },
        ],
      },
      {
        field: 'feePercentage',
        label: 'Fee percentage',
        type: 'number',
        suffix: '%',
        percent: true,
        rules: [
          {
            validator: (value: number) => value <= 1 && value >= 0,
            message: t('The value of feePercentage is between 0 and 1'),
          },
        ],
        visibleMethod: (data: MagicDoor.Api.ManagementFeeSettingsDto) => {
          return data?.feeType === 'percentage';
        },
      },
      {
        field: 'minimumFee',
        label: 'Minimum fee',
        type: 'number',
        rules: [
          {
            validator: (value: number) => value >= 0,
            message: t('The value of minimumFee is greater than 0'),
          },
        ],
      },
      {
        field: 'maximumFee',
        label: 'Maximum fee',
        type: 'number',
        min: 0,
        rules: [
          {
            validator: (value: number) => (value ? value >= 0 : true),
            message: t('The value of maximumFee is greater than 0'),
          },
        ],
      },
    ];
  });

  return (
    <>
      <ThreeSectionBox
        class="h-fit"
        topAction={
          <Button variant="white" class="rounded-3xl px-3 py-1 text-sm" onClick={() => setShowModal(true)}>
            <IconPlus /> <div class="hidden font-medium md:block">{t('Add new overwrites')}</div>
          </Button>
        }
        topTitle={<SettingTitle title={t('Management fees')} icon={IconManagementFees} />}
        middle={
          <div class="flex flex-col gap-5 p-4">
            <div class="flex flex-col gap-4 p-2 text-left">
              <EditForm
                sections={sections()}
                onOk={(data) => handleSave(data)}
                model={allManagementFeesSettings()?.settings || ({} as MagicDoor.Api.ManagementFeeSettingsDto)}
              />
            </div>
            <Overrides overrides={allManagementFeesSettings()?.overrides || []} onDelete={onDelete} title={t('Management fees overwrites')}>
              {(override) => {
                return (
                  <div class="flex flex-col gap-4 p-2 text-left">
                    <EditForm
                      sections={sections()}
                      onOk={(data) => handleSave(data, override.level, override.levelId)}
                      model={override.settings || ({} as MagicDoor.Api.ManagementFeeSettingsDto)}
                    />
                  </div>
                );
              }}
            </Overrides>
          </div>
        }
      />
      <Show when={showModal()}>
        <AddOverridesModal
          overrides={allManagementFeesSettings()?.overrides || []}
          onClose={onClose}
          onDone={onDone}
          loading={isLoading()}
        />
      </Show>
    </>
  );
};
