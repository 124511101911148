import { createSignal, onMount, Show } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import { useLocalization } from '~/contexts/global';
import { PropertyManagersRepository } from '~/repositories/propertyManagersRepository';
import type { LabeledInputSearchProps } from '~/components/common/Inputs/LabeledInputSearch';
import type { PropertyManagerDto } from '~/swagger/Api';

async function getPropertyManagers() {
  const propertyManagersRepo = new PropertyManagersRepository();
  try {
    const res = await propertyManagersRepo.getPropertyManagers();
    return { managers: res };
  } catch (error: any) {
    return { error: error.message };
  }
}

export const AssignedTo = (props: { isCreate?: boolean }) => {
  const { t } = useLocalization();
  const [managers, setManagers] = createSignal<PropertyManagerDto[]>([]);

  onMount(async () => {
    const { managers: data, error } = await getPropertyManagers();
    if (data) {
      setManagers(data);
    } else {
      console.error(error);
    }
  });

  return (
    <div class="flex flex-col gap-8">
      <div class="flex gap-7">
        <FormItem<LabeledInputSearchProps>
          label={t(`Assigned to`)}
          formFieldName="assignedPropertyManagerId"
          options={managers()}
          onChangeMethodName="onSelect"
          component={LabeledInputSearch}
          placeholder={t('Please select')}
        />
      </div>
      <Show when={props.isCreate}>
        <FormItem showLabel label={t('Disable AI')} formFieldName="disableAi" onChangeMethodName="onInput" component={Checkbox} />
      </Show>
    </div>
  );
};
