import { createSignal } from 'solid-js';
import { toast } from '~/components/ui';

interface CopyToClipboardOptions {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  successMessage?: string;
  errorMessage?: string;
  tooltipDuration?: number;
  tooltipMessages?: {
    default: string;
    copied: string;
  };
}

export const createClipboard = (defaultOptions?: Partial<CopyToClipboardOptions>) => {
  const [showCopied, setShowCopied] = createSignal(false);

  const copy = async (text: string, options: CopyToClipboardOptions = {}): Promise<boolean> => {
    const {
      onSuccess,
      onError,
      successMessage,
      errorMessage = 'Failed to copy',
      tooltipDuration = 2000,
    } = { ...defaultOptions, ...options };

    if (navigator.clipboard && window.isSecureContext) {
      try {
        await navigator.clipboard.writeText(text);
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), tooltipDuration);
        onSuccess?.();
        if (successMessage) toast.success(successMessage);
        return true;
      } catch (err) {
        onError?.(err);
        if (errorMessage) toast.error(errorMessage);
        return false;
      }
    }
    return false;
  };

  const tooltipMessage = (messages = defaultOptions?.tooltipMessages) => {
    return showCopied() ? messages?.copied : messages?.default;
  };

  return {
    copy,
    showCopied,
    tooltipMessage,
  };
};
