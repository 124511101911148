import { useNavigate } from '@solidjs/router';
import { Switch, Match } from 'solid-js';
import BackIcon from '~/assets/images/head-layout/back.svg';
import LeaseTextImage from '~/assets/images/head-layout/lease-text.svg';
import LeaseImage from '~/assets/images/head-layout/lease.svg';
import PropertyTextImage from '~/assets/images/head-layout/property-text.svg';
import PropertyImage from '~/assets/images/head-layout/property.svg';
import UnitTextImage from '~/assets/images/head-layout/unit-text.svg';
import UnitImage from '~/assets/images/head-layout/unit.svg';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

type HeadLayoutProps = {
  type: 'unit' | 'property' | 'lease';
  backLink?: string | (() => void);
  children: JSX.Element;
  class?: string;
};

export const HeadLayout: Component<HeadLayoutProps> = (props) => {
  const navigate = useNavigate();
  function onClickBack() {
    if (typeof props.backLink === 'function') {
      props.backLink();
      return;
    }
    props.backLink && navigate(props.backLink);
  }

  return (
    <div class={cn('relative flex items-center rounded-t-lg p-2 xl:px-5', props.class)}>
      <div class="-ml-2 flex size-12 items-center justify-center border-r-[1.5px] border-[#FFFFFF3D] xl:-ml-4 xl:size-16">
        <img src={BackIcon} class="w-6 cursor-pointer xl:w-auto" onClick={() => onClickBack()} />
      </div>
      <div class="flex-1 pl-3 xl:pl-6">{props.children}</div>
      <div class="relative mr-1  hidden items-center gap-x-2 xl:mr-2 xl:flex xl:gap-x-4">
        <Switch>
          <Match when={props.type === 'property'}>
            <img src={PropertyTextImage} class="h-4 w-auto xl:h-auto" />
            <img src={PropertyImage} class="-mb-6 w-8 xl:-mb-8 xl:w-auto" />
          </Match>
          <Match when={props.type === 'lease'}>
            <img src={LeaseTextImage} class="h-4 w-auto xl:h-auto" />
            <img src={LeaseImage} class="-mb-6 w-8 xl:-mb-8 xl:w-auto" />
          </Match>
          <Match when={props.type === 'unit'}>
            <img src={UnitTextImage} class="h-4 w-auto xl:h-auto" />
            <img src={UnitImage} class="-mb-6 w-8 xl:-mb-8 xl:w-auto" />
          </Match>
        </Switch>
      </div>
    </div>
  );
};
