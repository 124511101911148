import { Show, createMemo, For } from 'solid-js';
import IconField from '~/components/common/IconField';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { IconSquarePen } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { Section } from '~/pages/settings/account-settings/types';

export const InformationOverview: Component<{ sections: Section[]; onEdit: () => void; title?: string }> = (props) => {
  const { t } = useLocalization();

  const memo = createMemo(() => props.sections.find((s) => s.field === 'memo'));

  return (
    <ThreeSectionBox
      class="border-input-border"
      coloredClass="border-input-border bg-input"
      topTitle={<div class="text-sm font-medium text-text-level02">{t(props?.title || 'Basic information')}</div>}
      topAction={
        <button class="flex hover:opacity-80" onClick={props.onEdit}>
          <IconSquarePen class="size-5" />
        </button>
      }
      middle={
        <div class="flex w-full flex-col gap-8 p-4 text-left">
          <div class="grid justify-between gap-y-6 md:grid-cols-2 xl:grid-cols-3">
            <For each={props.sections.filter((s) => s.field !== 'memo')}>
              {(section) => <IconField classLabel="text-sm" name={t(section.label)} value={section.value} src={section.icon} />}
            </For>
          </div>
          <Show when={memo()}>
            <div class="rounded-md border p-3 text-sm font-medium text-text-level01">
              <span class="text-text-level02">{t('Memo')}:</span> {memo()?.value}
            </div>
          </Show>
        </div>
      }
    />
  );
};
