import { FormItem } from '~/components/common/BetterForm';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';

interface LabeledInsuranceInputProps extends LabeledGroupProps {
  value: MagicDoor.VendorInsuranceModel;
  onInput: (value: MagicDoor.VendorInsuranceModel) => void;
  hasError?: boolean;
  onClick?: (isMouseDown: boolean) => void;
  isSubmit?: boolean;
}

const LabeledInsuranceInput: Component<LabeledInsuranceInputProps> = (props) => {
  const { t } = useLocalization();

  const handleChange = (name: string, value: string | null) => {
    const updatedValue = { ...props.value, [name]: value };
    props.onInput(updatedValue);
  };

  return (
    <div class="flex flex-col gap-5 py-6">
      <div class="flex gap-5">
        <div class="w-1/2">
          <LabeledTextInput
            label={t('Provider')}
            placeholder={t('Enter provider')}
            value={props.value.provider}
            onInput={(value: string | null) => handleChange('provider', value)}
            onClick={props.onClick}
          />
        </div>
        <div class="w-1/2">
          <FormItem
            label={t('Policy number')}
            placeholder={t('Enter policy number')}
            onClick={props.onClick}
            formFieldName={['insurancePolicy', 'policyNumber']}
            component={LabeledTextInput}
          />
        </div>
      </div>
      <div class="flex gap-5">
        <div class="w-1/2">
          <DueDateInputField
            label={t('Expiration date')}
            placeholder={t('Select expiration date')}
            value={props.value.expirationDate}
            onInput={(value: string | null) => handleChange('expirationDate', value)}
            onClick={props.onClick}
          />
        </div>
        <div class="w-1/2" />
      </div>
    </div>
  );
};

export default LabeledInsuranceInput;
