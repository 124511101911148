import { BaseRestRepository } from './baseRestRepository';
import type { DeleteBillDto } from '~/swagger/Api';
const LEASES_URL = '/api/leases';
export function generateDummyDataForMonth(month: string): MagicDoor.VendorTransaction {
  return {
    id: month,
    month: month,
    creditAmount: String(Math.floor(Math.random() * 100)),
    billAmount: String(Math.floor(Math.random() * 100)),
  };
}

export class TransactionsRepository extends BaseRestRepository {
  public async getTransactions(): Promise<MagicDoor.VendorTransaction[]> {
    const MONTHS = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const transactionsData = MONTHS.map(generateDummyDataForMonth);
    return transactionsData;
  }
  public async getLeaseRentTransactions(leaseId: string, take?: number): Promise<MagicDoor.Api.HydratedGetLeaseTransactionsDto> {
    const url = `${LEASES_URL}/${leaseId}/transactions` + (take ? `/?Take=${take}` : '');
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async setupCharge(leaseId: string, payload: MagicDoor.Api.CreateLeaseChargeDto): Promise<void> {
    const url = `${LEASES_URL}/${leaseId}/charges`;
    await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  public async offlinePayment(leaseId: string, payload: MagicDoor.Api.OfflinePaymentDto, showErrorMessage = true): Promise<void> {
    const url = `${LEASES_URL}/${leaseId}/charges/offline-payment`;
    await this.fetchWithAuth(
      url,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
      showErrorMessage
    );
  }

  public async addCredit(leaseId: string, payload: MagicDoor.Api.CreateLeaseCreditDto): Promise<void> {
    const url = `${LEASES_URL}/${leaseId}/charges/credit`;
    await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  public async deleteTransaction(transactionId: string): Promise<void> {
    const url = `/api/transactions/${transactionId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  public async deleteBill(billId: string, params: DeleteBillDto): Promise<void> {
    const url = `/api/bills/${billId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
      body: JSON.stringify(params),
    });
  }

  public async payoutTenant(
    leaseId: string,
    request: MagicDoor.Api.ReleaseLeaseBalanceRequestDto
  ): Promise<MagicDoor.Api.HydratedTransactionDto> {
    const url = `${LEASES_URL}/${leaseId}/release`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(request),
    });
    return this.getJsonResponse(response);
  }

  public async markAsReturned(leaseId: string, transactionId: string, payload: MagicDoor.Api.ReturnLeasePaymentDto): Promise<void> {
    const url = `${LEASES_URL}/${leaseId}/payments/return/${transactionId}`;
    await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  }

  public async updateTransaction(
    transactionId: string,
    payload: MagicDoor.Api.UpdateTransactionDto
  ): Promise<MagicDoor.Api.HydratedTransactionDto> {
    const url = `/api/transactions/${transactionId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updateBill(billId: string, payload: MagicDoor.Api.UpdateBillDto): Promise<MagicDoor.Api.BillDto> {
    const url = `/api/bills/${billId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getTransaction(transactionId: string): Promise<MagicDoor.Api.HydratedTransactionDto> {
    const url = `/api/transactions/${transactionId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}
