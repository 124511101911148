import { FormItem } from '~/components/common/BetterForm';
import { useCategoryComponentWrapper } from '~/components/common/Category';
import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

export const OwnerAddressSection: Component = () => {
  const { t } = useLocalization();
  const { onClickSection } = useCategoryComponentWrapper();

  const handleInteraction = () => {
    onClickSection('addressInformation');
  };

  return (
    <div id="addressInformation" onClick={handleInteraction}>
      <div class="sticky top-0 z-10 border-b bg-white py-3 text-lg font-semibold text-text-level01">{t('Address')}</div>

      <div class="py-8">
        <FormItem class="col-span-3 mt-4" formFieldName="address" component={LabeledAddressInput} />
      </div>
    </div>
  );
};
