import { createSignal } from 'solid-js';
import { eventEmitter } from '~/pdfsigner/state/eventEmitter';
import type { EventEmitter } from '~/pdfsigner/state/eventEmitter';
import type { UseCase } from '~/pdfsigner/usecases/useCase';

function useUseCase(useCaseClass: new (eventEmitter?: EventEmitter) => UseCase) {
  const useCase = new useCaseClass(eventEmitter);
  const [isLoading, setIsLoading] = createSignal(false);

  const execute = async (params?: any) => {
    try {
      setIsLoading(true);
      await useCase.execute(params);
    } finally {
      setIsLoading(false);
    }
  };
  return { execute, isLoading };
}

export default useUseCase;
