import { A } from '@solidjs/router';
import dayjs from 'dayjs';
import { For, Show, createEffect, createMemo, createSignal } from 'solid-js';
import IconArchiveList from '~/assets/images/chat/archiveList.svg?component-solid';
import { PortfolioIcon } from '~/components/portfolios';
import { DropdownActions, IconListFilter, IconMessageSquareText, IconSearch, IconX } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { useChat, useChatsList } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { ChatDropdown } from './ChatDropdown';
import { categoryIcons, parseChats } from './chatUtils';
import type { CategorizedChats } from './chatUtils';
import type { Component, ComponentProps } from 'solid-js';

export const ChatSidebar: Component<ComponentProps<'aside'>> = (props) => {
  const { t } = useLocalization();
  const { chats, isLoading } = useChatsList();
  const { chatId } = useChat();
  const { companies } = useCompanies();

  const [categorizedChats, setCategorizedChats] = createSignal<CategorizedChats>({});
  const [filter, setFilter] = createSignal<string>('clearFilter');
  const [searchQuery, setSearchQuery] = createSignal<string>('');

  const searchingInProgress = createMemo<boolean>(() => !!searchQuery());

  const filteredChats = createMemo<Record<string, any>>(() => {
    const currentFilter = filter();
    const now = dayjs();
    const searchLowerQuery = searchQuery().toLowerCase();

    // eslint-disable-next-line solid/reactivity
    return Object.keys(categorizedChats()).reduce<Record<string, any>>((acc, category) => {
      let chats = categorizedChats()[category];

      chats = chats.filter((chat) => !chat.closed);

      if (searchQuery()) {
        chats = chats.filter((chat) => chat.subject.toLowerCase().includes(searchLowerQuery));
      }

      chats = chats.filter((chat) => {
        const chatDate = chat.lastMessageSentAt ? dayjs(chat.lastMessageSentAt) : null;
        const monthDifference = chatDate ? chatDate.diff(now, 'month') : null;
        const isUnread = chat.unreadMessages > 0;
        const isWithin1Month = monthDifference !== null && monthDifference < 1;
        const isWithin3Months = monthDifference !== null && monthDifference < 3;

        switch (currentFilter) {
          case 'unread':
            return isUnread;
          case '1month':
            return isWithin1Month;
          case '3months':
            return isWithin3Months;
          default:
            return true;
        }
      });

      acc[category] = chats;
      return acc;
    }, {});
  });

  const filterText = createMemo(() => {
    switch (filter()) {
      case 'unread':
        return t('Unread messages');
      case '1month':
        return t('Within 1 month');
      case '3months':
        return t('Within 3 months');
    }
  });

  createEffect(() => {
    if (!isLoading()) {
      setCategorizedChats(parseChats(chats()));
    }
  });

  return (
    <aside {...props} class={cn('w-[340px] bg-partingline', props.class)}>
      <div class="thinscroll h-[calc(100%-56px)] w-full select-none overflow-auto px-5 py-3">
        <div class="flex items-center justify-between gap-2 border-b py-4">
          <PortfolioIcon />
          <div class="mr-auto	text-lg font-semibold capitalize">{companies()?.name}</div>
          <A href="/communications/chats/add" class="rounded-full bg-primary p-2 text-white" noScroll>
            <IconMessageSquareText class="size-5" />
          </A>
        </div>

        <div class="relative my-4 flex w-full items-center gap-2">
          <DropdownActions
            align="start"
            class="shrink-0"
            triggerClass="rounded-lg border border-text-level03 p-2 aria-expanded:border-primary aria-expanded:text-primary"
            actions={[
              {
                label: t('Reset to default'),
                onClick: () => setFilter('clearFilter'),
              },
              {
                label: t('Unread'),
                onClick: () => setFilter('unread'),
              },
              {
                label: t('Within 1 month'),
                onClick: () => setFilter('1month'),
              },
              {
                label: t('Within 3 months'),
                onClick: () => setFilter('3months'),
              },
            ]}>
            <IconListFilter class="size-5" />
          </DropdownActions>
          <input
            type="text"
            onInput={(e) => setSearchQuery(e.target.value)}
            placeholder={`${t('Search chats')}...`}
            class="w-full rounded-lg border border-text-level03 bg-transparent px-3 py-2 outline-none transition-colors focus:border-primary"
          />
          <IconSearch class="absolute right-3 size-4 text-text-level03" />
        </div>

        <Show when={filterText()}>
          <div class="my-4 flex w-fit items-center gap-1 rounded-full border border-primary/50 px-2 py-0.5 text-xs text-primary">
            <span>
              {t('Filter')}: {filterText()}
            </span>
            <button onClick={() => setFilter('clearFilter')}>
              <IconX class="size-3.5 text-text-level03" />
            </button>
          </div>
        </Show>

        <For each={Object.keys(categorizedChats())}>
          {(category) => (
            <ChatDropdown
              icon={categoryIcons[category]}
              title={category}
              chats={filteredChats()[category]}
              selectedChatId={chatId() || ''}
              searchingInProgress={searchingInProgress()}
            />
          )}
        </For>
      </div>

      <A
        href="/communications/archive-messages"
        class="flex h-[56px] items-center justify-center gap-1.5 border-t bg-white text-sm font-medium text-text-level02">
        <IconArchiveList /> <span>{t('Archive Messages')}</span>
      </A>
    </aside>
  );
};
