import { createSignal } from 'solid-js';
import { createMagicDoorContext } from '~/contexts/utils';
import { chartOfAccountSettingRepository } from '~/repositories/settings/chartOfAccountSettingsRepository';
import type { Accessor } from 'solid-js';

interface ChartOfAccountSettingsContextValue {
  addChartOfAccountSettings: (payload: MagicDoor.Api.LeaseChartOfAccountSettingsDto) => Promise<void>;
  addChartOfAccountSettingsByLevel: (
    level: string,
    levelId: string,
    payload: MagicDoor.Api.LeaseChartOfAccountSettingsDto
  ) => Promise<void>;
  getChartOfAccountSettings: () => Promise<MagicDoor.Api.LeaseChartOfAccountSettingsDto | undefined>;
  getChartOfAccountSettingsByLevel: (level: string, levelId: string) => Promise<MagicDoor.Api.LeaseChartOfAccountSettingsDto | undefined>;
  getAllChartOfAccountSettings: () => Promise<MagicDoor.Api.LeaseChartOfAccountSettingsDto | undefined>;
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  chartOfAccountSettings: Accessor<MagicDoor.Api.LeaseChartOfAccountSettingsDto | undefined>;
}

export const [ChartOfAccountSettingsProvider, useChartOfAccountSettings] = createMagicDoorContext<ChartOfAccountSettingsContextValue>(
  'ChartOfAccountSettings',
  () => {
    const repo = new chartOfAccountSettingRepository();
    const [chartOfAccountSettings, setChartOfAccountSettings] = createSignal<MagicDoor.Api.LeaseChartOfAccountSettingsDto | undefined>(
      undefined
    );
    const [isLoading, setIsLoading] = createSignal<boolean>(false);
    const [error, setError] = createSignal<Error | undefined>(undefined);

    const addChartOfAccountSettings = async (payload: MagicDoor.Api.LeaseChartOfAccountSettingsDto) => {
      setIsLoading(true);
      try {
        await repo.addChartOfAccountSettings(payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const addChartOfAccountSettingsByLevel = async (
      level: string,
      levelId: string,
      payload: MagicDoor.Api.LeaseChartOfAccountSettingsDto
    ) => {
      setIsLoading(true);
      try {
        await repo.addChartOfAccountSettingsByLevel(level, levelId, payload);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getChartOfAccountSettings = async (): Promise<MagicDoor.Api.LeaseChartOfAccountSettingsDto | undefined> => {
      setIsLoading(true);
      try {
        const result = await repo.getChartOfAccountSettings();
        setChartOfAccountSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getChartOfAccountSettingsByLevel = async (
      level: string,
      levelId: string
    ): Promise<MagicDoor.Api.LeaseChartOfAccountSettingsDto | undefined> => {
      setIsLoading(true);
      try {
        const result = await repo.getChartOfAccountSettingsByLevel(level, levelId);
        setChartOfAccountSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    const getAllChartOfAccountSettings = async (): Promise<MagicDoor.Api.LeaseChartOfAccountSettingsDto | undefined> => {
      setIsLoading(true);
      try {
        const result = await repo.getAllChartOfAccountSettings();
        setChartOfAccountSettings(result);
        return result;
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    };

    return {
      addChartOfAccountSettings,
      addChartOfAccountSettingsByLevel,
      getChartOfAccountSettings,
      getChartOfAccountSettingsByLevel,
      getAllChartOfAccountSettings,
      isLoading,
      chartOfAccountSettings,
      error,
    };
  }
);
