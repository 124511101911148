import { useParams } from '@solidjs/router';
import { createMemo, createEffect, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { Panel } from '~/components/common/Panels';
import { LeasePeriod, LeaseStatus } from '~/components/leases';
import { formatTenantNamesWithoutContainer } from '~/components/tenants/utils';
import { Table } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type CurrentLeasesTableProps = {
  class?: string;
  leases: MagicDoor.Api.HydratedLeaseDto[];
};

export const OngoingLeasesTable: Component<CurrentLeasesTableProps> = (props) => {
  const params = useParams();
  const { t } = useLocalization();
  const { filtered, setFilter } = useTenants();

  createEffect(() => {
    setFilter({ page: 1, pageSize: 100, active: true });
  });

  const getTenantNames = (tenants: MagicDoor.Api.LeaseTenantDto[]) => {
    const ids = tenants?.map((tenant) => tenant.tenantId);

    return formatTenantNamesWithoutContainer(filtered()?.items?.filter((tenant) => ids?.includes(tenant.id)));
  };

  const query = () => new URLSearchParams(params);

  const columns = createMemo<TableColumns<MagicDoor.Api.HydratedLeaseDto>>(() => [
    {
      title: t('Lease term'),
      render: (item) => (
        <>
          <LeaseStatus lease={item} />
          <LeasePeriod lease={item} />
        </>
      ),
    },
    {
      title: t('Tenants'),
      render: (item) => (
        <div class="flex items-center">
          <div class="ml-2 text-nowrap text-sm font-medium">{getTenantNames(item.tenants as any)}</div>
        </div>
      ),
    },
    {
      title: t('Rent'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => (
        <div>
          <div class="text-sm text-text-level01">{currency(item.currentRent)}</div>
          <div class="mt-1 text-xs text-text-level03">
            {t('Last payment')}:
            <Show when={item.lastPaymentDate} fallback={emptyPlaceholder}>
              {dateFormat('', item.lastPaymentDate)}
            </Show>
          </div>
        </div>
      ),
    },
    {
      title: t('Balances'),
      headerClass: 'text-right',
      render: (item) => <CnMoney money={item.balance} showIcon />,
    },
    {
      title: t('Posted balances'),
      headerClass: 'text-right',
      render: (item) => <CnMoney money={item.postedBalance} showIcon />,
    },
  ]);

  return (
    <Panel
      title={t('Leases')}
      class={props.class}
      actions={
        <div class="flex gap-2">
          <LinkButton href={`/leasing/leases/new?${query()}`} class="ml-auto text-sm">
            <IconPlus /> {t('Set up a new lease')}
          </LinkButton>
        </div>
      }>
      <div class="thinscroll overflow-x-auto">
        <Table columns={columns()} data={props.leases} rowLink={(item) => `/leasing/leases/${item.id}?from=unit`} />
      </div>
    </Panel>
  );
};
