import { useLocalization } from '~/contexts/global';
import { LeaseStatus } from '~/swagger/Api';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';

export const isMonthToMonth = (lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto | undefined) =>
  lease?.status === 'monthToMonth';

export const isEndedLease = (lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto | undefined) => lease?.status === 'ended';

export const isFutureLease = (lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto | undefined) => lease?.status === 'future';
export const isEvictionLease = (lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto | undefined) => lease?.status === 'eviction';
export const isLeased = (lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto | undefined) =>
  lease?.status && ['lease', 'leaseMonthToMonth'].includes(lease?.status);
export const isExpiredLease = (lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto | undefined) => lease?.status === 'expired';
export const isDraftLease = (lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto | undefined) => lease?.status === 'draft';

export const getDeposit = (lease: MagicDoor.Api.HydratedLeaseDto | undefined) => {
  if (lease) {
    const { securityDepositPaid, securityDepositRequested } = lease;
    return securityDepositPaid === securityDepositRequested
      ? `${currency(securityDepositPaid)}`
      : `${currency(securityDepositPaid)} / ${currency(securityDepositRequested)}`;
  }
  return emptyPlaceholder;
};

export const leaseStatusText = (lease: MagicDoor.Api.HydratedLeaseDto | undefined) => {
  const { t } = useLocalization();
  if (!lease) return t('Loading');
  return leaseStatusString(lease.status);
};

export const leaseStatusString = (status: LeaseStatus | undefined) => {
  const { t } = useLocalization();
  switch (status) {
    case LeaseStatus.Ended:
      return t('Ended lease');
    case LeaseStatus.Future:
      return t('Coming up');
    case LeaseStatus.MonthToMonth:
      return t('Month-To-Month');
    case LeaseStatus.Eviction:
      return t('Eviction');
    case LeaseStatus.Expired:
      return t('Expired');
    case LeaseStatus.Draft:
      return t('Draft');
    case LeaseStatus.LeaseMonthToMonth:
    case LeaseStatus.Lease:
      return t('Leased');
    default:
      return t('Unknown');
  }
};

export const leasePeriodString = (lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto) => {
  const arr = isMonthToMonth(lease) ? [lease?.start] : [lease?.start, lease?.end];
  return arr
    .filter((e) => e)
    .map((date) => (date ? dateFormat('', date) : ''))
    .join(' - ');
};

export const getLeaseFullname = (lease?: MagicDoor.Api.HydratedLeaseDto) => {
  return lease && lease?.property
    ? `${lease?.property?.displayName || lease?.property?.address?.streetAddress1}, ${lease?.unit?.name}`
    : emptyPlaceholder;
};

export const getUnitFullname = (unit?: MagicDoor.Api.HydratedUnitDto) => {
  return unit && unit?.property
    ? `${unit?.property?.displayName || unit?.property?.address?.streetAddress1}, ${unit?.name}`
    : emptyPlaceholder;
};

export const getLeaseDateName = (lease: MagicDoor.Api.HydratedLeaseDto) => {
  return `${lease.unit?.name}-[${leasePeriodString(lease)}]`;
};

export const getDescription = (description: any) => {
  if (typeof description === 'string') {
    const jsonMatch = description.match(/{.*?}/);
    if (jsonMatch) {
      try {
        const parsedDescription = JSON.parse(jsonMatch[0]);
        const remainingText = description.slice(jsonMatch[0].length).trim();
        if (typeof parsedDescription === 'object' && parsedDescription !== null && parsedDescription.Key) {
          return remainingText ? `${parsedDescription.Key} ${remainingText}` : parsedDescription.Key;
        }
      } catch (e) {
        return description;
      }
    }
    return description;
  } else if (typeof description === 'object' && description !== null) {
    if (description.Key) {
      return description.Key;
    } else {
      return JSON.stringify(description);
    }
  }
  return '';
};
