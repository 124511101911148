import { createEffect, createSignal, For, Show } from 'solid-js';
import IconCheck from '~/assets/images/settings/portal/chek.svg?component-solid';
import IconImgUpload from '~/assets/images/settings/portal/imgUpload.svg?component-solid';
import IconColorTheme from '~/assets/images/settings/portal/portalColorTheme.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import MiddleModal from '~/components/modals/MiddleModal';
import { Modal } from '~/components/modals/Modal';
import { DragAndDrop } from '~/components/ui';
import { useLocalization, useCompanies } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { themeColors } from '~/utils/constant';
import { getFileAllUrl } from '~/utils/file';
import { cloneDeep } from '~/utils/tool';

export const PortalColorTheme = () => {
  const { t } = useLocalization();
  const { portalSettings, uploadFiles, updatePortalSettings } = useCompanies();
  const [activeColorTheme, setActiveColorTheme] = createSignal<string | undefined>();
  const [themeModal, setThemeModal] = createSignal<boolean>(false);
  const [previewModal, setPreviewModal] = createSignal<boolean>(false);

  const handleSwitchColorTheme = (theme: string) => {
    setActiveColorTheme(theme);
    setThemeModal(true);
  };
  const submitTheme = async () => {
    const params = cloneDeep(portalSettings()?.metadata);
    params.themeColor = activeColorTheme();
    try {
      await updatePortalSettings({ metadata: params });
      setThemeModal(false);
    } catch (err) {
      console.log(err);
    }
  };
  const SwitchColorTheme = () => {
    return (
      <>
        <ul class="flex gap-5">
          <For each={themeColors}>
            {(theme) => (
              <li
                class="relative size-16 cursor-pointer rounded-full border border-[#E5E3F2]"
                style={{ background: theme.value }}
                onClick={() => handleSwitchColorTheme(theme.label)}>
                <Show when={activeColorTheme() === theme.label}>
                  <IconCheck class="absolute right-0" />
                </Show>
              </li>
            )}
          </For>
        </ul>
        <Modal
          visible={themeModal()}
          onDone={() => submitTheme()}
          doneText={t('Sure')}
          class="lg:w-modalSm"
          title={t('Change theme')}
          onCancel={() => {
            setThemeModal(false);
            setActiveColorTheme(portalSettings()?.metadata?.themeColor);
          }}>
          <div class="p-4">{t('Are you sure you want to change the theme?')}</div>
        </Modal>
        <Show when={previewModal()}>
          <MiddleModal class="lg:w-modalSm" onClose={() => setPreviewModal(false)} confirmation={false}>
            <div class="relative flex flex-col overflow-hidden">
              <div class="absolute w-full bg-white px-6 py-3">
                <p class="text-lg font-semibold text-title-gray">{t('Preview theme')}</p>
                <p class="text-xs font-light text-text-level03">
                  {t('The data in the preview website is virtual data and does not represent the data after the launch')}
                </p>
              </div>
              <div class="thinscroll overflow-auto bg-[#EEF0F6] p-6 pt-20" />
            </div>
          </MiddleModal>
        </Show>
      </>
    );
  };
  const LogoDisplay = () => {
    const [logoSrc, setLogoSrc] = createSignal<string>('');
    const [iconSrc, setIconSrc] = createSignal<string>('');

    const handleUpload = async (e: Event, type: string) => {
      const input = e.target as HTMLInputElement;
      if (input.files?.length !== 1) return;
      const file = input.files[0];
      try {
        const result = await uploadFiles(file, type, true);
        let param;
        if (result) {
          if (type === 'logo') {
            param = { logoFileId: result.file.fileId };
            setLogoSrc(result.file?.signedUrl);
          } else {
            param = { iconFileId: result.file.fileId };
            setIconSrc(result.file?.signedUrl);
          }
          await updatePortalSettings(param);
        }
      } catch (err) {
        console.log(err);
      }
    };
    createEffect(() => {
      if (portalSettings()?.logoUrl) {
        setLogoSrc(getFileAllUrl(portalSettings()?.logoUrl));
      }
      if (portalSettings()?.iconUrl) {
        setIconSrc(getFileAllUrl(portalSettings()?.iconUrl));
      }
    });
    return (
      <div class="my-8 border-y border-input-border py-6">
        <h2 class="text-base font-semibold capitalize text-title-gray">{t('Logo display')}</h2>
        <p class="mt-1 text-xs text-text-level03">
          {t('Upload your logo according to the theme. For dark theme, a lighter or white version of your logo is recommended.')}
        </p>
        <div class="mt-4 flex gap-2">
          <div class="flex items-center gap-3">
            <Show when={logoSrc()}>
              <div
                class="size-16 rounded-full border border-essential-colour bg-cover bg-center bg-no-repeat"
                style={{ 'background-image': `url(${logoSrc()})` }}
              />
            </Show>
            <label class="group relative cursor-pointer">
              <span class="flex cursor-pointer items-center gap-1 rounded-lg bg-primary px-5 py-2.5 text-base text-white">
                <IconImgUpload />
                {t('Upload logo')}
                <input hidden type="file" accept="image/*" onChange={(e) => handleUpload(e, 'logo')} />
              </span>
            </label>
          </div>
        </div>
        <div class="mt-8">
          <h2 class="text-base font-semibold capitalize text-title-gray">{t('Icon display')}</h2>
          <p class="mt-1 text-xs text-text-level03">{t('Choose an icon that represents your brand and displays in browser tabs.')}</p>
          <div class="mt-4 flex gap-2">
            <div class="flex items-center gap-3">
              <Show when={iconSrc()}>
                <div
                  class="size-16 rounded-lg border border-[#C6D2E1] bg-cover bg-center bg-no-repeat"
                  style={{ 'background-image': `url(${iconSrc()})` }}
                />
              </Show>
              <label class="group relative cursor-pointer">
                <span class="flex cursor-pointer items-center gap-1 rounded-lg bg-primary px-5 py-2.5 text-base text-white">
                  <IconImgUpload />
                  {t('Upload icon')}
                  <input hidden type="file" accept="image/*" onChange={(e) => handleUpload(e, 'icon')} />
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const PhotoDisplay = () => {
    const handleDropFiles = async (fileList: FileList) => {
      try {
        const result = await uploadFiles(fileList[0], 'cover');
        if (result) {
          await updatePortalSettings({ coverFileId: result.file.fileId });
        }
      } catch (err) {
        console.log(err);
      }
    };
    return (
      <>
        <h2 class="text-base font-semibold capitalize text-title-gray">{t('Photo display')}</h2>
        <p class="mt-1 text-xs text-text-level03">{t('Select and upload a background photo.')}</p>
        <div
          class="rounded-lg bg-light-gray"
          style={{
            'background-image': `url(/api${portalSettings()?.coverUrl})`,
            'background-repeat': 'no-repeat',
            'background-position': 'center',
            'background-size': 'cover',
          }}>
          <DragAndDrop
            accept="image/*"
            class="mt-6 flex h-52 cursor-pointer flex-col items-center justify-center"
            onDropFiles={handleDropFiles}>
            <span class="flex items-center gap-1 rounded-lg bg-primary px-5 py-2.5 text-base text-white">
              <IconImgUpload />
              {t('Upload background photo')}
            </span>
          </DragAndDrop>
        </div>
      </>
    );
  };

  createEffect(() => {
    if (portalSettings()?.metadata.themeColor) {
      setActiveColorTheme(portalSettings()?.metadata.themeColor);
    }
  });
  return (
    <ThreeSectionBox
      class="border-0"
      topTitle={<SettingTitle title={t('Images')} icon={IconColorTheme} />}
      // topAction={
      //   <Button variant="white" class="rounded-3xl px-3 py-1 text-sm" onClick={() => setPreviewModal(true)}>
      //     <IconPreview /> <div class="hidden font-medium md:block">{t('Preview theme')}</div>
      //   </Button>
      // }
      middle={
        <div class="p-6">
          <SwitchColorTheme />
          <LogoDisplay />
          <PhotoDisplay />
        </div>
      }
    />
  );
};
