import { useParams } from '@solidjs/router';
import { useUnits } from '~/contexts/global';
import { AnnouncementProvider } from '~/contexts/local';
import AnnouncementsListView from '~/pages/announcements/list-announcements/AnnouncementsListView';

export const UnitAnnouncement = () => {
  const params = useParams();
  const { unit } = useUnits();

  return (
    <AnnouncementProvider>
      <div class="flex w-full gap-5 text-sm">
        <AnnouncementsListView
          filter={{ unitIds: [unit()?.id || params.unitId] }}
          newAnnouncementsParams={{
            unitId: unit()?.id || params.unitId,
            targetType: 'lease',
            selectionType: 'unit',
            portfolioId: unit()?.portfolioId || params.portfolioId,
            propertyId: unit()?.propertyId || params.propertyId,
            name: unit()?.name || '',
          }}
        />
      </div>
    </AnnouncementProvider>
  );
};
