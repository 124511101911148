import type { PaymentStatusIndicatorProps, StatusIndicatorProps } from './constants';
import type { Component } from 'solid-js';

export const PaymentStatusIndicator: Component<PaymentStatusIndicatorProps> = (props) => {
  return (
    <div class="relative flex h-4 items-center justify-center overflow-hidden rounded-2xl px-2" style={{ color: props.color }}>
      <div class="capitalize">{props.label}</div>
      <div class="absolute inset-0" style={{ background: props.color, opacity: 0.2 }} />
    </div>
  );
};

export const ReportStatusIndicator: Component<StatusIndicatorProps> = (props) => (
  <div class="relative flex h-4 items-center justify-center overflow-hidden rounded-2xl px-2" style={{ color: props.color }}>
    <div class="mr-1 size-2 rounded-full" style={{ background: props.color }} />
    <div class="capitalize">{props.label}</div>
    <div class="absolute inset-0" style={{ background: props.color, opacity: 0.2 }} />
  </div>
);
