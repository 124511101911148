import { BaseRestRepository } from '~/repositories/baseRestRepository';
import { MaintenanceRequestRepository } from '~/repositories/maintenanceRequestRepository';
import { urlWithQuery } from '~/utils/url';
import type { MaintenanceRequestFilter } from '~/repositories/maintenanceRequestRepository';

const BASE_URL = '/api/run-books';

export class runBooksRepository extends BaseRestRepository {
  public async getRunBooks(filters: any): Promise<MagicDoor.Api.RunBooksPaginationDto> {
    const url = BASE_URL;
    const response = await this.fetchWithAuth(urlWithQuery(url, filters));
    return this.getJsonResponse(response);
  }

  public async getRunBookById(runBookId: string): Promise<MagicDoor.Api.HydratedRunBookDto> {
    const url = `${BASE_URL}/${runBookId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async enableRunBook(runBookId: string): Promise<void> {
    const url = `${BASE_URL}/${runBookId}/enable`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
    });
  }

  public async disableRunBook(runBookId: string): Promise<void> {
    const url = `${BASE_URL}/${runBookId}/disable`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
    });
  }

  public async deleteRunBook(runBookId: string) {
    const url = `${BASE_URL}/${runBookId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
  }

  public async addRunBook(payload: MagicDoor.Api.CreateRunBookDto): Promise<MagicDoor.Api.HydratedRunBookDto> {
    const url = BASE_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
  public async updateRunBook(runBookId: string, payload: MagicDoor.Api.UpdateRunBookDto): Promise<void> {
    const url = `${BASE_URL}/${runBookId}`;
    await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
  }

  public async getRunBookMaintenanceRequest(
    filters: Omit<MaintenanceRequestFilter, 'runBookId'> & { runBookId: string }
  ): Promise<MagicDoor.Api.MaintenanceRequestPaginationDto> {
    const maintenanceRequestRepository = new MaintenanceRequestRepository();
    return maintenanceRequestRepository.getMaintenanceRequests(filters);
  }

  public async updateDefaultVendor(
    runBookId: string,
    payload: MagicDoor.Api.UpdateRunBookDefaultVendorDto
  ): Promise<MagicDoor.Api.HydratedRunBookDto> {
    const url = `${BASE_URL}/${runBookId}/vendor`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updateDefaultPropertyManager(
    runBookId: string,
    payload: MagicDoor.Api.UpdateRunBookDefaultPropertyManagerDto
  ): Promise<MagicDoor.Api.HydratedRunBookDto> {
    const url = `${BASE_URL}/${runBookId}/property-manager`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async addVendorOverride(
    runBookId: string,
    payload: MagicDoor.Api.AddVendorOverwriteForRunBookRequestDto
  ): Promise<MagicDoor.Api.HydratedRunBookDto> {
    const url = `${BASE_URL}/${runBookId}/overwrite/vendors`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async addPropertyManagersOverride(
    runBookId: string,
    payload: MagicDoor.Api.AddPropertyManagerOverwriteForRunBookRequestDto
  ): Promise<MagicDoor.Api.HydratedRunBookDto> {
    const url = `${BASE_URL}/${runBookId}/overwrite/property-managers`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteVendorOverride(runBookId: string, overwriteId: string) {
    const url = `${BASE_URL}/${runBookId}/overwrite/vendors/${overwriteId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }
  public async deletePropertyManagersOverride(runBookId: string, overwriteId: string) {
    const url = `${BASE_URL}/${runBookId}/overwrite/property-managers/${overwriteId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'DELETE',
    });
    return this.getJsonResponse(response);
  }
}
