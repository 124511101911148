import { Switch, Match, For, createSignal, createMemo } from 'solid-js';
import { produce } from 'solid-js/store';
import CircleArrowIcon from '~/assets/images/common/circleArrow.svg?component-solid';
import { Empty } from '~/components/common/Empty';
import { unicodeToEscapeSequence } from '~/components/common/FeedbackIcon/utils';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { RingLoader } from '~/components/common/Loaders';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import { isNull, isUndefined } from '~/utils/tool';
import type { Component } from 'solid-js';
import type { BillItemProps, BillPayments, PayBillsSubForm } from '~/pages/bills/pay-bill/types';

const BillItem = (props: BillItemProps) => {
  const { t } = useLocalization();
  const [activeIndex, setActiveIndex] = createSignal<number[]>([0]);

  const changeActive = (index: number) => {
    const bisIndex = activeIndex().findIndex((item) => item === index);
    setActiveIndex((prev) => {
      if (bisIndex === -1) {
        return [...prev, index];
      }
      return prev.toSpliced(bisIndex, 1);
    });
  };
  const isOpen = (index: number) => {
    return activeIndex().includes(index);
  };

  const totalPaymentAmount = createMemo(() => {
    return props.bills.reduce((total, item) => total + Number(item.paymentAmount || 0), 0);
  });

  return (
    <>
      <For each={props.bills}>
        {(item, index) => (
          <div class="mb-4 w-full overflow-hidden rounded-lg border">
            <div
              onClick={() => changeActive(index())}
              class={cn('flex cursor-pointer items-center justify-between bg-input px-3 py-4', { 'border-b rounded-lg': isOpen(index()) })}>
              <PropertyTitle property={item.property} feedbackIconReadonly />
              <div class="flex shrink-0 items-center justify-start">
                <span class="text-xs uppercase text-text-level02">{t('Payment amount')}</span>：
                <span class="text-xs font-semibold text-text-level01">{currency(item.paymentAmount)}</span>
                <CircleArrowIcon
                  class="ml-5 mr-1"
                  classList={{
                    'text-text-level02': !isOpen(index()),
                    'text-primary rotate-180': isOpen(index()),
                  }}
                />
              </div>
            </div>
            <div class={cn('w-full px-5 transition-all', { hidden: !isOpen(index()), block: isOpen(index()) })}>
              <For each={item.vendors}>
                {(vendor, vendorIndex) => (
                  <>
                    <div class="my-3 text-xs font-semibold text-text-level01">{t('Vendor')}</div>
                    <div class="mb-5 flex items-center text-sm font-medium text-text-level01">
                      <span class="mr-2">{vendor.name}</span>
                      <For each={vendor.icons}>
                        {(icon) => (
                          <div class="flex cursor-pointer items-center justify-center rounded-full border-essential-colour">
                            <emoji-regular class="text-xl">{unicodeToEscapeSequence(icon)}</emoji-regular>
                          </div>
                        )}
                      </For>
                    </div>
                    <div class="mb-3 text-xs font-semibold text-text-level01">{t('Bills')}</div>
                    <div class="mb-4">
                      <div class="not-prose relative bg-white">
                        <div
                          class="bg-grid-slate-100 absolute inset-0 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] "
                          style={{ 'background-position': '10px 10px' }}
                        />
                        <div class="relative">
                          <div class="my-1 overflow-hidden rounded-lg shadow-sm">
                            <table class="w-full table-auto border-collapse bg-cell-purple-bg text-xs text-cell-purple-text">
                              <thead>
                                <tr>
                                  <th class="border-b p-3.5 text-left font-normal">{t('Due date')}</th>
                                  <th class="border-b p-3.5 text-left font-normal">{t('Memo')}</th>
                                  <th class="border-b p-3.5 text-left font-normal">{t('Chart of accounts')}</th>
                                  <th class="border-b p-3.5 text-right font-normal">{t('Total')}</th>
                                  <th class="border-b p-3.5 text-right font-normal">{t('Unpaid balance')}</th>
                                  <th class="border-b p-3.5 text-right font-normal">{t('Amount to pay')}</th>
                                </tr>
                              </thead>
                              <tbody class="bg-white text-text-level01">
                                <For each={vendor.bills}>
                                  {(bill, billIndex) => (
                                    <>
                                      <tr>
                                        <td class="border-b border-slate-100 p-3">{dateFormat('', bill.dueDate)}</td>
                                        <td class="border-b border-slate-100 p-3">{bill.memo || emptyPlaceholder}</td>
                                        <td class="border-b border-slate-100 p-3">{bill.chartOfAccountName.join(',')}</td>
                                        <td class="border-b border-slate-100 p-3 text-right">{currency(bill.totalAmount)}</td>
                                        <td class="border-b border-slate-100 p-3 text-right">{currency(bill.amount)}</td>
                                        <td class="flex justify-end border-b border-slate-100 p-3">
                                          <LabeledNumberInput
                                            class="w-32 "
                                            inputContainerClass="bg-white"
                                            inputClass=" text-right"
                                            prepend="$"
                                            onInput={(val) =>
                                              props.onChange?.({
                                                val,
                                                index: index(),
                                                billIndex: billIndex(),
                                                vendorIndex: vendorIndex(),
                                              })
                                            }
                                            value={bill.amount}
                                            placeholder={t('Please enter')}
                                            error={Number(bill.amount) > Number(bill.due) ? t('Amount exceeds due') : ''}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </For>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="pointer-events-none absolute inset-0 rounded-lg border border-black/5 " />
                      </div>
                      <BillSummary bills={vendor.bills} availableBalance={(item.property as any)?.availableBalance || 0} />
                    </div>
                  </>
                )}
              </For>
            </div>
          </div>
        )}
      </For>
      {props.bills.length > 1 && (
        <div class="mt-4 flex justify-end">
          <span class="text-sm font-semibold">
            {t('Total Payment Amount')}: {currency(totalPaymentAmount())}
          </span>
        </div>
      )}
    </>
  );
};

const BillSummary = (props: { bills: any[]; availableBalance: string | number | undefined }) => {
  const { t } = useLocalization();

  const enteredPaymentAmount = createMemo(() => {
    return props.bills.reduce((total, bill) => total + Number(bill.amount || 0), 0);
  });

  const remainingBalance = createMemo(() => {
    return (props.availableBalance as number) - enteredPaymentAmount();
  });

  return (
    <div class="flex flex-col items-end pt-2.5 text-right text-xs text-text-level02">
      <div class="mb-1 normal-case">{`${t('Available balance')}: ${currency(props.availableBalance)}`}</div>
      <div class="mb-2.5 w-[203px] border-b border-input-border pb-2">{`${t('Amount to pay')}: ${currency(enteredPaymentAmount())}`}</div>
      <div
        class={cn('text-sm', {
          'text-danger': remainingBalance() < 0,
          'text-warning': remainingBalance() > 0 && remainingBalance() < Number(props.availableBalance),
          'text-green': remainingBalance() === props.availableBalance,
        })}>
        {`${t('Remaining balance')}: ${currency(remainingBalance())}`}
      </div>
    </div>
  );
};

const AllocationsForm: Component<PayBillsSubForm> = (props) => {
  const { t } = useLocalization();

  const [validationErrors, setValidationErrors] = createSignal<string[]>([]);

  const isValidPayment = createMemo(() => {
    const errors: string[] = [];
    const isValid = props.form.billPayments?.every((payment) =>
      payment.vendors?.every((vendor) =>
        vendor.bills?.every((bill) => {
          const amount = Number(bill.amount);
          const due = Number(bill.due);

          if (isUndefined(amount) || isNull(amount)) {
            errors.push(t('All bills must have an amount entered'));
            return false;
          }
          if (isNaN(amount) || amount < 0) {
            errors.push(t('All amounts must be valid positive numbers'));
            return false;
          }
          if (amount > due) {
            errors.push(t('Payment amount cannot exceed due amount for any bill'));
            return false;
          }
          return true;
        })
      )
    );
    setValidationErrors(errors);
    return isValid;
  });

  return (
    <div class="thinscroll mx-auto flex w-10/12 min-w-[710px] flex-col gap-5 overflow-x-auto">
      <Switch>
        <Match when={!props.store.billsLoading && props.store.bills?.length !== 0}>
          <div>
            <h4 class="py-3 text-base font-semibold">{t('Allocations')}</h4>
            <BillItem
              bills={props.form.billPayments || []}
              onChange={(arg) => {
                const { val, index, billIndex, vendorIndex } = arg;
                props.setFormData(
                  produce((state) => {
                    let value = Number(val);
                    if (isNaN(value)) {
                      value = 0;
                    }
                    state.billPayments[index].vendors[vendorIndex as number].bills[billIndex].amount = value;
                    const bills: BillPayments['bills'] = [];
                    state.billPayments[index].vendors?.forEach((item) => {
                      bills.push(...(item.bills || []));
                    });
                    state.billPayments[index].paymentAmount = bills.reduce(
                      (accumulator, current) => accumulator + Number(current.amount || 0),
                      0
                    );
                  })
                );
              }}
            />
          </div>
        </Match>
        <Match when={props.store.billsLoading}>
          <div class="flex items-center justify-center">
            <RingLoader color="#A126EC" text={`${t('Loading')}...`} />
          </div>
        </Match>
        <Match when={props.store.bills?.length == 0 && !props.store.billsLoading}>
          <Empty description={t('The property and the vendor you selected do not have a bill')} />
        </Match>
      </Switch>
      {!isValidPayment() && (
        <div class="mt-4 text-red-500">
          <For each={validationErrors()}>{(error) => <div>{error}</div>}</For>
        </div>
      )}
    </div>
  );
};

export default AllocationsForm;
