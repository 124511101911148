import { createSignal, createEffect, Match, Switch } from 'solid-js';
import { isMonthToMonth, isDraftLease, isEndedLease, isEvictionLease, isExpiredLease, isFutureLease } from '~/components/leases/utils';
import { useLocalization } from '~/contexts/global';
import { LeaseStatus as ILeaseStatus } from '~/swagger/Api';
import { cn } from '~/utils/classnames';
import { dateDiffInDays } from '~/utils/date';
import { IconActive, IconPastDue, IconInEviction, IconEndingSoon, IconEnded } from './LeaseIcons';
import type { Component } from 'solid-js';

type LeaseStatusProps = {
  class?: string;
  lease?: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto;
};

export const LeaseStatus: Component<LeaseStatusProps> = (props) => {
  const { t } = useLocalization();
  return (
    <span class={cn('text-xs font-medium', props.class)}>
      <Switch fallback={<span class="text-light-green">{t('Leased')}</span>}>
        <Match when={isEvictionLease(props.lease)}>
          <span class="text-[#F76B6B]">{t('Eviction')}</span>
        </Match>
        <Match when={isMonthToMonth(props.lease)}>
          <span class="text-light-warning">{t('Month to month')}</span>
        </Match>
        <Match when={isExpiredLease(props.lease)}>
          <span class="text-dragonFruit">{t('Expired')}</span>
        </Match>

        <Match when={isEndedLease(props.lease)}>
          <span class="text-[#898D97]">{t('Ended')}</span>
        </Match>
        <Match when={isFutureLease(props.lease)}>
          <span class="text-[#898D97]">{t('Coming up')}</span>
        </Match>
        <Match when={isDraftLease(props.lease)}>
          <span class="text-primary">{t('Draft')}</span>
        </Match>
        <Match when={!props.lease}>
          <span class="text-light-danger">{t('Not leased')}</span>
        </Match>
      </Switch>
    </span>
  );
};

type LeaseStatusElementProps = {
  class?: string;
  lease: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto;
};

export const LeaseStatusElement: Component<LeaseStatusElementProps> = (props) => {
  const { t } = useLocalization();

  const [status, setStatus] = createSignal<'active' | 'due' | 'eviction' | 'ending' | 'ended'>('active');

  createEffect(() => {
    if (props.lease.eviction) {
      setStatus('eviction');
    } else if (props.lease.ended) {
      setStatus('ended');
    } else if (props.lease.balance > 0 && props.lease.pastDueDays) {
      setStatus('due');
    } else if (props.lease.end && props.lease.status !== ILeaseStatus.MonthToMonth && dateDiffInDays(props.lease.end) <= 30) {
      setStatus('ending');
    }
  });

  return (
    <div class={cn('text-sm', props.class)}>
      <Switch>
        <Match when={status() === 'active'}>
          <div class="flex items-center gap-1 whitespace-nowrap text-green">
            <IconActive class="size-4" />
            {t('Active')}
          </div>
        </Match>
        <Match when={status() === 'due'}>
          <div class="flex items-center gap-1 whitespace-nowrap text-danger">
            <IconPastDue class="size-4" />
            {t('Past due')}
          </div>
          <span class="mr-1 whitespace-nowrap text-xs text-text-level03">{t('Past due')}</span>
          <span class="whitespace-nowrap text-xs text-danger">
            {props.lease.pastDueDays && t('{count} Days', { count: props.lease.pastDueDays.toString() })}
          </span>
        </Match>
        <Match when={status() === 'eviction'}>
          <div class="flex items-center gap-1 whitespace-nowrap text-text-level03">
            <IconInEviction class="size-4" />
            {t('In eviction')}
          </div>
        </Match>
        <Match when={status() === 'ending'}>
          <div class="flex items-center gap-1 whitespace-nowrap text-warning">
            <IconEndingSoon class="size-4" />
            {t('Ending soon')}
          </div>
          <span class="text-xs text-text-level03">{t('Due in {count} days', { count: dateDiffInDays(props.lease.end).toString() })}</span>
        </Match>
        <Match when={status() === 'ended'}>
          <div class="flex items-center gap-1 whitespace-nowrap text-text-level03">
            <IconEnded class="size-4" />
            {t('Ended lease')}
          </div>
        </Match>
      </Switch>
    </div>
  );
};
