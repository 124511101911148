import IconTempUnit from '~/assets/images/common/tempUnit.png';
import { Image } from '~/components/ui';
import { getFileAllUrl } from '~/utils/file';
import type { Component } from 'solid-js';
import type { UnitListingDto } from '~/swagger/Api';

export const UnitListingTitle: Component<{
  unit: UnitListingDto;
}> = (props) => {
  return (
    <div class="flex items-center gap-2">
      <Image
        class="size-10 rounded-lg"
        src={props.unit.primaryImage?.signedThumbUrl ? getFileAllUrl(props.unit.primaryImage?.signedThumbUrl) : IconTempUnit}
      />
      <div class="flex flex-col gap-1">
        <span class="text-sm font-semibold">{props.unit.title}</span>
        <span class="text-xs text-text-level02">{props.unit.singleLineAddress}</span>
      </div>
    </div>
  );
};
