import { createMemo, For } from 'solid-js';
import { FullPieChart } from '~/components/ui';
import { useLocalization, useProperties } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import { stringToColor } from '~/utils/strings';

interface Props {
  class: string;
}
export const PropertyOwnerShare = (props: Props) => {
  const { t } = useLocalization();
  const { propertyOverview } = useProperties();

  const template = createMemo(() => {
    return propertyOverview()?.owners?.map((x) => {
      const name = x.firstName + ' ' + (x.lastName || '');
      return {
        title: name,
        value: (x.ownershipPercentage || 0) * 100,
        color: stringToColor(name),
      };
    });
  });

  return (
    <Card class={props.class} title={t("Owners' share")} contentClass="mt-[14px] h-full">
      <div class="flex size-full items-center justify-center p-2">
        <div class="flex w-full flex-col items-center justify-between gap-6 px-2 2xl:flex-row 2xl:gap-4 2xl:px-9">
          <div class="w-fit 2xl:max-w-40">
            <FullPieChart data={template()! || []} />
          </div>

          <div class="flex w-full flex-col gap-2 2xl:ml-8 2xl:w-48">
            <For each={template()}>
              {(item) => (
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <span style={{ background: item.color }} class="size-3 rounded" />
                    <div class="ml-2 text-xs text-text-level03">{item.title}</div>
                  </div>
                  <span class="text-xs font-semibold text-text-level01">{item.value}%</span>
                </div>
              )}
            </For>
          </div>
        </div>
      </div>
    </Card>
  );
};
