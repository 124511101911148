import { createStore } from 'solid-js/store';
import { BankAccountRepository } from '~/repositories/bankAccountRepository';
import { BillRepository } from '~/repositories/billRepository';
import { ChartOfAccountsRepository } from '~/repositories/chartOfAccountsRepository';
import type { Option } from '~/components/common/DropdownMenu';
import type { VendorBillsPayListFilter } from '~/repositories/billRepository';

export interface TBillStore {
  submitLoading?: boolean;
  propertiesLoading?: boolean;
  chartOfAccountOptions?: Option[];
  chartOfAccountMap?: Map<string, string>;
  chartOfAccountLoading?: boolean;
  bills?: ProcessedBill[];
  billsLoading?: boolean;
  bankAccountOptions?: Option[];
  bankAccountsLoading?: boolean;
}

const ChartOfAccountsRepo = new ChartOfAccountsRepository();
const BillRepo = new BillRepository();
const BankAccountRepo = new BankAccountRepository();

const [store, setStore] = createStore<TBillStore>({});

const getChartOfAccounts = async () => {
  setStore('chartOfAccountLoading', true);
  const response = await ChartOfAccountsRepo.getChartOfAccounts();
  const options = (response ?? []).map((account) => ({
    label: account.name,
    value: account.id,
  }));
  const accountMap = new Map(response.map((account) => [account.id, account.name]));
  setStore('chartOfAccountOptions', options);
  setStore('chartOfAccountMap', accountMap);
  setStore('chartOfAccountLoading', false);
};

interface ProcessedBill extends MagicDoor.Api.BillDto {
  property: MagicDoor.Api.PropertyDto & { availableBalance?: number };
  vendor: MagicDoor.Api.VendorDto;
}

const getVendorBillsPay = async (filter: VendorBillsPayListFilter): Promise<void> => {
  setStore('billsLoading', true);
  try {
    const response = await BillRepo.getVendorBillsPay(filter);

    if (!response || !response.properties || !Array.isArray(response.properties) || !response.vendors || !Array.isArray(response.vendors)) {
      console.error("Invalid response structure. Expected 'properties' and 'vendors' arrays.");
      setStore('bills', []);
      return;
    }

    const vendorMap = new Map(response.vendors.map((vendor) => [vendor.id, vendor]));

    const processedBills = response.properties
      .flatMap(
        (property) =>
          property.bills
            ?.map<ProcessedBill | null>((bill) => {
              const vendor = bill.vendorId ? vendorMap.get(bill.vendorId) : undefined;
              if (!vendor) {
                console.warn(`No vendor found for vendorId: ${bill.vendorId}`);
                return null;
              }

              if (!property.property) {
                console.warn(`No property`);
                return null;
              }

              return {
                ...bill,
                property: { ...property.property, availableBalance: property.availableBalance },
                vendor: vendor,
              };
            })
            .filter((bill) => bill !== null) ?? []
      )
      .filter((bill) => bill.due > 0) as ProcessedBill[];

    setStore('bills', processedBills);
  } catch (error) {
    console.error('Error fetching bills:', error);
    setStore('bills', []);
  } finally {
    setStore('billsLoading', false);
  }
};

const getBankAccounts = async () => {
  setStore('bankAccountsLoading', true);
  const response = await BankAccountRepo.getBankAccounts();
  const options = (response ?? []).map((account: any) => ({
    label: account.name,
    value: account.id,
  }));
  setStore('bankAccountOptions', options);
  setStore('bankAccountsLoading', false);
};

const paymentMethodOptions: Option[] = [
  {
    label: 'ACH',
    value: 'ach',
  },
  {
    label: 'Credit',
    value: 'credit',
  },
  {
    label: 'Debit card',
    value: 'debitCard',
  },
  {
    label: 'Wallet',
    value: 'wallet',
  },
  {
    label: 'Credit card',
    value: 'creditCard',
  },
  {
    label: 'Check',
    value: 'check',
  },
  {
    label: 'Cashiers check',
    value: 'cashiersCheck',
  },
  {
    label: 'Money order',
    value: 'moneyOrder',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export default {
  paymentMethodOptions,
  store,
  getVendorBillsPay,
  setStore,
  getChartOfAccounts,
  getBankAccounts,
};
