import { Routes, Route, useParams, useNavigate } from '@solidjs/router';
import { Show } from 'solid-js';
import { AddServiceBillTypeModal } from '~/components/service-bill';
import { useLease, AttachmentsProvider } from '~/contexts/local';
import { LeaseTransactionMarkAsReturnedModal } from '~/pages/leases/lease-details/components/lease-deposit-transaction/LeaseTransactionMarkAsReturnedModal';
import { LeaseCreditModal } from './LeaseCreditModal';
import { PayoutTenantModal } from './LeasePayoutTenantModal';
import { LeaseSetupChargeModal } from './LeaseSetupChargeModal';
import { LeaseTransactionDetailModal } from './LeaseTransactionDetailModal';
import { LeaseTransactionReceivePaymentModal } from './LeaseTransactionReceivePaymentModal';
import { LeaseTransactionTable } from './LeaseTransactionTable';

export const LeaseTransactionRoutes = () => {
  const params = useParams<{ leaseId: string }>();
  const navigate = useNavigate();
  const { lease } = useLease();

  const backToTransactions = () => {
    navigate(`/leasing/leases/${params.leaseId}/transactions`, { scroll: false, replace: true });
  };

  return (
    <>
      <div class="text-left">
        <AttachmentsProvider>
          <LeaseTransactionTable lease={lease() as MagicDoor.Api.HydratedLeaseDto} />
        </AttachmentsProvider>
      </div>
      <Routes>
        <Route
          path="/add-charge"
          element={
            <Show when={lease()}>
              <LeaseSetupChargeModal
                onCancel={backToTransactions}
                currentLease={lease() as MagicDoor.Api.HydratedLeaseDto}
                visible={true}
              />
            </Show>
          }
        />
        <Route
          path={'/receive-payment'}
          element={
            <Show when={lease()}>
              <LeaseTransactionReceivePaymentModal
                currentLease={lease() as MagicDoor.Api.HydratedLeaseDto}
                visible={true}
                onCancel={backToTransactions}
              />
            </Show>
          }
        />
        <Route
          path={'/issue-credit'}
          element={
            <Show when={lease()}>
              <LeaseCreditModal onCancel={backToTransactions} currentLease={lease() as MagicDoor.Api.HydratedLeaseDto} visible={true} />
            </Show>
          }
        />
        <Route
          path={'/payout-tenant'}
          element={
            <Show when={lease()}>
              <PayoutTenantModal onCancel={backToTransactions} currentLease={lease() as MagicDoor.Api.HydratedLeaseDto} visible={true} />
            </Show>
          }
        />
        <Route path={'/add-service-bill'} element={<AddServiceBillTypeModal type="property" />} />
        <Route path={'/:transactionId/returned'} component={LeaseTransactionMarkAsReturnedModal} />
        <Route
          path={'/:transactionId'}
          element={
            <AttachmentsProvider>
              <LeaseTransactionDetailModal currentLease={lease() as MagicDoor.Api.HydratedLeaseDto} />
            </AttachmentsProvider>
          }
        />
      </Routes>
    </>
  );
};
