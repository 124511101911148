import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { For, Show } from 'solid-js';
import IconNoWorkOrder from '~/assets/images/common/noWorkOrder.svg';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization, useVendors } from '~/contexts/global';
import WorkOrderItems from './WorkOrderItems';

const VendorWorkOrder = () => {
  const { t } = useLocalization();
  const { vendorOverview } = useVendors();
  const navigate = useNavigate();

  return (
    <ThreeSectionBox
      topTitle={<div>{t('Work order')}</div>}
      topAction={
        <button onClick={() => navigate('/maintenance/work-orders')} class="flex items-center gap-1">
          <div>{t('View all')}</div>
          <IconViewArrow class="size-4 text-link" />
        </button>
      }
      middle={
        <Show
          when={vendorOverview()?.workOrders?.totalCount}
          fallback={
            <div class="flex h-64 flex-col items-center justify-center gap-y-4">
              <img class="size-14" src={IconNoWorkOrder} alt="No bill" />
              <div class="text-text-level03">{t('There is no work order in progress')}</div>
            </div>
          }>
          <div class="thinscroll flex h-64 flex-col gap-2 overflow-x-auto px-6 py-4">
            <For each={vendorOverview()?.workOrders?.items}>
              {(workOrder) => <WorkOrderItems workOrder={workOrder} title={workOrder.title} date={dayjs(workOrder.dueDate)} />}
            </For>
            <div class="w-full text-center text-xs text-text-level03">
              {t('A total of')} <span class="font-semibold text-essential-colour">{vendorOverview()?.workOrders?.totalCount}</span>{' '}
              {t('Intermediate work orders')}
            </div>
          </div>
        </Show>
      }
    />
  );
};

export default VendorWorkOrder;
