import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { ToolbarPresenter } from '~/pdfsigner/presenters/ToolbarPresenter';
import { CategorySelector } from '~/pdfsigner/ui/components/header/CategorySelector';
import { TitleInput } from '~/pdfsigner/ui/components/header/TitleInput';
import { UpdateLeaseTemplateUseCase } from '~/pdfsigner/usecases/updateLeaseTemplateUseCase';

export const LeaseTemplateHeader = () => {
  const { execute: updateLeaseTemplate } = useUseCase(UpdateLeaseTemplateUseCase);
  const { model: presentableToolbar } = usePresenter(ToolbarPresenter);

  return (
    <div class="mb-5 flex w-full items-center gap-6 rounded-lg bg-white px-6 py-2.5">
      <TitleInput title={presentableToolbar()?.title} onInput={(title) => updateLeaseTemplate({ title })} />
      <CategorySelector
        value={presentableToolbar()?.categoryId}
        options={presentableToolbar()?.templateCategoryOptions}
        onChange={(value) => updateLeaseTemplate({ leaseCategoryId: value })}
      />
    </div>
  );
};
