import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { searchSelectValuePropNames } from '~/components/ui/Select/SearchSelect';
import { useLocalization } from '~/contexts/global';
import { PropertyManagersRepository } from '~/repositories/propertyManagersRepository';
import type { EntrySearchProps } from '~/components/ui';
import type { LabeledSearchSelectProps } from '~/components/ui/Select/SearchSelect';

const repo = new PropertyManagersRepository();

export const PropertyManagerSearch = <T extends boolean | undefined = undefined>(
  props: EntrySearchProps<MagicDoor.Api.PropertyManagerDto, T>
) => {
  const { t } = useLocalization();

  const initialSelectedLoader = async (ids: string[]) => {
    return await Promise.all(ids.map((id) => repo.getPropertyManagerById(id)));
  };

  return (
    <SearchSelect
      fetcher={() =>
        repo.getPropertyManagers().then((items) => ({
          items,
          totalPages: 1,
        }))
      }
      placeholder={t('Search property managers')}
      renderSelected="name"
      renderItem={props.renderItem || ((item) => item.name)}
      initialSelectedLoader={initialSelectedLoader}
      {...props}
    />
  );
};

export const LabeledPropertyManagerSearch = <T extends boolean | undefined = undefined>(
  props: LabeledSearchSelectProps<MagicDoor.Api.PropertyManagerDto, T>
) => {
  const [searchProps, labeledProps] = splitProps(props, searchSelectValuePropNames);

  return (
    <LabeledGroup {...labeledProps}>
      <PropertyManagerSearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.(
            (Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string,
            selected
          );
        }}
      />
    </LabeledGroup>
  );
};
