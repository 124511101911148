import { createEffect, createSignal } from 'solid-js';
import IconTempUnit from '~/assets/images/common/tempUnit.png';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { PaymentAmount } from '~/components/common/PaymentAmount';
import { LeaseStatus } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { Image, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeaseRentTransactions } from '~/contexts/local';
import { formConfirmationContent, MAX_INPUT_AMOUNT } from '~/utils/constant';
import type { Component, JSX } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';

type PayoutTenantModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
};

type TenantOption = {
  id: string;
  name: string;
};

export const PayoutTenantModal: Component<PayoutTenantModalProps> = (props) => {
  const { t } = useLocalization();
  const { payoutTenant } = useLeaseRentTransactions();

  const formId = 'payout_tenant_form';
  const MIN_AMOUNT = 1;

  const [loading, setLoading] = createSignal<boolean>(false);
  const [formData, setFormData] = createSignal<MagicDoor.Api.ReleaseLeaseBalanceRequestDto>({
    amount: 0,
    tenantId: undefined,
    memo: undefined,
    externalTransactionId: undefined,
    releaseDate: new Date().toISOString().split('T')[0],
  });
  const [formErrors, setFormErrors] = createSignal<Record<keyof MagicDoor.Api.ReleaseLeaseBalanceRequestDto, string | undefined>>({
    amount: undefined,
    tenantId: undefined,
    memo: undefined,
    externalTransactionId: undefined,
    releaseDate: undefined,
  });
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const setFormDataCommon = (value: string | number, key: keyof MagicDoor.Api.ReleaseLeaseBalanceRequestDto) => {
    setFormData((prev) => ({
      ...prev,
      [key]: key === 'amount' ? Number(value) : value,
    }));
  };

  const validator = (): boolean => {
    const errors: Record<keyof MagicDoor.Api.ReleaseLeaseBalanceRequestDto, string | undefined> = {
      amount: undefined,
      tenantId: undefined,
      memo: undefined,
      externalTransactionId: undefined,
      releaseDate: undefined,
    };

    if (!formData().amount || formData().amount <= 0) {
      errors.amount = t('Amount must be greater than 0');
    }

    if (!formData().releaseDate) {
      errors.releaseDate = t('Release date is required');
    }

    setFormErrors(errors);
    return Object.values(errors).every((error) => error === undefined);
  };

  const handleSubmit = async (ev: Event) => {
    ev.preventDefault();
    const validateResult = validator();
    if (!validateResult) return;

    setLoading(true);
    try {
      await payoutTenant(props.currentLease.id, formData());

      props.onCancel && props.onCancel();
      toast.success(t('Tenant payout has been processed successfully'));
    } finally {
      setLoading(false);
    }
  };

  const tenantOptions = (): TenantOption[] => {
    return props.currentLease.tenants.map((tenant) => ({
      id: tenant.tenant.id,
      name: `${tenant.tenant.firstName || ''} ${tenant.tenant.lastName || ''}`,
    }));
  };

  const renderTenantOption = (option: TenantOption): JSX.Element => {
    return <div>{option.name}</div>;
  };

  createEffect(() => {
    setShowCloseConfirmation(
      formData().amount !== 0 || formData().tenantId !== '' || formData().memo !== '' || formData().externalTransactionId !== ''
    );
  });

  return (
    <Modal
      {...props}
      title={t('Payout tenant')}
      doneText={t('Process payout')}
      submitId={formId}
      loading={loading()}
      confirmation={showCloseConfirmation() ? formConfirmationContent(t) : false}>
      <form class="thinscroll flex flex-col gap-5 overflow-auto px-8 pb-16 pt-6" id={formId} onSubmit={handleSubmit}>
        <div class="flex h-16 w-full items-center justify-items-start rounded-lg bg-slate-100 p-2">
          <Image src={IconTempUnit} class="size-12 rounded-lg" />
          <div class="ml-2">
            <div class="text-sm">
              {props.currentLease.property.address?.streetAddress1}-{props.currentLease.unit.name}
            </div>
            <LeaseStatus lease={props.currentLease} />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-x-7 gap-y-6 pb-5 pt-4">
          <LabeledNumberInput
            class="col-span-full lg:col-span-1"
            required
            label={t('Amount')}
            step={0.01}
            value={formData().amount.toString()}
            onInput={(value) => setFormDataCommon(`${value}`, 'amount')}
            error={formErrors().amount}
            placeholder={t('Enter amount')}
            isBlur={false}
            min={MIN_AMOUNT}
            max={MAX_INPUT_AMOUNT}
          />

          <DueDateInputField
            class="col-span-full lg:col-span-1"
            required
            label={t('Release date')}
            value={formData().releaseDate}
            onInput={(value) => setFormDataCommon(value as string, 'releaseDate')}
            error={formErrors().releaseDate}
          />

          <LabeledInputSearch
            class="col-span-full lg:col-span-1"
            label={t('Tenant')}
            value={formData().tenantId || undefined}
            labelJSX={t('Tenant')}
            onSelect={(value) => setFormDataCommon(value as string, 'tenantId')}
            error={formErrors().tenantId}
            options={tenantOptions()}
            placeholder={t('Select tenant')}
            renderItem={renderTenantOption}
            renderMatchedElement={renderTenantOption}
            labelPropName="name"
            valuePropName="id"
          />
          <LabeledTextInput
            class="col-span-full lg:col-span-1"
            label={t('Memo')}
            value={formData().memo || ''}
            onInput={(value) => setFormDataCommon(value, 'memo')}
            placeholder={t('Please enter')}
            maxlength={200}
          />
          <LabeledTextInput
            class="col-span-full"
            label={t('External transaction ID')}
            value={formData().externalTransactionId || ''}
            onInput={(value) => setFormDataCommon(value, 'externalTransactionId')}
            placeholder={t('Please enter')}
            maxlength={200}
          />
        </div>
      </form>
      <PaymentAmount label={t('Total amount')} mount={formData().amount} min={MIN_AMOUNT} max={MAX_INPUT_AMOUNT} />
    </Modal>
  );
};
