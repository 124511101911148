import { useNavigate, useParams } from '@solidjs/router';
import { onMount, Show } from 'solid-js';
import { FileListView, FileTitle } from '~/components/file-attachments';
import { IconDownload, IconTrash, ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { AttachmentsProvider, useLease } from '~/contexts/local';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { LeaseDraftsPresenter } from '~/pdfsigner/presenters/LeaseDraftsPresenter';
import { DeleteLeaseDraftUseCase } from '~/pdfsigner/usecases/deleteLeaseDraftUseCase';
import { LoadLeaseDraftsForLeaseUseCase } from '~/pdfsigner/usecases/loadLeaseDraftsForLeaseUseCase';
import { downloadFile } from '~/utils/file';
import type { Component } from 'solid-js';
import type { PresentableDocumentDescriptor } from '~/pdfsigner/ui/types/presentablePaginatedLeaseDrafts';

export const LeaseFiles: Component = () => {
  const params = useParams();
  const { lease } = useLease();
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { execute: getDocuments, isLoading: isLoadingDocuments } = useUseCase(LoadLeaseDraftsForLeaseUseCase);
  const { execute: deleteDraft, isLoading: isDeletingDraft } = useUseCase(DeleteLeaseDraftUseCase);
  const { model: documents } = usePresenter(LeaseDraftsPresenter);

  const loadLeaseDrafts = async () => {
    const docs = documents();
    const page = docs ? docs.page : 1;
    getDocuments({ leaseId: params.leaseId, page });
  };

  onMount(() => {
    loadLeaseDrafts();
  });

  const hasDocuments = () => {
    const docs = documents();
    return docs && docs.items.length > 0;
  };

  const onRowClick = (item: PresentableDocumentDescriptor) => {
    navigate(`/leasing/leases/${params.leaseId}/draft/${item.id}`);
  };

  const tableActions = (item: PresentableDocumentDescriptor, index: number) => {
    const actions = [
      {
        label: t('Download'),
        icon: IconDownload,
        onClick: () => downloadFile(item.signedUrl),
      },
      {
        label: t('Delete'),
        icon: IconTrash,
        onClick: async () => {
          await deleteDraft(item.id);
          await loadLeaseDrafts();
        },
      },
    ];
    return actions;
  };

  return (
    <div class="text-left">
      <AttachmentsProvider>
        <FileListView entryType={'lease' as MagicDoor.Api.EntityType} entryId={lease()?.id ?? params.leaseId} />
      </AttachmentsProvider>
      <Show when={hasDocuments()}>
        <ListView
          class="mt-4"
          title={t('Lease Documents')}
          onRowClick={onRowClick}
          loading={isLoadingDocuments() || isDeletingDraft()}
          data={documents()?.items}
          page={documents()?.page}
          totalPages={documents()?.totalPages}
          pageSize={documents()?.pageSize}
          columns={[
            {
              title: t('File'),
              render: (item) => <FileTitle file={{ fileName: item.name }} />,
            },
            {
              title: t('Status'),
              headerClass: 'w-40',
              key: 'status',
            },
          ]}
          actions={(item, index) => tableActions(item, index)}
        />
      </Show>
    </div>
  );
};
