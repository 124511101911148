import { Route, Routes } from '@solidjs/router';
import { ListListingPage } from './list-listing';
import { AddUnitListingModal } from './listing-add';
import ListingDetailsPage from './listing-detail';
import { EditListing } from './listing-detail/edit-listing';

const ListingRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/:portfolioId/details/:listingId/*" component={ListingDetailsPage} matchFilters={{ listingId: /^\d+$/ }} />
        <Route path="/:portfolioId/details/:listingId/edit-listing" component={EditListing} />
        <Route path="/:portfolioId?/*" component={ListListingPage} matchFilters={{ portfolioId: /^\d+$/ }} />
      </Routes>

      <Routes>
        <Route path="/:portfolioId/add" component={AddUnitListingModal} />
      </Routes>
    </>
  );
};

export default ListingRoutes;
