import { createSignal, Show, splitProps } from 'solid-js';
import IconMinus from '~/assets/images/common/minus.svg?component-solid';
import IconGID from '~/assets/images/rental-application/gid-trigger.svg?component-solid';
import IconPayStub from '~/assets/images/rental-application/paystub-trigger.svg?component-solid';
import { FormItem } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import { LabeledGroup } from '~/components/common/Inputs';
import { RingLoader } from '~/components/common/Loaders';
import { FileItem, Upload, UploadTrigger } from '~/components/common/Upload';
import { useLocalization } from '~/contexts/global';
import { fileRepository } from '~/repositories/fileRepository';
import { FileType } from '~/swagger/Api';
import type { Component, JSX } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { HydrateFile } from '~/components/common/Upload/Interface';
import type { RentalApplicationFileDto, UpdateRentalApplicationFileDto, UploadResultDto } from '~/swagger/Api';

const FilePreview = (props: { file: HydrateFile; onClean: () => void }) => {
  return (
    <div class="flex items-center gap-2">
      <FileItem file={props.file} />
      <Button onClick={props.onClean} variant="text">
        <IconMinus />
      </Button>
    </div>
  );
};

const FileUploader: Component<
  {
    initialFile?: HydrateFile;
    accept: string;
    trigger: JSX.Element;
    onUpload: (file: File) => Promise<void>;
    onClean: () => void;
  } & LabeledGroupProps
> = (props) => {
  const [labeledGroudProps, fileUploaderProps] = splitProps(props, ['labelClass', 'error', 'label', 'groupClass']);

  const [isUploading, setIsUploading] = createSignal(false);

  const onFilesListChange = async (files: File[]) => {
    const file = files[0];
    if (!file) return;
    setIsUploading(true);
    try {
      await fileUploaderProps.onUpload(file);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Upload multiple={false} accept={props.accept} onFilesListChange={onFilesListChange}>
      <LabeledGroup {...labeledGroudProps} labelContainerClass="h-full">
        <div class="relative flex min-h-20 flex-1 cursor-pointer flex-col items-center justify-center gap-1 overflow-hidden rounded-lg border border-partingline text-xs text-primary">
          <Show when={isUploading()}>
            <div class="absolute inset-0 z-10 flex items-center justify-center bg-black/20">
              <RingLoader class="mx-auto my-2 h-8" />
            </div>
          </Show>
          <Show
            when={!fileUploaderProps.initialFile}
            fallback={<FilePreview file={fileUploaderProps.initialFile as HydrateFile} onClean={fileUploaderProps.onClean} />}>
            <UploadTrigger>
              <div class="flex cursor-pointer flex-col items-center gap-1">{props.trigger}</div>
            </UploadTrigger>
          </Show>
        </div>
      </LabeledGroup>
    </Upload>
  );
};

export const RentalApplicationDocuments: Component<{
  onInput?: (files: UpdateRentalApplicationFileDto[]) => void;
  value?: (RentalApplicationFileDto | UpdateRentalApplicationFileDto)[] | null;
  beforeUpload?: (file: File) => void;
  afterUpload?: (uploadResult: Record<string, UploadResultDto>) => void;
}> = (props) => {
  const { t } = useLocalization();

  function getInitialFile(type: FileType) {
    return props.value?.find((f) => f.type === type);
  }

  async function onUpload(file: File, type: FileType) {
    props.beforeUpload?.(file);
    let uploadResult: Record<string, UploadResultDto> = {};
    try {
      uploadResult = await fileRepository.uploadFile(file);
      props.onInput?.(
        Array.from(
          new Map(
            (props.value ?? [])
              ?.concat({
                fileId: uploadResult.file.fileId!,
                type,
                fileName: file.name,
              })
              .map((f) => [f.type, f])
          ).values()
        )
      );
    } finally {
      props.afterUpload?.(uploadResult);
    }
  }

  function onClean(type: FileType) {
    props.onInput?.(props.value?.filter((f) => f.type !== type) ?? []);
  }

  return (
    <div class="flex flex-wrap gap-5">
      <FormItem
        label={t('Government ID')}
        component={FileUploader}
        trigger={
          <>
            <IconGID />
            <span>{t(`Drag & drop or click to add government ID`)}</span>
          </>
        }
        type={FileType.Identification}
        initialFile={getInitialFile(FileType.Identification) as any}
        accept="image/*,application/pdf"
        groupClass="flex-1"
        onUpload={(file: File) => onUpload(file, FileType.Identification)}
        onClean={() => onClean(FileType.Identification)}
      />
      <FormItem
        label={t('Pay Stub')}
        component={FileUploader}
        trigger={
          <>
            <IconPayStub />
            <span>{t(`Drag & drop or click to add pay stub`)}</span>
          </>
        }
        type={FileType.EmploymentVerification}
        initialFile={getInitialFile(FileType.EmploymentVerification) as any}
        accept="image/*,application/pdf"
        groupClass="flex-1"
        onUpload={(file: File) => onUpload(file, FileType.EmploymentVerification)}
        onClean={() => onClean(FileType.EmploymentVerification)}
      />
    </div>
  );
};
