import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { PortfolioRepository } from '~/repositories/portfolioRepository';
import { PortfolioTitle } from './PortfolioTitle';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { EntrySearchProps } from '~/components/ui';
import type { PortfolioFilter } from '~/repositories/portfolioRepository';

const repo = new PortfolioRepository();

export const PortfolioSearch = <T extends boolean | undefined = undefined>(
  props: EntrySearchProps<MagicDoor.Api.HydratedPortfolioDto, T, PortfolioFilter>
) => {
  const { t } = useLocalization();
  const fetcher = async (searchText: string | undefined) => {
    const filtered = await repo.getPortfolios({
      ...props.filter,
      active: true,
      search: searchText,
    });
    return {
      items: filtered,
      totalPages: 1,
    };
  };

  const initialSelectedLoader = async (ids: string[]) => {
    return await repo.batchGetPortfolioByIds(ids);
  };

  return (
    <SearchSelect
      placeholder={t('Search portfolios')}
      fetcher={fetcher}
      renderSelected="name"
      renderItem={(item) => <PortfolioTitle feedbackIconReadonly portfolio={item} />}
      initialSelectedLoader={initialSelectedLoader}
      {...props}
    />
  );
};

export const LabeledPortfolioSearch = <T extends boolean | undefined = undefined>(
  props: Omit<EntrySearchProps<MagicDoor.Api.HydratedPortfolioDto, T>, 'onSelect'> &
    LabeledGroupProps & {
      value?: T extends true ? string[] : string;
      onInput?: (value: T extends true ? string[] : string) => void;
    }
) => {
  const [searchProps, labeledProps] = splitProps(props, ['multiple', 'selected', 'placeholder', 'onInput', 'exclude']);

  // TODO: get entries by ids???

  return (
    <LabeledGroup {...labeledProps}>
      <PortfolioSearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.((Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string);
        }}
      />
    </LabeledGroup>
  );
};
