import { useParams } from '@solidjs/router';
import { createMemo, onMount, Show } from 'solid-js';
import { AIScoreCell } from '~/components/common/AiScoreCell';
import { Avatar } from '~/components/common/Avatar';
import { Panel } from '~/components/common/Panels';
import { RentalApplicationEmpty } from '~/components/rental-application/list-rental-applications/RentalApplicationEmpty';
import { getTenantFullName, getTenantShortName } from '~/components/tenants/utils';
import { Table } from '~/components/ui';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { TableColumns } from '~/components/ui';

type PropertyApplicationProps = {
  class?: string;
};

export const PropertyApplication: Component<PropertyApplicationProps> = (props) => {
  const { t } = useLocalization();
  const { propertyId } = useParams();

  const { getRentalApplications, rentalApplications, isLoading } = useRentalApplication();

  onMount(() => {
    getRentalApplications({
      propertyIds: [propertyId],
    });
  });

  const columns = createMemo<TableColumns<MagicDoor.Api.RentalApplicationListDto>>(() => [
    {
      title: t('Applicant'),
      render: (item) => (
        <div class="flex items-center space-x-2 text-sm">
          <Show when={item.firstName}>
            <Avatar name={getTenantShortName({ firstName: item.firstName, lastName: item.lastName })} />
          </Show>
          <div class="flex flex-col">
            <span class="group-hover:underline ">{getTenantFullName({ firstName: item.firstName, lastName: item.lastName })}</span>
            <span class="text-xs text-text-level03">{dateFormat('', item.createdAt)}</span>
          </div>
        </div>
      ),
    },
    {
      title: t('Status'),
      render: (item) => (
        <div
          class="inline-block text-nowrap rounded-full px-2 py-0.5 capitalize"
          classList={{
            'text-green bg-green/10': item.applicationDecision === 'approved',
            'text-danger bg-danger-light': item.applicationDecision === 'rejected',
            'text-warning bg-warning-light': item.applicationDecision === 'noDecision',
            'text-text-level03 bg-input': item.applicationDecision === 'ignored',
          }}>
          • {t(item.applicationDecision)}
        </div>
      ),
    },
    {
      title: t('Applied unit'),
      render: (item) => {
        const unitNamesStr = createMemo(() => {
          const unitNames =
            item.interests?.map((interest: { unit: { name: string } }) => interest.unit?.name).filter((name: string) => name) || [];
          return unitNames.length > 0 ? unitNames.join(', ') : emptyPlaceholder;
        });

        return <span class="text-sm">{unitNamesStr()}</span>;
      },
    },
    {
      title: t('AI score'),
      render: (item) => <AIScoreCell score={item.aiApplicationScore} />,
    },
    {
      title: t('Annual income'),
      headerClass: 'text-right',
      class: 'text-right',
      render: (item) => <span class="text-sm">{currency(item.incomeInformation?.annualIncome || 0, { minimumFractionDigits: 2 })}</span>,
    },
  ]);

  return (
    <div class="px-8 text-left">
      <Panel title={t('Applications')} class={props.class}>
        <Show when={rentalApplications().length || isLoading()}>
          <div class="thinscroll overflow-x-auto">
            <Table
              rowLink={(item) => `../application-details/${item.id}`}
              columns={columns()}
              loading={isLoading()}
              data={rentalApplications()}
            />
          </div>
        </Show>
        <Show when={!rentalApplications().length && !isLoading()}>{RentalApplicationEmpty()}</Show>
      </Panel>
    </div>
  );
};
