import { useNavigate, useParams } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import { Panel } from '~/components/common/Panels';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBankAccount } from '~/contexts/local';
import BankAccountForm from './BankAccountForm';

function EditBankAccount() {
  const navigate = useNavigate();
  const { t } = useLocalization();

  const form = useForm();

  const { bankAccountId } = useParams();

  const { updateBankAccount, bankAccount } = useBankAccount();

  createEffect(() => {
    if (bankAccount()) {
      form.setFormStore({
        name: bankAccount()?.name,
        description: bankAccount()?.description,
        type: bankAccount()?.type,
        accountNumber: bankAccount()?.accountNumber,
        routingNumber: bankAccount()?.routingNumber,
      });
    }
  });

  const handleEditBankAccount = async () => {
    await updateBankAccount(bankAccountId, form.formStore as MagicDoor.Api.CreateOrUpdateBankAccountDto);
    navigate(`/accounting/bank-accounts/${bankAccountId}`, { replace: true });
    toast.success(t('{name} has been edited successfully', { name: 'BankAccount' }));
  };

  return (
    <Panel title={t('Edit bank account')} class="m-8 h-section2 text-left">
      <BankAccountForm form={form} onSubmit={handleEditBankAccount} />
    </Panel>
  );
}

export default EditBankAccount;
