import { useParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { For, Show, createMemo } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconPic from '~/assets/images/common/pic.svg?component-solid';
import IconFeatures from '~/assets/images/identity/robotBox.png';
import IconBathroom from '~/assets/images/property/bathroom.svg';
import IconBedroom from '~/assets/images/property/bedroom.svg';
import IconDateAvaliable from '~/assets/images/property/date.svg';
import IconUnitSize from '~/assets/images/property/unitSize.svg';
import IconCheck from '~/assets/images/units/listing/check.svg?component-solid';
import IconFacts from '~/assets/images/units/listing/factsDefault.png';
import NoAllowImg from '~/assets/images/units/listing/no_allow.png';
import IconSelect from '~/assets/images/units/listing/select.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { DropdownMenu } from '~/components/common/DropdownMenu/DropdownMenu';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { Panel } from '~/components/common/Panels';
import Tooltip from '~/components/common/Tooltip';
import { propertyTypes } from '~/components/properties';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { GoogleMap } from '~/pages/listing/components/GoogleMap';
// import { ListingStatus, statusMap } from '~/pages/listing/components/ListingStatus';
import { ListingIs } from '~/pages/listing/components/ListingIs';
import { UnitMetric } from '~/pages/units/components/UnitMetric';
import { emptyPlaceholder } from '~/utils/constant';
import { getFileAllUrl } from '~/utils/file';
import { currency } from '~/utils/number';
import { hydratedCheckbox } from '~/utils/tool';
import { ListingDescriptions } from './components/ListingDescrpitions';

type petSizeType = 'small' | 'medium' | 'large';
export const UnitInformationTab = () => {
  const params = useParams<{ portfolioId: `${number}`; listingId: `${number}` }>();

  const { t } = useLocalization();
  const { unitListingDetail, updateUnitLising } = useUnitListings();

  const combinedAmenities = createMemo(() => hydratedCheckbox(unitListingDetail()?.amenities as MagicDoor.Api.AmenityDto[]));
  const petPolicies = createMemo(() => Object.keys(unitListingDetail()?.petPolicy?.policies ?? {}));
  const petSizeMap = {
    small: t('Small (under 10kg)'),
    medium: t('Medium (10 - 25kg)'),
    large: t('Large (25 - 50kg)'),
  };

  const ImageList = (props: { listing: MagicDoor.Api.HydratedUnitListingDto; images: MagicDoor.Api.EmbedFileDto[] }) => {
    return (
      <>
        <For each={props.images?.slice(0, 4)}>
          {(item, index) => (
            <div class="relative overflow-hidden rounded-lg">
              <img src={getFileAllUrl(item.signedUrl!)} class="h-52 w-full rounded-lg border-2 border-essential-colour object-cover" />
              <Show when={index() === 3 && props.images?.length > 0}>
                <div class="absolute inset-0 flex items-center justify-center bg-black/50">
                  <IconPic class="size-6" />
                  <span class="ml-1 text-2xl text-white">{props.images?.length}</span>
                </div>
              </Show>
            </div>
          )}
        </For>
      </>
    );
  };

  const listingOptions = [
    { label: t('Unlisted'), value: 'Unlisted' },
    { label: t('Listed'), value: 'Listed' },
  ];

  const handleUpdateStatus = async (status: string) => {
    try {
      const listed = status === 'Listed';
      const model = { ...unitListingDetail(), listed };
      await updateUnitLising(model as MagicDoor.Api.UpdateUnitListingDto, params.listingId);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div class="grid grid-cols-1 gap-5 lg:grid-cols-12">
      <Panel class="col-span-full px-6 py-5 lg:col-span-9">
        <div>
          <div class="flex flex-wrap justify-between gap-2">
            <div class="flex flex-col gap-2">
              <div class="flex flex-wrap items-center gap-2 text-2xl font-semibold text-text-level01">
                <Tooltip
                  class="w-fit max-w-[90%] whitespace-normal break-words sm:max-w-[500px] md:max-w-[700px]"
                  message={unitListingDetail()?.title}
                  align="bottom">
                  {unitListingDetail()?.title}
                </Tooltip>
                <div class="mt-2 flex flex-wrap items-center gap-2 sm:mt-0">
                  <div class="flex items-center rounded-full bg-[#8712CF] px-3 py-1 text-xs text-white">
                    <Dynamic
                      component={propertyTypes[unitListingDetail()?.property?.type as MagicDoor.Api.PropertyType]?.icon}
                      class="size-4"
                    />
                    {propertyTypes[unitListingDetail()?.property?.type as MagicDoor.Api.PropertyType]?.name}
                  </div>

                  <div class="flex">
                    <DropdownMenu
                      class="cursor-pointer"
                      options={listingOptions}
                      value={unitListingDetail()?.listed ? 'Listed' : 'Unlisted'}
                      onChange={handleUpdateStatus}
                      renderLabel={() => <ListingIs listed={unitListingDetail()?.listed} />}
                      contentPosition={['bottom']}
                      buttonClass="gap-2 border-none bg-transparent p-0"
                      arrowIcon={<IconSelect class={unitListingDetail()?.listed ? 'text-[#B5FFEE]' : 'text-[#FFF4D7]'} />}
                    />
                  </div>
                </div>
              </div>

              <DisplayAddress class="truncate text-xs text-text-level03" address={unitListingDetail()?.property?.address} />
            </div>
            <div class="flex items-center gap-2">
              <LinkButton
                href={`/portfolios/${params.portfolioId}/properties/${unitListingDetail()?.property?.id}/units/${
                  unitListingDetail()?.unit?.id
                }`}
                variant="outlined"
                class="border-link py-2 font-medium text-link hover:bg-link/10">
                {t('View unit')}
              </LinkButton>
              <LinkButton href="./edit-listing" variant="outlined" class="border-link py-2 font-medium text-link hover:bg-link/10">
                <IconEdit /> {t('Edit listing')}
              </LinkButton>
            </div>
          </div>

          <div class="my-6 flex gap-20">
            <div>
              <div class="text-xs text-text-level03">{t('Rent')}</div>
              <div class="text-xl font-semibold text-essential-colour">
                {currency(unitListingDetail()?.rentalPrice || emptyPlaceholder)}
              </div>
            </div>
            <div>
              <div class="text-xs text-text-level03">{t('Security deposit')}</div>
              <div class="text-xl font-semibold text-text-level01">
                {currency(unitListingDetail()?.securityDeposit || emptyPlaceholder)}
              </div>
            </div>
          </div>
          <div class="flex flex-wrap gap-10 py-6">
            <UnitMetric icon={IconBedroom} title={t('Bedroom')} value={unitListingDetail()?.unit?.beds || emptyPlaceholder} />
            <UnitMetric icon={IconBathroom} title={t('Bathroom')} value={unitListingDetail()?.unit?.baths || emptyPlaceholder} />
            <UnitMetric
              icon={IconUnitSize}
              title={t('Unit size')}
              value={unitListingDetail()?.unit?.unitSizeSqft || emptyPlaceholder}
              suffix={t('Sqft')}
            />
            <UnitMetric
              icon={IconDateAvaliable}
              title={t('Date available')}
              value={unitListingDetail()?.availableFrom ? dayjs(unitListingDetail()?.availableFrom).format('YYYY-MM-DD') : emptyPlaceholder}
            />
          </div>
        </div>
      </Panel>

      <Panel class="col-span-full h-64 overflow-hidden rounded-lg bg-white p-2 lg:col-span-3 lg:h-auto">
        <GoogleMap address={unitListingDetail()?.singleLineAddress} />
      </Panel>

      <Panel title={t('Facts and features')} class="col-span-full lg:col-span-4">
        <div class="relative h-[calc(100%-2.75rem)] px-6 py-4">
          <Show
            when={combinedAmenities().length > 0}
            fallback={
              <div class="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center">
                <img src={IconFacts} alt="Facts icon" />
                <p class="mt-1 text-xs normal-case text-text-level03">{t('Facts and features have not been added')}</p>
              </div>
            }>
            <div class="flex w-full flex-wrap gap-3">
              <For each={combinedAmenities()}>
                {(item) => (
                  <div class="flex w-full items-center gap-2 text-sm text-text-level02 md:w-[calc(50%-0.375rem)] xl:w-[calc(50%-0.5rem)]">
                    <Checkbox checked={item?.value} disabled />
                    <span class="w-[98%] truncate">{item?.label}</span>
                  </div>
                )}
              </For>
            </div>
          </Show>
        </div>
      </Panel>
      <Panel title={t('Pet policy')} class="col-span-full lg:col-span-4">
        <div class="relative h-[calc(100%-2.75rem)] px-6 py-4">
          <Show
            when={unitListingDetail()?.petPolicy?.allowed}
            fallback={
              <div class="absolute left-1/2 top-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center">
                <img src={NoAllowImg} alt="pet icon" />
                <p class="mt-2 text-center text-sm capitalize text-light-danger">{t('Not allow')}</p>
              </div>
            }>
            <div class="w-full py-1">
              <div class="mb-3 flex w-full items-center justify-center rounded-lg bg-green-level01 py-2 text-light-green">
                <IconCheck class="mr-1" />
                {t('Allowed')}
              </div>
              <ul class="px-3 text-sm text-text-level02">
                <Show when={petPolicies().length > 0}>
                  <li class="mb-2 list-disc">{petPolicies().join(', ')}</li>
                </Show>
                <Show when={unitListingDetail()?.petPolicy?.size}>
                  <li class="mb-2 list-disc">{petSizeMap[unitListingDetail()?.petPolicy?.size as petSizeType]}</li>
                </Show>
                <Show when={unitListingDetail()?.petPolicy?.fee}>
                  <li class="mb-2 list-disc">
                    {unitListingDetail()?.petPolicy?.fee} {t('Monthly pet fee')}
                  </li>
                </Show>
                <Show when={unitListingDetail()?.petPolicy?.deposit}>
                  <li class="mb-2 list-disc">
                    ${unitListingDetail()?.petPolicy?.deposit} {t('pet deposit')}
                  </li>
                </Show>
                <Show when={unitListingDetail()?.petPolicy?.description}>
                  <li class="list-disc">{unitListingDetail()?.petPolicy?.description}</li>
                </Show>
              </ul>
            </div>
          </Show>
        </div>
      </Panel>
      <Panel title={t('Highlight features')} class="col-span-full lg:col-span-4">
        <div class="px-6 py-4 text-text-level02">
          <Show
            when={unitListingDetail()?.tags}
            fallback={
              <div class="flex w-full flex-col items-center">
                <img src={IconFeatures} />
                <p class="mt-1 text-center text-xs normal-case text-text-level03">
                  {t("Click on 'Magic AI' below to automatically generate")}
                </p>
              </div>
            }>
            <div class="text-sm normal-case">{t("Here are the features we've analyzed for your property!")}:</div>
            <div class="mt-2 flex flex-wrap items-center gap-1.5">
              <For each={unitListingDetail()?.tags}>
                {(tag) => <div class="rounded-md bg-light-pink px-3 py-1 text-sm text-[#B140F5]">{tag}</div>}
              </For>
            </div>
          </Show>
        </div>
      </Panel>

      <ListingDescriptions />
      <Show
        when={unitListingDetail()?.images && unitListingDetail()?.images?.length}
        fallback={
          <span class="col-span-full text-center text-sm text-text-level03">
            {t('There are no images associated with this listing')}...
          </span>
        }>
        <div class="col-span-full grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <ImageList images={unitListingDetail()?.images || []} listing={unitListingDetail() as MagicDoor.Api.HydratedUnitListingDto} />
        </div>
      </Show>
    </div>
  );
};
