import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const BILLS_URL = '/api/vendors/bills';
const BILLS_URL_WITHOUT_VENDORS = '/api/bills';

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}
export type BillListFilter = {
  page?: number;
  pageSize?: number;
  companyBill?: boolean;
  direction?: string;
  leaseId?: string;
  unitId?: string;
  propertyId?: string;
  portfolioId?: string;
  unitIdOr?: string[];
  propertyIdOr?: string[];
  portfolioIdOr?: string[];
  vendorIdOr?: string[];
  bankAccountId?: string;
  'DueDate.Start'?: string;
  'DueDate.End'?: string;
  'BillDate.Start'?: string;
  'BillDate.End'?: string;
  'Amount.From'?: number;
  'Amount.To'?: number;
  'Due.From'?: number;
  'Due.To'?: number;
  chartOfAccountId?: string;
  allocationTransactionId?: string;
  recurringChargeId?: string;
  identity?: string;
  includeIdentities?: string[];
  excludeIdentities?: string[];
  vendorId?: string;
};

export type VendorBillsPayListFilter = {
  propertyIdOr?: string[];
  vendorIdOr?: string[];
  'DueDate.Start'?: string;
  'DueDate.End'?: string;
};

export class BillRepository extends BaseRestRepository {
  public async getBills(filter?: BillListFilter): Promise<MagicDoor.Api.HydratedBillDtoPaginationDto> {
    const url = urlWithQuery(BILLS_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getBill(billId: string): Promise<MagicDoor.Api.HydratedBillDto> {
    validateId(billId);
    const url = `${BILLS_URL}/${billId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async createBill(bill: MagicDoor.Api.CreateVendorBillDto): Promise<MagicDoor.Api.HydratedBillDto> {
    const url = BILLS_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(bill),
    });
    return this.getJsonResponse(response);
  }

  public async updateBill(billId: string, bill: MagicDoor.Api.UpdateVendorBillDto): Promise<MagicDoor.Api.HydratedBillDto> {
    validateId(billId);
    const url = `${BILLS_URL}/${billId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(bill),
    });
    return this.getJsonResponse(response);
  }

  public async deleteBill(billId: string, deleteMethod?: MagicDoor.Api.DeleteBillPaymentMethod): Promise<void> {
    validateId(billId);
    const url = `${BILLS_URL}/${billId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
      body: JSON.stringify({ deleteMethod }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public async payBill(payload: MagicDoor.Api.PayVendorBillsDto): Promise<MagicDoor.Api.PayBillsResultsDto> {
    const url = `${BILLS_URL}/pay`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async deleteBillItem(billId: string, transactionId: string, deleteMethod?: MagicDoor.Api.DeleteBillPaymentMethod): Promise<void> {
    validateId(billId);
    const url = `${BILLS_URL_WITHOUT_VENDORS}/${billId}/transaction/${transactionId}`;
    await this.fetchWithAuth(url, {
      method: 'DELETE',
      body: JSON.stringify({ deleteMethod }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  public async addBillFile(billId: string, file: File, description?: string): Promise<MagicDoor.Api.HydratedBillDto> {
    const url = `${BILLS_URL_WITHOUT_VENDORS}/${billId}/files`;
    const formData = new FormData();
    formData.append('File', file);
    formData.append('Description', description || '');

    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return this.getJsonResponse(response);
  }

  public async deleteBillFile(billId: string, fieldId: string): Promise<void> {
    const url = `${BILLS_URL_WITHOUT_VENDORS}/${billId}/files/${fieldId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async getVendorBillsPay(filter: VendorBillsPayListFilter): Promise<MagicDoor.Api.VendorBillsToPayDto> {
    const url = urlWithQuery(`${BILLS_URL}/pay`, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}
