import { createMemo, Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

export interface LabeledGroupProps {
  class?: string;
  label?: string;
  labelJSX?: JSX.Element;
  type?: string;
  id?: string;
  isInvalid?: boolean;
  children?: JSX.Element;
  error?: string | undefined | null;
  labelClass?: string;
  required?: boolean;
  labelContainerClass?: string;
  disabled?: boolean;
  description?: string;
  inputContainerClass?: string;
  groupClass?: string;
}

export const LabeledGroup: Component<LabeledGroupProps> = (props) => {
  const id = createMemo(() => props.id || props.label?.replace(/[^a-zA-Z0-9]/g, ''));
  const isInvalid = createMemo(() => !!props.error);

  const renderLabelContent = () => {
    if (props.labelJSX) {
      return props.labelJSX;
    }

    if (props.label) {
      return props.label;
    }
    return null;
  };

  return (
    <div class={cn('relative', props.class || props.groupClass)} classList={{ 'mb-10': !!props.error }}>
      <div class={cn('flex flex-col ', props.labelContainerClass)}>
        <Show when={props.label || props.labelJSX}>
          <label class={cn('mb-2 flex text-sm uppercase text-label', props.labelClass)} for={id()}>
            {renderLabelContent()}
            <Show when={props.required}>
              <span class="text-error"> *</span>
            </Show>
          </label>
        </Show>
        {props.children}
      </div>
      <Show when={isInvalid()}>
        <span class="mt-2 text-left text-xs normal-case text-red-500">{props.error}</span>
      </Show>
    </div>
  );
};
