import { useNavigate, useSearchParams } from '@solidjs/router';
import { createEffect, createMemo, Show } from 'solid-js';
import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import { DatePicker, IconLoader } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import { useReportPrinter } from '~/pdfsigner/hooks/useReportPrinter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { BalanceSheetPresenter } from '~/pdfsigner/presenters/reports/BalanceSheetPresenter';
import { GetBalanceSheetReportUseCase } from '~/pdfsigner/usecases/reports/getBalanceSheetReportUseCase';
import { PropertySelect } from './components/PropertySelect';
import { ReportTable, parseColumns, parseLines } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';

const BalanceSheetReport: Component<{ class?: string; report?: MagicDoor.Api.BalanceSheetReportDataDto }> = (props) => {
  const { t } = useLocalization();

  const lines = () => {
    if (props.report == null) return [];
    const res: LineItem[] = [];
    res.push({ label: t('Assets'), level: 0 });
    res.push(...parseLines(props.report.assets));
    res.push({ label: t('Total assets'), type: 'total', level: 0, values: props.report.totalAssets });
    res.push({ label: t('Liabilities'), level: 0 });
    res.push(...parseLines(props.report.liabilities));
    res.push({ label: t('Total liabilities'), type: 'total', level: 0, values: props.report.totalLiabilities });
    res.push({ label: t('Capital'), level: 0 });
    res.push(...parseLines(props.report.capital));
    res.push({ label: t('Total capital'), type: 'total', level: 0, values: props.report.totalCapital });
    return res;
  };
  return <ReportTable label={t('Account name')} lines={lines()} columns={parseColumns(props.report?.groupHeaders)} />;
};

export const BalanceSheetReportPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams<{
    reportAsOff?: string;
    portfolioIds?: string;
    propertyIds?: string;
    includeNoPropertyBills?: string;
    companyBills?: string;
  }>();

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Balance sheet') }]);

  const { execute: getBalanceSheetReport, isLoading: isLoadingBalanceSheet } = useUseCase(GetBalanceSheetReportUseCase);
  const { model: balanceSheetReport } = usePresenter(BalanceSheetPresenter);
  const { print } = useReportPrinter();

  createEffect(() => {
    const params: any = {
      reportAsOff: searchParams.reportAsOff,
    };
    if (searchParams.propertyIds) {
      params.propertyIds = searchParams.propertyIds?.split(',').filter(Boolean);
    } else if (searchParams.portfolioIds) {
      params.portfolioIds = searchParams.portfolioIds?.split(',').filter(Boolean);
    }
    getBalanceSheetReport(params);
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Balance sheet report')}</h1>
          <Button
            onClick={() => print(balanceSheetReport()?.balanceSheetReport)}
            disabled={isLoadingBalanceSheet()}
            color={'primary'}
            variant={'outlined'}>
            <div class="flex items-center">
              <PrintIcon class="mr-1 size-5" />
              {t('Print')}
            </div>
          </Button>
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <PropertySelect />
          <DatePicker
            class="min-w-64 grow"
            prefix={t('Report as off')}
            value={searchParams.reportAsOff}
            onChange={(value) => {
              setSearchParams({ ...searchParams, reportAsOff: value });
            }}
          />
        </div>
        <Show when={isLoadingBalanceSheet()} fallback={<BalanceSheetReport report={balanceSheetReport()?.balanceSheetData} />}>
          <IconLoader class="mx-auto my-56 animate-spin" />
        </Show>
      </div>
    </>
  );
};
