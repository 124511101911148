import { createEffect, For, onCleanup, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import term from '~/assets/images/common/term.png';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Radio } from '~/components/common/Inputs/Radio';
import { useLocalization } from '~/contexts/global';
import { useNewRentalApplication } from '~/contexts/local';
import { magicdoorTerms } from '~/utils/constant';

const Term = (props: { title: string; content: string; checked: boolean; onInput: (checked: boolean) => void }) => {
  const { t } = useLocalization();
  return (
    <div class="flex flex-col gap-4">
      <div class="flex items-center gap-2 font-medium text-essential-colour">
        <img class="size-4" src={term} alt="term-icon" />
        <span>{props.title}</span>
      </div>
      <div class="rounded bg-partingline p-4 pr-0">
        <div class="thinscroll h-40 overflow-auto normal-case">{props.content}</div>
      </div>

      <Checkbox showLabel checked={props.checked} label={t('I have read and accept the terms of service')} onInput={props.onInput} />
    </div>
  );
};

export const QuestionAndTerms = () => {
  const { t } = useLocalization();
  const { settings, setStore, store } = useNewRentalApplication();
  const form = useFormContext();
  const [localStore, setLocalStore] = createStore({
    terms: [
      { title: t('MagicDoor Terms And Conditions'), content: magicdoorTerms, checked: false },
      { title: t('Transunion Terms And Conditions'), content: magicdoorTerms, checked: false },
    ],
  });

  createEffect(() => {
    if (store.currentStep !== 4) return;
    setStore({ disableNext: localStore.terms.some((term) => !term.checked) });
  });

  onCleanup(() => {
    setStore({ disableNext: false });
  });

  return (
    <div class="flex flex-col gap-4">
      <For each={settings()?.questions}>
        {(val) => {
          return (
            <div class="flex gap-4">
              <span class="flex-1">{val.text}</span>
              <Show
                when={val.options?.length && val.text}
                fallback={
                  <LabeledTextInput
                    value={form?.formStore.questions?.[val.text!] ?? ''}
                    onInput={(value) => form?.setValueToStore(['questions', val.text!], value)}
                  />
                }>
                <Radio
                  items={val.options?.map((o) => ({ label: o, value: o })) ?? []}
                  value={form?.formStore.questions?.[val.text!] ?? ''}
                  onInput={(value) => form?.setValueToStore(['questions', val.text!], value)}
                />
              </Show>
            </div>
          );
        }}
      </For>
      <For each={localStore.terms}>
        {(val, index) => (
          <Term
            title={val.title}
            content={val.content}
            checked={val.checked}
            onInput={(checked) => setLocalStore('terms', index(), 'checked', checked)}
          />
        )}
      </For>
    </div>
  );
};
