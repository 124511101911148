import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

interface RefundPaymentModalProps {
  refundAmount: string;
  refundReason: string;
  maxAmount: number;
  onAmountChange: (amount: string) => void;
  onReasonChange: (reason: string) => void;
}

export const RefundPaymentModal: Component<RefundPaymentModalProps> = (props) => {
  const { t } = useLocalization();

  const handleInputRefundAmount = (_: string, event: Event) => {
    const input = event.target as HTMLInputElement;
    const paymentAmount = props.maxAmount;
    const numericValue = parseFloat(input.value) || 0;

    if (numericValue > paymentAmount) {
      toast.error(t('The refund amount cannot exceed the paid amount.'));
      const formattedAmount = paymentAmount.toFixed(2);
      props.onAmountChange(formattedAmount);
      input.value = formattedAmount;
    } else if (numericValue < 1) {
      toast.error(t('The minimum refund amount is 1.'));
      props.onAmountChange('1.00');
      input.value = '1.00';
    } else {
      const formattedAmount = numericValue.toFixed(2);
      props.onAmountChange(formattedAmount);
      input.value = formattedAmount;
    }
  };

  const handleReasonChange = (value: string, event: Event) => {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;

    if (inputValue.length > 150) {
      input.value = value.slice(0, 150);
      toast.error(t('Refund reason must be less than 150 characters.'));
    }
    props.onReasonChange(value.slice(0, 150));
  };

  return (
    <div class="w-96 border-t">
      <div class="px-6 pt-4">
        <div class="font-size-12 pb-3 text-label">{t('Refund the payment')}</div>

        <div class="mb-2 flex w-full items-center rounded-md bg-input">
          <div class="ml-4">{t('Amount')}</div>
          <div class="ml-auto mr-1 text-primary">$</div>
          <LabeledTextInput
            inputClass="align-item input-no-focus-border w-28 bg-input pl-0 text-primary"
            inputContainerClass="border-none focus-within:ring-0"
            placeholder="Enter amount"
            value={props.refundAmount}
            onInput={handleInputRefundAmount}
          />
        </div>

        <LabeledTextInput
          label="Refund reason"
          labelClass="normal-case"
          inputClass="input-no-focus-border bg-input"
          inputContainerClass="border-none focus-within:ring-0"
          placeholder="Please enter refund reason"
          value={props.refundReason}
          onInput={handleReasonChange}
        />
      </div>
    </div>
  );
};
