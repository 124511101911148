import { leaseDraftRepository } from '~/pdfsigner/repositories/leaseDraftRepository';
import { UseCase } from './useCase';
import { ValidateDocumentUseCase } from './validateDocumentUSeCase';

export class SendRemindersUseCase extends UseCase {
  public async runLogic() {
    new ValidateDocumentUseCase().execute();
    if (this.state.selectedDocument && this.state.selectedDocument.id) {
      await leaseDraftRepository.sendReminders(this.state.selectedDocument.id);
    }
  }
}
