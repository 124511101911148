import { For, Show } from 'solid-js';
import { Empty } from '~/components/common/Empty';
import { SearchListInput } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useRunBooks } from '~/contexts/local';
import { AIRunbookItem } from '~/pages/ai-runbooks/list-runbooks/components/AiRunbookItem';
import type { Component } from 'solid-js';
import type { ListRunBookDto } from '~/swagger/Api';

type AIRunbookTableProps = {
  title: string;
  runbooks?: ListRunBookDto[];
};

const TableHeader = (props: { title: string; count: number; onSearch: (value?: string) => void }) => {
  const { t } = useLocalization();
  return (
    <div class="flex items-center justify-between gap-4 rounded-lg bg-white px-6 py-4 text-lg font-semibold text-text-level01">
      <div class="flex gap-4">
        <span>{props.title}</span>
        <span class="text-purple">{props.count}</span>
      </div>
      <SearchListInput placeholder={t('Search')} onChange={props.onSearch} />
    </div>
  );
};

export const AIRunbookTable: Component<AIRunbookTableProps> = (props) => {
  const { getRunBooks, changeRunbookStatus, runBooks } = useRunBooks();

  const LoadingPlaceholder = () => (
    <>
      <AIRunbookItem loading />
      <AIRunbookItem loading />
      <AIRunbookItem loading />
      <AIRunbookItem loading />
    </>
  );

  return (
    <div>
      <TableHeader title={props.title} count={props.runbooks?.length ?? 0} onSearch={(value) => getRunBooks({ title: value })} />

      <div class="mt-4 grid grid-cols-2 items-stretch justify-between gap-5 lg:grid-cols-3 xl:grid-cols-4">
        <Show when={!runBooks.loading} fallback={<LoadingPlaceholder />}>
          <For
            each={props.runbooks}
            fallback={
              <div class="col-span-full flex justify-center pt-10">
                <Empty />
              </div>
            }>
            {(runbook) => <AIRunbookItem runbook={runbook} onChangeStatus={(checked) => changeRunbookStatus(runbook.id, checked)} />}
          </For>
        </Show>
      </div>
    </div>
  );
};
