import { useNavigate } from '@solidjs/router';
import { For, Show, createEffect, createSignal } from 'solid-js';
import IconChevron from '~/assets/images/common/lineArrow.svg?component-solid';
import { useForm } from '~/components/common/BetterForm';
import { FormWrapper } from '~/components/common/BetterForm/Form';
import CallToActionButton from '~/components/common/Buttons/CallToActionButton';
import {
  CategoryComponentSection,
  CategoryComponentWrapperProvider,
  CategoryContentSection,
  getErrorAndProgressProps,
} from '~/components/common/Category';
import CancelWithConfirmation from '~/components/modals/CancelWithConfirmation';
import { toast } from '~/components/ui';
import { useLocalization, useOwners } from '~/contexts/global';
import { isEmptyData } from '~/utils/tool';
import { OwnerAddressSection } from './OwnerAddressSection';
import { OwnerBankInformationSection } from './OwnerBankInformationSection';
import OwnerInformationSection from './OwnerInformationSection';
import { OwnerPaymentInformationSection } from './OwnerPaymentInformationSection';
import type { JSX, Setter } from 'solid-js';
import type { Section } from '~/components/common/Category';

interface Props {
  onClose: () => void;
  setShowCloseConfirmation?: Setter<boolean>;
  showCloseConfirmation?: boolean;
}

type AddSection = {
  key: string;
  component: (props: any) => JSX.Element;
  name: string;
  ref?: HTMLElement | null;
  required?: boolean;
} & Omit<Section, 'component'>;

export const AddOwner = (props: Props) => {
  const navigate = useNavigate();

  const { t } = useLocalization();
  const { addOwner } = useOwners();

  const form = useForm();

  const [activeCategory, setActiveCategory] = createSignal<string>('ownerInformation');
  const [expandedAdditional, setExpandedAdditional] = createSignal<boolean>(false);
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [preventScroll, setPreventScroll] = createSignal<boolean>(false);

  const sections: AddSection[] = [
    {
      key: 'ownerInformation',
      component: OwnerInformationSection,
      name: t('Owner information'),
      required: true,
      fields: ['firstName', 'lastName', 'email', 'phone', 'clearTextPassword', 'language', 'isCompany'],
    },
    {
      key: 'addressInformation',
      component: OwnerAddressSection,
      name: t('Address'),
      fields: ['address'],
    },
    {
      key: 'bankInformation',
      component: OwnerBankInformationSection,
      name: t('Bank information'),
      fields: ['bankAccountInformation'],
    },
    {
      key: 'paymentInformation',
      component: OwnerPaymentInformationSection,
      name: t('Payment information'),
      fields: ['federalTaxInformation'],
    },
  ];

  const handleToggleExpandedAdditional = () => {
    setPreventScroll(true);
    setExpandedAdditional((prev) => !prev);
    setTimeout(() => setPreventScroll(false), 100);
  };

  const handleActiveCategoryChange = (key: string) => {
    if (preventScroll()) return;

    if (key !== 'ownerInformation') {
      setExpandedAdditional(true);
    }
    setActiveCategory(key);
  };

  let scrollContainer!: HTMLDivElement;

  const submit = async () => {
    setIsLoading(true);
    try {
      const { validateStatus } = form.validateForm();
      if (!validateStatus) {
        return;
      }
      const owner = await addOwner(form.formStore as MagicDoor.Api.UpdateOwnerDto);
      navigate(`/users/owners/${owner.id}`);

      toast.success(t('{name} has been added successfully', { name: t('Owner') }));
    } finally {
      setIsLoading(false);
    }
  };

  createEffect(() => {
    const store = { ...form.formStore };
    delete store.language;
    props.setShowCloseConfirmation?.(!isEmptyData(store));
  });

  return (
    <FormWrapper
      class="relative flex flex-col text-sm"
      initialValues={{
        language: 'en',
      }}
      defaultForm={form}
      onFormSubmit={submit}>
      <CategoryComponentWrapperProvider
        onActiveCategoryChange={handleActiveCategoryChange}
        sections={sections}
        getScrollElement={() => scrollContainer}
        activeCategory={activeCategory()}>
        <div class="relative flex flex-1 items-start">
          <div class="sticky top-0 hidden h-full w-1/3 bg-inputbox-bg text-text-level03 xs:block">
            <div class="flex flex-col gap-2">
              <CategoryComponentSection
                headerTitle={<div class="py-3 pl-5 text-lg font-semibold text-text-level01">{t('Add owner')}</div>}
                categoryFooterTitle={t('Owner')}
                class="min-w-0 bg-transparent"
                {...getErrorAndProgressProps(form)}
              />
            </div>
          </div>

          <CategoryContentSection
            renderMethod={({ store, setContentRef }) => (
              <div ref={scrollContainer} class="thinscroll flex h-[610px] flex-1 flex-col overflow-auto px-8">
                <div ref={(ref) => setContentRef('ownerInformation', ref)}>
                  <OwnerInformationSection />
                </div>
                <button
                  type="button"
                  class="text-normal my-4 flex items-center justify-center gap-3 px-8 py-4 text-text-level02"
                  onClick={handleToggleExpandedAdditional}>
                  <hr class="grow" /> {t('Add additional information')}{' '}
                  <IconChevron class="transition" classList={{ 'rotate-180': !expandedAdditional() }} /> <hr class="grow" />
                </button>
                <Show when={expandedAdditional()}>
                  <For each={(store.sections as AddSection[]).slice(1)}>
                    {({ key, component: SectionComponent }) => (
                      <div ref={(ref) => setContentRef(key, ref)}>
                        <SectionComponent />
                      </div>
                    )}
                  </For>
                </Show>
              </div>
            )}
          />
        </div>

        <div class="z-20 flex w-full items-center justify-between border-t bg-white p-6">
          <div class="w-2/3 text-left text-xs text-text-level03">{t('You may modify these information later')}</div>
          <div class="flex w-1/3 justify-end gap-2">
            <CancelWithConfirmation
              enabled={props.showCloseConfirmation}
              onConfirm={props.onClose}
              buttonsDisabled={isLoading()}
              message={t('Are you sure you want to cancel? The changes will not be saved.')}
            />
            <CallToActionButton
              type="submit"
              loading={isLoading()}
              tooltipMessage={t('Please fill out all the required fields that are in red')}>
              {t('Create owner')}
            </CallToActionButton>
          </div>
        </div>
      </CategoryComponentWrapperProvider>
    </FormWrapper>
  );
};
