import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, createMemo, onMount, Show } from 'solid-js';
import { useForm } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { LeaseItem } from '~/components/leases/LeaseItem';
import { PrintPageHeader } from '~/components/print';
import { PrintTableContainer } from '~/components/print/PrintTablePageButton';
import { PropertyTitle } from '~/components/properties';
import { toast } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { useBills } from '~/contexts/local';
import UnitItem from '~/pages/bills/components/UnitItem';
import VendorItem from '~/pages/bills/components/VendorItem';
import BillStore from '~/pages/bills/store';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import { printDocument } from '~/utils/print';
import { upperFirst } from '~/utils/tool';
import BillDetailsView from './components/BillDetailsView';
import type { Payment } from './components/PaymentTable';

const BillDetailsPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const params = useParams<{ billId: `${number}`; type: `${string}` }>();
  const { loading, setCurrentId, current, updateBill } = useBills();
  const { companies } = useCompanies();
  const form = useForm();
  const { getChartOfAccounts, getBankAccounts } = BillStore;
  const breadcrumbItems = createMemo(() => [{ label: t('Bills'), link: '/accounting/bills' }, { label: t('Edit bill') }]);

  const handleUpdateBill = async () => {
    if (!current()?.id) return;
    const { validateStatus } = form.validateForm();
    if (!validateStatus) throw new Error();
    try {
      await updateBill(current()?.id as string, form.formStore as MagicDoor.Api.UpdateVendorBillDto);
      navigate(-1);
      toast.success(t('{name} has been edited successfully', { name: t('Bill') }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  createEffect(() => {
    if (params.billId) {
      setCurrentId(params.billId);
    }
  });

  createEffect(() => {
    if (current()) {
      form.setFormStore({
        vendorId: current()?.vendor?.id,
        reference: current()?.reference,
        memo: current()?.memo,
        propertyId: current()?.propertyId,
        leaseId: current()?.leaseId,
        unitId: current()?.unitId,
        billDate: current()?.billDate,
        dueDate: current()?.dueDate,
        lineItems: current()?.lineItems.map((item) => ({
          chartOfAccountId: item.chartOfAccount.id,
          description: item.description,
          amount: item.amount,
        })),
      });
    }
  });

  const payment = createMemo<Payment[]>(() => {
    const result: Payment[] = [];
    const transactions = current()?.transactions || {};
    Object.keys(transactions).forEach((key) => {
      const transaction = transactions[key];
      result.push({
        payDate: dateFormat('', transaction.transactionDate),
        status: transaction.status,
        memo: transaction.memo || emptyPlaceholder,
        vendor: current()?.vendor?.name,
        paymentMethod: transaction.transactionPaymentMethod,
        paymentAmount: currency(transaction.amount),
        transactionId: transaction.id,
      });
    });

    return result;
  });

  const print = () => {
    const printContainer = (
      <div>
        <div class="mt-5 text-center text-xl font-medium capitalize text-title-gray">{t('Bill details')}</div>
        <div class="mt-5 flex items-center justify-between text-xxs text-[#6A6D85]">
          <div>
            <span>{t('Bill id')}: </span>
            <span>{current()?.id}</span>
          </div>
          <div>
            <span>{t('Print time')}: </span>
            <span>{dateFormat()}</span>
          </div>
          <div>
            <span>{t('Bill date')}: </span>
            <span>{dateFormat('', current()?.billDate)}</span>
          </div>
          <div>
            <span>{t('Due date')}: </span>
            <span>{dateFormat('', current()?.dueDate)}</span>
          </div>
        </div>
        <div class="mt-6">
          <div class="text-sm font-medium text-title-gray">{`${t('Vendor')}/${t('Payee')}`}</div>
          <div class="mt-1.5 rounded-md border border-[#D6D6D6] p-2">
            <VendorItem class="text-xxs font-medium text-text-level01" vendor={current()?.vendor} />
          </div>
        </div>
        <div class="mt-5">
          <div class="text-sm font-medium text-title-gray">{t('Property')}</div>
          <div class="mt-1.5 rounded-md border border-[#D6D6D6] p-2">
            <PropertyTitle property={current()?.property} />
          </div>
        </div>
        <Show when={current()?.unit}>
          <div class="mt-5">
            <div class="text-sm font-medium text-title-gray">{t('Unit')}</div>
            <div class="mt-1.5 rounded-md border border-[#D6D6D6] p-2">
              <UnitItem unit={current()?.unit} />
            </div>
          </div>
        </Show>
        <Show when={current()?.lease}>
          <div class="mt-5">
            <div class="text-sm font-medium text-title-gray">{t('Unit')}</div>
            <div class="mt-1.5 rounded-md border border-[#D6D6D6] p-2">
              <LeaseItem
                lease={
                  {
                    ...current()?.lease,
                    property: current()?.property,
                    unit: current()?.unit,
                  } as any
                }
              />
            </div>
          </div>
        </Show>
        <div class="mt-5">
          <div class="mb-3 text-sm font-medium text-title-gray">{t('Bill detail')}</div>
          <PrintTableContainer
            table={{
              class: '[&_thead]:bg-[#F0CFF3] text-xs',
              rowClass: (_, index: number) => (index % 2 === 0 ? 'bg-[#F6EEF5]' : 'bg-white'),
              columns: [
                {
                  title: t('Chart of account'),
                  render: (item) => <div>{item.chartOfAccount.name}</div>,
                },
                {
                  title: t('Description'),
                  key: 'description',
                  render: (item) => item.description || emptyPlaceholder,
                },
                {
                  title: t('Unpaid balance'),
                  headerClass: 'text-right',
                  render: (item) => <div class="text-right">{currency((item?.amount || 0) - (item?.paid || 0))}</div>,
                },
                {
                  title: t('Amount'),
                  key: 'amount',
                  class: 'text-right',
                  headerClass: 'text-right',
                },
              ],
              data: current()?.lineItems ?? [],
              total: currency(current()?.lineItems.reduce((previous, current) => previous + current.amount, 0)),
            }}
            t={t}
          />
        </div>
        <Show when={payment().length}>
          <div class="mt-5">
            <div class="mb-3 text-sm font-medium text-title-gray">{t('Payment')}</div>
            <PrintTableContainer
              table={{
                class: '[&_thead]:bg-[#F0CFF3] text-xs',
                rowClass: (_, index: number) => (index % 2 === 0 ? 'bg-[#F6EEF5]' : 'bg-white'),
                columns: [
                  {
                    title: t('Pay date'),
                    key: 'payDate',
                  },
                  {
                    title: t('Status'),
                    key: 'status',
                    render: (item) => (
                      <div
                        class="rounded-xl text-xs"
                        classList={{
                          'text-light-warning': item.status === 'pending',
                          'text-light-danger': item.status === 'failed',
                          'text-light-green': item.status === 'success',
                          'text-text-level04': ['cancelled', 'returned'].includes(item.status),
                        }}>
                        {t(upperFirst(item.status))}
                      </div>
                    ),
                  },
                  {
                    title: t('Memo'),
                    key: 'memo',
                  },
                  {
                    title: t('Vendor'),
                    key: 'vendor',
                  },
                  {
                    title: t('Payment method'),
                    key: 'paymentMethod',
                  },
                  {
                    title: t('Payment amount'),
                    key: 'paymentAmount',
                    class: 'text-right',
                    headerClass: 'text-right',
                  },
                ],
                data: payment(),
                total: currency(
                  payment().reduce((previous, current) => previous + parseFloat(current.paymentAmount?.replace('$', '') || '0'), 0)
                ),
              }}
              t={t}
            />
          </div>
        </Show>
      </div>
    );
    printDocument(printContainer as HTMLDivElement, undefined, PrintPageHeader({ companies: companies() }) as Element);
  };

  onMount(() => {
    getChartOfAccounts();
    getBankAccounts();
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <MultiStepsPanel
        onClose={() => navigate(-1)}
        title={t('Edit bill')}
        confirmation={false}
        submitText={t('Confirm')}
        disableNext={!form.isValidate}
        onSubmit={handleUpdateBill}
        showPrint
        onPrint={print}>
        <Show
          when={!loading()}
          fallback={
            <div class="flex size-full items-center justify-center">
              <RingLoader text={`${t('Loading')}...`} />
            </div>
          }>
          <BillDetailsView form={form} bill={current()} payment={payment()} />
        </Show>
      </MultiStepsPanel>
    </>
  );
};

export default BillDetailsPage;
