import { useNavigate, useSearchParams } from '@solidjs/router';
import { createEffect, Show, createMemo, createSignal, onMount, createResource } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import { PrintPageHeader } from '~/components/print';
import { DatePicker, IconLoader } from '~/components/ui';
import { useLocalization, useCompanies } from '~/contexts/global';
import { useReports } from '~/contexts/local';
import { lastMonthRange } from './ListReports';
import { PropertySelect } from './components/PropertySelect';
import { parseLines, ReportTable, parseColumns } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';

const IncomeStatementReport: Component<{ class?: string; report?: MagicDoor.Api.IncomeStatementDto }> = (props) => {
  const { t } = useLocalization();

  const lines = () => {
    if (props.report == null) return [];

    const res: LineItem[] = [];

    res.push({ label: t('Income'), level: 0 });
    res.push(...parseLines(props.report.income));
    res.push({ label: t('Total {type}', { type: t('Income') }), type: 'total', level: 0, values: props.report.totalIncome });

    res.push({ label: t('Expenses'), level: 0 });
    res.push(...parseLines(props.report.expenses));
    res.push({ label: t('Total {type}', { type: t('Expenses') }), type: 'total', level: 0, values: props.report.totalExpense });

    res.push({ type: 'empty' });

    res.push({ label: t('Net operating income'), level: 0, values: props.report.netOperatingIncome });

    return res;
  };

  return <ReportTable label={t('Account name')} lines={lines()} columns={parseColumns(props.report?.groupHeaders)} />;
};

export const IncomeStatementReportPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { companies } = useCompanies();
  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();

  const [searchParams, setSearchParams] = useSearchParams<{
    start?: string;
    end?: string;
    portfolioIds?: string;
    propertyIds?: string;
    includeNoPropertyBills?: string;
    companyBills?: string;
  }>();

  const { incomeStatementReport, getIncomeStatementReport, getFilterDisplay } = useReports();

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Income statement report') }]);

  onMount(() => {
    if (searchParams.start !== lastMonthRange.start && searchParams.end !== lastMonthRange.end) {
      setSearchParams({
        start: lastMonthRange.start,
        end: lastMonthRange.end,
      });
    }
  });

  createEffect(() => {
    const params: any = {
      start: searchParams.start,
      end: searchParams.end,
    };
    if (searchParams.propertyIds) params.propertyIds = searchParams.propertyIds.split(',');
    else if (searchParams.portfolioIds) params.portfolioIds = searchParams.portfolioIds.split(',');

    getIncomeStatementReport(params);
  });

  const [filterDisplay] = createResource(
    () => [searchParams.propertyIds, searchParams.portfolioIds],
    async ([propertyIds, portfolioIds]) => {
      return await getFilterDisplay(propertyIds, portfolioIds);
    }
  );

  const printHeader = createMemo(() => {
    const start = searchParams.start;
    const end = searchParams.end;
    return (
      <div>
        <PrintPageHeader companies={companies()} />
        <div class="flex flex-col gap-1 py-6">
          <h1 class="text-center text-xl font-semibold text-[#3F4156]">{t('Income statement report')}</h1>
          <Show when={filterDisplay()?.propertiesStr || filterDisplay()?.portfoliosStr}>
            <p class="text-center text-base font-medium text-title-gray">
              {filterDisplay()?.portfoliosStr || t('All')} - {filterDisplay()?.propertiesStr || t('All properties')}
            </p>
          </Show>
          <p class="text-center text-base font-medium text-title-gray">
            {start} - {end}
          </p>
        </div>
      </div>
    );
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Income statement report')}</h1>
          <PrintButton
            color="primary"
            variant="outlined"
            disabled={incomeStatementReport.loading}
            isReport={true}
            printContainer={printContainer()!}
            printHeader={printHeader() as Element}
            printWrapClass="w-auto"
          />
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <PropertySelect />
          <DatePicker
            class="min-w-64 grow"
            prefix={t('Date range start')}
            value={searchParams.start}
            onChange={(value) => {
              if (searchParams.end != null && value && searchParams.end <= value) {
                const start = new Date(value);
                start.setDate(start.getDate() + 30);
                searchParams.end = start.toISOString().split('T')[0];
              }
              setSearchParams({ ...searchParams, start: value });
            }}
          />
          <DatePicker
            class="min-w-64 grow"
            prefix={t('Date range end')}
            value={searchParams.end}
            onChange={(value) => {
              if (searchParams.start != null && value && searchParams.start >= value) {
                const end = new Date(value);
                end.setDate(end.getDate() - 30);
                searchParams.start = end.toISOString().split('T')[0];
              }
              setSearchParams({ ...searchParams, end: value });
            }}
          />
          {/* TODO: BACKEND WILL UPDATE THIS TO A NEW FORMAT, HIDE FOR NOW */}

          {/* <Checkbox
            showLabel
            label={<span class="text-sm text-title-gray">{t('Include No Property Bills')}</span>}
            checked={searchParams.includeNoPropertyBills === 'true'}
            onChange={(e) => setSearchParams({ includeNoPropertyBills: e.target.checked })}
          />
          <Checkbox
            showLabel
            label={<span class="text-sm text-title-gray">{t('Company Bills')}</span>}
            checked={searchParams.companyBills === 'true'}
            onChange={(e) => setSearchParams({ companyBills: e.target.checked })}
          /> */}
        </div>
        <div ref={(e) => setPrintContainer(e)}>
          <Show when={incomeStatementReport.loading} fallback={<IncomeStatementReport report={incomeStatementReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </>
  );
};
