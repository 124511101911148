import { createSignal, onMount, splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { BankAccountRepository } from '~/repositories/bankAccountRepository';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { EntrySearchProps } from '~/components/ui';

const repo = new BankAccountRepository();

export const BankAccountSearch = <T extends boolean | undefined = undefined>(
  props: EntrySearchProps<MagicDoor.Api.HydratedBankAccountDto, T> & {
    onGetBankAccounts: () => Promise<{ items: MagicDoor.Api.HydratedBankAccountDto[]; totalPages: number }>;
  }
) => {
  const { t } = useLocalization();

  return (
    <SearchSelect
      placeholder={t('Search bank accounts')}
      fetcher={props.onGetBankAccounts}
      renderSelected={(item) => item.name}
      renderItem={(item) => item.name}
      {...props}
    />
  );
};

export const LabeledBankAccountSearch = <T extends boolean | undefined = undefined>(
  props: Omit<EntrySearchProps<MagicDoor.Api.HydratedBankAccountDto, T>, 'onSelect'> &
    LabeledGroupProps & {
      value?: string | string[];
      onInput?: (
        value: T extends true ? string[] : string,
        selected: T extends true ? MagicDoor.Api.HydratedBankAccountDto[] : MagicDoor.Api.HydratedBankAccountDto
      ) => void;
    }
) => {
  const [searchProps, labeledProps] = splitProps(props, ['multiple', 'value', 'placeholder', 'onInput', 'exclude']);
  const [allBankAccountOptions, setAllBankAccountOptions] = createSignal<MagicDoor.Api.HydratedBankAccountDto[]>([]);

  const getBankAccounts = async () => {
    const response = await repo.getBankAccounts();
    return { items: response, totalPages: 1 };
  };

  onMount(async () => {
    const options = (await getBankAccounts())?.items ?? [];
    setAllBankAccountOptions(options);
  });

  return (
    <LabeledGroup {...labeledProps}>
      <BankAccountSearch
        {...searchProps}
        selected={
          allBankAccountOptions().filter((item) => (Array.isArray(props.value) ? props.value : [props.value]).includes(item.id)) as any
        }
        onSelect={(selected) => {
          props.onInput?.(
            (Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string,
            selected
          );
        }}
        onGetBankAccounts={getBankAccounts}
      />
    </LabeledGroup>
  );
};
