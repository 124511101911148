import { Show } from 'solid-js';
import { AIProcessing, AIProcessed } from '~/pages/maintenance-request/list-maintenance-request/list-components/StatusColumn';
import type { Component } from 'solid-js';
// eslint-disable-next-line no-restricted-imports
import type { CrossMaintenanceRequestListDto } from '~/contexts/maintenance-request/MaintenanceRequestsContext';

export const MaintenanceRequestTitle: Component<{ class?: string; maintenanceRequest: CrossMaintenanceRequestListDto }> = (props) => (
  <div class={props.class}>
    <div class="mb-1 flex items-center">
      <div class="mr-2 cursor-pointer text-xs font-medium text-link">{props.maintenanceRequest.title}</div>
      <Show
        when={
          (props.maintenanceRequest.status === 'aiProcessing' || props.maintenanceRequest.aiProcessed) &&
          !props.maintenanceRequest.disableAi
        }>
        <AIProcessing />
      </Show>
      <Show when={props.maintenanceRequest.aiProcessed && props.maintenanceRequest.disableAi}>
        <AIProcessed />
      </Show>
    </div>
  </div>
);
