import { useNavigate } from '@solidjs/router';
import { createSignal, createEffect } from 'solid-js';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { ChatRepository } from '~/repositories/chatRepository';
import type { Accessor } from 'solid-js';
import type { ChatCreationResponse, SendChatData } from '~/repositories/chatRepository';

interface ChatContextValue {
  sendChat: (chatId: string, chatData: SendChatData) => Promise<Response | void>;
  setChatId: (chatId?: string) => void;
  fetchChatById: (chatId: string) => Promise<MagicDoor.Api.HydratedMagicChatDto>;
  fetchChatMessagesById: (
    chatId: string,
    before?: string,
    after?: string,
    take?: number
  ) => Promise<MagicDoor.Api.ChatMessageDto[] | undefined>;
  chat: Accessor<MagicDoor.Api.HydratedMagicChatDto | undefined>;
  chatMessages: Accessor<MagicDoor.Api.ChatMessageDto[]>;
  isLoading: Accessor<boolean>;
  error: Accessor<Error | undefined>;
  chatId: Accessor<string | undefined>;
  createChat: (
    entryType: `${MagicDoor.Api.EntityType}` | `${MagicDoor.Api.ChatTypes}`,
    entryId: string
  ) => Promise<ChatCreationResponse | undefined>;
  getParticipantName: (
    chatMessage: MagicDoor.Api.ChatMessageDto,
    chatDetails: MagicDoor.Api.HydratedMagicChatDto
  ) => string | undefined | null;
  hasMoreMessages: Accessor<boolean>;
  setHasMoreMessages: (value: boolean) => void;
  chatMagicIt: (chatId: string, chatData: string) => Promise<MagicDoor.Api.MagicChatTextResponseDto | undefined>;
  markMessagesAsRead: (chatId: string) => Promise<void>;
  newChat: Accessor<MagicDoor.Api.ChatMessageDto[]>;
  setNewChat: (value: MagicDoor.Api.ChatMessageDto[]) => void;
  editChat: (chatId: string, chatData: MagicDoor.Api.UpdateChatDto) => Promise<MagicDoor.Api.HydratedMagicChatDto | undefined>;
  addNewMessage: (message: MagicDoor.Api.ChatMessageDto) => void;
  removeMessage: (messageId: string) => void;
  createTextChat: (request: MagicDoor.Api.CreatePhoneChatDto) => Promise<ChatCreationResponse | undefined>;
  setChatMessages: (value: MagicDoor.Api.ChatMessageDto[]) => void;
  isInitialized: Accessor<boolean>;
  initializeChat: (id: string) => Promise<void>;
  resetChat: () => void;
  rewriteMessage: (chatId: string, messageId: string) => Promise<MagicDoor.Api.MagicChatTextResponseDto | undefined>;
  archiveChat: (chatId: string) => Promise<MagicDoor.Api.HydratedMagicChatDto | undefined>;
}

export const [ChatProvider, useChat] = createMagicDoorContext<ChatContextValue>('Chat', () => {
  const repo = new ChatRepository();
  const navigate = useNavigate();
  const { t } = useLocalization();

  const [chat, setChat] = createSignal<MagicDoor.Api.HydratedMagicChatDto>();
  const [chatMessages, setChatMessages] = createSignal<MagicDoor.Api.ChatMessageDto[]>([]);

  const [chatId, setChatId] = createSignal<string | undefined>(undefined);
  const [isLoading, setIsLoading] = createSignal<boolean>(false);
  const [error, setError] = createSignal<Error | undefined>(undefined);

  const [hasMoreMessages, setHasMoreMessages] = createSignal<boolean>(true);
  const [newChat, setNewChat] = createSignal<MagicDoor.Api.ChatMessageDto[]>([]);
  const [isInitialized, setIsInitialized] = createSignal<boolean>(false);

  const initializeChat = async (id: string) => {
    if (!isInitialized() || chatId() !== id) {
      setIsLoading(true);
      try {
        const chatData = await fetchChatById(id);
        const messages = await fetchChatMessagesById(id);
        setChatId(id);
        setChat(chatData);
        setChatMessages(messages || []);
        setIsInitialized(true);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const resetChat = () => {
    setChatId(undefined);
    setChat(undefined);
    setChatMessages([]);
    setIsInitialized(false);
  };

  const addNewMessage = (newMessage: any) => {
    setChatMessages((prevMessages) => [newMessage, ...prevMessages]);
  };

  const removeMessage = (messageId: string) => {
    setChatMessages((prevMessages) => prevMessages.filter((message) => message.id !== messageId));
  };

  const fetchChatById = async (chatId: string): Promise<MagicDoor.Api.HydratedMagicChatDto> => {
    setIsLoading(true);
    try {
      const chatData = await repo.getChat(chatId);
      if (!chatData) {
        throw new Error('Chat data not found');
      }
      setChat(chatData);

      return chatData;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      navigate('/404');
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchChatMessagesById = async (chatId: string, before?: string, after?: string, take = 20) => {
    if (!chatId) return;

    try {
      setIsLoading(true);
      const data = await repo.getChatMessages(chatId, before, after, take);

      if (data.length < take) {
        setHasMoreMessages(false);
      } else {
        setHasMoreMessages(true);
      }

      if (before) {
        setChatMessages((prev) => [...prev, ...data]);
      } else if (after) {
        setChatMessages((prev) => [...data, ...prev]);
      } else {
        setChatMessages(data);
      }
      return data;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  createEffect(() => {
    setChatMessages([]);
  });

  const sendChat = async (chatId: string, chatData: SendChatData, retryCount = 3): Promise<Response | void> => {
    setIsLoading(true);
    try {
      const result = await repo.sendChatMessage(chatId, chatData);
      await fetchChatMessagesById(chatId);
      return result;
    } catch (err) {
      if (retryCount > 0) {
        return await sendChat(chatId, chatData, retryCount - 1);
      } else {
        setError(err instanceof Error ? err : new Error(String(err)));
        toast.error(t('Operation failed, please try again later'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const chatMagicIt = async (chatId: string, chatData: string): Promise<MagicDoor.Api.MagicChatTextResponseDto | undefined> => {
    setIsLoading(true);

    try {
      const response = await repo.sendChatMagicIt(chatId, chatData);
      await fetchChatMessagesById(chatId);
      return response;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const createChat = async (entryType: `${MagicDoor.Api.EntityType}` | `${MagicDoor.Api.ChatTypes}`, entryId: string) => {
    setIsLoading(true);
    try {
      const response = await repo.createChatWindow(entryType, entryId);
      if (response && response.chatId) {
        return { chatId: response.chatId };
      } else {
        toast.error(response.failureReason);
        throw new Error('Failed to create a new chatMessages. No chatId returned.');
      }
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const createTextChat = async (request: MagicDoor.Api.CreatePhoneChatDto) => {
    setIsLoading(true);
    try {
      const response = await repo.createTextMessageChat(request);
      if (response && response.chatId) {
        return { chatId: response.chatId };
      } else {
        toast.error(response.failureReason);
        throw new Error('Failed to create a new chatMessages. No chatId returned.');
      }
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const editChat = async (
    chatId: string,
    chatData: MagicDoor.Api.UpdateChatDto
  ): Promise<MagicDoor.Api.HydratedMagicChatDto | undefined> => {
    setIsLoading(true);
    try {
      const response = await repo.updateChat(chatId, chatData);
      setChat(response);
      return response;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const markMessagesAsRead = async (chatId: string) => {
    try {
      await repo.markMessagesAsRead(chatId);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    }
  };

  function getParticipantName(
    chatMessage: MagicDoor.Api.ChatMessageDto,
    chatDetails: MagicDoor.Api.HydratedMagicChatDto
  ): string | undefined | null {
    if (chatMessage.participantId && chatDetails.participants) {
      const participant = chatDetails.participants.find((p) => p.id === chatMessage.participantId);
      return participant ? participant.name : undefined;
    }
    return undefined;
  }

  const rewriteMessage = async (chatId: string, messageId: string): Promise<MagicDoor.Api.MagicChatTextResponseDto | undefined> => {
    setIsLoading(true);
    try {
      const message = await repo.paraphraseMessage(chatId, messageId);
      return message;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  const archiveChat = async (chatId: string) => {
    setIsLoading(true);
    try {
      const response = await repo.archiveChat(chatId);
      return response;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    sendChat,
    fetchChatMessagesById,
    setChatId,
    chatMessages,
    isLoading,
    archiveChat,
    hasMoreMessages,
    editChat,
    rewriteMessage,
    setHasMoreMessages,
    error,
    chat,
    chatId,
    getParticipantName,
    createChat,
    newChat,
    createTextChat,
    markMessagesAsRead,
    addNewMessage,
    setChatMessages,
    chatMagicIt,
    setNewChat,
    fetchChatById,
    isInitialized,
    initializeChat,
    resetChat,
    removeMessage,
  };
});
