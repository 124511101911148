import dayjs from 'dayjs';
import { leaseStatusString } from '~/components/leases/utils';
import { useLocalization } from '~/contexts/global';
import { Presenter } from '~/pdfsigner/presenters/Presenter';
import { Alignment, Orientation, PageSize, WidthDistribution } from '~/pdfsigner/usecases/types/reportDocument';
import { RentStatus } from '~/swagger/Api';
import { emptyPlaceholder } from '~/utils/constant';
import { commaNumber } from '~/utils/number';
import type { PdfState } from '~/pdfsigner/state/pdfAppState';
import type { DocumentFooter, DocumentHeader, Entry, ReportDocument, Section } from '~/pdfsigner/usecases/types/reportDocument';
import type {
  AddressDto,
  CompanyDto,
  PortfolioDto,
  PropertyDto,
  RentRollReportDto,
  RentRollReportGroupDto,
  TenantDto,
} from '~/swagger/Api';

export interface PresentableRentRoll {
  rentRollData: RentRollReportDto;
  rentRollReport: ReportDocument;
}

const { t, currentLanguage } = useLocalization();

export class RentRollPresenter extends Presenter<PresentableRentRoll> {
  createModel(state: PdfState): PresentableRentRoll {
    return {
      rentRollData: state.reports.rentRoll,
      rentRollReport: this.createRentRollReport(state.portfolios, state.properties, state.reports.rentRoll, state.companyInfo),
    };
  }

  private createRentRollReport = (
    portfolios: PortfolioDto[],
    properties: PropertyDto[],
    rentRollData?: RentRollReportDto,
    companyInfo?: CompanyDto
  ): ReportDocument => {
    const mergedArray = [...portfolios, ...properties];
    const memo = mergedArray.map((item) => item.name).join(', ');
    const additionalInfo: string[] = [];
    additionalInfo.push(`${companyInfo?.address?.streetAddress1} ${companyInfo?.address?.streetAddress2 || ''}`);
    additionalInfo.push(
      `${companyInfo?.address?.city}, ${companyInfo?.address?.zipCode}, ${companyInfo?.address?.state}, ${companyInfo?.address?.country}`
    );
    const header: DocumentHeader = {
      title: t('Rent roll report'),
      subTitle: new Date().toLocaleDateString(),
      additionalInfo,
      companyName: companyInfo?.name,
    };
    const footer: DocumentFooter = {
      text: 'MagicDoor.com',
    };
    const sections: Section[] = [];
    rentRollData?.groups?.forEach((group) => {
      const occupancy = this.getOccupancyRate(group);
      const propertyAddress = this.createPropertyAddress(group.property?.address);
      sections.push({
        header: {
          values: [
            { text: propertyAddress, width: WidthDistribution.flex, isBold: true },
            { text: t('BD/BA'), width: WidthDistribution.fixed, isBold: true, horizontalAlignment: Alignment.center },
            { text: t('Tenants'), width: WidthDistribution.flex, isBold: true },
            { text: t('Status'), width: WidthDistribution.fixed, isBold: true, horizontalAlignment: Alignment.center },
            { text: t('Sqft'), width: WidthDistribution.fixed, isBold: true, horizontalAlignment: Alignment.center },
            { text: t('Rent'), width: WidthDistribution.fixed, isBold: true, horizontalAlignment: Alignment.center },
            { text: t('Deposit'), width: WidthDistribution.fixed, isBold: true, horizontalAlignment: Alignment.center },
            { text: t('Lease from'), width: WidthDistribution.fixed, isBold: true, horizontalAlignment: Alignment.center },
            { text: t('Move in'), width: WidthDistribution.fixed, isBold: true, horizontalAlignment: Alignment.center },
          ],
          hasTopLine: true,
          hasBottomLine: true,
          hasBackgroundColor: true,
        },
        entries: this.createEntries(group, occupancy || ''),
      });
    });
    return {
      language: currentLanguage(),
      orientation: Orientation.LANDSCAPE,
      memo,
      header,
      footer,
      sections,
      pageSize: PageSize.LETTER,
      shouldShowPageNumbers: true,
      isEachSectionOnANewPage: false,
    };
  };

  getOccupancyRate = (group: RentRollReportGroupDto): string => {
    let occupancy = emptyPlaceholder;
    let rentedUnits = 0;
    group.items?.forEach((item) => {
      if (item.status === RentStatus.Rented) {
        rentedUnits++;
      }
    });
    if (group.items && group.property?.unitCount) {
      occupancy = `${Math.round((rentedUnits / group.property?.unitCount) * 100)}%`;
    }
    return occupancy;
  };

  createPropertyAddress = (address?: AddressDto): string => {
    if (!address) {
      return '';
    }
    return `${address.streetAddress1 || ''} ${address.streetAddress2 || ''}, ${address.city || ''}, ${address.zipCode || ''}, ${
      address.state || ''
    }`;
  };

  createEntries = (group: RentRollReportGroupDto, occupancy: string): Entry[] => {
    let totalRent = 0;
    let totalDeposit = 0;
    let totalSquareFeet = 0;
    if (!group.items || group.items.length === 0) {
      return [];
    }
    const leases = group.items ?? [];
    const entries: Entry[] = leases.flatMap((lease) => {
      totalRent += lease.lease?.currentRent ?? 0;
      totalDeposit += lease.lease?.securityDepositPaid ?? 0;
      totalSquareFeet += lease.unit?.unitSizeSqft ?? 0;
      return [
        {
          label: lease.unit?.name || '',
          values: [
            { text: lease.unit?.name || '' },
            { text: `${lease.unit?.beds ?? '-'} / ${lease.unit?.baths ?? '-'}`, horizontalAlignment: Alignment.center },
            { text: this.createTenantNames(lease.tenants || []) },
            { text: leaseStatusString(lease.lease?.status), horizontalAlignment: Alignment.center },
            { text: commaNumber(lease.unit?.unitSizeSqft), horizontalAlignment: Alignment.end },
            { text: commaNumber(lease.lease?.currentRent), horizontalAlignment: Alignment.end },
            { text: commaNumber(lease.lease?.securityDepositPaid), horizontalAlignment: Alignment.end },
            { text: dayjs(lease.lease?.start).format('MM/DD/YYYY'), horizontalAlignment: Alignment.center },
            { text: dayjs(lease.lease?.tenants?.[0].moveIn).format('MM/DD/YYYY'), horizontalAlignment: Alignment.center },
          ],
          hasBottomLine: true,
        } as Entry,
      ];
    });
    entries.push({
      values: [
        { text: `Total ${leases.length} units`, isBold: true },
        { text: '' },
        { text: '' },
        { text: t(`{percentage} occupied`, { percentage: occupancy }), isBold: true, horizontalAlignment: Alignment.center },
        { text: commaNumber(totalSquareFeet), isBold: true, horizontalAlignment: Alignment.end },
        { text: commaNumber(totalRent), isBold: true, horizontalAlignment: Alignment.end },
        { text: commaNumber(totalDeposit), isBold: true, horizontalAlignment: Alignment.end },
        { text: '' },
        { text: '' },
      ],
    });
    return entries;
  };

  createTenantNames = (tenants: TenantDto[]): string => {
    if (!tenants || tenants.length === 0) return '';
    const tenantNames = tenants.map((tenant) => `${tenant.firstName || ''} ${tenant.lastName || ''}`.trim());
    return tenantNames.join(', ');
  };
}
