import { Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { JSX, ParentComponent } from 'solid-js';

export const Section: ParentComponent<
  {
    title: JSX.Element | string;
    actions?: JSX.Element;
    border?: boolean;
    required?: boolean;
  } & JSX.HTMLAttributes<Element>
> = (props) => {
  return (
    <div class={cn('mb-8 flex flex-col items-stretch', props.class)}>
      <div class={cn('mb-4 flex justify-between', props.border && 'border-b border-b-stone-200 pb-3')}>
        <h2 class={cn('text-left text-base font-semibold text-text-level01')}>
          {props.title}
          <Show when={props.required}>
            <span class="text-error">*</span>
          </Show>
        </h2>
        {props.actions}
      </div>
      {props.children}
    </div>
  );
};
