import { useNavigate } from '@solidjs/router';
import { createSignal } from 'solid-js';
import { Modal } from '~/components/modals/Modal';
import { LabeledLeveledSearchSelect } from '~/components/search-select/LevelSearchSelect';
import { toast } from '~/components/ui';
import { useLocalization, usePortfolios, useUnitListings } from '~/contexts/global';
import { LevelSearch } from '~/swagger/Api';
import type { Component } from 'solid-js';
import type { addUnitListingPayload } from '~/repositories/unitListingsRepository';
import type { ImportUnitListingDto } from '~/swagger/Api';

export const AddUnitListingModal: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { current } = usePortfolios();
  const { addUnitListing } = useUnitListings();
  const [selected, setSelected] = createSignal<any[]>([]);

  const handleClose = () => {
    const currentId = current()?.id;
    navigate(currentId ? `/listings/${currentId}` : '/listings', { scroll: false, replace: true });
  };

  const handleSubmit = async () => {
    const selectedItems = selected();
    if (!selectedItems.length) {
      return;
    }

    try {
      const selectedItem = selectedItems[0];
      let payload: addUnitListingPayload;

      switch (selectedItem.__type) {
        case LevelSearch.Portfolio:
          payload = { portfolioIds: [selectedItem.id] };
          break;
        case LevelSearch.Property:
          payload = { propertyIds: [selectedItem.id] };
          break;
        case LevelSearch.Unit:
          payload = { unitIds: [selectedItem.id] };
          break;
        default:
          return;
      }

      const res = await addUnitListing(payload);
      handleClose();
      handleImportTip(res);
    } catch (e) {
      console.error(e);
      toast.error(t('Failed to add listing'));
    }
  };

  const handleImportTip = (res: ImportUnitListingDto) => {
    const count = res?.unitListingIds?.length || 0;
    if (count) {
      toast.success(t('Successfully added {count} items of data', { count }));
    } else {
      toast.error(t('No data imported'));
    }
  };

  const handleChange = (items: any[]) => {
    setSelected(items);
  };

  return (
    <Modal
      visible
      class="animate-zoom-in"
      loading={false}
      title={t('Select listing units')}
      doneText={t('Confirm')}
      onDone={handleSubmit}
      onCancel={handleClose}
      disabled={!selected().length}
      confirmation={false}>
      <div class="px-7 py-6">
        <LabeledLeveledSearchSelect
          label={t('Select items')}
          enabledTypes={[LevelSearch.Portfolio, LevelSearch.Property, LevelSearch.Unit]}
          onChange={handleChange}
        />
      </div>
    </Modal>
  );
};
