import { createEffect } from 'solid-js';
import { createStore, reconcile } from 'solid-js/store';
import { eventEmitter } from '~/pdfsigner/state/eventEmitter';
import type { AppState } from '~/pdfsigner/state/appState';

export abstract class Presenter<T extends object> {
  private presentableModel?: any;
  private setPresentableModel: (model: any) => void;

  constructor() {
    const [presentableModel, setPresentableModel] = createStore({ model: undefined });
    this.presentableModel = presentableModel;
    this.setPresentableModel = setPresentableModel;

    const stateChangeHandler = (newState: AppState<any>) => {
      const newModel = { model: this.createModel(newState.getState()) };
      this.setPresentableModel(reconcile(newModel));
    };

    createEffect(() => {
      eventEmitter.registerForStateChange(stateChangeHandler);
      return () => {
        eventEmitter.unregisterFromStateChange(stateChangeHandler);
      };
    });
  }

  protected abstract createModel(state: any): T;

  public getPresentableModel(): T | undefined {
    return this.presentableModel.model;
  }
}
