import { createMemo } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Description } from '~/components/common/Description';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledInputSearch } from '~/components/common/Inputs/LabeledInputSearch';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LeaseItem } from '~/components/leases/LeaseItem';
import { getLeaseDateName } from '~/components/leases/utils';
import { useLocalization } from '~/contexts/global';
import { useMaintenanceRequests } from '~/contexts/local';
import { useAddOrEditMaintenanceModalContext } from '~/pages/maintenance-request/add-maintenance-request/Context';
import { permissionToEnterTypes, priorityTypes } from '~/utils/constant';
import { upperFirst } from '~/utils/tool';
import type { LabeledInputSearchProps } from '~/components/common/Inputs/LabeledInputSearch';
import type { UploaderFile } from '~/components/common/Upload/Interface';

export const EssentialInfomation = () => {
  const { t } = useLocalization();
  const { store, getLeases, setStore } = useAddOrEditMaintenanceModalContext();
  const { resetFields, formStore } = useFormContext();
  const { maintenanceRequestCategory } = useMaintenanceRequests();

  const maintenanceRequestFiles = createMemo(() => store.maintenanceInfo?.files);

  const leases = createMemo<MagicDoor.Api.HydratedLeaseDto[]>(() => store.leases.filter((item) => item.status !== 'ended'));

  return (
    <div class="flex flex-col gap-8">
      <FormItem
        class="w-full"
        label={t(`Description`)}
        rules={[{ required: true, message: t(`Please input description`) }]}
        formFieldName="description"
        component={Description}
        placeholder={t('Please enter')}
        onFilesListChange={(files: UploaderFile[]) => {
          setStore({ newFiles: files });
        }}
        entityFilePrefixURL={store.uploadFileUrl}
        defaultFileList={maintenanceRequestFiles()}
      />

      <FormItem
        class="w-full"
        label={t(`Title`)}
        rules={[{ required: true, message: t('Please input title') }]}
        formFieldName="title"
        component={LabeledTextInput}
        placeholder={t('Please enter')}
      />

      <div class="grid grid-cols-2 gap-8">
        <div class="col-span-full lg:col-span-1">
          <FormItem<LabeledInputSearchProps>
            label={t(`Unit`)}
            options={store.units}
            rules={[
              {
                required: true,
                message: t(`Please select unit`),
              },
            ]}
            formFieldName="unitId"
            onChangeMethodName="onSelect"
            component={LabeledInputSearch}
            onSelect={(val) => {
              resetFields(['leaseId']);
              if (val) getLeases({ unitId: formStore.unitId, propertyId: formStore.propertyId });
            }}
            placeholder={t('Please search')}
          />
        </div>
        <div class="col-span-full lg:col-span-1">
          <FormItem<LabeledInputSearchProps<MagicDoor.Api.HydratedLeaseDto>>
            label={t(`Lease`)}
            rules={[{ required: true, message: t(`Please select lease`) }]}
            formFieldName="leaseId"
            options={leases()}
            onChangeMethodName="onSelect"
            filter={(item, keyword) => getLeaseDateName(item)?.toLowerCase().includes(keyword.toLowerCase())}
            labelPropName="id"
            renderItem={(item) => (
              <div class="overflow-hidden">
                <LeaseItem lease={item} />
              </div>
            )}
            component={LabeledInputSearch}
            placeholder={t('Please search')}
          />
        </div>

        {/* TODO: UI 与接口不一致，不确定匹配字段，先隐藏 */}
        {/* <FormItem label={t(`Associated task`)} formFieldName="task" component={LabeledTextInput} /> */}

        <FormItem
          class="col-span-full lg:col-span-1"
          label={t(`Permission to enter`)}
          options={permissionToEnterTypes(t)}
          rules={[
            {
              required: true,
              message: t(`Please select permission to enter`),
            },
          ]}
          formFieldName="permissionToEnter"
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
        />

        <FormItem
          class="col-span-full lg:col-span-1"
          label={t(`Priority`)}
          rules={[{ required: true, message: t('Please select priority') }]}
          options={priorityTypes.map((item) => ({ value: item.value, label: t(upperFirst(item.label)) }))}
          formFieldName="urgency"
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
        />
        <FormItem class="col-span-full lg:col-span-1" label={t(`Date`)} formFieldName="due" component={DueDateInputField} />

        <FormItem
          class="col-span-full lg:col-span-1"
          label={t('Category')}
          rules={[{ required: true, message: t('Please select category') }]}
          options={maintenanceRequestCategory()?.map((item) => ({ value: item.id!, label: item.name! }))}
          formFieldName="categoryId"
          onChangeMethodName="onChange"
          component={DropdownMenu}
          placeholder={t('Please select')}
        />
      </div>
    </div>
  );
};
