import { Show, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { pickProps, TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { LeasePeriod } from '~/components/leases';
import { isMonthToMonth } from '~/components/leases/utils';
import { useLocalization } from '~/contexts/global';
import { IconLease } from '~/pages/settings/components/Icons';
import { cn } from '~/utils/classnames';
import type { ComponentProps, ValidComponent } from 'solid-js';
import type { TitleWithFeedbackIconProps } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import type { CustomLink } from '~/types/display';
import type { Merge } from '~/utils/types';

export const LevelLeaseTitle = <T extends ValidComponent>(
  props: Merge<
    ComponentProps<T>,
    {
      as?: T;
      class?: string;
      lease?: MagicDoor.Api.HydratedLeaseDto | MagicDoor.Api.LeaseDto;
      property?: MagicDoor.Api.PropertyDto;
      unit?: MagicDoor.Api.UnitDto;
    } & Partial<TitleWithFeedbackIconProps> & {
        showTenants?: boolean;
        customLinks?: CustomLink[];
      }
  >
) => {
  const { t } = useLocalization();
  const [params, rest] = splitProps(props, ['as', 'class', 'lease']);

  return (
    <TitleWithFeedbackIcon
      class="h-fit"
      {...pickProps(rest)}
      type="leases"
      entityId={params.lease?.id}
      initalSelectedIcons={params.lease?.icons}>
      <Dynamic component={params.as || 'div'} class={cn('flex flex-col', params.class)} {...rest}>
        <div class="flex items-center gap-3">
          <IconLease />
          <div class="flex items-center">
            <Show when={isMonthToMonth(params.lease)}>{t('Month-To-Month')}:</Show>
            <LeasePeriod class="text-xs" lease={params.lease} />
          </div>
        </div>
      </Dynamic>
    </TitleWithFeedbackIcon>
  );
};
