import { A } from '@solidjs/router';
import { createEffect, For, on } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import IconFile from '~/assets/images/chat/chatFile.png';
import RemoveUploadFile from '~/assets/images/common/uploadFileClose.svg?component-solid';
import { useUpload } from '~/components/common/Upload/Upload';
import { checkMimeType } from '~/components/common/Upload/attrAccept';
import { Image } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { createFileUrl } from '~/pages/chats/chat-details/components/GetFilePreview';
import { imageType } from '~/utils/constant';
import type { FileFrom, HydrateFile, UploaderFile } from '~/components/common/Upload/Interface';

const fileFrom = (file: HydrateFile): FileFrom => {
  if ('signedThumbUrl' in file || 'signedUrl' in file) {
    return 'api';
  }
  return 'local';
};

const getFileName = (file: HydrateFile) => {
  return file.fileName ?? file.name;
};

export const FileList = function <T extends HydrateFile & UploaderFile>(props: { showType?: 'img'; defaultFileList?: T[] }) {
  const { store, onFileRemove, entityFilesURL, fileRepository, uploadProps, combinedFiles, setStore } = useUpload();

  async function _onFileRemove(from: FileFrom, file: HydrateFile) {
    if (from === 'api') {
      setStore(
        produce((draft) => {
          draft.fileList = draft.fileList.filter((f) => f.fileId !== file.fileId);
        })
      );

      if (uploadProps.onDeleteFile && file.fileId) {
        return uploadProps.onDeleteFile(file.fileId);
      }

      if (!entityFilesURL()) throw new Error('entityFilePrefixURL is required when removing file from api');
      return fileRepository.deleteFile(`${entityFilesURL()}/${file.fileId}`);
    }

    onFileRemove(file as UploaderFile);
  }

  createEffect(
    on(
      () => props.defaultFileList,
      (val, preVal) => {
        if (!preVal && val) {
          setStore(produce((draft) => (draft.fileList = (props.defaultFileList ?? []).concat(store.newFiles as T[]))));
        }
      }
    )
  );

  const isImage = (file: HydrateFile) => {
    if (file.type) {
      return checkMimeType(file, 'image/*');
    }
    return imageType.some((type) => (file.name ?? file.fileName)?.endsWith(type));
  };

  return (
    <div class="flex gap-2 overflow-x-auto">
      <For each={combinedFiles()}>
        {(file) => {
          return (
            <div class="relative">
              <RemoveUploadFile
                onClick={() => {
                  _onFileRemove(fileFrom(file), file);
                }}
                class="absolute right-0 top-0 cursor-pointer"
              />

              {isImage(file) ? <ImgFileItem file={file} /> : <FileItem file={file} />}
            </div>
          );
        }}
      </For>
    </div>
  );
};

export const ImgFileItem = (props: { file: HydrateFile; onFileRemove?: (from: FileFrom) => void }) => {
  const [preview, setPreview] = createStore({
    previewUrl: '',
    downloadUrl: '',
  });
  createEffect(() => {
    getPreview();
  });

  async function getPreview() {
    if (fileFrom(props.file) === 'api') {
      const signedThumbUrl = props.file.signedThumbUrl ?? props.file.signedUrl;
      return setPreview({ downloadUrl: `/api${props.file.signedUrl}`, previewUrl: `/api${signedThumbUrl}` });
    }
    const url = createFileUrl(props.file as UploaderFile);
    setPreview({ previewUrl: url, downloadUrl: url });
  }

  return (
    <div class="flex size-24 select-none items-center overflow-hidden rounded-lg border border-dashed border-partingline">
      <A href={preview.downloadUrl} target="__blank" rel="noreferrer">
        <Image src={preview.previewUrl} />
      </A>
    </div>
  );
};

export const FileItem = (props: { file: HydrateFile }) => {
  const { t } = useLocalization();

  return (
    <div class="flex flex-col items-center justify-center rounded-lg p-2 transition">
      <div class="relative py-2">
        <img src={IconFile} alt="IconFile" />
        <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-xs font-bold text-white">{t('File')}</div>
      </div>
      <span class="w-full max-w-24 truncate text-xs text-text-level02">{getFileName(props.file)}</span>
    </div>
  );
};
