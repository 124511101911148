import { Show } from 'solid-js';
import { PaymentStatusIndicator } from '~/components/rental-application/components/StatusIndicators';
import { paymentStatusIndicatorsArray } from '~/components/rental-application/components/constants';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { emptyPlaceholder } from '~/utils/constant';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { RentalApplicationProps } from '~/components/rental-application/components/constants';

export const PaymentStatus: Component<RentalApplicationProps> = () => {
  const { t } = useLocalization();
  const { applicationStore } = useRentalApplication();

  return (
    <div class="relative h-fit grow rounded-lg border border-partingline capitalize sm:w-full lg:my-6 lg:w-auto ">
      <div class="flex h-11 items-center border-b p-3 text-sm font-medium capitalize text-text-level01">
        {t('Payment status')}
        <div class="pl-6">
          <PaymentStatusIndicator
            {...(paymentStatusIndicatorsArray.find(
              (item) => item.label === applicationStore.application?.paymentStatus
            ) as (typeof paymentStatusIndicatorsArray)[0])}
          />
        </div>
      </div>

      <div class="min-h-20 divide-y">
        <div class="space-y-2 p-4">
          <div class="flex items-center justify-between">
            <span class="text-sm text-gray-600">{t('Payment amount')}</span>
            <span class="text-sm font-medium text-gray-900">
              {!applicationStore.application?.paymentAmount ? emptyPlaceholder : currency(applicationStore.application?.paymentAmount)}
            </span>
          </div>

          <Show when={applicationStore.application?.paymentStatus === 'refunded'}>
            <div class="flex items-center justify-between">
              <span class="text-sm text-gray-600">{t('Refund amount')}</span>
              <span class="text-sm font-medium text-red-500">
                {!applicationStore.application?.refundAmount ? emptyPlaceholder : currency(applicationStore.application?.refundAmount)}
              </span>
            </div>
          </Show>
        </div>
      </div>
    </div>
  );
};
