import { Show } from 'solid-js';
import { DeactivatedFlag } from '~/components/common';
import { pickProps, TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { PortfolioIcon } from '~/components/portfolios';
import { Skeleton } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';
import type { TitleWithFeedbackIconProps } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import type { SearchNode } from '~/components/search-select/Context';

export const LevelPortfolioTitle: Component<
  {
    class?: string;
    portfolio?: SearchNode<MagicDoor.Api.HydratedPortfolioDto | MagicDoor.Api.PortfolioDto>;
  } & Partial<TitleWithFeedbackIconProps>
> = (props) => {
  const { t } = useLocalization();

  return (
    <TitleWithFeedbackIcon
      {...pickProps(props)}
      type="portfolios"
      entityId={props.portfolio?.id}
      initalSelectedIcons={props.portfolio?.icons}>
      <div class={cn('flex items-center gap-2', props.class)}>
        <PortfolioIcon class="size-8 shrink-0 rounded-lg bg-light-pink p-2 md:block" data-slot="icon" />
        <div class="flex flex-1 items-center gap-2" data-slot="content">
          <Show
            when={props.portfolio}
            fallback={
              <>
                <Skeleton class="h-7 w-20" />
                <Skeleton class="h-4 w-44" />
              </>
            }>
            <h1 class="relative z-10 w-fit truncate" data-slot="title">
              {props.portfolio?.name}
              <DeactivatedFlag class="ml-2" active={props.portfolio?.active} />
            </h1>
            <p class="text-xs text-text-level03" data-slot="subtitle">
              ({props.portfolio?.propertyCount} {t('Properties')})
            </p>
          </Show>
        </div>
      </div>
    </TitleWithFeedbackIcon>
  );
};
