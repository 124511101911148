import { Show } from 'solid-js';
import RobotLoaderGif from '~/assets/images/identity/robotLoading.gif';
import type { Component } from 'solid-js';

interface RobotLoaderProps {
  gifSrc?: string;
  text?: string;
  class?: string;
}

export const RobotLoader: Component<RobotLoaderProps> = (props) => {
  return (
    <div class={`flex flex-col items-center justify-center ${props.class || ''}`}>
      <img src={props.gifSrc || RobotLoaderGif} alt="Loading" class="size-24" />
      <Show when={props.text}>
        <p class="mt-4 text-center text-sm text-text-level03">{props.text}...</p>
      </Show>
    </div>
  );
};
