import Decimal from 'decimal.js';
import { createEffect, createSignal, mergeProps, Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import { getDecimalPlaces } from '~/utils/tool';
import { LabeledGroup } from './LabeledGroup';
import type { LabeledGroupProps } from './LabeledGroup';
import type { Component } from 'solid-js';

interface InputProps extends LabeledGroupProps {
  placeholder?: string;
  prepend?: string;
  value: number | undefined | string | null;
  onInput: (e: number | undefined) => void;
  onClick?: (isMouseDown: boolean) => void;
  max?: number;
  min?: number;
  step?: number;
  suffix?: string;
  percent?: boolean;
  suffixClass?: string;
  inputClass?: string;
  isBlur?: boolean;
  disabled?: boolean;
}

const LabeledNumberInput: Component<InputProps> = (rawProps) => {
  let inputRef: undefined | HTMLInputElement;
  const props = mergeProps({ max: 1000000000 }, rawProps);
  const [value, setValue] = createSignal<string | number>();

  createEffect(() => {
    const dpFixed = getDecimalPlaces(props.value) < 2 ? 0 : getDecimalPlaces(props.value) - 2;
    const newValue = (props.percent && props.value ? (100 * (props.value as number)).toFixed(dpFixed) : props.value) ?? '';

    setValue(newValue);
  });

  function getValueByStep(value: number, step: number) {
    const _value = new Decimal(value);
    const _step = new Decimal(step);
    return _value.minus(_value.mod(_step)).toNumber();
  }

  const handleInput = (e: any) => {
    let inputNum = parseFloat(e.target.value);

    if (props.step) {
      inputNum = getValueByStep(inputNum, props.step);
    }

    const parsedValue = props.percent && inputNum ? parseFloat((inputNum / 100).toFixed(getDecimalPlaces(inputNum) + 2)) : inputNum;
    props.onInput(parsedValue);
  };

  const handleBlur = (e: any) => {
    if (props.isBlur === false) return;
    if (e.target.value !== '') {
      const value = Number(e.target.value);
      const max = props.max !== undefined ? props.max : Number.MAX_SAFE_INTEGER;
      const min = props.min !== undefined ? props.min : Number.MIN_SAFE_INTEGER;
      const realValue = Math.max(Math.min(value, max), min);

      setValue(realValue);
      e.target.value = realValue;
      handleInput(e);
    }
  };

  return (
    <LabeledGroup {...props} isInvalid={false} error={props.error}>
      <div class="flex w-full items-center rounded-md border bg-inputbox-bg focus-within:ring-1 focus-within:ring-essential-colour">
        <Show when={props.prepend}>
          <span class="flex items-center border-r border-[#5551] px-3 text-text-level03">{props.prepend}</span>
        </Show>
        <input
          ref={inputRef}
          min={props.min}
          max={props.max}
          class={cn(
            'w-full rounded-md border border-transparent bg-inputbox-bg px-3 py-2 text-sm text-black outline-none placeholder:text-auxiliary-text',
            { 'rounded-r-none': !!props.suffix },
            props.inputClass
          )}
          value={value()}
          onChange={handleInput}
          onInput={handleInput}
          onBlur={handleBlur}
          type="number"
          placeholder={props.placeholder}
          onMouseDown={() => props.onClick && props.onClick(true)}
          onMouseUp={() => props.onClick && props.onClick(false)}
          step={props.step ?? 0.01}
          disabled={props.disabled}
        />
        <Show when={props.suffix}>
          <div class={cn('mr-2 w-10 bg-inputbox-bg text-center text-text-level03', props.suffixClass)}>{props.suffix}</div>
        </Show>
      </div>
    </LabeledGroup>
  );
};
export default LabeledNumberInput;
