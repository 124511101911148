import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const REPORTS_URL = '/api/reports';

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

type DateRange = { start: string; end?: string } | { start?: string; end: string };

export type CashFlowReportFilter = {
  page?: number;
  pageSize?: number;
  dateRange: DateRange;
  propertyIds: string[];
  groupBy?: `${MagicDoor.Api.CashFlowGroupBy}`;
};

export type GeneralLedgerReportFilter = {
  portfolioIds?: string[];
  propertyIds?: string[];
  dateRange: DateRange;
  includeChartOfAccountIds?: string[];
  includeNoPropertyBills?: boolean;
  companyBills?: boolean;
};

export type BalanceSheetReportFilter = {
  reportAsOff?: boolean;
  portfolioIds?: string[];
  propertyIds?: string[];
  includeNoPropertyBills?: boolean;
  companyBills?: boolean;
};

export type IncomeStatementReportFilter = {
  start?: string;
  end?: string;
  portfolioIds?: string[];
  propertyIds?: string[];
  includeNoPropertyBills?: boolean;
  companyBills?: boolean;
};

export type OwnerStatementReportFilter = {
  propertyIds?: string[];
  ownerId: string;
  dateRange?: DateRange;
  includeAllUnpaidBills: boolean;
};
export type TenantDirectoryReportFilter = {
  portfolioIds?: string[];
  propertyIds?: string[];
  includeNoActiveLease?: boolean;
};

export type RentRollReportFilter = {
  portfolioIds?: string[];
  propertyIds?: string[];
  includeEmptyUnits?: 'true' | 'false';
  includeEmptyProperties?: 'true' | 'false';
};

export type BankAccountActivityReportFilter = {
  dateRange?: DateRange;
  bankAccountId: string;
};

export type DelinquencyReportFilter = {
  portfolioIds?: string[];
  propertyIds?: string[];
  MinimumDue?: string;
  IncludeEmptyProperties?: boolean;
};

export class ReportRepository extends BaseRestRepository {
  public async getCashFlowReport(filter: CashFlowReportFilter): Promise<MagicDoor.Api.CashFlowReportDto> {
    const url = urlWithQuery(REPORTS_URL + '/cash-flow', filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getGeneralLedgerReport(filter: GeneralLedgerReportFilter): Promise<MagicDoor.Api.GeneralLedgerReportDataDto> {
    const url = urlWithQuery(REPORTS_URL + '/general-ledger', filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getBalanceSheetReport(filter?: BalanceSheetReportFilter): Promise<MagicDoor.Api.BalanceSheetReportDataDto> {
    const url = urlWithQuery(REPORTS_URL + '/balance-sheet', filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getIncomeStatementReport(filter?: IncomeStatementReportFilter): Promise<MagicDoor.Api.IncomeStatementDto> {
    const url = urlWithQuery(REPORTS_URL + '/income-statement', filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getOwnerStatementReport(filter: OwnerStatementReportFilter): Promise<MagicDoor.Api.OwnerStatementReportDto> {
    const { ownerId, ...rest } = filter;
    const url = urlWithQuery(REPORTS_URL + `/owner-statement/${ownerId}`, rest);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getRentRollReport(filter?: RentRollReportFilter): Promise<MagicDoor.Api.RentRollReportDto> {
    const url = urlWithQuery(REPORTS_URL + '/rent-roll', filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
  public async getPropertyDirectoryReport(filter?: { active: boolean }): Promise<MagicDoor.Api.PropertyDirectoryReportDto> {
    const url = urlWithQuery(REPORTS_URL + '/property-directory', filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getOwnerDirectoryReport(): Promise<MagicDoor.Api.OwnerDirectoryReportDto> {
    const url = `${REPORTS_URL}/owner-directory`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getTenantDirectoryReport(filter: TenantDirectoryReportFilter): Promise<MagicDoor.Api.TenantDirectoryReportDto> {
    const url = urlWithQuery(REPORTS_URL + '/tenant-directory', filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getBankAccountActivityReport(filter: BankAccountActivityReportFilter): Promise<MagicDoor.Api.BankAccountActivityReportDto> {
    const { bankAccountId, ...rest } = filter;
    validateId(bankAccountId, 'Bank account');
    const url = urlWithQuery(`${REPORTS_URL}/bank-account-activity/${bankAccountId}`, rest);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getLeaseUnpaidBillsReport(
    filter: {
      leaseId?: string;
    } = {}
  ): Promise<MagicDoor.Api.LeaseUnpaidLedgerReportDto> {
    const url = urlWithQuery(REPORTS_URL + '/lease-unpaid-ledger', filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getDelinquencyReport(filter: DelinquencyReportFilter): Promise<MagicDoor.Api.DelinquencyReportDto> {
    const url = urlWithQuery(REPORTS_URL + '/delinquency', filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
}

export const reportRepository = new ReportRepository();
