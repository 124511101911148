import { useNavigate, useParams } from '@solidjs/router';
import { Show, createMemo, onMount } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { confirm, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { LeaseDraftPresenter } from '~/pdfsigner/presenters/PdfPresenter';
import { LeaseDraftPreviewContainer } from '~/pdfsigner/ui/components/LeaseDraftPreviewContainer';
import { LeaseSigningContainer } from '~/pdfsigner/ui/components/LeaseSigningContainer';
import { PdfDocument } from '~/pdfsigner/ui/components/PdfDocument';
import { LoadLeaseDraftUseCase } from '~/pdfsigner/usecases/loadLeaseDraftUseCase';
import { SaveLeaseDraftUseCase } from '~/pdfsigner/usecases/saveLeaseDraftUseCase';
import { SendDraftForSigningUseCase } from '~/pdfsigner/usecases/sendDraftForSigningUseCase';
import { SendRemindersUseCase } from '~/pdfsigner/usecases/sendRemindersUseCase';
import { DocumentStatus } from '~/pdfsigner/usecases/types/paginatedDocumentDescriptor';
import type { JSX } from 'solid-js';

const LeasePreview = (): JSX.Element => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { draftId, leaseId } = useParams();
  const { execute: save, isLoading: isSaving } = useUseCase(SaveLeaseDraftUseCase);
  const { execute: sendForSigning, isLoading: isSendingForSigning } = useUseCase(SendDraftForSigningUseCase);
  const { execute: onSendReminders } = useUseCase(SendRemindersUseCase);
  const { execute: loadDraft, isLoading: isLoadingDraft } = useUseCase(LoadLeaseDraftUseCase);
  const { model: presentablePdf } = usePresenter(LeaseDraftPresenter);
  const breadcrumbItems = createMemo(() => [{ label: t('Files'), link: `/leasing/leases/${leaseId}/files` }, { label: draftId }]);

  onMount(() => {
    loadDraft(draftId);
  });

  const isDraft = (): boolean => {
    return presentablePdf()?.status === DocumentStatus.Draft;
  };

  const sendReminders = () => {
    confirm({
      class: 'w-[396px]',
      title: t('Send reminders'),
      content: t('Are you sure you want to resend the document to the people who have not yet signed?'),
      async onResolve(confirmed) {
        if (!confirmed) return;
        await onSendReminders();
        toast.success('Operation successful');
      },
    });
  };

  return (
    <div>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <Show
        when={!isLoadingDraft() && !isSaving() && !isSendingForSigning()}
        fallback={
          <div class="flex h-[calc(100vh_-_125px)] w-full items-center justify-center">
            <RingLoader color="#a126ec" size={125} borderWidth={5} showIcon />
          </div>
        }>
        <Show
          when={isDraft()}
          fallback={
            <LeaseSigningContainer onSendReminders={sendReminders}>
              <PdfDocument pages={presentablePdf()?.pdf || []} />
            </LeaseSigningContainer>
          }>
          <LeaseDraftPreviewContainer presentablePdf={presentablePdf()?.pdf || []} onSave={save} onStartSigning={sendForSigning} />
        </Show>
      </Show>
    </div>
  );
};

export default LeasePreview;
