import { leaseDraftRepository } from '~/pdfsigner/repositories/leaseDraftRepository';
import { UseCase } from './useCase';
import { ValidateDocumentUseCase } from './validateDocumentUSeCase';

export class SaveLeaseDraftUseCase extends UseCase {
  public async runLogic() {
    new ValidateDocumentUseCase().execute();
    if (this.state.selectedDocument) {
      await leaseDraftRepository.saveDraft(this.state.selectedDocument);
    }
  }
}
