import { Show, For } from 'solid-js';
import IconGreenCheck from '~/assets/images/owner-funds/greenCheck.svg';
import IconPurpleInfo from '~/assets/images/owner-funds/purpleInfo.svg';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import Tooltip from '~/components/common/Tooltip';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { cloneDeep } from '~/utils/tool';
import type { PropertyReviewRecord } from '~/contexts/local';

export const PropertiesReviewListView = () => {
  const { t } = useLocalization();
  const { propertyValidationRecords, onChangePropertyValidationRecords } = useAddOwnerDistributions();

  const onChangeChecked = (checked: boolean, index: number) => {
    const data = cloneDeep(propertyValidationRecords());
    if (data) {
      data[index].checked = checked;
      onChangePropertyValidationRecords(data);
    }
  };

  const columns = [
    {
      title: t('Property'),
      class: 'w-[60%] p-4',
      render: (item: PropertyReviewRecord) => (
        <PropertyTitle feedbackIconReadonly property={item.property} class="h-9 [&>img]:size-9 [&_h4]:text-xs [&_h4]:font-medium" />
      ),
    },
    {
      title: t('OWNER'),
      class: 'w-[30%] p-4',
      render: (item: PropertyReviewRecord) => (
        <Show
          when={!item.isMissingOwnerInformation}
          fallback={
            <div class="flex max-w-fit items-center gap-1 overflow-hidden whitespace-nowrap rounded-full border border-essential-colour bg-[rgba(214,69,251,0.08)] px-3 py-1 text-xs font-normal text-essential-colour">
              <img src={IconPurpleInfo} />
              <Show
                when={(item.ownershipPercentage ?? 0) < 1}
                fallback={
                  <span>{t('{percentage}% Assigned', { percentage: `${((item.ownershipPercentage ?? 0) * 100).toFixed(0)}` })}</span>
                }>
                <span>{t('Only {percentage}% Assigned', { percentage: `${((item.ownershipPercentage ?? 0) * 100).toFixed(0)}` })}</span>
              </Show>
            </div>
          }>
          <div class="flex max-w-fit items-center gap-1 overflow-hidden whitespace-nowrap text-xs font-normal text-light-green">
            <img src={IconGreenCheck} />
            <span>{t('Owner Assigned')}</span>
          </div>
        </Show>
      ),
    },
  ];

  return (
    <>
      <div class="flex flex-col">
        <div class="ml-10 flex border-input-border bg-white text-xs text-text-level02">
          <For each={columns}>
            {(column) => <div class={cn('whitespace-nowrap p-4 text-text-level02', column.class)}>{column.title}</div>}
          </For>
        </div>
        <div class="flex flex-col gap-4">
          <For each={propertyValidationRecords()}>
            {(record, index) => (
              <div class="flex items-center gap-3">
                <Tooltip message={t('Missing owner information,can not be selected')} disabled={!record.isMissingOwnerInformation}>
                  <Checkbox
                    checked={record.checked}
                    disabled={record.isMissingOwnerInformation}
                    onInput={(checked) => onChangeChecked(checked, index())}
                  />
                </Tooltip>
                <div class="flex flex-1 items-center rounded-lg border border-input-border bg-input text-title-gray">
                  <For each={columns}>{(column) => <span class={cn('overflow-hidden', column.class)}>{column.render(record)}</span>}</For>
                </div>
              </div>
            )}
          </For>
        </div>
      </div>
    </>
  );
};
