import { createEffect, getOwner, createSignal } from 'solid-js';
import { FormItem, SegmentFormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { LabeledSelect } from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LabeledUploadImage } from '~/components/common/Inputs/LabeledUploadImage';
import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import { propertyTypes } from '~/components/properties';
import { useLocalization, useProperties } from '~/contexts/global';
import { getImageUrl } from '~/utils/file';
import { addressFormat, addressRequired } from '~/utils/formRules';
import type { Photo } from '~/components/common/Inputs/LabeledUploadImage';

export const PropertyInformationSection = () => {
  const { t } = useLocalization();
  const owner = getOwner();
  const form = useFormContext();
  const { current: currentProperty } = useProperties();
  const [image, setImage] = createSignal<Photo>();

  const propertyTypeOptions = Object.entries(propertyTypes).map(([key, value]) => ({ label: t(value.name), value: key }));

  createEffect(() => {
    if (currentProperty()) {
      const propertyData = {
        ...currentProperty(),
        imageId: currentProperty()?.image?.fileId,
        type: currentProperty()?.type,
      };
      form.setFieldsValue(propertyData);

      currentProperty()?.image &&
        setImage({
          id: currentProperty()?.image?.fileId,
          url: getImageUrl('property', currentProperty()?.id as string, currentProperty()?.image?.fileId),
        });
    }
  });

  const uploadChange = (fieldId: string | undefined, url: string | undefined) => {
    if (fieldId) {
      setImage({ id: fieldId, url });
    } else {
      setImage();
    }
  };

  const progress = () => {
    let fieldsFilled = 0;
    if (form.formStore.name) fieldsFilled++;
    if (form.formStore.address?.streetAddress1) fieldsFilled++;
    if (form.formStore.address?.city) fieldsFilled++;
    if (form.formStore.address?.zipCode) fieldsFilled++;
    if (form.formStore.address?.state) fieldsFilled++;
    if (form.formStore.type) fieldsFilled++;
    return fieldsFilled / 6;
  };

  return (
    <SegmentFormItem title={t('Property information')} id="property-information" progress={progress()} error={!form.isValidate} required>
      <div class="grid grid-cols-2 gap-7 gap-y-8 p-8">
        <FormItem
          class="col-span-full lg:col-span-1"
          label={t('Property name')}
          placeholder={t('Please enter the name')}
          component={LabeledTextInput}
          formFieldName="name"
          rules={[
            { required: true, message: t('Please enter the name') },
            { length: 200, message: t('Name cannot exceed 200 characters') },
          ]}
        />
        <FormItem
          class="col-span-full lg:col-span-1"
          label={t('Property type')}
          formFieldName="type"
          options={propertyTypeOptions}
          placeholder={t('Select a property type')}
          component={LabeledSelect}
          rules={[{ required: true, message: t('Please select') }]}
        />
        <FormItem
          class="col-span-full"
          component={LabeledAddressInput}
          formFieldName="address"
          required
          rules={[...addressRequired(t), addressFormat(owner, t)]}
        />
      </div>
      <div class="grid p-8 pt-0">
        <FormItem
          class="col-span-full lg:col-span-1"
          formFieldName="imageId"
          component={LabeledUploadImage}
          onInput={uploadChange}
          image={image()}
          type="properties"
        />
      </div>
    </SegmentFormItem>
  );
};
