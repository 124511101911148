import { createMemo, mergeProps, Show, splitProps } from 'solid-js';
import IconMinus from '~/assets/images/common/minus.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import { cn } from '~/utils/classnames';
import type { Accessor, Component, ParentProps, JSX } from 'solid-js';

type FormListRemoveProps = {
  index: Accessor<number>;
  class?: string;
  remove?: () => void;
  showRemove?: boolean;
  removeAll?: boolean;
};

type remover = (props: FormListRemoveProps) => JSX.Element;

export const FormListRemover: remover = (props) => {
  const { t } = useLocalization();
  return (
    <div class="col-span-full mt-4 flex justify-end">
      <Button size="sm" onClick={props.remove} class="flex justify-center" variant="outlined">
        <IconMinus /> {t('Remove')}
      </Button>
    </div>
  );
};

export const SimpleRemover: remover = (props) => {
  return (
    <Button size="sm" onClick={props.remove} class={cn('ml-2 mt-7 flex justify-center rounded-full p-2', props.class)} variant="text">
      <IconMinus />
    </Button>
  );
};

export const FormListRemoverWrapper: Component<ParentProps<FormListRemoveProps & { customRemover?: remover; children?: JSX.Element }>> = (
  props
) => {
  const newProps = mergeProps({ showRemove: true }, props);
  const [localProps, _props] = splitProps(newProps, ['class']);
  const _remover = createMemo(() => {
    const Com = props.customRemover || FormListRemover;
    return <Com {..._props} />;
  });

  return (
    <div class={cn('mb-4 flex flex-col border-b border-partingline pb-8', localProps.class)}>
      {props.children}
      <Show when={(props.index() > 0 || props.removeAll) && props.showRemove}>{_remover()}</Show>
    </div>
  );
};
