import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, createSignal, For } from 'solid-js';
import noteBg from '~/assets/images/vendors/note_bg.png';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization, useNotes } from '~/contexts/global';
import { HttpError } from '~/errors';

type NoteProps = {
  type: string;
  id: string;
  action: string;
};
export const NoteModal = (props: NoteProps) => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [textAreaVal, setTextAreaVal] = createSignal<string>('');
  const { addNote, updateNote, getNote } = useNotes();
  const params = useParams<{ vendorId: `${number}`; noteId: `${number}` }>();

  const saveHandle = async () => {
    if (!textAreaVal().trim()) {
      toast.error(t('Please enter'));
      return;
    }
    try {
      if (props.action === 'Add') {
        await addNote({ content: textAreaVal(), entityId: props.id, entityType: props.type });
      } else {
        await updateNote({ content: textAreaVal(), entityId: props.id, entityType: props.type, noteId: params.noteId });
      }
      navigate(-1);
    } catch (e) {
      const errors = e instanceof HttpError && e.getErrors();
      const message = errors ? (
        <For each={Object.values(errors).flat()}>{(error) => <p>{error as string}</p>}</For>
      ) : (
        t('Operation failed, please try again later')
      );
      toast.error(message);
      throw e;
    }
  };
  const getCurrentNote = async () => {
    try {
      const currentNote = await getNote({ entityId: props.id, entityType: props.type, noteId: params.noteId });
      setTextAreaVal(currentNote.content);
    } catch (e) {
      const errors = e instanceof HttpError && e.getErrors();
      const message = errors ? (
        <For each={Object.values(errors).flat()}>{(error) => <p>{error as string}</p>}</For>
      ) : (
        t('Operation failed, please try again later')
      );
      toast.error(message);
      throw e;
    }
  };
  createEffect(() => {
    if (props.action === 'Edit') {
      getCurrentNote();
    }
  });

  return (
    <Modal
      visible={true}
      title={t(`${props.action} note`)}
      onDone={saveHandle}
      doneText={t('Save')}
      class="lg:w-modalSm"
      onCancel={() => {
        navigate(-1);
      }}>
      <div
        class="h-24"
        style={{
          'background-image': `url(${noteBg})`,
          'background-position': 'center',
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
        }}
      />
      <div class="p-6">
        <LabeledTextArea rows={5} onInput={(val) => setTextAreaVal(val)} value={textAreaVal()} placeholder={t('Please enter')} />
      </div>
    </Modal>
  );
};
