import { For, Show } from 'solid-js';
import IconCircleNotSelected from '~/assets/images/common/circleNotSelected.svg?component-solid';
import IconCircleSelected from '~/assets/images/common/circleSelected.svg?component-solid';
import IconClock from '~/assets/images/common/timeThin.svg?component-solid';
import IconApprove from '~/assets/images/rental-application/approve.svg';
import IconIgnore from '~/assets/images/rental-application/quick-ignore.svg';
import IconReject from '~/assets/images/rental-application/quick-reject.svg';
import { Button } from '~/components/common/Buttons';
import { Dropdown } from '~/components/common/Dropdown';
import Tooltip from '~/components/common/Tooltip';
import { getTenantFullName } from '~/components/tenants/utils';
import { IconChevronDown, toast } from '~/components/ui';
import { useCompanies, useLocalization, useRentalApplication } from '~/contexts/global';
import { createClipboard } from '~/utils/clipboard';

export const ApplicantInformation = () => {
  const { t } = useLocalization();
  const { companies } = useCompanies();

  const { applicationStore, updateDecision, setApplicationStore } = useRentalApplication();

  const { copy, tooltipMessage } = createClipboard({
    successMessage: t('Link copied to clipboard'),
    errorMessage: t('Failed to copy link'),
    tooltipMessages: {
      default: t('Click to copy link'),
      copied: t('Link copied'),
    },
  });

  const getApplicationLink = () =>
    `${companies()?.publicUrl}/rental-applications/${applicationStore.application?.id}/${
      applicationStore.application?.password
    }/applicant-information`;

  const getDecisionClass = (decision?: string) => {
    switch (decision) {
      case 'rejected':
        return 'bg-[#F9487433] text-danger';
      case 'ignored':
        return 'bg-[#DFE8FF] text-[#4B64C6]';
      case 'approved':
        return 'bg-[#E1FFF9] text-light-green';
      default:
        return 'bg-gray-200';
    }
  };

  const getDecisionIcon = (decision?: string) => {
    const allIcon = {
      rejected: IconReject,
      ignored: IconIgnore,
      approved: IconApprove,
    };
    const icon = allIcon[decision as keyof typeof allIcon];
    return (
      <Show when={icon}>
        <img src={icon} class="size-3" alt="DecisionIcon" />
      </Show>
    );
  };

  const getDecisionTagHtml = (decision?: string) => {
    return (
      <div class={`${getDecisionClass(decision)} flex w-fit items-center gap-1 rounded-full px-4 py-1 text-xs capitalize`}>
        {getDecisionIcon(decision)}
        {t(decision || '')}
      </div>
    );
  };

  const onUpdateDecision = async (decision: MagicDoor.Api.ApplicationDecision, showToast = true) => {
    if (!applicationStore.application?.id) return;

    try {
      const response = await updateDecision(applicationStore.application.id, {
        decision,
      });

      setApplicationStore('application', {
        ...applicationStore.application,
        applicationDecision: response.applicationDecision,
      });

      showToast && toast.success(t('Operation successful'));
    } catch (error) {
      console.error('Failed to update decision:', error);
      toast.error(t('Failed to update decision'));
    }
  };

  return (
    <div class="flex w-full flex-col gap-4 sm:flex-row sm:justify-between">
      <div class="items-left flex w-full flex-col gap-2 px-4 text-left sm:w-auto xl:w-2/5">
        <div>
          <h2 class="text-lg font-medium text-black sm:text-xl">{getTenantFullName(applicationStore.application)}</h2>
          <span class="block w-full break-words text-sm sm:w-56 sm:text-base">
            {applicationStore.application?.email},{applicationStore.application?.phone}
          </span>
        </div>
        <Show
          when={['rejected', 'approved'].includes(applicationStore.application?.applicationDecision || '')}
          fallback={getDecisionTagHtml(applicationStore.application?.applicationDecision)}>
          <Dropdown
            class="w-full cursor-pointer"
            contentPosition={['bottom']}
            renderLabel={(isOpen, open) => (
              <div
                onClick={() => open(!isOpen)}
                class={`${getDecisionClass(
                  applicationStore.application?.applicationDecision
                )} flex w-fit items-center gap-1 rounded-full px-4 py-1 text-xs capitalize`}>
                {getDecisionIcon(applicationStore.application?.applicationDecision)}
                {t(applicationStore.application?.applicationDecision || '')}
                <IconChevronDown
                  class="size-3.5 transition-transform"
                  classList={{
                    'rotate-180': isOpen,
                  }}
                />
              </div>
            )}>
            {(_, open) => (
              <div class="flex flex-col gap-2">
                <For each={['approved', 'rejected', 'ignored']}>
                  {(action) => (
                    <div
                      class="flex cursor-pointer items-center justify-between gap-2"
                      onClick={() => {
                        onUpdateDecision(action as MagicDoor.Api.ApplicationDecision);
                        open(false);
                      }}>
                      {getDecisionTagHtml(action)}
                      <Show when={action === applicationStore.application?.applicationDecision} fallback={<IconCircleNotSelected />}>
                        <IconCircleSelected />
                      </Show>
                    </div>
                  )}
                </For>
              </div>
            )}
          </Dropdown>
        </Show>

        <Show when={!applicationStore.application?.hasBeenSubmitted}>
          <div class="flex h-6 w-fit items-center gap-1 rounded-xl bg-text-level03 px-3 text-xs capitalize text-white">
            <IconClock class="stroke-white" />
            {t('Information not completed')}
          </div>
        </Show>
      </div>

      <Show when={companies()?.publicUrl}>
        <div class="px-4 sm:px-0">
          <div class="flex flex-col items-center gap-2 sm:flex-row">
            <Tooltip message={tooltipMessage()} class="w-full cursor-pointer sm:w-auto" align="top">
              <Button variant="outlined" class="w-full sm:w-auto" onClick={() => copy(getApplicationLink())}>
                {t('Copy application link')}
              </Button>
            </Tooltip>
          </div>
        </div>
      </Show>
    </div>
  );
};
