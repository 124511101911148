import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const RENTAL_APPLICATION_URL = '/api/rental-applications';
const RENTAL_APPLICATION_REGIONS_URL = '/api/public/rental-application-regions';
export function validateId(id: string, entityName = 'RentalApplication'): void {
  if (!id) {
    throw new ValidationError(`${entityName} ID must be provided`);
  }
}
export type RentalApplicationFilter = {
  page?: number;
  pageSize?: number;
  portfolioIds?: string[];
  propertyIds?: string[];
  unitIds?: string[];
  draft?: boolean;
  new?: boolean;
  hasBeenSubmitted?: boolean;
  'submitted.start'?: string;
  'submitted.end'?: string;
  decisions?: `${MagicDoor.Api.ApplicationDecision}`[];
  tenantId?: string;
  email?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  hasName?: boolean;
};

export class RentalApplicationRepository extends BaseRestRepository {
  public async getApplications(filter?: RentalApplicationFilter) {
    const url = urlWithQuery(RENTAL_APPLICATION_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getApplication(applicationId: string): Promise<MagicDoor.Api.RentalApplicationDto> {
    const url = `${RENTAL_APPLICATION_URL}/${applicationId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async requestReport(applicationId: string) {
    const url = `${RENTAL_APPLICATION_URL}/${applicationId}/request-reports`;
    return await this.fetchWithAuth(
      url,
      {
        method: 'POST',
      },
      false
    );
  }

  public async getApplicationReport(applicationId: string, reportType: MagicDoor.Api.TransunionReportType) {
    const url = `${RENTAL_APPLICATION_URL}/${applicationId}/report/${reportType}`;
    const response = await this.fetchWithAuth(url);
    return await response.text();
  }

  public async updateApplication(applicationId: string, body: Partial<MagicDoor.Api.CreateOrUpdateRentalApplicationDto>) {
    const url = `${RENTAL_APPLICATION_URL}/${applicationId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    return this.getJsonResponse(response);
  }

  public async createApplication(body: MagicDoor.Api.CreateOrUpdateRentalApplicationDto) {
    const url = RENTAL_APPLICATION_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    return this.getJsonResponse(response);
  }

  public async getApplicationSettings(): Promise<MagicDoor.Api.RentalApplicationsSettingsDto> {
    const url = `${RENTAL_APPLICATION_URL}/settings`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getRentalApplications(filter?: RentalApplicationFilter): Promise<MagicDoor.Api.RentalApplicationListDtoPaginationDto> {
    const url = urlWithQuery(RENTAL_APPLICATION_URL, filter);
    const response = await this.fetchWithAuth(url);
    return await this.getJsonResponse(response);
  }

  public async getRentalApplicationCounts(): Promise<MagicDoor.Api.RentalApplicationCountsDto> {
    const url = `${RENTAL_APPLICATION_URL}/counts`;
    const response = await this.fetchWithAuth(url);
    return await this.getJsonResponse(response);
  }

  public async getRentalApplication(id: string): Promise<MagicDoor.Api.RentalApplicationDto> {
    validateId(id);
    const url = `${RENTAL_APPLICATION_URL}/${id}`;
    const response = await this.fetchWithAuth(url);
    return await this.getJsonResponse(response);
  }

  public async createRentalApplication(
    model: MagicDoor.Api.CreateOrUpdateRentalApplicationDto
  ): Promise<MagicDoor.Api.RentalApplicationDto> {
    const url = RENTAL_APPLICATION_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(model),
    });
    return await this.getJsonResponse(response);
  }

  public async updateRentalApplication(
    id: string,
    model: MagicDoor.Api.CreateOrUpdateRentalApplicationDto
  ): Promise<MagicDoor.Api.RentalApplicationDto> {
    validateId(id);
    const url = `${RENTAL_APPLICATION_URL}/${id}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(model),
    });
    return await this.getJsonResponse(response);
  }

  public async deleteRentalApplication(id: string): Promise<void> {
    validateId(id);
    if (!id) {
      throw new ValidationError('RentalApplication ID must be provided');
    }
    const url = `${RENTAL_APPLICATION_URL}/${id}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async lockRentalApplication(id: string): Promise<void> {
    validateId(id);
    const url = `${RENTAL_APPLICATION_URL}/${id}/lock`;
    await this.fetchWithAuth(url, { method: 'POST' });
  }

  public async getRentalApplicationPayment(id: string): Promise<MagicDoor.Api.RentalApplicationPaymentIntentDto | undefined> {
    validateId(id);
    const url = `${RENTAL_APPLICATION_URL}/${id}/payment`;
    try {
      const response = await this.fetchWithAuth(url, { method: 'POST' });
      return await this.getJsonResponse(response);
    } catch (error) {
      return undefined;
    }
  }

  public async getRentalApplicationPaymentStatus(id: string): Promise<MagicDoor.Api.RentalApplicationPaymentStatusDto> {
    validateId(id);
    const url = `${RENTAL_APPLICATION_URL}/${id}/payment`;
    const response = await this.fetchWithAuth(url);
    return await this.getJsonResponse(response);
  }

  public async updateDecision(
    rentalApplicationId: string,
    payload: MagicDoor.Api.UpdateRentalApplicationDecisionDto
  ): Promise<MagicDoor.Api.RentalApplicationDto> {
    const url = `${RENTAL_APPLICATION_URL}/${rentalApplicationId}/decision`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async tenantConvert(rentalApplicationId: string): Promise<MagicDoor.Api.HydratedTenantDto> {
    const url = `${RENTAL_APPLICATION_URL}/${rentalApplicationId}/tenant-convert`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
    });
    return this.getJsonResponse(response);
  }

  public async startScreening(rentalApplicationId: string): Promise<MagicDoor.Api.CheckScreeningStatusResultDto> {
    const url = `${RENTAL_APPLICATION_URL}/${rentalApplicationId}/screening`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
    });
    return this.getJsonResponse(response);
  }

  public async getScreeningStatus(rentalApplicationId: string): Promise<MagicDoor.Api.CheckScreeningStatusResultDto> {
    const url = `${RENTAL_APPLICATION_URL}/${rentalApplicationId}/screening`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getScreeningQuestions(rentalApplicationId: string): Promise<Partial<MagicDoor.Api.TransunionExamQuestionsDto>> {
    const url = `${RENTAL_APPLICATION_URL}/${rentalApplicationId}/exam`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
    });
    return this.getJsonResponse(response);
  }

  public async postScreeningAnswers(rentalApplicationId: string, data: MagicDoor.Api.TransunionAnswersDto) {
    const url = `${RENTAL_APPLICATION_URL}/${rentalApplicationId}/answers`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return this.getJsonResponse(response);
  }

  public async skipPayment(rentalApplicationId: string) {
    const url = `${RENTAL_APPLICATION_URL}/${rentalApplicationId}/payment/skip`;
    await this.fetchWithAuth(url, {
      method: 'POST',
    });
  }

  public async paymentRefund(rentalApplicationId: string, data: MagicDoor.Api.RefundRentalApplicationPaymentDto) {
    const url = `${RENTAL_APPLICATION_URL}/${rentalApplicationId}/payment/refund`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    return this.getJsonResponse(response);
  }

  public async getRentalApplicationRegions(): Promise<MagicDoor.Api.PublicCountry[]> {
    const response = await this.fetchWithAuth(RENTAL_APPLICATION_REGIONS_URL);
    return await this.getJsonResponse(response);
  }
}
