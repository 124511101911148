export enum DocumentStatus {
  None = 'none',
  Draft = 'draft',
  PendingSignatures = 'pendingSignatures',
  Completed = 'completed',
}

export interface DocumentDescriptor {
  name: string;
  id: string;
  signedUrl: string;
  status: DocumentStatus;
}

export interface PaginatedDocumentDescriptor {
  page: number;
  pageSize: number;
  totalPages: number;
  items: DocumentDescriptor[];
}
