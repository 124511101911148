import { createMagicDoorContext } from '~/contexts/utils';
import { UnitListingsRepository } from '~/repositories/unitListingsRepository';
import { createTriggerResource, createMutation } from '~/utils/resource';
import type { UnitListingFilter, addUnitListingPayload } from '~/repositories/unitListingsRepository';

const repo = new UnitListingsRepository();

export const [UnitListingsProvider, useUnitListings] = createMagicDoorContext('UnitListings', () => {
  const [unitListings, getUnitListings, { refetch }] = createTriggerResource((filter: UnitListingFilter) => repo.getUnitListings(filter));
  const [unitListingDetail, getUnitListingsDetail, { refetch: refetchUnitListingDetail }] = createTriggerResource((unitListingId: string) =>
    repo.getUnitListingDetail(unitListingId)
  );

  const addUnitListingImage = createMutation(async (id: string, images: File[]) => {
    const response = await repo.addUnitListingImage(id, images);
    return response;
  });
  const deleteUnitListingImage = createMutation(async (id: string, imageId: string) => {
    await repo.deleteUnitListingImage(id, imageId);
    refetchUnitListingDetail();
  });
  const addUnitListing = createMutation(async (payload: addUnitListingPayload) => {
    const response = await repo.addUnitListing(payload);
    refetch();
    return response;
  });
  const generateAiListing = createMutation(async (unitId: string, prompt: MagicDoor.Api.UnitMasterListingRequestDto) => {
    return await repo.getnerateAiListing(unitId, prompt);
  });
  const updateUnitLising = createMutation(async (model: MagicDoor.Api.UpdateUnitListingDto, unitId: string) => {
    const response = await repo.updateUnitListing(model, unitId);
    refetchUnitListingDetail();
    return response;
  });
  return {
    unitListings,
    getUnitListings,
    unitListingDetail,
    getUnitListingsDetail,
    refetchUnitListingDetail,
    addUnitListingImage,
    deleteUnitListingImage,
    addUnitListing,
    generateAiListing,
    updateUnitLising,
  };
});
