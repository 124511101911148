import { Route, Routes, Navigate } from '@solidjs/router';
import { TabLinks } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { BankAccountsListProvider } from '~/contexts/local';
// import { CompanyRevenueTab } from './company-renvenue-tab/CompanyRevenueTab';
import { ManagementFeeTab } from './management-fee-tab/ManagementFeeTab';
import { PayOutsTab } from './pay-outs-tab/PayOutsTab';
import { PropertyCreditsTab } from './property-credits-tab/PropertyCreditsTab';
import { AddPropertyCreditModal } from './property-credits-tab/add-property-credit/AddPropertyCreditModal';

export const CompanyRevenueTabRoutes = () => {
  const { t } = useLocalization();

  return (
    <div class="relative h-section p-8">
      <TabLinks
        class="mb-4 rounded-lg border border-partingline bg-white"
        items={[
          {
            label: t('Management fee'),
            href: `management-fees${location.search}`,
          },
          {
            label: t('Property credits'),
            href: `property-credits${location.search}`,
          },
          {
            label: t('Pay outs'),
            href: `pay-outs${location.search}`,
          },
          { label: t('Company revenue'), href: `company-revenue${location.search}` },
        ]}
      />
      <Routes>
        <Route path="/" element={<Navigate href="management-fees" />} />
        <Route path="/management-fees" component={ManagementFeeTab} />
        <Route path="/property-credits/*" component={PropertyCreditsTab} />
        <Route path="/pay-outs/*" component={PayOutsTab} />
        {/* TODO need backend to support this route */}
        {/* <Route path="/company-revenue" component={CompanyRevenueTab} /> */}
      </Routes>
      <Routes>
        <Route
          path="/property-credits/add"
          element={
            <BankAccountsListProvider>
              <AddPropertyCreditModal />
            </BankAccountsListProvider>
          }
        />
      </Routes>
    </div>
  );
};
