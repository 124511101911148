import { useParams } from '@solidjs/router';
import { createEffect, createSignal, Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Panel } from '~/components/common/Panels';
import Tooltip from '~/components/common/Tooltip';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization, useTenants } from '~/contexts/global';
import { createClipboard } from '~/utils/clipboard';
import { formConfirmationContent } from '~/utils/constant';
import type { HttpError } from '~/errors';

export const TenantPortalAccessTab = () => {
  const { t } = useLocalization();
  const params = useParams();

  const { inviteTenant, changeTenantPassword, current, setCurrentId } = useTenants();
  const [showInviteModal, setShowInviteModal] = createSignal<boolean>(false);
  const [showResetModal, setShowResetModal] = createSignal<boolean>(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const [inviteResult, setInviteResult] = createSignal<MagicDoor.Api.PortalInviteResponseDto | undefined>(undefined);
  const [error, setError] = createSignal<string | undefined>(undefined);
  const [newPassword, setNewPassword] = createSignal<string | undefined>(undefined);
  const [passwordVisible, setPasswordVisible] = createSignal(false);

  const { copy, tooltipMessage } = createClipboard({
    errorMessage: t('Failed to copy'),
    tooltipMessages: {
      default: t('Click to copy'),
      copied: t('Copied'),
    },
  });

  const handleInvite = async () => {
    try {
      const inviteResponse = await inviteTenant(params.tenantId);
      setInviteResult(inviteResponse);
      setError(undefined);
      if (inviteResponse.newPassword) {
        setShowInviteModal(true);
      }
    } catch (e) {
      const errorDetail = (e as HttpError).getErrors();
      const errMsg = typeof errorDetail === 'string' ? errorDetail : errorDetail?.unknown;
      setError(`${t('Failed to send invite')}: ${errMsg}`);
      setInviteResult(undefined);
      toast.error(error()?.toString() || t('Failed to send invite'));
    }
  };

  const openResetPasswordModal = () => {
    setShowResetModal(true);
    setPasswordVisible(false);
  };

  const handleResetPassword = async () => {
    if (!newPassword()) {
      setError(t('Please enter a new password'));
      return;
    }

    try {
      await changeTenantPassword(params.tenantId, newPassword() ?? '');
      setError(undefined);
      setShowResetModal(false);
      toast.success(t('Password has been changed successfully'));
    } catch (e) {
      setError(`${t('Failed to reset password')}: ${(e as Error).message}`);
      toast.error(error()?.toString() || t('Failed to reset password'));
    }
  };

  createEffect(() => {
    setShowCloseConfirmation(!!newPassword());
  });

  const onConfirmation = () => {
    setShowInviteModal(false);
    setCurrentId(params.tenantId);
  };

  return (
    <>
      <Panel class="flex w-full gap-2 p-4">
        <Button class="w-1/3" disabled={current.loading} loading={inviteTenant.loading} onClick={handleInvite}>
          {t('Send invite')}
        </Button>
        <Button class="w-1/3" onClick={openResetPasswordModal} disabled={current.loading}>
          {t('Change password')}
        </Button>
      </Panel>
      <Show when={showInviteModal()}>
        <Modal
          visible={true}
          title={t('Send invite')}
          doneText={t('Confirm')}
          onDone={onConfirmation}
          onCancel={() => setShowInviteModal(false)}
          confirmation={false}>
          <div class="flex flex-col gap-2 p-6">
            <Show when={inviteResult()}>
              <div>{t('An invite has been sent to the tenant')}!</div>
              <div class="flex gap-2 rounded-md bg-input p-4">
                <div class="font-semibold">{t('Tenant password')}:</div>
                <Tooltip message={tooltipMessage()} class="cursor-pointer" align="top">
                  <div onClick={() => copy(inviteResult()?.newPassword || '')}>{inviteResult()?.newPassword}</div>
                </Tooltip>
              </div>
            </Show>
            <Show when={error()}>
              <div>{error()}</div>
            </Show>
          </div>
        </Modal>
      </Show>
      <Show when={showResetModal()}>
        <Modal
          visible={true}
          title={t('Change password')}
          doneText={t('Confirm')}
          onDone={handleResetPassword}
          loading={changeTenantPassword.loading}
          onCancel={() => setShowResetModal(false)}
          confirmation={showCloseConfirmation() ? formConfirmationContent(t) : false}>
          <div class="flex flex-col gap-2 p-6">
            <LabeledTextInput
              label={t('New password')}
              type={passwordVisible() ? 'text' : 'password'}
              placeholder={t('Enter new password')}
              onInput={(e) => setNewPassword(e)}
            />

            <Checkbox
              id="show-password"
              name="showPassword"
              checked={passwordVisible()}
              onChange={() => setPasswordVisible(!passwordVisible())}
              label={t('Show password')}
              labelPosition="left"
              showLabel={true}
            />

            <Show when={error()}>
              <div>{error()}</div>
            </Show>
          </div>
        </Modal>
      </Show>
    </>
  );
};
