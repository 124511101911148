import { useParams } from '@solidjs/router';
import { onMount } from 'solid-js';
import { RentalApplicationList } from '~/components/rental-application/list-rental-applications/RentalApplicationList';
import { useRentalApplication } from '~/contexts/global';
import type { Component } from 'solid-js';

export const ListingApplicationsTab: Component = () => {
  const params = useParams<{ listingId: string; unitId: string; portfolioId?: string; propertyId?: string }>();
  const { getRentalApplications, rentalApplications, isLoading } = useRentalApplication();

  onMount(() => {
    getRentalApplications({
      unitIds: [params.unitId],
    });
  });

  return (
    <RentalApplicationList
      data={rentalApplications()}
      loading={isLoading()}
      filterStatuses={['noDecision', 'approved']}
      generateRowLink={(item) =>
        `/listings/${params.portfolioId}/details/${params.listingId}/units/${params.unitId}/rental-applications/application-details/${item.id}`
      }
    />
  );
};
