import { createSignal } from 'solid-js';
import { LeaseDraftPreviewHeader } from '~/pdfsigner/ui/components/header/LeaseDraftPreviewHeader';
import { cn } from '~/utils/classnames';
import { PdfDocument } from './PdfDocument';
import { LeaseTemplateToolbarHeader } from './header/LeaseTemplateToolbarHeader';
import { LeaseDraftPreviewToolbar } from './toolbar/LeaseDraftPreviewToolbar';
import type { PresentableAnnotatedPdfPage } from '~/pdfsigner/ui/types/presentableAnnotatedPdfPage';

export interface LeaseDraftPreviewContainerProps {
  onSave: () => void;
  onStartSigning: () => void;
  presentablePdf: PresentableAnnotatedPdfPage[];
}

export const LeaseDraftPreviewContainer = (props: LeaseDraftPreviewContainerProps) => {
  let pdfDocumentRef!: any;

  const [pdfSize, setPdfSize] = createSignal<string>('w-full');

  const onChangePdfSize = (val: any) => {
    setPdfSize(val);
    pdfDocumentRef.handleResize();
  };

  return (
    <div class="flex flex-col px-8 pt-5">
      <LeaseDraftPreviewHeader onStartSigning={props.onStartSigning} />
      <div class="flex h-section2 items-start gap-3">
        <div class="relative h-full flex-1 overflow-hidden bg-[#E6E6E6]">
          <LeaseTemplateToolbarHeader pdfSize={pdfSize()} onChangePdfSize={onChangePdfSize} />
          <div id="pdf-viewer" class="thinscroll h-[calc(100%-60px)] max-h-full overflow-hidden overflow-y-auto p-1">
            <div class={cn('mx-auto', pdfSize())}>
              <PdfDocument pages={props.presentablePdf} ref={pdfDocumentRef} />
            </div>
          </div>
        </div>
        <LeaseDraftPreviewToolbar onSave={props.onSave} />
      </div>
    </div>
  );
};
