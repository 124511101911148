import { createSignal, Show, For, splitProps, createMemo, mergeProps } from 'solid-js';
import CloseIcon from '~/assets/images/common/close.svg?component-solid';
import { Dropdown } from '~/components/common/Dropdown';
import { Empty } from '~/components/common/Empty';
import { LabeledGroup } from '~/components/common/Inputs';
import { RingLoader } from '~/components/common/Loaders';
import { IconSearch } from '~/components/ui';
import { cn } from '~/utils/classnames';
import { validArr } from '~/utils/tool';
import type { JSX, ComponentProps } from 'solid-js';
import type { LabeledGroupProps } from '~/components/common/Inputs';

const defaultLabelPropName = 'name';

export type DefaultOptionItem = { id: any; [key: string]: any };

export type LabeledInputSearchProps<OptionItem = DefaultOptionItem> = Omit<ComponentProps<'input'>, 'onSelect'> & {
  options?: OptionItem[];
  renderItem?: (props: OptionItem) => JSX.Element;
  onSelect?: (val: string | number | undefined, props: OptionItem | undefined) => void;
  renderMatchedElement?: (props: OptionItem) => JSX.Element;
  loading?: boolean;
  dropdownClass?: string;
  labelPropName?: string;
  valuePropName?: string;
  filter?: ((item: any, keyword: string) => boolean) | boolean;
  remoteMethod?: (query: string) => void;
  tiggerAs?: string;
  allowClear?: boolean;
  clearRender?: JSX.Element;
} & LabeledGroupProps;

export const LabeledInputSearch = function <OptionItem extends DefaultOptionItem>(_props: LabeledInputSearchProps<OptionItem>) {
  const newProps = mergeProps({ allowClear: true }, _props);
  const [localProps, props] = splitProps(newProps, [
    'onSelect',
    'dropdownClass',
    'options',
    'renderItem',
    'renderMatchedElement',
    'loading',
    'labelPropName',
    'valuePropName',
    'filter',
    'remoteMethod',
  ]);

  const [focus, setFocus] = createSignal<boolean>(false);
  const [inputValue, setInputValue] = createSignal<string>('');

  const matched = createMemo(() => {
    return localProps.options?.find((item) => item.id === props.value);
  });

  const getRenderMethod = createMemo(() => localProps.renderItem || defaultRenderItemMethod);
  const getRenderMatchedElementMethod = createMemo(() => localProps.renderMatchedElement || getRenderMethod() || defaultRenderItemMethod);
  const getLabelPropName = createMemo(() => localProps.labelPropName || defaultLabelPropName);

  const filteredOptions = createMemo(() => {
    const filterMethod = typeof localProps.filter === 'function' ? localProps.filter : defaultFilterMethod;
    return inputValue() && localProps.filter !== false
      ? localProps.options?.filter((item) => filterMethod(item as any, inputValue()))
      : localProps.options;
  });

  function defaultRenderItemMethod(item: OptionItem) {
    return <span class="cursor-pointer">{item[getLabelPropName()]}</span>;
  }

  function defaultFilterMethod(item: OptionItem, keyword: string) {
    return item[getLabelPropName()]?.toLowerCase().includes(keyword.toLowerCase());
  }

  return (
    <LabeledGroup labelJSX={props.labelJSX} error={props.error}>
      <Dropdown
        disabled={props.disabled}
        triggerClass="w-full"
        tiggerAs={props.tiggerAs}
        contentClass={cn('px-0', localProps.loading && 'hidden')}
        contentPosition={['top', 'right']}
        triggerElement={
          <span
            class={cn(
              'flex w-full items-center justify-start rounded-md border bg-inputbox-bg px-3 py-2 text-sm text-black',
              props.class,
              focus() && 'ring-1 ring-essential-colour'
            )}>
            <Show
              when={matched()}
              fallback={
                <>
                  <input
                    {...props}
                    value={inputValue()}
                    onInput={(e) => {
                      setInputValue(e.target.value);
                      localProps.remoteMethod && localProps.remoteMethod(e.target.value);
                    }}
                    onFocus={() => {
                      setFocus(true);
                    }}
                    onBlur={() => {
                      setFocus(false);
                    }}
                    class="min-w-0 flex-1 bg-transparent outline-none placeholder:text-auxiliary-text"
                  />
                  {localProps.loading ? <RingLoader /> : <IconSearch class="size-4 text-text-level03" />}
                </>
              }>
              <div class="flex w-full justify-between">{getRenderMatchedElementMethod()(matched() as OptionItem)}</div>
              <Show when={props.allowClear}>
                <span
                  class="shrink-0"
                  onClick={() => {
                    localProps.onSelect && localProps.onSelect(undefined, undefined);
                  }}>
                  <Show when={props.clearRender} fallback={<CloseIcon class="ml-3 cursor-pointer text-text-level03" />}>
                    {props.clearRender}
                  </Show>
                </span>
              </Show>
            </Show>
          </span>
        }>
        {(isOpen, open) => (
          <Show when={isOpen}>
            <div
              onClick={() => {
                open(!isOpen);
              }}
              class={cn('thinscroll max-h-dropdown overflow-auto px-2', localProps.dropdownClass)}>
              <Show when={validArr(filteredOptions())} fallback={<Empty size="120px" class="py-0" />}>
                <For each={filteredOptions()}>
                  {(item) => {
                    return (
                      <div
                        onClick={() => {
                          localProps.onSelect && localProps.onSelect(item.id, item);
                        }}
                        class="box-border cursor-pointer rounded border border-transparent p-2 hover:border-essential-colour hover:bg-essential-colour/10">
                        {getRenderMethod()(item)}
                      </div>
                    );
                  }}
                </For>
              </Show>
            </div>
          </Show>
        )}
      </Dropdown>
    </LabeledGroup>
  );
};
