import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { createMemo, createSignal, Show } from 'solid-js';
import SuccessImg from '~/assets/images/payout/success.png';
import { LabeledBankAccountSearch } from '~/components/bank-accounts/BankAccountSearch';
import { handlePrint } from '~/components/checks/PrintCheck';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledInputSwitch } from '~/components/common/Inputs/LabeledInputSwitch';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { useLocalization, usePayOuts } from '~/contexts/global';
import { paymentMethodOptions, paymentTypeOptions } from '~/utils/constant';

export const AddPayOutPage = () => {
  const { t } = useLocalization();
  const breadcrumbItems = createMemo(() => [
    { label: t('Deposit slips'), link: '/accounting/company-revenue/pay-outs' },
    { label: t('Add payout') },
  ]);
  const form = useForm();
  const navigate = useNavigate();
  const { addPayOut, setFilter } = usePayOuts();
  const [payout, setPayout] = createSignal<MagicDoor.Api.HydratedCheckDto>();

  const onClose = () => navigate(-1);

  const submitPayout = async () => {
    const { validateStatus, errorFields } = form.validateForm();
    if (validateStatus) {
      const res = await addPayOut({
        payOuts: [
          {
            bankAccountId: form.formStore.bankAccountId,
            amount: form.formStore.amount,
            paymentType: form.formStore.paymentType,
            paymentMethod: form.formStore.paymentMethod,
            externalTransactionId: form.formStore.externalTransactionId,
            memo: form.formStore.memo,
          },
        ],
        paymentDate: form.formStore.paymentDate,
        setPayOutDate: form.formStore.setPayOutDate,
      });

      setFilter({});

      const check = res.payOuts?.[0].check;

      if (check) {
        setPayout(check);
      } else {
        onClose();
      }
    } else {
      throw errorFields;
    }
  };

  const printCheck = async () => {
    handlePrint(payout() as MagicDoor.Api.HydratedCheckDto);
    onClose();
  };

  const doneAction = createMemo(() => {
    if (payout()) {
      return printCheck;
    }
    return submitPayout;
  });

  const SuccessPage = () => {
    return (
      <div class="flex size-full flex-col items-center justify-center">
        <img src={SuccessImg} class="w-[367px]" alt="SuccessImg" />
        <div class="mt-4 text-lg font-semibold normal-case text-text-level01">{t('A new payout is successfully added')}</div>
        <div class="mt-2 w-[337px] text-center text-sm normal-case text-text-level03">
          {t('A new payout is successfully added to your bank account, print out to see the details.')}
        </div>
        <div class="mt-4 flex gap-4">
          <Button class="text-base font-medium capitalize" size="sm" onClick={printCheck} variant="outlined">
            {t('Print check')}
          </Button>
          <Button onClick={onClose} class="text-base font-medium" size="sm">
            {t('Done')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Breadcrumb backLink={onClose} items={breadcrumbItems()} />
      <div class="relative">
        <MultiStepsPanel
          successPage={SuccessPage()}
          onClose={onClose}
          title={t('Add payout')}
          onSubmit={doneAction()}
          loading={addPayOut.loading}>
          <Form
            class="grid grid-cols-1 gap-2 p-5 lg:grid-cols-2"
            defaultForm={form}
            initialValues={{
              paymentDate: dayjs().format('YYYY-MM-DD'),
            }}>
            <FormItem
              class="col-span-1 lg:col-span-2"
              placeholder={t('Please select bank account')}
              label={t(`Bank account`)}
              formFieldName="bankAccountId"
              component={LabeledBankAccountSearch}
              rules={[{ message: t(`Please select bank account`), required: true }]}
            />

            <FormItem class="col-span-1" formFieldName="setPayOutDate" label={t('Set payment date')} component={LabeledInputSwitch} />

            <Show when={form.formStore.setPayOutDate}>
              <FormItem
                formFieldName="paymentDate"
                label={t('Payment date')}
                component={DueDateInputField}
                placeholder={t('Please select')}
                class="col-span-1"
                rules={[{ message: t(`Please select`), required: true }]}
                removeValueOnClean
              />
            </Show>
            <FormItem
              placeholder={t('Please enter')}
              label={t(`Payout amount`)}
              formFieldName="amount"
              prepend="$"
              component={LabeledNumberInput}
              rules={[{ message: t(`Please enter`), required: true }]}
              class="col-span-1"
            />

            <FormItem
              formFieldName="paymentType"
              label={t('Payment type')}
              options={paymentTypeOptions(t)}
              onChangeMethodName="onChange"
              component={DropdownMenu}
              placeholder={t('Please select')}
              class="col-span-1"
              rules={[{ message: t(`Please select`), required: true }]}
            />

            <Show when={form.formStore.paymentType === 'manual'}>
              <FormItem
                formFieldName="paymentMethod"
                label={t('Payment method')}
                options={paymentMethodOptions(t)}
                onChangeMethodName="onChange"
                component={DropdownMenu}
                placeholder={t('Please select')}
                class="col-span-1"
              />

              <FormItem
                label={t('External transaction ID')}
                placeholder={t('Enter transaction ID')}
                component={LabeledTextInput}
                rules={[{ required: true, message: t('Please enter') }]}
                formFieldName="externalTransactionId"
                class="col-span-1"
                maxlength={50}
              />
            </Show>

            <FormItem
              class="col-span-1 "
              formFieldName="memo"
              label={t('Memo')}
              component={LabeledTextInput}
              placeholder={t('Please enter')}
              maxlength={200}
            />

            <Show when={form.formStore.setPayOutDate}>
              <div class="col-span-1 rounded-2xl bg-[#faf1ff] p-4 text-text-level02 lg:col-span-2">
                <div class="text-base normal-case text-text-level02">
                  {t('Setting pay out date, will tell the systems that all payouts before this date has been done')}
                </div>
              </div>
            </Show>
          </Form>
        </MultiStepsPanel>
      </div>
    </>
  );
};
