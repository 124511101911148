export interface PaymentStatusIndicatorProps {
  label: string | undefined;
  color: string;
}
export interface StatusIndicatorProps {
  label: string;
  color: string;
}

export interface RentalApplicationProps {
  application?: MagicDoor.Api.RentalApplicationDto;
}

export const paymentStatusIndicatorsArray: Array<PaymentStatusIndicatorProps> = [
  {
    label: 'paid',
    color: '#36CBAC',
  },
  {
    label: 'unpaid',
    color: '#BEC6DB',
  },
  {
    label: 'processing',
    color: '#00C0FF',
  },
  {
    label: 'failed',
    color: '#FF0000',
  },
  {
    label: 'refunded',
    color: '#999999',
  },
];
export const statusIndicatorsArray: Array<StatusIndicatorProps> = [
  {
    label: 'available',
    color: '#36CBAC',
  },
  {
    label: 'notRequested',
    color: '#BEC6DB',
  },
  {
    label: 'requested',
    color: '#3C35F2',
  },
];
