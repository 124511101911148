import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { createEffect, createSignal } from 'solid-js';
import IconViewArrow from '~/assets/images/common/viewArrow.svg?component-solid';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { useLocalization, useVendors } from '~/contexts/global';
import UpDownBarGraph, { getLastFiveMonths } from './UpDownBarGraph';
import type { BarData } from './UpDownBarGraph';
const VendorTransactions = () => {
  const { vendorOverview, current } = useVendors();

  const [transactionData, setTransactionData] = createSignal<BarData[]>([]);
  const { t } = useLocalization();
  const navigate = useNavigate();

  const generateDummyTransactions = () => {
    const months = getLastFiveMonths();
    return months.map((month, index) => {
      const transaction =
        vendorOverview()?.transactions?.items?.[
          dayjs()
            .subtract(months.length - index - 1, 'month')
            .format('YYYY-MM')
        ];
      return {
        axisLabel: month,
        segments: [
          { value: transaction?.paid ?? 0, color: 'warning', label: t('Paid') },
          { value: transaction?.amount ?? 0, color: 'green', label: t('Bills') },
        ],
      };
    });
  };

  createEffect(() => {
    const dummyData = generateDummyTransactions();
    setTransactionData(dummyData);
  });

  return (
    <div class="relative">
      <ThreeSectionBox
        topTitle={<div>{t('Transactions')}</div>}
        topAction={
          <button onClick={() => navigate(`/maintenance/vendors/${current()?.id}/bills`)} class="flex items-center gap-1">
            <div>{t('Details')}</div>
            <IconViewArrow class="size-4 text-link" />
          </button>
        }
        middle={
          <div class="h-64 w-full">
            <UpDownBarGraph data={transactionData()} capHeight={400} />
          </div>
        }
      />
    </div>
  );
};

export default VendorTransactions;
