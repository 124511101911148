import { createEffect, createSignal, For, Show } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import { IconCirclePlus, IconCircleX } from '~/components/ui/Icons';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';

type TagProps = {
  tags: string[] | null | undefined;
  onChange: (tags: string[]) => void;
};
export const OperateTags: Component<TagProps> = (props) => {
  const { t } = useLocalization();
  let inputRef: HTMLInputElement | undefined;
  const [value, setValue] = createSignal<string>('');
  const [visible, setVisible] = createSignal<boolean>(false);
  const [store, setStore] = createStore<{ tags: string[] }>({ tags: [] });

  const handleDelTag = (index: number) => {
    // props.deleteTag(tag);
    setStore(
      'tags',
      produce((prev) => prev.splice(index, 1))
    );
    props?.onChange(store.tags);
  };
  const handleAddTag = () => {
    const value = inputRef?.value.trim() ?? '';
    if (value) {
      setStore(
        'tags',
        produce((prev) => {
          prev.push(value);
        })
      );
      setVisible(false);
      props?.onChange(store.tags);
      setValue('');
    }
  };
  const handleAdd = () => {
    setVisible(true);
    inputRef?.focus();
  };
  createEffect(() => {
    if (props.tags) {
      setStore(
        'tags',
        produce((prev) => {
          prev.push(...(props.tags ?? []));
        })
      );
    }
  });
  return (
    <div class="flex flex-wrap gap-2">
      <For each={store.tags}>
        {(tag, index) => (
          <div class="flex w-fit items-center rounded-full bg-[#F2E7F5] px-3 py-1">
            <span class="text-xs text-[#242529]">{tag}</span>
            <IconCircleX class="ml-1 cursor-pointer text-base text-primary" width={16} onClick={() => handleDelTag(index())} />
          </div>
        )}
      </For>
      <div class="flex items-center gap-2">
        <Show when={visible()}>
          <div class="flex items-center rounded-full border border-essential-colour px-3 text-xs text-primary">
            <input
              class="w-28 flex-1 bg-transparent px-3 py-1 text-sm text-[#242529] outline-none placeholder:text-auxiliary-text"
              type="text"
              placeholder={t('Please enter')}
              value={value()}
              onInput={(e) => {
                setValue(e.target.value);
              }}
              ref={inputRef}
              onKeyDown={(e) => e.key === 'Enter' && handleAddTag()}
            />
            <IconCircleX
              class="cursor-pointer text-base text-primary"
              width={16}
              onClick={() => {
                setVisible(false);
                setValue('');
              }}
            />
          </div>
        </Show>
        <IconCirclePlus class="cursor-pointer text-base text-primary" width={20} onClick={handleAdd} />
      </div>
    </div>
  );
};
