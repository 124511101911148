import { Show, For } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import { cn } from '~/utils/classnames';
import { useCategoryComponentWrapper } from './Wrapper';
import type { JSX } from 'solid-js';

export const CategoryContentSection = (props: {
  mode?: 'padding';
  renderMethod?: (context: ReturnType<typeof useCategoryComponentWrapper>) => JSX.Element;
  hideTitle?: boolean;
}) => {
  const wrapperContext = useCategoryComponentWrapper();

  return (
    <Show when={typeof props.renderMethod !== 'function'} fallback={props.renderMethod?.(wrapperContext)}>
      <For each={wrapperContext.store.sections}>
        {(section, index) => (
          <div ref={(ref) => wrapperContext.setContentRef(section.key, ref)}>
            <Show when={!props.hideTitle}>
              <div
                class={cn('sticky top-0 z-10 mb-7 mt-1 flex items-center justify-between border-b bg-white pb-4 pt-10 capitalize', {
                  'pt-4': index() === 0,
                })}>
                <span
                  class={cn('text-lg font-semibold text-text-level01', {
                    'px-11': props.mode === 'padding',
                  })}>
                  {section.name}
                </span>
                <Show when={section.titleActions}>
                  <div class="flex gap-2">
                    <For each={section.titleActions}>
                      {({ icon: Icon, label, disabled, loading, ...action }) => (
                        <Button
                          variant="outlined"
                          class="rounded-full p-1 px-3 capitalize"
                          disabled={disabled && disabled()}
                          loading={loading && loading()}
                          {...action}>
                          <Show when={Icon}>{<Icon />}</Show>
                          {label}
                        </Button>
                      )}
                    </For>
                  </div>
                </Show>
              </div>
            </Show>

            <div
              class={cn({
                'px-11': props.mode === 'padding',
              })}>
              <section.component />
            </div>
          </div>
        )}
      </For>
    </Show>
  );
};
