import dayjs from 'dayjs';
import { useLocalization } from '~/contexts/global';
import { isNumber } from './number';
import type { FormDataType } from '~/pages/leases/lease-details/utils/leaseTransactionValidator';

export const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const emailRegex =
  /^[a-zA-Z0-9](?:[a-zA-Z0-9._%+-]*[a-zA-Z0-9])?@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const validatePhone = (input: unknown): input is string => typeof input === 'string' && phoneRegex.test(input);

export const validateEmail = (input: unknown): input is string => typeof input === 'string' && emailRegex.test(input);

export const arrayRequiredValidator = (value: any, name: string, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  const newValue = value ? value : [];

  if (newValue.length === 0 && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  return undefined;
};

export const verifyRequiredValidator = (value: string | undefined | null, name: string, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);

  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  return undefined;
};

export const requiredValidator = (value: string | undefined | null, name: string, length: number, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);

  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  if (value && value.length > length) {
    return t('{name} must be {length} characters or less', { name: translatedName, length: length.toString() });
  }
  return undefined;
};

export const minLengthValidator = (value: string | undefined | null, name: string, minLength: number, isRequired = false) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  if (value && value.length < minLength) {
    return t('{name} must be at least {minLength} characters', { name: translatedName, minLength: minLength.toString() });
  }
  return undefined;
};

const regexValidator = (value: string | undefined | null, pattern: RegExp, errorMessage: string) => {
  const { t } = useLocalization();
  if (value && !pattern.test(value)) {
    return t(errorMessage);
  }
  return undefined;
};

const rangeValidator = (value: string | undefined | null, name: string, min: number, max: number, isRequired = false) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  if (value && (value.length < min || value.length > max)) {
    return t('{name} must be between {min} and {max} characters', { name: translatedName, min: min.toString(), max: max.toString() });
  }
  return undefined;
};

export const equalLengthValidator = (value: string | undefined, name: string, length: number, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  if (value && value.length !== length) {
    return t('{name} must be {length} characters', { name: translatedName, length: length.toString() });
  }
  return undefined;
};

export const dateFormatValidator = (value: string | undefined | null, name: string, formatString: string, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  if (!value && isRequired) {
    return t('{name} is required', { name: translatedName });
  }
  if (!dayjs(value, formatString).isValid()) {
    return t('{name} date string is incorrect', { name: translatedName });
  }
  return undefined;
};

export const minNumberValidator = (value: number | string | undefined, name: string, min: number, isRequired: boolean) => {
  const { t } = useLocalization();
  const translatedName = t(name);
  if (!value && isRequired && !isNaN(value as number)) {
    return t('{name} is required', { name: translatedName });
  }
  if (typeof value === 'string' || isNaN(value as number)) {
    const num = parseFloat(value as string);
    if (isNaN(num) || !isFinite(num)) {
      return t('{name} is not a number', { name: translatedName });
    }
    value = num;
  }
  if (typeof value !== 'number') {
    return t('{name} is not a number', { name: translatedName });
  }
  if (value < min) {
    return t('{name} at least greater than {min}', { name: translatedName, min: min.toString() });
  }
  return undefined;
};

export const getNameValidationError = (name: string | undefined, isRequired: boolean) => {
  return requiredValidator(name, 'Name', 200, isRequired);
};

export const getFirstNameValidationError = (name: string | undefined, isRequired: boolean) => {
  return requiredValidator(name, 'FirstName', 200, isRequired);
};

export const getLastNameValidationError = (name: string | undefined, isRequired: boolean) => {
  return requiredValidator(name, 'LastName', 200, isRequired);
};

export const getEmailValidationError = (email: string | undefined | null, isRequired: boolean) => {
  return requiredValidator(email, 'Email', 320, isRequired) || regexValidator(email, emailRegex, 'Invalid email format');
};

export const getPhoneValidationError = (phone: string | undefined | null, isRequired: boolean) => {
  return requiredValidator(phone, 'Phone', 10, isRequired) || regexValidator(phone, phoneRegex, 'Phone must be a number and 10 characters');
};

export const getStreetAddress1ValidationError = (streetAddress1: string | undefined | null, isRequired: boolean) => {
  return requiredValidator(streetAddress1, 'Street address 1', 150, isRequired);
};

export const getStreetAddress2ValidationError = (streetAddress2: string | undefined | null, isRequired: boolean) => {
  return (
    requiredValidator(streetAddress2, 'Street address 2', 150, isRequired) || minLengthValidator(streetAddress2, 'Street address 2', 2)
  );
};

export const getCityValidationError = (city: string | undefined | null, isRequired: boolean) => {
  return requiredValidator(city, 'City', 150, isRequired) || minLengthValidator(city, 'City', 2);
};

export const getStateValidationError = (state: string | undefined | null, isRequired: boolean) => {
  return requiredValidator(state, 'State', 2, isRequired) || regexValidator(state, /^[A-Za-z]{2}$/, 'State must be 2 characters');
};

export const getZipCodeValidationError = (zipCode: string | undefined | null, isRequired: boolean) => {
  return requiredValidator(zipCode, 'Zip code', 5, isRequired) || regexValidator(zipCode, /^\d{5}$/, 'Zip code must be 5 digits');
};

export const getCountryValidationError = (country: string | undefined | null, isRequired: boolean) => {
  return rangeValidator(country, 'Country', 3, 25, isRequired);
};

export const emptyValuesToUndefined = (obj: any) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === '') {
      newObj[key] = undefined;
    }
  });
  return newObj;
};

export const hasAtLeastOneFieldFilled = (address: MagicDoor.Api.AddressDto) => {
  return Object.values(address).some((field) => field !== undefined && field !== '');
};

export const addChargeValidator = (formData: FormDataType, setFormErrors: (errors: FormDataType) => void): boolean => {
  const { t } = useLocalization();
  let result = true;
  const errors: FormDataType = {};
  Object.keys(formData).forEach((key: string) => {
    if (key !== 'description') {
      const nameObj: any = {
        dueDate: 'Due date',
        chartOfAccountId: 'Chart of account',
        amount: 'Amount',
      };
      errors[key as keyof FormDataType] = (() => {
        const name = nameObj[key];
        const value = formData[key as keyof FormDataType];
        const translatedName = t(name);

        if (!value) {
          return t('{name} is required', { name: translatedName });
        }
        if (value && value.length > 255) {
          return t('{name} must be {length} characters or less', { name: translatedName, length: '255' });
        }
        if (key === 'amount' && !isNumber(value)) {
          return t('{name} must be a number', { name: translatedName });
        }
        return undefined;
      })();
    }
  });
  for (const key in errors) {
    if (errors[key as keyof FormDataType]) {
      result = false;
      break;
    }
  }
  setFormErrors(errors);
  return result;
};

export const getUrlValidator = (url: string | undefined | null) => {
  if (url) {
    return /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/.test(url);
  } else {
    return true;
  }
};
