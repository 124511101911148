import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { searchSelectValuePropNames } from '~/components/ui/Select/SearchSelect';
import { useLocalization } from '~/contexts/global';
import { PropertiesRepository } from '~/repositories/propertiesRepository';
import { PropertyTitle } from './PropertyTitle';
import type { EntrySearchProps } from '~/components/ui';
import type { LabeledSearchSelectProps } from '~/components/ui/Select/SearchSelect';
import type { PropertiesFilter } from '~/repositories/propertiesRepository';

const repo = new PropertiesRepository();

export const PropertySearch = <T extends boolean | undefined = undefined>(
  props: EntrySearchProps<MagicDoor.Api.HydratedPropertyDto, T, PropertiesFilter>
) => {
  const { t } = useLocalization();

  const initialSelectedLoader = async (ids: string[]) => {
    return await repo.batchGetPropertyByIds(ids);
  };

  return (
    <SearchSelect
      placeholder={t('Search properties')}
      fetcher={(query: string | undefined, page: number) =>
        repo.getProperties({ active: true, pageSize: 20, ...props.filter, page, search: query || undefined })
      }
      renderSelected="displayName"
      renderItem={(item, { disabledLabel }) => (
        <PropertyTitle feedbackIconReadonly property={item} warning={disabledLabel} class="w-full" />
      )}
      initialSelectedLoader={initialSelectedLoader}
      {...props}
    />
  );
};

export const LabeledPropertySearch = <T extends boolean | undefined = undefined>(
  props: LabeledSearchSelectProps<MagicDoor.Api.HydratedPropertyDto, T>
) => {
  const [searchProps, labeledProps] = splitProps(props, searchSelectValuePropNames);

  return (
    <LabeledGroup {...labeledProps}>
      <PropertySearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.(
            (Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string,
            selected
          );
        }}
      />
    </LabeledGroup>
  );
};
