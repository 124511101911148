import { createContext, createEffect, createSignal, onCleanup, onMount, Show, useContext } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { Component, JSX } from 'solid-js';

const StaticTabsContext = createContext<{
  selected: () => string;
  select: (value: string) => void;
}>();

export const StaticTabs: Component<{
  defaultSelected: string;
  children: JSX.Element;
  class?: string;
  onChange?: (value: string) => void;
  selected?: string;
}> = (props) => {
  const [selected, setSelected] = createSignal(props.defaultSelected);

  const handleSelect = (value: string) => {
    setSelected(value);
    props.onChange?.(value);
  };

  createEffect(() => {
    if (props.selected !== undefined) {
      setSelected(props.selected);
    }
  });

  return (
    <StaticTabsContext.Provider value={{ selected, select: handleSelect }}>
      <div class={props.class}>{props.children}</div>
    </StaticTabsContext.Provider>
  );
};

export const StaticTabsList: Component<{ children: JSX.Element; class?: string }> = (props) => {
  let tabsListRef: HTMLDivElement | undefined;
  let isDragging = false;
  let startX: number;
  let scrollLeft: number;
  const [isDraggable, setIsDraggable] = createSignal(false);

  const checkDraggable = () => {
    if (tabsListRef) {
      setIsDraggable(tabsListRef.scrollWidth > tabsListRef.clientWidth);
    }
  };

  const handleMouseDown = (e: MouseEvent) => {
    if (!isDraggable()) return;
    isDragging = true;
    startX = e.pageX - tabsListRef!.offsetLeft;
    scrollLeft = tabsListRef!.scrollLeft;
    tabsListRef!.style.cursor = 'grabbing';
  };

  const handleMouseUp = () => {
    isDragging = false;
    if (tabsListRef) {
      tabsListRef.style.cursor = isDraggable() ? 'grab' : 'default';
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging) return;
    const x = e.pageX - tabsListRef!.offsetLeft;
    const walk = (x - startX) * 2;
    tabsListRef!.scrollLeft = scrollLeft - walk;
  };

  onMount(() => {
    if (tabsListRef) {
      tabsListRef.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('resize', checkDraggable);
      checkDraggable();
    }
  });

  onCleanup(() => {
    if (tabsListRef) {
      tabsListRef.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', checkDraggable);
    }
  });

  return (
    <div ref={tabsListRef} role="tablist" class={cn('thinscroll flex overflow-x-auto', isDraggable() ? 'cursor-grab' : '', props.class)}>
      {props.children}
    </div>
  );
};

export const StaticTabsTrigger: Component<{
  value: string;
  children: JSX.Element;
  class?: string;
  resultCount?: number;
}> = (props) => {
  const ctx = useContext(StaticTabsContext);
  if (!ctx) throw new Error('StaticTabsTrigger must be used within StaticTabs');

  return (
    <button
      role="tab"
      aria-selected={props.value === ctx.selected()}
      onClick={(e) => {
        e.preventDefault();
        ctx.select(props.value);
      }}
      class={`${props.class || ''} py-2 text-sm transition-colors duration-200 hover:text-[#3F9BFC] ${
        props.value === ctx.selected() ? 'border-b-2 border-[#3F9BFC] text-[#3F9BFC]' : 'text-[#667085]'
      }`}>
      {props.children}
      <Show when={props.resultCount !== undefined}>
        <span
          class={`ml-2 rounded-sm bg-[#F4F8FB] px-1 py-0.5 text-xs ${
            props.value === ctx.selected() ? 'text-[#3F9BFC]' : 'text-[#667085]'
          }`}>
          {props.resultCount}
        </span>
      </Show>
    </button>
  );
};

export const StaticTabsContent: Component<{ value: string; children: JSX.Element; class?: string }> = (props) => {
  const ctx = useContext(StaticTabsContext);
  if (!ctx) throw new Error('StaticTabsContent must be used within StaticTabs');

  return (
    <div role="tabpanel" hidden={props.value !== ctx.selected()} class={cn('px-4', props.class)}>
      {props.children}
    </div>
  );
};
