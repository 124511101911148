import { useNavigate } from '@solidjs/router';
import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { useLocalization } from '~/contexts/global';
import { LeaseTemplateForm } from './LeaseTemplateForm';

export const LeaseDocumentDraftCreator = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  const breadcrumbItems = createMemo(() => [{ label: t('Leases'), link: '/leasing/leases' }, { label: t('Create Lease Document') }]);

  return (
    <div>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <LeaseTemplateForm />
    </div>
  );
};
