import { Route, Routes, useParams } from '@solidjs/router';
import { NotesTableList } from '~/components/notes';
import { NoteModal } from '~/components/notes/NoteModal';

export const WorkOrderNotesTab = () => {
  const params = useParams<{ workOrderId: string }>();
  return (
    <>
      <Routes>
        <Route path="/add" element={<NoteModal type="workOrder" id={params.workOrderId} action="Add" />} />
        <Route path="/:noteId/edit" element={<NoteModal type="workOrder" id={params.workOrderId} action="Edit" />} />
      </Routes>
      <NotesTableList type="workOrder" id={params.workOrderId} />
    </>
  );
};
