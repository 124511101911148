import { leasePeriodString, leaseStatusString } from '~/components/leases/utils';
import { useLocalization } from '~/contexts/global';
import { Presenter } from '~/pdfsigner/presenters/Presenter';
import { Alignment, Orientation, PageSize, WidthDistribution } from '~/pdfsigner/usecases/types/reportDocument';
import { commaNumber } from '~/utils/number';
import type { PdfState } from '~/pdfsigner/state/pdfAppState';
import type { DocumentFooter, DocumentHeader, Entry, ReportDocument, Section } from '~/pdfsigner/usecases/types/reportDocument';
import type { CompanyDto, HydratedLeaseDto, HydratedLeaseTenantDto, PortfolioDto, PropertyDto } from '~/swagger/Api';

export interface PresentableLeasesWithBalancesDue {
  leasesWithBalancesDueReport: ReportDocument;
}

const { t, currentLanguage } = useLocalization();

export class LeasesWithBalancesDuePresenter extends Presenter<PresentableLeasesWithBalancesDue> {
  createModel(state: PdfState): PresentableLeasesWithBalancesDue {
    return {
      leasesWithBalancesDueReport: this.createLeasesWithBalancesDueReport(
        state.portfolios,
        state.properties,
        state.reports.leasesWithBalancesDueReport,
        state.companyInfo
      ),
    };
  }

  private createLeasesWithBalancesDueReport = (
    portfolios: PortfolioDto[],
    properties: PropertyDto[],
    leasesWithBalancesDue?: HydratedLeaseDto[],
    companyInfo?: CompanyDto
  ): ReportDocument => {
    const mergedArray = [...portfolios, ...properties];
    const memo = mergedArray.map((item) => item.name).join(', ');
    const additionalInfo: string[] = [];
    additionalInfo.push(`${companyInfo?.address?.streetAddress1} ${companyInfo?.address?.streetAddress2 || ''}`);
    additionalInfo.push(
      `${companyInfo?.address?.city}, ${companyInfo?.address?.zipCode}, ${companyInfo?.address?.state}, ${companyInfo?.address?.country}`
    );
    const header: DocumentHeader = {
      title: t('Leases with balance due'),
      subTitle: new Date().toLocaleDateString(),
      additionalInfo,
      companyName: companyInfo?.name,
    };
    const footer: DocumentFooter = {
      text: 'MagicDoor.com',
    };
    const leases = this.createEntries(leasesWithBalancesDue);
    const sections: Section[] = [];
    if (leases.length > 1) {
      sections.push({
        header: {
          values: [
            { text: t('Lease'), isBold: true },
            { text: t('Tenants'), isBold: true },
            { text: t('Status'), isBold: true, width: WidthDistribution.fixed },
            { text: t('Lease term'), isBold: true, width: WidthDistribution.fixed },
            { text: t('Past due'), isBold: true, width: WidthDistribution.fixed },
            { text: t('Balance'), isBold: true, width: WidthDistribution.fixed },
          ],
          hasBottomLine: true,
          hasTopLine: true,
        },
        entries: leases,
        hasAlternatingRowColors: true,
      });
    }
    return {
      language: currentLanguage(),
      orientation: Orientation.PORTRAIT,
      memo,
      header,
      footer,
      sections,
      pageSize: PageSize.LETTER,
      shouldShowPageNumbers: true,
      isEachSectionOnANewPage: false,
    };
  };

  createEntries = (leases?: HydratedLeaseDto[]): Entry[] => {
    if (!leases || leases.length === 0) {
      return [];
    }
    const entries = leases.flatMap<Entry>((lease) => {
      const propertyName = lease.property?.displayName;
      const unitName = lease.unit?.name;
      let leaseName = undefined;
      if (unitName && propertyName) {
        leaseName = `${propertyName}, ${unitName}`;
      } else if (propertyName) {
        leaseName = propertyName;
      } else {
        leaseName = unitName;
      }
      const formattedBalance = lease.balance !== undefined ? commaNumber(lease.balance) : '';
      return {
        values: [
          { text: leaseName || '' },
          { text: this.createTenantNames(lease.tenants || []) },
          { text: leaseStatusString(lease.status) },
          { text: leasePeriodString(lease) },
          { text: lease.pastDueDays ? t('{count} Days', { count: lease.pastDueDays }) : '-', horizontalAlignment: Alignment.end },
          { text: formattedBalance, horizontalAlignment: Alignment.end },
        ],
      };
    });
    if (entries.length > 0) {
      entries[entries.length - 1].hasBottomLine = true;
    }
    return entries;
  };

  createTenantNames = (tenants: HydratedLeaseTenantDto[]): string => {
    if (!tenants || tenants.length === 0) return '';
    const tenantNames = tenants.map((tenant) => `${tenant.tenant.firstName || ''} ${tenant.tenant.lastName || ''}`.trim());
    return tenantNames.join(', ');
  };
}
