import { useNavigate } from '@solidjs/router';
import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import { PrintPageHeader } from '~/components/print';
import { IconLoader } from '~/components/ui';
import { useCompanies, useLocalization } from '~/contexts/global';
import { useReports } from '~/contexts/local';
import { percentFormat } from '~/utils/number';
import { ReportTable, parseColumns } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';

const PropertyDirectroyReport: Component<{ class?: string; report?: MagicDoor.Api.OwnerDirectoryReportDto }> = (props) => {
  const { t } = useLocalization();

  const columns = [
    {
      name: 'Owner email',
      id: 'email',
      type: 'string' as const,
    },
    {
      name: 'Property name',
      id: 'propertyName',
      type: 'string' as const,
    },
    {
      name: 'Property addres',
      id: 'propertyAddres',
      type: 'string' as const,
    },
    {
      name: 'Property unitCount',
      id: 'propertyUnitCount',
      type: 'string' as const,
    },
    {
      name: 'OwnershipPercentage',
      id: 'ownershipPercentage',
      type: 'string' as const,
    },
  ];

  const lines = () => {
    const res: LineItem[] = [];

    props?.report?.items?.forEach((item) => {
      res.push(
        ...[
          {
            label: item?.owner?.firstName + ' ' + (item?.owner?.lastName ?? ''),
            level: 0,
            values: {
              email: item?.owner?.email,
            },
          },
          {
            label: t('Properties'),
            level: 1,
          },
          ...(item?.properties?.map((propertyItem) => {
            const { property, ownerProperty } = propertyItem;
            return {
              label: '',
              level: 2,
              values: {
                ownershipPercentage: percentFormat(ownerProperty?.ownershipPercentage),
                propertyName: property?.name,
                propertyAddres:
                  property?.address?.streetAddress1 +
                  ', ' +
                  property?.address?.city +
                  ', ' +
                  property?.address?.state +
                  ' ' +
                  property?.address?.zipCode,
                propertyUnitCount: property?.unitCount,
              },
            };
          }) || []),
        ]
      );
    });
    return res;
  };

  return <ReportTable label={t('Owner name')} lines={lines()} columns={parseColumns(columns)} />;
};

export const OwnerDirectoryReportPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { companies } = useCompanies();

  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();

  const { getOwnerDirectoryReport, ownerDirectoryReport } = useReports();

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Owner directory') }]);

  createEffect(() => {
    getOwnerDirectoryReport({});
  });

  const printHeader = createMemo(() => {
    return (
      <div>
        <PrintPageHeader companies={companies()} />
        <h1 class="py-6 text-center text-xl font-semibold text-title-gray">{t('Owner directory report')}</h1>
      </div>
    );
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />

      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="mb-6 flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Owner directory report')}</h1>

          <PrintButton
            color="primary"
            variant="outlined"
            disabled={ownerDirectoryReport.loading}
            printContainer={printContainer()!}
            isReport={true}
            printWrapClass="w-auto"
            printHeader={printHeader() as Element}
            extraStyle="zoom:0.5"
          />
        </div>

        <div ref={(e) => setPrintContainer(e)}>
          <Show when={ownerDirectoryReport.loading} fallback={<PropertyDirectroyReport report={ownerDirectoryReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </>
  );
};
