import { useParams } from '@solidjs/router';
import { Button } from '~/components/common/Buttons';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';

const BankTransactionsTable = () => {
  const { bankAccountId } = useParams();
  const { t } = useLocalization();

  return (
    <Panel title={t('Bank report')}>
      <div class="flex p-4">
        <Button href={`/reports/bank-account-activity?bankAccountId=${bankAccountId}`}>{t('View bank report')}</Button>
      </div>
    </Panel>
  );
};

export default BankTransactionsTable;
