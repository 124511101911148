import { Route, Routes } from '@solidjs/router';
import { AttachmentsProvider } from '~/contexts/local';
import { AddLeaseTemplateCategoryModal } from './components/AddLeaseTemplateCategoryModal';
import { DeleteLeaseTemplateCategoryModal } from './components/DeleteLeaseTemplateCategoryModal';
import { LeaseDocumentDraftCreator } from './lease-document-draft-creator/LeaseDocumentDraftCreator';
import { LeaseTemplateDetails } from './lease-template-details/LeaseTemplateDetails';
import { ListLeaseTemplate } from './list-lease-template/ListLeaseTemplate';

export const LeaseTemplateRoutes = () => {
  return (
    <AttachmentsProvider>
      <Routes>
        <Route path="/" component={ListLeaseTemplate} />
        <Route path="/create-lease-draft/:leaseId" component={LeaseDocumentDraftCreator} />
        <Route path="/add-category" component={AddLeaseTemplateCategoryModal} />
        <Route path="/:templateId" component={LeaseTemplateDetails} />
        <Route path="/edit-category/:categoryId" component={AddLeaseTemplateCategoryModal} />
        <Route path="/delete-category/:categoryId" component={DeleteLeaseTemplateCategoryModal} />
      </Routes>
    </AttachmentsProvider>
  );
};
