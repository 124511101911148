import { createEffect, createSignal, onCleanup, onMount } from 'solid-js';
import { PdfReportPresenter } from '~/pdfsigner/presenters/reports/PdfReportPresenter';
import { DeletePdfReportUseCase } from '~/pdfsigner/usecases/reports/deletePdfReportUseCase';
import { PrintReportUseCase } from '~/pdfsigner/usecases/reports/printReportUseCase';
import usePresenter from './usePresenter';
import useUseCase from './useUseCase';
import type { ReportDocument } from '~/pdfsigner/usecases/types/reportDocument';

export function useReportPrinter() {
  const { execute: printReport } = useUseCase(PrintReportUseCase);
  const { execute: deleteReport } = useUseCase(DeletePdfReportUseCase);
  const { model: reportModel } = usePresenter(PdfReportPresenter);

  const [didPrintReport, setDidPrintReport] = createSignal(false);
  const [isPrinting, setIsPrinting] = createSignal(false);

  let iframe: HTMLIFrameElement | undefined;
  let url: string | undefined;

  const printPdf = (pdf: Blob | undefined) => {
    if (!pdf || !didPrintReport()) {
      return;
    }
    const url = URL.createObjectURL(pdf);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    iframe.src = url;
    iframe.onload = function () {
      try {
        iframe.contentWindow?.print();
      } catch (error) {
        console.error('Error printing the PDF:', error);
      }
    };
  };

  const saveAsCSV = (reportDocument?: ReportDocument) => {
    if (!reportDocument) {
      return;
    }
    const csvContent = convertReportDocumentToCSV(reportDocument);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', reportDocument.header?.title + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  function convertReportDocumentToCSV(reportDocument: ReportDocument): string {
    let csvContent = '';
    reportDocument.sections.forEach((section) => {
      if (section.header && section.header.values) {
        csvContent += `${section.header.values.map((header) => escapeCsvValue(header.text)).join(',')}\n`;
      }
      section.entries?.forEach((entry) => {
        const row = entry.values?.map((value) => [escapeCsvValue(value.text)]);
        csvContent += row?.join(',') + '\n';
      });
    });
    return csvContent;
  }

  function escapeCsvValue(value: string): string {
    if (value.includes(',') || value.includes('"') || value.includes('\n')) {
      // Escape double quotes by doubling them
      value = value.replace(/"/g, '""');
      // Wrap the value in double quotes
      return `"${value}"`;
    }
    return value;
  }

  const resetPrintSystem = () => {
    setDidPrintReport(false);
    if (iframe) {
      document.body.removeChild(iframe);
    }
    if (url) {
      URL.revokeObjectURL(url);
    }
    deleteReport();
  };

  createEffect(() => {
    const pdf = reportModel()?.pdf;
    if (pdf) {
      printPdf(pdf);
    }
  });

  onMount(() => {
    resetPrintSystem();
  });

  onCleanup(() => {
    resetPrintSystem();
  });

  const print = async (report?: ReportDocument) => {
    setIsPrinting(true);
    try {
      if (!report) {
        return;
      }
      setDidPrintReport(true);
      await printReport(report);
    } finally {
      setIsPrinting(false);
    }
  };

  return { print, isPrinting, saveAsCSV };
}
