import { Show } from 'solid-js';
import { useLocalization } from '~/contexts/global';

export const ListingIs = (props: { listed: string | boolean | undefined | null }) => {
  const { t } = useLocalization();
  return (
    <>
      <Show when={!props.listed}>
        <div class="rounded-xl bg-[#FFF4D7] px-2 py-1 text-xs text-light-warning">{t('Unlisted')}</div>
      </Show>
      <Show when={props.listed}>
        <div class="rounded-xl bg-green-level01 px-2 py-1 text-xs text-light-green">{t('Listed')}</div>
      </Show>
    </>
  );
};
