import { Show, onMount } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { Empty } from '~/components/common/Empty';
import { LeasePeriod, LeaseStatus, LeaseTitle } from '~/components/leases';
import { Html, ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeasesList } from '~/contexts/local';
import { useLeaseFilter, useLocalPagination } from '~/hooks';
import { QuickLeaseNotifyBalanceDue } from '~/pages/leases/quick-lease-notify-balance-due';
import { diffDates } from '~/utils/date';
import { IconLeasesOverviewHeader } from './assets';
import type { Component } from 'solid-js';
export const LeaseRenewal: Component = () => {
  const { t } = useLocalization();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('LeaseRenewalListView');
  const { leases, getExpiringLeases, loading, pagination } = useLeasesList();
  const { filterValue, filterItems, parseFilter, handleFilterReset } = useLeaseFilter({ status: false });

  const calculateDaysUntilExpiration = (endDate: string): number => {
    const end = new Date(endDate);
    const today = new Date();
    const timeDiff = end.getTime() - today.getTime();
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  };

  onMount(() => getExpiringLeases({ page: page(), pageSize: pageSize() }));

  return (
    <ListView
      onChange={({ page, pageSize, search, filter }) => {
        setPage(page);
        setPageSize(pageSize);
        getExpiringLeases({ page, pageSize, search, ...parseFilter(filter) });
      }}
      headerClass="relative bg-gradient-to-br from-[#ee3964] to-[#ec4494] text-white [&>h2]:flex [&>h2]:gap-6 [&_[data-badges]_span]:bg-white [&_[data-badges]_span]:text-danger [&_[data-filter]_button]:border-white [&_[data-filter]_button]:bg-transparent [&_[data-filter]_button]:text-white [&_[data-search]]:border-white [&_[data-search]]:bg-transparent [&_[data-search]]:text-white [&_[data-search]_input]:placeholder:text-light-gray"
      title={
        <>
          <span class="text-sm font-semibold md:text-lg">{t('Expiring leases')}</span>

          <IconLeasesOverviewHeader class="absolute inset-0 left-1/2 h-full" />
        </>
      }
      searchPlaceholder={t('Search leases')}
      totalPages={pagination()?.totalPages}
      page={pagination()?.current}
      pageSize={pagination()?.pageSize}
      filterValue={filterValue()}
      filterItems={filterItems()}
      onFilterReset={handleFilterReset}
      rowLink={(item) => `/leasing/leases/${item.id}`}
      skeletonSize={10}
      loading={loading()}
      data={leases().filter((item) => calculateDaysUntilExpiration(item.end || ''))}
      empty={<Empty description={t('No lease expiring')} />}
      columns={[
        {
          title: t('Lease'),
          render: (item) => <LeaseTitle lease={item} />,
        },
        {
          title: t('Lease term'),
          headerClass: 'w-56',
          render: (item) => (
            <>
              <LeaseStatus lease={item} />
              <LeasePeriod lease={item} />
            </>
          ),
        },
        {
          title: t('Expiring in'),
          headerClass: 'w-36',
          render: (item) => (
            <div class="text-xs font-semibold text-danger md:text-sm">
              {calculateDaysUntilExpiration(item.end || '')} {t('days')}
            </div>
          ),
        },
        {
          title: t('Balance'),
          headerClass: 'w-36 text-right',
          class: 'text-right',
          render: (item) => <CnMoney money={item.balance} />,
        },
        {
          title: t('Posted balance'),
          headerClass: 'w-36 text-right',
          class: 'text-right',
          render: (item) => <CnMoney money={item.postedBalance} />,
        },
        {
          title: t('Operation'),
          headerClass: 'w-72 text-right',
          class: '',
          render: (item) => (
            <div class="relative z-10 flex justify-end gap-3">
              <Show when={item.balance > 0 && diffDates(item.end, item.earliestUnpaidBillDueDate)}>
                <QuickLeaseNotifyBalanceDue targets={[item.id]} />
              </Show>

              <LinkButton
                href={`/leasing/leases/${item.id}/transactions/add-charge`}
                variant="outlined"
                rounded="full"
                class="border-link px-3 py-1 text-xs text-link hover:bg-link/10 [&_span]:whitespace-nowrap">
                {t('Post a charge')}
              </LinkButton>
            </div>
          ),
        },
      ]}
      footerSummary={
        <Html as="p" class="flex-1 text-xs text-text-level03 [&>b]:mx-1 [&>b]:font-bold [&>b]:text-essential-colour">
          {t('A total of <b>{count}</b> related lease contracts', {
            count: loading() ? '-' : pagination().total.toString(),
          })}
        </Html>
      }
    />
  );
};
