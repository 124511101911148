import { Route, Routes, useParams } from '@solidjs/router';
import { NotesTableList } from '~/components/notes';
import { NoteModal } from '~/components/notes/NoteModal';

export const VendorNotes = () => {
  const params = useParams();
  return (
    <>
      <Routes>
        <Route path="/add" element={<NoteModal type="vendor" id={params.vendorId} action="Add" />} />
        <Route path="/:noteId/edit" element={<NoteModal type="vendor" id={params.vendorId} action="Edit" />} />
      </Routes>
      <NotesTableList type="vendor" id={params.vendorId} />
    </>
  );
};
