import { A } from '@solidjs/router';
import { splitProps, Show, For } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import { DeactivatedFlag } from '~/components/common';
import { Avatar } from '~/components/common/Avatar';
import { pickProps, TitleWithFeedbackIcon } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import { cn } from '~/utils/classnames';
import type { TitleWithFeedbackIconProps } from '~/components/common/FeedbackIcon/TitleWithFeedbackIcon';
import type { CustomLink } from '~/types/display';
import type { PolymorphicComponent } from '~/utils/types';

export const VendorTitle: PolymorphicComponent<
  'div',
  {
    class?: string;
    vendor?: MagicDoor.Api.HydratedVendorDto;
  } & Partial<TitleWithFeedbackIconProps> & {
      customLinks?: CustomLink[];
      avatarSize?: 'large' | 'default' | 'small';
    }
> = (props) => {
  const [, rest] = splitProps(props, ['as', 'class', 'vendor']);

  return (
    <TitleWithFeedbackIcon {...pickProps(rest)} type="tenants" entityId={props.tenant?.id} initalSelectedIcons={props.tenant?.icons}>
      <Dynamic component={props.as || 'div'} class={cn('flex items-center gap-2', props.class)} {...rest}>
        <div class="flex items-center gap-3">
          <Avatar name={props.vendor?.name ?? ''} data-slot="avatar" class="size-9 shrink-0" size={props.avatarSize} />
          <div class=" flex flex-col space-y-1 text-sm">
            <span data-slot="name" class="line-clamp-2 space-y-0.5 text-left text-sm font-medium">
              {props.vendor?.name}
            </span>
            <DeactivatedFlag active={props.tenant?.active} />

            <Show when={props.customLinks && props.customLinks.length > 0}>
              <div class="flex flex-wrap items-start text-xs">
                <For each={props.customLinks}>
                  {(link, index) => (
                    <>
                      <A href={link.href} class="text-primary hover:underline">
                        {link.displayName}
                      </A>
                      <Show when={index() < props.customLinks!.length - 1}>
                        <span class="mx-2  border-r border-partingline">&nbsp;</span>
                      </Show>
                    </>
                  )}
                </For>
              </div>
            </Show>
          </div>
        </div>
      </Dynamic>
    </TitleWithFeedbackIcon>
  );
};
