import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const NOTES_URL = '/api/notes';

export type NotesFilter = {
  page?: number;
  pageSize?: number;
  entityType: string;
  entityId: string;
};
export type NoteParams = {
  entityType: string;
  entityId: string;
};
export type AddNote = MagicDoor.Api.CreateOrUpdateNoteDto & NoteParams;
export type UpdateNote = MagicDoor.Api.CreateOrUpdateNoteDto & NoteParams & { noteId: string };
export type NoteEntity = {
  entityType: string;
  entityId: string;
  noteId: string;
};

export class NotesRepository extends BaseRestRepository {
  public async getNoteLists(filter: NotesFilter): Promise<MagicDoor.Api.NoteListDtoPaginationDto> {
    const url = urlWithQuery(`${NOTES_URL}/${filter.entityType}/${filter.entityId}/notes`, {
      page: filter.page,
      pageSize: filter.pageSize,
    });
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
  public async getNote(note: NoteEntity): Promise<MagicDoor.Api.NoteDto> {
    const url = `${NOTES_URL}/${note.entityType}/${note.entityId}/notes/${note.noteId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }
  public async addNote(note: AddNote): Promise<MagicDoor.Api.NoteDto> {
    const url = `${NOTES_URL}/${note.entityType}/${note.entityId}/notes`;
    const response = await this.fetchWithAuth(
      url,
      {
        method: 'POST',
        body: JSON.stringify({ content: note.content }),
      },
      false
    );
    return this.getJsonResponse(response);
  }
  public async editNote(note: UpdateNote): Promise<MagicDoor.Api.NoteDto> {
    const url = `${NOTES_URL}/${note.entityType}/${note.entityId}/${note.noteId}`;
    const response = await this.fetchWithAuth(
      url,
      {
        method: 'PUT',
        body: JSON.stringify({ content: note.content }),
      },
      false
    );
    return this.getJsonResponse(response);
  }
  public async deleteNote(note: NoteEntity): Promise<void> {
    const url = `${NOTES_URL}/${note.entityType}/${note.entityId}/${note.noteId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }
}
