import { Route, Routes } from '@solidjs/router';
import { ChatsListProvider } from '~/contexts/local';
import { ArchiveMessageDetailView } from './archive-message-detail';
import { ArchiveMessagesListView } from './archive-messages-list';

const ArchiveMessagesRoutes = () => {
  return (
    <Routes>
      <Route path="/:chatId" component={ArchiveMessageDetailView} />
      <Route
        path="/*"
        element={
          <ChatsListProvider>
            <ArchiveMessagesListView />
          </ChatsListProvider>
        }
      />
    </Routes>
  );
};

export default ArchiveMessagesRoutes;
