import { useParams } from '@solidjs/router';
import { usePortfolios } from '~/contexts/global';
import { AnnouncementProvider } from '~/contexts/local';
import AnnouncementsListView from '~/pages/announcements/list-announcements/AnnouncementsListView';

export const PortfolioAnnouncement = () => {
  const params = useParams();
  const { current } = usePortfolios();

  return (
    <AnnouncementProvider>
      <div class="flex w-full gap-5 text-sm">
        <AnnouncementsListView
          filter={{ portfolioIds: [current()?.id || params.portfolioId] }}
          newAnnouncementsParams={{
            portfolioId: current()?.id || params.portfolioId,
            targetType: 'lease',
            selectionType: 'portfolio',
            name: current()?.name || '',
          }}
        />
      </div>
    </AnnouncementProvider>
  );
};
