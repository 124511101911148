import { splitProps } from 'solid-js';
import { LabeledGroup } from '~/components/common/Inputs';
import { SearchSelect } from '~/components/ui';
import { searchSelectValuePropNames } from '~/components/ui/Select/SearchSelect';
import { useLocalization } from '~/contexts/global';
import { LeaseRepository } from '~/repositories/leaseRepository';
import { LeaseTitle } from './LeaseTitle';
import { getLeaseFullname, leasePeriodString } from './utils';
import type { LabeledGroupProps } from '~/components/common/Inputs';
import type { EntrySearchProps } from '~/components/ui';
import type { LeaseListFilter } from '~/repositories/leaseRepository';

const repo = new LeaseRepository();

export const LeaseSearch = <T extends boolean | undefined = undefined>(
  props: EntrySearchProps<MagicDoor.Api.HydratedLeaseDto, T, LeaseListFilter>
) => {
  const { t } = useLocalization();

  const initialSelectedLoader = async (ids: string[]) => {
    return await repo.batchGetLeasesByIds(ids);
  };

  return (
    <SearchSelect
      placeholder={t('Search leases')}
      fetcher={(query: string | undefined, page: number) => {
        return repo.getLeases({ pageSize: 20, ...props.filter, page, search: query || undefined });
      }}
      renderSelected={(item) => {
        const name = getLeaseFullname(item);
        const period = (item.status === 'monthToMonth' ? `${t('Month-To-Month')}: ` : '') + leasePeriodString(item);
        return `${name} (${period})`;
      }}
      renderItem={(item) => <LeaseTitle feedbackIconReadonly showTenants lease={item} />}
      initialSelectedLoader={initialSelectedLoader}
      {...props}
    />
  );
};

export const LabeledLeaseSearch = <T extends boolean | undefined = undefined>(
  props: Omit<EntrySearchProps<MagicDoor.Api.HydratedLeaseDto, T>, 'onSelect'> &
    LabeledGroupProps & {
      value?: T extends true ? string[] : string;
      onInput?: (
        value: T extends true ? string[] : string,
        selected: T extends true ? MagicDoor.Api.HydratedLeaseDto[] : MagicDoor.Api.HydratedLeaseDto
      ) => void;
      filter?: LeaseListFilter;
    }
) => {
  const [searchProps, labeledProps] = splitProps(props, searchSelectValuePropNames);

  // TODO: get entries by ids???

  return (
    <LabeledGroup {...labeledProps}>
      <LeaseSearch
        {...searchProps}
        onSelect={(selected) => {
          props.onInput?.(
            (Array.isArray(selected) ? selected.map((t) => t.id) : selected.id) as T extends true ? string[] : string,
            selected
          );
        }}
      />
    </LabeledGroup>
  );
};
