import { createEffect, createMemo, For, Show, useContext } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import IconAdd from '~/assets/images/common/iconAdd.svg?component-solid';
import IconSmile from '~/assets/images/common/iconSmile.svg?component-solid';
import IconTag from '~/assets/images/common/iconTag.svg?component-solid';
import IconWrench from '~/assets/images/common/iconWrench.svg?component-solid';
import { Footer } from '~/components/common/FeedbackIcon/Footer';
import { FeedbackGroupContext } from '~/components/common/FeedbackIcon/Group';
import { Header } from '~/components/common/FeedbackIcon/Header';
import { iconsMap, IconType, unicodeToEscapeSequence } from '~/components/common/FeedbackIcon/utils';
import Tooltip from '~/components/common/Tooltip';
import { Popover, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { FeedbackIconRepository } from '~/repositories/feedbackIconRepository';
import { cn } from '~/utils/classnames';
import type { BackendEntitiesEnum } from '~/utils/constant';

const feedbackIconRepo = new FeedbackIconRepository();

export type FeedbackIconProps = {
  initalSelectedIcons?: string[];
  type: BackendEntitiesEnum[number];
  entityId?: string;
  onSelect?: (icon: string) => void;
  class?: string;
  theme?: 'main' | 'white';
  maxPreviewIcons?: number;
  readOnly?: boolean;
  alwaysShow?: boolean;
};

export const FeedbackIcon = (props: FeedbackIconProps) => {
  const { t } = useLocalization();
  const [store, setStore] = createStore<{
    activeKey: IconType;
    innerSelectedIcons: string[];
  }>({
    activeKey: IconType.FACE,
    innerSelectedIcons: [],
  });

  const currentMaps = createMemo(() => iconsMap[store.activeKey as keyof typeof iconsMap]);
  const isUnderFeedbackGroup = useContext(FeedbackGroupContext);
  const forbidden = createMemo(() => !props.entityId || props.readOnly);
  const classNamesByContext = (isOpen: boolean) => {
    if (forbidden()) {
      return 'invisible';
    }

    return {
      'invisible group-hover:visible': !props.alwaysShow && isUnderFeedbackGroup,
      visible: isOpen || props.alwaysShow,
    };
  };

  createEffect(() => {
    if (props.initalSelectedIcons) {
      setStore({ innerSelectedIcons: props.initalSelectedIcons });
    }
  });

  function onAddIcon(icon: string) {
    if (forbidden()) return;
    if (isIconMatch(icon)) {
      return;
    }

    if (store.innerSelectedIcons.length >= 5) {
      toast(t('You can only select up to 5 icons'), 'normal');
      return;
    }

    feedbackIconRepo.setIcon(props.type, props.entityId!, icon);
    setStore(
      produce((state) => {
        state.innerSelectedIcons.push(icon);
      })
    );
  }

  function onDeleteIcon(icon: string) {
    if (forbidden()) return;
    feedbackIconRepo.deleteIcon(props.type, props.entityId!, icon);
    setStore(
      produce((state) => {
        state.innerSelectedIcons.splice(state.innerSelectedIcons.indexOf(icon), 1);
      })
    );
  }

  function isIconMatch(icon: string) {
    return store.innerSelectedIcons.findIndex((selectedIcon) => selectedIcon.toLowerCase() === icon.toLowerCase()) !== -1;
  }

  const colors = createMemo(() => {
    if (props.theme === 'white') {
      return {
        text: '#fff',
        hover: '#A126EC',
      };
    }
    return {
      text: '#A126EC',
      hover: '#fff',
    };
  });

  const previewIcons = createMemo(() => {
    return store.innerSelectedIcons.slice(0, props.maxPreviewIcons || 5);
  });

  const totalIconsLabel = createMemo(() => {
    if (!props.maxPreviewIcons || store.innerSelectedIcons.length <= props.maxPreviewIcons) return '';
    return `+${store.innerSelectedIcons.length - props.maxPreviewIcons}`;
  });

  const renderIcons = (icons: string[]) => {
    return (
      <div class="flex">
        <For each={icons}>
          {(icon) => (
            <div class="relative flex cursor-pointer items-center justify-center rounded-full border-[color:var(--text)]">
              <emoji-regular class="text-sm">{unicodeToEscapeSequence(icon)}</emoji-regular>
            </div>
          )}
        </For>
      </div>
    );
  };

  return (
    <Popover
      class="relative"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}>
      {({ setOpen, open }) => (
        <>
          <div
            class="flex items-center gap-1"
            style={{
              '--text': colors().text,
              '--hover': colors().hover,
            }}>
            <Show when={previewIcons().length > 0}>
              <Tooltip
                disabled={!totalIconsLabel()}
                message={<div class="flex flex-nowrap">{renderIcons(store.innerSelectedIcons)}</div>}
                align="bottom">
                {renderIcons(previewIcons())}
              </Tooltip>
            </Show>
            <div
              onClick={() => {
                setOpen(true);
              }}
              class={cn(
                'box-content inline-flex size-6 cursor-pointer items-center justify-center rounded-full border border-[color:var(--text)] text-[color:var(--text)] hover:bg-[color:var(--text)] hover:text-[color:var(--hover)]',
                classNamesByContext(open()),

                props.class
              )}>
              <IconAdd />
            </div>
          </div>

          <Popover.Content>
            <div class="noto-color rounded bg-white shadow">
              <Header
                items={[
                  {
                    title: <IconSmile />,
                    key: IconType.FACE,
                  },
                  {
                    title: <IconWrench />,
                    key: IconType.FUNCTIONAL,
                  },
                  {
                    title: <IconTag />,
                    key: IconType.LABEL,
                  },
                ]}
                activeKey={store.activeKey}
                onChange={(key) => {
                  setStore({ activeKey: key });
                }}
              />
              <div class="thinscroll grid max-h-72 grid-cols-7 gap-1 overflow-y-auto p-4">
                <For each={currentMaps()}>
                  {(icon) => (
                    <div class="flex items-center justify-center">
                      <div
                        class={cn(
                          'flex size-9 cursor-pointer items-center justify-center rounded-full border-essential-colour hover:border',
                          {
                            'bg-essential-colour': isIconMatch(icon.label),
                          }
                        )}
                        onClick={() => {
                          onAddIcon(icon.label);
                        }}>
                        <emoji-regular class="text-xl">{unicodeToEscapeSequence(icon.unicode)}</emoji-regular>
                      </div>
                    </div>
                  )}
                </For>
              </div>
              <Footer icons={store.innerSelectedIcons} onDelete={onDeleteIcon} />
            </div>
          </Popover.Content>
        </>
      )}
    </Popover>
  );
};
