import { createSignal, For, onMount } from 'solid-js';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { LeaseOverviewBanner } from '~/components/leases';
import { Modal } from '~/components/modals';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useLeaseRentTransactions } from '~/contexts/local';
import { HttpError } from '~/errors';
import { MAX_INPUT_AMOUNT } from '~/utils/constant';

export interface LeaseBillUpdateModalProps {
  lease: MagicDoor.Api.HydratedLeaseDto;
  visible: boolean;
  transactionDetail?: MagicDoor.Api.HydratedLeaseTransactionDto;
  onCancel: () => void;
  onSuccess: () => void;
}

export const LeaseBillUpdateModal = (props: LeaseBillUpdateModalProps) => {
  const { t } = useLocalization();
  const form = useForm();
  const [isLoading, setIsLoading] = createSignal(false);
  const { updateBill } = useLeaseRentTransactions();

  const onSubmit = async () => {
    let hasError = false;

    if (!props.transactionDetail?.bill?.id) {
      return;
    }

    const { validateStatus } = form.validateForm();

    if (!validateStatus) {
      return;
    }

    try {
      const { billDate, dueDate, memo, reference, chartOfAccountId, amount, description } = form.formStore;

      setIsLoading(true);
      await updateBill(props.transactionDetail?.bill?.id as string, {
        billDate,
        dueDate,
        memo,
        reference,
        lineItems: [{ chartOfAccountId, amount, description }],
      });
      toast.success(t('Bill has been updated successfully'));
    } catch (error) {
      const errors = error instanceof HttpError && error.getErrors();
      const messages = errors ? (
        <For each={Object.values(errors)}>{(message) => <p>{message as string}</p>}</For>
      ) : (
        t('Operation failed, please try again later')
      );

      hasError = true;
      toast.error(messages);
    } finally {
      setIsLoading(false);

      if (!hasError) {
        props.onCancel();
        props.onSuccess();
      }
    }
  };

  onMount(() => {
    if (!props.transactionDetail) {
      return;
    }

    const { bill } = props.transactionDetail;
    const { lineItems = [], billDate, dueDate, memo, reference } = bill ?? {};

    form.setFieldsValue({
      billDate,
      dueDate,
      memo,
      reference,
      chartOfAccountId: lineItems[0]?.chartOfAccount?.id,
      amount: lineItems[0]?.amount,
      description: lineItems[0]?.description,
    });
  });

  return (
    <Modal title={t('Update Bill')} loading={isLoading()} visible={props.visible} onDone={onSubmit} onCancel={props.onCancel}>
      <div class="px-8 py-6">
        <LeaseOverviewBanner lease={props.lease} />
        <Form class="grid grid-cols-2 gap-4 pt-6" defaultForm={form}>
          <FormItem
            class="col-span-1 !mb-0"
            label={t('Bill Date')}
            formFieldName="billDate"
            placeholder={t('Please select the bill date')}
            component={DueDateInputField}
          />
          <FormItem
            class="col-span-1 !mb-0"
            label={t('Due Date')}
            formFieldName="dueDate"
            required
            rules={[{ message: t('Please select the due date'), required: true }]}
            placeholder={t('Please select the due date')}
            component={DueDateInputField}
          />
          <FormItem
            class="col-span-full"
            label={t('Memo')}
            formFieldName="memo"
            maxlength={1000}
            placeholder={t('Please input the memo')}
            component={LabeledTextInput}
          />
          <FormItem
            class="col-span-full"
            label={t('Reference')}
            formFieldName="reference"
            maxlength={1000}
            placeholder={t('Please input the reference')}
            component={LabeledTextInput}
          />
          <FormItem
            class="col-span-1"
            label={t('Chart of account')}
            formFieldName="chartOfAccountId"
            rules={[{ message: t(`Please select chart of account`), required: true }]}
            component={LabeledChartOfAccountSelect}
          />
          <FormItem
            class="col-span-1"
            label={t('Amount')}
            formFieldName="amount"
            prepend="$"
            rules={[
              { required: true, message: t('Please input the amount') },
              {
                type: 'number',
                range: [1, MAX_INPUT_AMOUNT],
                message: t('{name} must be between {min} and {max}', { name: t('Amount'), min: '1', max: MAX_INPUT_AMOUNT }),
              },
            ]}
            component={LabeledNumberInput}
          />
          <FormItem
            class="col-span-full"
            label={t('Description')}
            formFieldName="description"
            maxlength={1000}
            placeholder={t('Please input description')}
            component={LabeledTextInput}
          />
        </Form>
      </div>
    </Modal>
  );
};
