import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

function validateId(ownerId: string): void {
  if (!ownerId) throw new ValidationError(`Owner ID must be provided`);
}
export type OwnersFilter = MagicDoor.UserFilter<{
  propertyIds?: string[];
}>;

export type OwnersTransfersFilter = {
  page?: number;
  pageSize?: number;
  ownerId?: string;
  portfolioId?: string;
  propertyId?: string;
  bankAccountId?: string;
  type: 'contribution' | 'distribution';
  'CreatedRange.Start'?: string;
  'CreatedRange.End'?: string;
};

const BASE_URL = '/api/owners';
export class OwnersRepository extends BaseRestRepository {
  public async getOwners(filter: OwnersFilter): Promise<MagicDoor.Api.PaginatedOwnerListDtoPaginationDto> {
    const url = urlWithQuery(BASE_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getOwner(ownerId: string): Promise<MagicDoor.Api.OwnerDto> {
    validateId(ownerId);
    const url = `${BASE_URL}/${ownerId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async addOwner(payload: MagicDoor.Api.UpdateOwnerDto): Promise<MagicDoor.Api.OwnerCreatedDto> {
    const url = `${BASE_URL}`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async addOwnerContribution(
    payload: MagicDoor.Api.RegisterOwnerContributionDto,
    ownerId: string
  ): Promise<MagicDoor.Api.HydratedBillDto> {
    const url = `${BASE_URL}/transfers/${ownerId}/contributions`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
  public async getOwnersDistributionsValidation(
    payload: MagicDoor.Api.DistributionValidatePropertiesDto
  ): Promise<MagicDoor.Api.DistributionValidationDto> {
    const url = `${BASE_URL}/transfers/distributions/validate`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getOwnersDistributionsCalculation(
    payload: MagicDoor.Api.DistributionValidatePropertiesDto
  ): Promise<MagicDoor.Api.CalculatedDistributionsForPropertiesDto> {
    const url = `${BASE_URL}/transfers/distributions/calculate`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async submitOwnerDistributions(
    payload: MagicDoor.Api.CreateOwnerDistributionsDto
  ): Promise<MagicDoor.Api.OwnerDistributionsResultDto> {
    const url = `${BASE_URL}/transfers/distributions`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async getOwnersTransfers(payload: OwnersTransfersFilter): Promise<MagicDoor.Api.OwnerTransferPaginatedDto> {
    const url = urlWithQuery(`${BASE_URL}/transfers`, payload);
    const response = await this.fetchWithAuth(url, { method: 'GET' });
    return this.getJsonResponse(response);
  }

  public async getOwnerTransfers(payload: OwnersTransfersFilter): Promise<MagicDoor.Api.OwnerTransferPaginatedDto> {
    const url = urlWithQuery(`${BASE_URL}/transfers`, payload);
    const response = await this.fetchWithAuth(url, { method: 'GET' });
    return this.getJsonResponse(response);
  }

  public async getOwnerTransferDetails(id: string): Promise<MagicDoor.Api.OwnerTransferPaginatedDto> {
    const url = `${BASE_URL}/transfers/${id}`;
    const response = await this.fetchWithAuth(url, { method: 'GET' });
    return this.getJsonResponse(response);
  }

  public async deleteOwnerTransfers(id: string): Promise<void> {
    const url = `${BASE_URL}/transfers/${id}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async postOwnerTransfers(payload: MagicDoor.Api.TransferOwnerFundsDto): Promise<any> {
    const url = `${BASE_URL}/transfers/transfer`;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }

  public async updateOwner(id: string, payload: MagicDoor.Api.UpdateOwnerDto): Promise<MagicDoor.Api.OwnerCreatedDto> {
    const url = `${BASE_URL}/${id}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(payload),
    });
    return this.getJsonResponse(response);
  }
}
