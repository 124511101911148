import { useNavigate, useParams, useSearchParams } from '@solidjs/router';
import { createMemo, createSignal, Show, onMount } from 'solid-js';
import IconPay from '~/assets/images/bill/pay.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import TextOverflow from '~/components/common/TextOverflow';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { PropertyTitle } from '~/components/properties';
import { Table, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBills } from '~/contexts/local';
import VendorBillSkeleton from '~/pages/vendors/vendor-details/view-components/skeleton/VendorBillSkeleton';
import { DeleteBillPaymentMethod } from '~/swagger/Api';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { TableColumns } from '~/components/ui';

const VendorBill = () => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const { vendorId } = useParams();
  const [searchParams] = useSearchParams();

  const { deleteBill, filtered, setFilter } = useBills();
  const propertyId = searchParams.propertyId;
  const fixedParams = { vendorId, propertyId };

  const [selectedBills, setSelectedBills] = createSignal<MagicDoor.Api.HydratedBillDto[]>([]);

  onMount(() => {
    setFilter({ ...fixedParams });
  });

  const handleAddBill = () => {
    navigate(`/accounting/bills/add?vendorId=${vendorId}`);
  };

  const calculatedTotal = createMemo(() => {
    const total = selectedBills().reduce((sum, bill) => sum + Number(bill.totalAmount), 0);
    return total || 0;
  });

  const columns: TableColumns<MagicDoor.Api.HydratedBillDto> = [
    { title: <div>{t('Due')}</div>, key: 'due', render: (item) => dateFormat('', item.dueDate) },
    {
      title: <div>{t('Memo')}</div>,
      key: 'memo',
      render: (item) => (
        <div class="w-60">
          <Show when={item.memo} fallback={<span class="text-xs text-text-level03">--</span>}>
            <TextOverflow text={item.memo as string} />
          </Show>
        </div>
      ),
    },
    {
      title: <div>{t('Property')}</div>,
      key: 'propertyId',
      render: (item) => <PropertyTitle property={item.property} />,
    },
    {
      title: <div>{t('Amount')}</div>,
      key: 'totalAmount',
      render: (item: any) => `${currency(item.totalAmount)}`,
    },
  ];

  const onDeleteBill = async (id: string) => {
    await deleteBill(id, DeleteBillPaymentMethod.AdjustTransaction);
    toast.success(t('{name} has been deleted successfully', { name: 'Bill' }));
  };

  const unpaidBills = createMemo(() => filtered()?.items.filter((x) => x.due) ?? []);

  const handlePaySelectedBills = async () => {
    const selectedPropertyIds = selectedBills().map((bill) => bill.propertyId);
    const selectedVendorIds = selectedBills().map((bill) => bill.vendorId);

    const queryParams = new URLSearchParams();
    selectedPropertyIds.forEach((id) => queryParams.append('propertyId', id ?? ''));
    selectedVendorIds.forEach((id) => queryParams.append('vendorId', id ?? ''));

    navigate(`/accounting/bills/pay?${queryParams.toString()}`);
  };

  return (
    <Show when={!filtered.loading} fallback={<VendorBillSkeleton />}>
      <ThreeSectionBox
        topTitle={<div>{t('Unpaid bills')}</div>}
        topAction={
          <div class="flex gap-2">
            <Button onClick={handleAddBill} variant="outlined">
              {t('Add a bill')}
            </Button>
          </div>
        }
        middle={
          <Show when={unpaidBills()}>
            <Table
              data={unpaidBills()}
              columns={columns}
              selected={selectedBills()}
              onSelect={(selected) => setSelectedBills(selected)}
              actions={(item) => [
                { icon: IconPencil, label: t('Edit bill'), onClick: () => navigate(`/accounting/bills/${item.id}/edit`) },
                {
                  icon: IconPay,
                  label: t('Pay bill'),
                  onClick: () => navigate(`/accounting/bills/pay?propertyId=${item.propertyId}&vendorId=${item.vendorId}`),
                },
                { icon: IconDelete, label: 'Delete', onClick: () => onDeleteBill(item.id) },
              ]}
              loading={filtered.loading}
              skeletonSize={3}
              onRowClick={(item) => navigate(`/accounting/bills/${item.id}`)}
            />
          </Show>
        }
        bottom={
          <Show when={unpaidBills().length}>
            <div class="flex justify-between border-t p-4">
              <div class="flex flex-col justify-center text-left">
                <Show
                  when={selectedBills().length}
                  fallback={<div class="py-3 text-left text-text-level03">{t('Bill not selected yet')}</div>}>
                  <span class="text-base font-semibold text-essential-colour"> {currency(calculatedTotal())}</span>
                  <span>
                    {selectedBills().length} {t('bills have been selected')}
                  </span>
                </Show>
              </div>
              <Show when={selectedBills().length > 0}>
                <Button onClick={handlePaySelectedBills} class="self-end">
                  {t('Pay selected bills')}
                </Button>
              </Show>
            </div>
          </Show>
        }
      />
    </Show>
  );
};

export default VendorBill;
