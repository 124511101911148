import { useNavigate, useSearchParams } from '@solidjs/router';
import { onMount, Show } from 'solid-js';
import IconPayBtn from '~/assets/images/bill/pay-2.svg?component-solid';
import IconPay from '~/assets/images/bill/pay.svg?component-solid';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconDelete from '~/assets/images/units/delete.svg?component-solid';
import IconPencil from '~/assets/images/units/lease/editLease.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import CnMoney from '~/components/common/CnMoney';
import { Empty } from '~/components/common/Empty';
import TextOverflow from '~/components/common/TextOverflow';
import { PropertyTitle } from '~/components/properties';
import { ListView, TableActions, toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBills } from '~/contexts/local';
import { useLocalPagination } from '~/hooks';
import { useLeaseFilter } from '~/hooks/useLeaseFilter';
import { DeleteBillPaymentMethod } from '~/swagger/Api';
import { emptyPlaceholder } from '~/utils/constant';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';
import type { Component } from 'solid-js';
import type { ListViewArgs } from '~/components/ui';

export const BillsListView: Component = () => {
  const navigate = useNavigate();
  const { t } = useLocalization();
  const [query] = useSearchParams();
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('BillsListView');
  const { deleteBill, filtered, setFilter, refetch } = useBills();
  const { filterItems, filterValue, handleFilterReset } = useLeaseFilter({
    status: false,
    date: false,
    defaultValue: { property: query.property },
  });

  const onDeleteBill = async (id: string) => {
    await deleteBill(id, DeleteBillPaymentMethod.AdjustTransaction);
    refetch();
    toast.success(t('{name} has been deleted successfully', { name: 'Bill' }));
  };

  const getChartOfAccountText = (lineItems: MagicDoor.Api.HydratedBillDto['lineItems']) => {
    return lineItems.map((item) => item.chartOfAccount.name).toString();
  };

  const getTotalAmount = (lineItems: MagicDoor.Api.HydratedBillDto['lineItems']) => {
    return lineItems.reduce((previous, current) => previous + current.amount, 0);
  };

  const onChange = ({ page, pageSize, search, filter }: ListViewArgs<any>) => {
    setPage(page);
    setPageSize(pageSize);
    let _filter = {};
    if (filter.portfolio) {
      _filter = { ..._filter, portfolioIdOr: [filter.portfolio] };
    }
    if (filter.property) {
      _filter = { ..._filter, propertyIdOr: [filter.property] };
    }
    setFilter({
      page,
      pageSize,
      searchText: search,
      ..._filter,
    });
  };

  onMount(() => {
    setFilter((prev) => ({ ...prev, page: page(), pageSize: pageSize() }));
  });

  return (
    <>
      <ListView
        onChange={onChange}
        title={t('Bills')}
        totalPages={filtered()?.totalPages}
        page={filtered()?.page}
        pageSize={filtered()?.pageSize}
        data={filtered()?.items}
        loading={filtered.loading}
        skeletonSize={10}
        filterValue={filterValue()}
        filterItems={filterItems()}
        onFilterReset={handleFilterReset}
        empty={<Empty description={t('No bill yet')} />}
        titleActions={
          <div class="flex gap-2">
            <Button href={'pay'} variant="outlined" class="font-medium capitalize">
              <IconPayBtn class="size-5 text-essential-colour" />
              {t('Pay bills')}
            </Button>
            <Button href={'add'} class="font-medium capitalize">
              <IconPlus class="size-5 text-white" />
              {t('Add a bill')}
            </Button>
          </div>
        }
        rowLink={(item) => `/accounting/bills/${item.id}`}
        columns={[
          {
            title: t('Date'),
            render: (item) => <div class="w-20">{dateFormat('', item.billDate)} </div>,
          },
          {
            title: t('Chart of account'),
            render: (item) => <div class="w-40 break-words">{getChartOfAccountText(item.lineItems)} </div>,
          },
          {
            title: t('Property'),
            render: (item) => (
              <>
                <Show when={item.property} fallback={<span class="text-xs capitalize text-text-level03">{t('Unknown property')}</span>}>
                  <PropertyTitle class="w-60" property={item.property} />
                </Show>
              </>
            ),
          },
          {
            title: t('Unit'),
            render: (item) => <div class="w-20">{item.unit?.name || emptyPlaceholder} </div>,
          },
          {
            title: t('Memo'),
            render: (item) => (
              <div class="w-60">
                <Show when={item.memo} fallback={<span class="text-xs text-text-level03">--</span>}>
                  <TextOverflow text={item.memo as string} />
                </Show>
              </div>
            ),
          },

          {
            title: t('Vendor'),
            render: (item) => (
              <div class="w-40">
                <Show when={item.vendor} fallback={<span class="text-xs capitalize text-text-level03">{t('Unknown vendor')}</span>}>
                  <div>{item.vendor?.name}</div>
                </Show>
              </div>
            ),
          },
          {
            title: t('Amount'),
            headerClass: 'text-right w-36',
            class: 'text-right',
            render: (item) => <div>{currency(getTotalAmount(item.lineItems))}</div>,
          },
          {
            title: t('Unpaid balance'),
            headerClass: 'text-right min-w-40 capitalize',
            class: 'text-right',
            render: (item) => <CnMoney money={item.due} />,
          },
          {
            headerClass: 'w-[6%]',
            class: 'text-sm',
            render: (item) => (
              <TableActions
                actions={[
                  {
                    label: t('Edit bill'),
                    icon: IconPencil,
                    onClick: () => {
                      navigate(`/accounting/bills/${item.id}/edit`);
                    },
                  },
                  {
                    label: t('Pay bill'),
                    icon: IconPay,
                    onClick: () => {
                      navigate(`/accounting/bills/pay?propertyId=${item.propertyId}&vendorId=${item.vendor?.id}`);
                    },
                  },
                  {
                    label: t('Delete'),
                    icon: IconDelete,
                    onClick: () => onDeleteBill(item.id),
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </>
  );
};
