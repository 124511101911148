import { Show } from 'solid-js';
import { QuickAction } from '~/components/rental-application/rental-application-details/renter-report-tab/applicant-summary/quick-actions/QuickAction';
import { useRentalApplication } from '~/contexts/global';
import { useNewRentalApplication } from '~/contexts/local';
import { AIRatingSummary } from './components/AiRatingBar';
import { ApplicantInformation } from './components/ApplicantInformation';
import { PaymentStatus } from './components/PaymentStatus';
import { TransunionReports } from './components/TransunionReports';

export const ApplicantSummary = () => {
  const { applicationStore } = useRentalApplication();
  const { settings } = useNewRentalApplication();

  return (
    <div class="text-xs text-text-level03">
      <div class="flex flex-wrap items-center justify-between gap-10">
        <ApplicantInformation />

        <Show when={applicationStore.application?.aiApplicationScore}>
          <AIRatingSummary />
        </Show>
      </div>

      <div class="mb-4 flex w-full flex-wrap gap-4">
        <Show when={applicationStore.application?.paymentStatus !== 'refunded'}>
          <QuickAction />
        </Show>

        <PaymentStatus />
      </div>

      <Show when={settings()?.requireScreening}>
        <TransunionReports />
      </Show>
    </div>
  );
};
