import { Presenter } from './Presenter';
import type { PdfState } from '~/pdfsigner/state/pdfAppState';
import type { PresentableLeaseDocumentSigners } from '~/pdfsigner/ui/types/presentableLeaseDocumentSigners';

export class LeaseDocumentSignersPresenter extends Presenter<PresentableLeaseDocumentSigners> {
  createModel(state: PdfState): PresentableLeaseDocumentSigners {
    return {
      tenants: state.leaseDocumentSigners.filter((signer) => !signer.isPropertyManager).sort((a, b) => a.name.localeCompare(b.name)),
      pms: state.leaseDocumentSigners.filter((signer) => signer.isPropertyManager).sort((a, b) => a.name.localeCompare(b.name)),
    };
  }
}
