import { createMemo, onMount } from 'solid-js';
import { createStore } from 'solid-js/store';
import { FileRepository } from '~/components/common/Upload/request';
import { getUid } from '~/components/common/Upload/uid';
import { DragAndDrop, toast } from '~/components/ui';
import { contextCreator } from '~/utils/contextCreator';
import type { ParentProps } from 'solid-js';
import type { HydrateFile, UploaderFile, UploadProps } from '~/components/common/Upload/Interface';

export const [UploadProvider, useUpload] = contextCreator('Upload', (props: UploadProps) => {
  const fileRepository = new FileRepository();

  const [store, setStore] = createStore<{ newFiles: UploaderFile[]; fileList: HydrateFile[] }>({ newFiles: [], fileList: [] });

  const removeFilesIfReachTheLimit = (files: File[]) => {
    const _files = (files as UploaderFile[]).map((file) => {
      file.uid = getUid();
      return file;
    });
    if (props.maxCount) {
      const delta = _files.length + store.newFiles.length - props.maxCount;

      if (delta > 0) {
        toast.error(`You can only upload ${props.maxCount} files`);
        return _files.slice(0, -delta);
      }
    }

    return _files;
  };

  const setFileList = async (files: FileList) => {
    const _files = removeFilesIfReachTheLimit(Array.from(files));

    setStore({
      newFiles: store.newFiles.concat(_files),
    });
    props.onFilesListChange?.(store.newFiles);
  };

  const onFileRemove = (file: UploaderFile) => {
    setStore({
      newFiles: store.newFiles.filter((f) => f.uid !== file.uid),
    });
    props.onFilesListChange?.(store.newFiles);
  };

  const entityFilesURL = createMemo(() => {
    if (props.entityFilePrefixURL) return props.entityFilePrefixURL;
    return undefined;
  });

  onMount(() => {
    props.onFilesListChange?.(store.newFiles);
  });

  const combinedFiles = createMemo(() => {
    return store.fileList.concat(store.newFiles as UploaderFile[]);
  });

  return {
    uploadProps: props,
    setFileList: setFileList,
    store,
    onFileRemove,
    entityFilesURL: entityFilesURL,
    fileRepository,
    combinedFiles: combinedFiles,
    setStore: setStore,
  };
});

export const Upload = (props: ParentProps<UploadProps>) => {
  return <UploadProvider {...props}>{props.children}</UploadProvider>;
};

export const UploadTrigger = (props: ParentProps<{ class?: string; onUnsupportedFileType?: () => void }>) => {
  const { uploadProps, setFileList } = useUpload();

  const onDropFiles = async (files: FileList) => {
    if (files.length === 0) {
      props.onUnsupportedFileType?.();
    }
    setFileList(files);
  };

  return (
    <DragAndDrop {...uploadProps} class={props.class} multiple={uploadProps.multiple ?? true} onDropFiles={onDropFiles}>
      {props.children}
    </DragAndDrop>
  );
};
