import { useParams } from '@solidjs/router';
import { useProperties } from '~/contexts/global';
import { AnnouncementProvider } from '~/contexts/local';
import AnnouncementsListView from '~/pages/announcements/list-announcements/AnnouncementsListView';

export const PropertyAnnouncement = () => {
  const params = useParams();
  const { current } = useProperties();

  return (
    <AnnouncementProvider>
      <div class="flex w-full gap-5 px-8 text-sm">
        <AnnouncementsListView
          filter={{ propertyIds: [current()?.id || params.propertyId] }}
          newAnnouncementsParams={{
            propertyId: current()?.id || params.propertyId,
            targetType: 'lease',
            selectionType: 'property',
            portfolioId: current()?.portfolioId || params.portfolioId,
            name: current()?.displayName || '',
          }}
        />
      </div>
    </AnnouncementProvider>
  );
};
