import { For } from 'solid-js';
import { PieChart } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import Card from '~/pages/dashboard/components/Card';
import type { Component } from 'solid-js';

type ExpiringLeasesProps = {
  class?: string;
  data: { name: string; value: number; color: string }[];
};

const ExpiringLeases: Component<ExpiringLeasesProps> = (props) => {
  const { t } = useLocalization();

  return (
    <Card class={props.class} title={t('Expiring leases')} contentClass="my-6 h-full">
      <div class="flex size-full items-center justify-center">
        <div class="flex w-full flex-col items-center justify-between px-4 sm:flex-row sm:items-center sm:px-9">
          <div class="w-[99px] xs:w-[156px] sm:shrink-0">
            <PieChart data={props.data ?? []} />
          </div>

          <div class="mt-8 flex w-full flex-row flex-wrap justify-center gap-4 sm:ml-8 sm:mt-0 sm:flex-col">
            <For each={props.data}>
              {(item) => {
                return (
                  <div class="flex flex-col items-center">
                    <span class="text-base font-semibold text-text-level01">{item.value}</span>
                    <div class="flex items-center">
                      <span class="size-3 rounded" style={{ background: item.color }} />
                      <span class="ml-2 text-xs text-text-level03">{item.name}</span>
                    </div>
                  </div>
                );
              }}
            </For>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ExpiringLeases;
