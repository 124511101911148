import { UseCase } from '~/pdfsigner/usecases/useCase';
import { reportRepository } from '~/repositories/reportRepository';
import { BatchGetPortfoliosUseCase } from './batchGetPortfoliosUseCase';
import { BatchGetPropertiesUseCase } from './batchGetPropertiesUseCase';
import { GetCompanyInfoUseCase } from './getCompanyInfoUseCase';
import type { BalanceSheetReportFilter } from '~/repositories/reportRepository';
import type { BalanceSheetReportDataDto } from '~/swagger/Api';

export class GetBalanceSheetReportUseCase extends UseCase {
  public async runLogic(filters?: BalanceSheetReportFilter) {
    const promises: Promise<BalanceSheetReportDataDto | void>[] = [];
    if (!this.state.companyInfo) {
      promises.push(new GetCompanyInfoUseCase().execute());
    }
    promises.push(new BatchGetPortfoliosUseCase().execute(filters?.portfolioIds));
    promises.push(new BatchGetPropertiesUseCase().execute(filters?.propertyIds));
    await Promise.all(promises);
    const report = await reportRepository.getBalanceSheetReport(filters);
    this.state.reports.balanceSheet = report;
  }
}
