import { createMagicDoorContext } from '~/contexts/utils';
import { NotesRepository } from '~/repositories/notesRepository';
import { createTriggerResource, createMutation } from '~/utils/resource';
import type { NotesFilter, AddNote, NoteEntity, UpdateNote } from '~/repositories/notesRepository';

const repo = new NotesRepository();
export const [NotesProvider, useNotes] = createMagicDoorContext('Notes', () => {
  const [filtered, setFilter, filteredActions] = createTriggerResource((filter: NotesFilter) => repo.getNoteLists(filter));

  const addNote = createMutation(async (model: AddNote) => {
    const notes = await repo.addNote(model);
    filteredActions.refetch();
    return notes;
  });
  const updateNote = createMutation(async (model: UpdateNote) => {
    const updated = await repo.editNote(model);
    filteredActions.refetch();
    return updated;
  });
  const deleteNote = createMutation(async (model: NoteEntity) => {
    await repo.deleteNote(model);
    filteredActions.refetch();
  });
  const getNote = createMutation(async (model: NoteEntity) => {
    const note = await repo.getNote(model);
    return note;
  });

  return {
    filtered,
    setFilter: setFilter,
    addNote,
    updateNote,
    deleteNote,
    getNote,
  };
});
