import { useNavigate, useParams } from '@solidjs/router';
import { Show } from 'solid-js';
import { Button } from '~/components/common/Buttons';
import TextOverflow from '~/components/common/TextOverflow';
import { ListView } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useViolations } from '~/contexts/local';
import { dateFormat } from '~/utils/date';
import { currency } from '~/utils/number';

export const LeaseBillsTab = () => {
  const { violation } = useViolations();
  const { t } = useLocalization();
  const navigate = useNavigate();
  const params = useParams<{ violationId: string }>();

  const handleAddLeaseBill = () => {
    navigate(`/associations/violations/${params.violationId}/lease-bills/add`);
  };

  return (
    <div class="space-y-5">
      <ListView
        titleActions={
          <Show when={violation()?.leaseId}>
            <Button onClick={handleAddLeaseBill}>{t('Add lease bill')}</Button>
          </Show>
        }
        skeletonSize={10}
        title={t('Lease bills')}
        page={1}
        totalPages={1}
        pageSize={10}
        data={violation()?.leaseBills}
        loading={violation.loading}
        columns={[
          {
            title: t('Due date'),
            key: 'dueDate',
            render: (item) => <div>{dateFormat('', item.dueDate)}</div>,
          },
          {
            title: t('Memo'),
            render: (item) => (
              <div>
                <Show when={item.memo || item.lineItems[0].description} fallback={<span class="text-xs text-text-level03">--</span>}>
                  <TextOverflow text={(item.memo || item.lineItems[0].description) ?? ''} />
                </Show>
              </div>
            ),
          },
          //TODO： WAITING FOR API
          // {
          //   title: t('Status'),
          //   render: (item) => <div>{item.status}</div>,
          // },
          {
            title: t('Due'),
            render: (item) => <div>{currency(item.due)}</div>,
          },
          {
            title: t('Paid'),
            render: (item) => <div>{currency(item.paid)}</div>,
          },
          {
            title: t('Total amount'),
            render: (item) => <div>{currency(item.totalAmount)}</div>,
          },
        ]}
      />
    </div>
  );
};
