import { Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import IconWarning from '~/assets/images/common/linear-warning.svg?component-solid';
import IconArrowDown from '~/assets/images/lease-template/arrowDown.svg';
import IconAutofillFiled from '~/assets/images/lease-template/autofill.svg?component-solid';
import IconCheckBoxFiled from '~/assets/images/lease-template/checkBoxFiled.svg?component-solid';
import IconDateField from '~/assets/images/lease-template/dateFiled.svg?component-solid';
import IconInitialField from '~/assets/images/lease-template/initialFeild.svg?component-solid';
import IconSignatureField from '~/assets/images/lease-template/signatureField.svg?component-solid';
import IconTextFiled from '~/assets/images/lease-template/textFiled.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import LabeledSelect from '~/components/common/Inputs/LabeledSelect';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { CurrentAnnotationPresenter } from '~/pdfsigner/presenters/CurrentAnnotationPresenter';
import { ToolbarPresenter } from '~/pdfsigner/presenters/ToolbarPresenter';
import { FontSizeSelector } from '~/pdfsigner/ui/components/toolbar/FontSizeSelector';
import { DropdownWithTree } from '~/pdfsigner/ui/components/toolbar/TreeDropdown';
import { ChangeFontSizeUseCase } from '~/pdfsigner/usecases/changeFontSizeUseCase';
import { DeleteAnnotationUseCase } from '~/pdfsigner/usecases/deleteAnnotationUseCase';
import { SetAnnotationDataPathKeyUseCase } from '~/pdfsigner/usecases/setAnnotationDataPathKeyUseCase';
import { SetAnnotationTextUseCase } from '~/pdfsigner/usecases/setAnnotationTextUseCase';
import type { Component } from 'solid-js';
import type { DataPathNode } from '~/pdfsigner/usecases/types/dataPathNode';

interface LeaseTemplateToolbarHeaderProps {
  pdfSize?: string;
  onChangePdfSize: (val: any) => void;
}
export const LeaseTemplateToolbarHeader: Component<LeaseTemplateToolbarHeaderProps> = (props) => {
  const { t } = useLocalization();

  const { model: currentAnnotation } = usePresenter(CurrentAnnotationPresenter);
  const { execute: changeFontSize } = useUseCase(ChangeFontSizeUseCase);
  const { model: toolbar } = usePresenter(ToolbarPresenter);
  const { execute: deleteAnnotation } = useUseCase(DeleteAnnotationUseCase);
  const { execute: setText } = useUseCase(SetAnnotationTextUseCase);
  const { execute: setDataPathKey } = useUseCase(SetAnnotationDataPathKeyUseCase);

  const pdfSizeOptions = [
    {
      label: '25%',
      value: 'w-1/4',
    },
    {
      label: '50%',
      value: 'w-1/2',
    },
    {
      label: '75%',
      value: 'w-9/12',
    },
    {
      label: '100%',
      value: 'w-full',
    },
  ];

  const annotations = {
    signature: { title: t('Signature'), icon: IconSignatureField },
    initials: { title: t('Initials'), icon: IconInitialField },
    dateTime: { title: t('Date'), icon: IconDateField },
    text: { title: t('Text'), icon: IconTextFiled },
    autofill: { title: t('Autofill'), icon: IconAutofillFiled },
    flag: { title: t('Checkmark'), icon: IconCheckBoxFiled },
  };

  const handleAnnotationRemove = async () => {
    await deleteAnnotation({ id: currentAnnotation()?.selectedAnnotation?.id });
  };

  const onChangeSelectedNode = (node: DataPathNode) => {
    setText({ id: currentAnnotation()?.selectedAnnotation?.id, value: node.name });
    setDataPathKey({
      id: currentAnnotation()?.selectedAnnotation?.id,
      value: node.key,
    });
  };

  return (
    <div class="flex h-[56px] min-h-[56px]  items-center justify-between bg-white px-4">
      <div class="flex items-center gap-2">
        <Show when={currentAnnotation()?.selectedAnnotationType || currentAnnotation()?.selectedAnnotation}>
          <div class="flex items-center gap-1.5 text-sm text-essential-colour">
            <Dynamic
              component={
                annotations[
                  (currentAnnotation()?.selectedAnnotationType || currentAnnotation()?.selectedAnnotation?.type) as keyof typeof annotations
                ]?.icon
              }
            />
            <span>
              {
                annotations[
                  (currentAnnotation()?.selectedAnnotationType || currentAnnotation()?.selectedAnnotation?.type) as keyof typeof annotations
                ]?.title
              }
            </span>
          </div>
        </Show>
        <Show
          when={currentAnnotation()?.selectedAnnotationType}
          fallback={
            <div class="flex items-center gap-1">
              <IconWarning />
              <span class="text-sm text-warning">{t('Please select a annotation type to get started')}</span>
            </div>
          }>
          <div class="flex items-center gap-1">
            <IconWarning />
            <span class="text-sm text-warning">{t('Please click on the screen to add your annotation')}</span>
          </div>
        </Show>
        <Show when={currentAnnotation()?.selectedAnnotation}>
          <Show when={currentAnnotation()?.selectedAnnotation?.type === 'autofill'}>
            <DropdownWithTree
              data={toolbar()?.dataPaths}
              selectedNode={toolbar()?.selectedNode}
              enabled={true}
              onSelect={onChangeSelectedNode}
              triggerElement={
                <div class="flex cursor-pointer items-center rounded-lg border border-input-border pl-4 text-text-level02 transition-all hover:border-primary">
                  <span class="text-sm font-medium">{toolbar()?.selectedNode?.name ?? t('Please select')}</span>
                  <div class="inline-flex items-center justify-center p-2.5">
                    <img src={IconArrowDown} />
                  </div>
                </div>
              }
            />
          </Show>

          <FontSizeSelector options={toolbar()?.fontSizeOptions} value={toolbar()?.selectedFontSize} onInput={changeFontSize} />
          <Button variant="outlined" color="warning" onClick={handleAnnotationRemove}>
            {t('Remove')}
          </Button>
        </Show>
      </div>
      <LabeledSelect
        selectClass="bg-white"
        class="w-24"
        labelClass="capitalize text-text-level03"
        options={pdfSizeOptions}
        value={props.pdfSize}
        onInput={props.onChangePdfSize}
      />
    </div>
  );
};
